import { httpClient } from '@raizen/client-auth';
import { GetCertificateRequestsDto } from '../types/queryParams';
import { GetCertificateRequestsResponse } from '@raizen/types';

export const getCertRequestsPath = '/certificate-requests';

export const getCertRequests = async (params: GetCertificateRequestsDto) => {
  const { data } = await httpClient.get<GetCertificateRequestsResponse>(
    getCertRequestsPath,
    { params },
  );

  return data;
};
