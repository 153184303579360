import { useUser } from '../../contexts/UserContext';
import { CERTIFICATE_REQUEST_STATUS } from '@raizen/db';
import { formatDate } from '@raizen/common-utils';
import { Flex, Show, Stack, Text } from '@chakra-ui/react';
import {
  greenFactorBadgeColors,
  greenFactorRibbons,
} from '@raizen/frontend-utils';
import { useTranslation } from '@raizen/react-hooks';
import { LocaleKeys } from '@raizen/translations';
import { Badge, Card } from '@raizen/ui';
import {
  CertificateDataSerializer,
  CertificateRequestSerializer,
} from '@raizen/serializers';
import { PERFORM_ACTION } from '@raizen/casl-authorization';
import { CertificateAlerts } from './Alerts';
import { GeneralInformationSection } from './Sections/GeneralInformationSection';
import { BlockchainInformationSection } from './Sections/BlockchainInformationSection';
import { ProductInformationSection } from './Sections/ProductInformationSection';
import { FarmInformationSection } from './Sections/FarmInformationSection';

interface Props {
  certificateRequest: CertificateRequestSerializer;
  certificateData: CertificateDataSerializer;
}
export const CertificateRequestCard = ({
  certificateRequest,
  certificateData,
}: Props) => {
  const { t } = useTranslation();
  const { ability } = useUser();
  const { status, companyName, userName, accessKey } = certificateRequest;

  const isCompleteStatus = status === CERTIFICATE_REQUEST_STATUS.COMPLETED;
  const isNotRequestedStatus =
    status === CERTIFICATE_REQUEST_STATUS.NOT_REQUESTED;
  const shouldShowFullInformation = isCompleteStatus || isNotRequestedStatus;
  const shouldRenderAlertsComponent = !isNotRequestedStatus;

  const {
    greenFactor,
    productType,
    farmProductionQuantity,
    productQuantityUnit,
    productionDate,
    bioparkName,
    fiscalInvoiceNumber,
  } = certificateData ?? {};

  const productionDateFormatted = productionDate
    ? formatDate(Number(productionDate))
    : null;

  const canReadCompleteCertificate = ability?.can(
    PERFORM_ACTION.ReadType.Complete,
    'Certificate',
  );

  return (
    <Flex
      flexDir={'column'}
      w="full"
      maxW={{ base: 'auto', md: 'auto', lg: '800px' }}
      gap={8}
    >
      {shouldRenderAlertsComponent ? (
        <CertificateAlerts certificateRequest={certificateRequest} />
      ) : null}
      <Card
        p={8}
        className="certificate-request-detail-container remove-py-for-print-view"
      >
        {shouldShowFullInformation && (
          <Flex alignItems={'start'} mb={8} gap={4}>
            <Flex flexDir={'column'} gap={2}>
              <Flex
                gap={'2'}
                flexDir={{
                  base: 'column',
                  md: 'column',
                  lg: 'row',
                }}
              >
                <Text size={'xl'} variant={'medium'}>
                  {t('certificateRequestDetails.heading')}
                </Text>
                {!!greenFactor && canReadCompleteCertificate && (
                  <Badge colorScheme={greenFactorBadgeColors[greenFactor]}>
                    {t(`common.certifiedBadge.${greenFactor}`)}
                  </Badge>
                )}
              </Flex>
              {canReadCompleteCertificate && (
                <Text>
                  {t('certificateRequestDetails.certifyText')
                    .replace('{{companyName}}', companyName ?? '')
                    .replace(
                      '{{quantity}}',
                      farmProductionQuantity
                        ? farmProductionQuantity.toString()
                        : '',
                    )
                    .replace(
                      '{{quantity_unit}}',
                      farmProductionQuantity ? productQuantityUnit || '' : '',
                    )
                    .replace(
                      '{{productType}}',
                      t(`productType.${productType}` as LocaleKeys),
                    )
                    .replace('{{invoiceNumber}}', fiscalInvoiceNumber ?? '')
                    .replace('{{date}}', productionDateFormatted ?? '')
                    .replace('{{biopark}}', bioparkName ?? '')
                    .replace(
                      '{{greenFactor}}',
                      t(`greenFactor.${greenFactor}` as LocaleKeys),
                    )}
                </Text>
              )}
            </Flex>
            {greenFactor && canReadCompleteCertificate && (
              <Show above="md">
                <Flex>{greenFactorRibbons[greenFactor]}</Flex>
              </Show>
            )}
          </Flex>
        )}

        <Stack gap={12}>
          <GeneralInformationSection
            companyName={companyName}
            userName={userName}
            invoiceAccessKey={accessKey}
            certificateData={certificateData}
          />

          <BlockchainInformationSection certificateData={certificateData} />

          <ProductInformationSection certificateData={certificateData} />

          <FarmInformationSection certificateData={certificateData} />
        </Stack>
      </Card>
    </Flex>
  );
};
