import { IconButton, Tooltip } from '@chakra-ui/react';
import { ReactElement } from 'react';

interface Props {
  ariaLabel: string;
  icon: ReactElement;
  onClick: () => void;
  tooltip?: string;
}

export const TableActionsButton = ({
  ariaLabel,
  icon,
  onClick,
  tooltip,
}: Props) => {
  return (
    <Tooltip label={tooltip} hasArrow={true}>
      <IconButton
        aria-label={ariaLabel}
        icon={icon}
        variant={'link'}
        onClick={onClick}
        minW={'fit-content'}
      />
    </Tooltip>
  );
};
