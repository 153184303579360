import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { SearchInput, TableWrapper, Table, Pagination } from '@raizen/ui';
import { TableInstance, useTable, usePagination } from 'react-table';
import { Box, Button, Flex, Select } from '@chakra-ui/react';
import { useColumns } from './columns';
import { useTranslation } from '@raizen/react-hooks';
import { ListCertificateRequestsSerializer } from '@raizen/serializers';
import { getNoRequestedCertRequests } from '../../../services/certificateRequests/getNotRequestedCertificateRequestsData';
import {
  CertificateRequest,
  GreenFactorType,
  greenFactorTypes,
} from '@raizen/db';
import { createSearchParams, useNavigate } from 'react-router-dom';

export function NotRequestedCertificatesTable(): React.ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [greenFactor, setGreenFactorFilter] = useState<
    GreenFactorType | undefined
  >(undefined);

  const [searchTerm, setSearchTerm] = useState('');

  const [page, setPage] = useState(1);

  const pageSize = 50;

  const { data, isLoading } = useQuery(
    [
      'not-requested-cerificate-requests',
      searchTerm,
      greenFactor,
      page,
      pageSize,
    ],
    () =>
      getNoRequestedCertRequests({
        searchTerm,
        greenFactor,
        page,
        pageSize,
      }),
  );

  const { columns } = useColumns();

  const tableData = React.useMemo(
    () => data?.data || [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(data)],
  );

  const tableInstance = useTable<ListCertificateRequestsSerializer>(
    {
      columns: columns as any,
      data: tableData as any,
      initialState: { pageSize } as object,
    },
    usePagination,
  );

  const handleFilterChange = (e: any) => {
    const value = e.target.value || '';
    setSearchTerm(value);
    setPage(1);
  };

  const handleRowClick = (rowData: CertificateRequest) => {
    navigate({
      pathname: `/certificates/${rowData.accessKey}`,
      search: `?${createSearchParams({
        isNotRequested: 'true', // We use `NOT` here so it defaults as isRequested in all other routes
      })}`,
    });
  };

  const handleGreenFactorFilterChange = (e: any) => {
    const value = e.target.value || undefined;
    setGreenFactorFilter(value);
    setPage(1);
  };

  const handleFiltersReset = () => {
    setSearchTerm('');
    setGreenFactorFilter(undefined);
    setPage(1);
  };

  return (
    <>
      <TableWrapper
        searchComponent={
          <SearchInput
            value={searchTerm}
            onChange={handleFilterChange}
            placeholder={t('certificateRequests.table.filters.invoice')}
          />
        }
        quickFilters={
          <Flex
            gap={'16px'}
            flexDir={{ base: 'column', md: 'column', lg: 'row' }}
          >
            <Select
              minWidth={'fit-content'}
              placeholder={t(`certificateRequests.table.columns.greenFactor`)}
              textColor={'gray.500'}
              onChange={handleGreenFactorFilterChange}
              value={greenFactor ?? ''}
            >
              {greenFactorTypes.map((gf, key) => (
                <option value={gf} key={key}>
                  {t(`greenFactor.${gf}`)}
                </option>
              ))}
            </Select>

            <Button
              variant={'link'}
              minWidth={'fit-content'}
              size={'sm'}
              onClick={handleFiltersReset}
            >
              {t(`common.resetFilters`)}
            </Button>
          </Flex>
        }
      >
        <Table
          tableInstance={tableInstance as TableInstance<any>}
          isLoading={isLoading}
          emptyStateContent={{
            title: t('certificateRequests.table.emptyState.title'),
            description: t('certificateRequests.table.emptyState.description'),
          }}
          handleRowClick={handleRowClick}
        />
      </TableWrapper>
      <Box my="3">
        <Pagination
          currentPage={data?.paginationData?.currentPage!}
          totalPageCount={data?.paginationData?.totalPages!}
          previousButtonLabel={t('common.pagination.previous')}
          nextButtonLabel={t('common.pagination.next')}
          onPageChange={setPage}
        />
      </Box>
    </>
  );
}
