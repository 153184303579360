import { CertificateDataSerializer } from '@raizen/serializers';
import { CertificateRequestDetailRow } from '../CertificateRequestDetailsListSection';
import { useRowsMap } from '../useRowsMap';
import { useTranslation } from '@raizen/react-hooks';
import { List } from './List';
import { Section } from './Section';

interface Props {
  companyName: string;
  userName?: string;
  invoiceAccessKey: string;
  certificateData?: CertificateDataSerializer;
}

export const GeneralInformationSection = ({
  companyName,
  userName,
  invoiceAccessKey,
  certificateData,
}: Props) => {
  const { t } = useTranslation();
  const rowsMap = useRowsMap();

  const rows = [
    rowsMap.accessKey(invoiceAccessKey),
    rowsMap.invoiceNumber(certificateData?.fiscalInvoiceNumber),
    rowsMap.invoiceDate(certificateData?.fiscalInvoiceDeliveryDate),
    rowsMap.issuedAt(certificateData?.certificate?.createdAt),
    rowsMap.companyName(companyName),
    rowsMap.productType(certificateData?.productType),
    rowsMap.requesterFullName(userName),
  ].filter((e) => e !== null) as CertificateRequestDetailRow[];

  return rows.length ? (
    <Section title={t('common.generalInformation')}>
      <List rows={rows} />
    </Section>
  ) : null;
};
