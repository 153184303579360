import { useToast } from '@chakra-ui/react';

interface ToastParams {
  title: string;
  description: string;
  duration?: number;
}

const useToastsHook = () => {
  const toast = useToast();

  const defaultDuration = 3000;
  const defaultPosition = 'top-right';

  const showSuccessToast = ({
    title,
    description,
    duration = defaultDuration,
  }: ToastParams) => {
    toast({
      title,
      description,
      status: 'success',
      duration,
      isClosable: true,
      position: defaultPosition,
    });
  };

  const showErrorToast = ({
    title,
    description,
    duration = defaultDuration,
  }: ToastParams) => {
    toast({
      title,
      description,
      status: 'error',
      duration,
      isClosable: true,
      position: defaultPosition,
    });
  };

  const showWarningToast = ({
    title,
    description,
    duration = defaultDuration,
  }: ToastParams) => {
    toast({
      title,
      description,
      status: 'warning',
      duration,
      isClosable: true,
      position: defaultPosition,
    });
  };

  const showInfoToast = ({
    title,
    description,
    duration = defaultDuration,
  }: ToastParams) => {
    toast({
      title,
      description,
      status: 'info',
      duration,
      isClosable: true,
      position: defaultPosition,
    });
  };

  return {
    showErrorToast,
    showSuccessToast,
    showWarningToast,
    showInfoToast,
  };
};

export default useToastsHook;
