import { Flex } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

export const TableActions = ({ children }: Props) => {
  return (
    <Flex justifyContent={{ base: 'start', lg: 'end' }} gap={3}>
      {children}
    </Flex>
  );
};
