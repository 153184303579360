import { Header } from '../../components/Header/Header';
import { PageContainer } from '../../components/PageContainer/PageContainer';
import { useTranslation } from '@raizen/react-hooks';
import { SharingRequestsTable } from '../../components/SharingRequests/Table/SharingRequestsTable';

export const SharingRequests = () => {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <Header title={t('sharingRequests.title')} />
      <SharingRequestsTable />
    </PageContainer>
  );
};
