var __create = Object.create;
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};

// src/index.ts
var src_exports = {};
__export(src_exports, {
  BLOCKCHAIN_STATUS_VALUES: () => BLOCKCHAIN_STATUS_VALUES,
  CERTIFICATE_REJECTION_REASON: () => CERTIFICATE_REJECTION_REASON,
  CERTIFICATE_REQUEST_STATUS: () => CERTIFICATE_REQUEST_STATUS,
  CERTIFICATE_REQUEST_STATUS_FILTER_ADMIN: () => CERTIFICATE_REQUEST_STATUS_FILTER_ADMIN,
  CERTIFICATE_REQUEST_STATUS_FILTER_BASE: () => CERTIFICATE_REQUEST_STATUS_FILTER_BASE,
  CERTIFICATE_SHARE_REQUEST_STATUS: () => CERTIFICATE_SHARE_REQUEST_STATUS,
  CERTIFICATE_STATUS: () => CERTIFICATE_STATUS,
  COMPANY_TYPES: () => COMPANY_TYPES,
  Certificate: () => Certificate,
  CertificateData: () => CertificateData,
  CertificateDataProductionDetail: () => CertificateDataProductionDetail,
  CertificateRejectionReasonTypes: () => CertificateRejectionReasonTypes,
  CertificateRequest: () => CertificateRequest,
  CertificateRequestStatusFilterAdminTypes: () => CertificateRequestStatusFilterAdminTypes,
  CertificateRequestStatusFilterBaseTypes: () => CertificateRequestStatusFilterBaseTypes,
  CertificateRequestStatusTypes: () => CertificateRequestStatusTypes,
  CertificateShareRequest: () => CertificateShareRequest,
  CertificateStatusTypes: () => CertificateStatusTypes,
  Company: () => Company,
  CompanySharedCertificate: () => CompanySharedCertificate,
  DecimalColumn: () => DecimalColumn,
  DecimalTransformer: () => DecimalTransformer,
  Entities: () => entities_exports,
  GREEN_FACTOR_VALUES: () => GREEN_FACTOR_VALUES,
  RAIZEN_METADATA_PRODUCTS: () => RAIZEN_METADATA_PRODUCTS,
  RaizenMetadata: () => RaizenMetadata,
  RaizenMetadataProductTypes: () => RaizenMetadataProductTypes,
  USER_ROLES: () => USER_ROLES,
  USER_STATUS: () => USER_STATUS,
  USE_TYPES: () => USE_TYPES,
  UseTypes: () => UseTypes,
  User: () => User,
  VIEWER_USER_ROLES: () => VIEWER_USER_ROLES,
  blockchainStatusTypes: () => blockchainStatusTypes,
  certificateShareRequestStatusTypes: () => certificateShareRequestStatusTypes,
  companyTypes: () => companyTypes,
  greenFactorTypes: () => greenFactorTypes,
  userRoles: () => userRoles,
  userStatus: () => userStatus,
  viewerUserRoles: () => viewerUserRoles
});
module.exports = __toCommonJS(src_exports);

// src/entities/index.ts
var entities_exports = {};
__export(entities_exports, {
  Certificate: () => Certificate,
  CertificateData: () => CertificateData,
  CertificateDataProductionDetail: () => CertificateDataProductionDetail,
  CertificateRequest: () => CertificateRequest,
  CertificateShareRequest: () => CertificateShareRequest,
  Company: () => Company,
  CompanySharedCertificate: () => CompanySharedCertificate,
  RaizenMetadata: () => RaizenMetadata,
  User: () => User
});

// src/entities/certificates/certificate.entity.ts
var import_typeorm10 = require("typeorm");

// src/entities/certificates/certificate-data.entity.ts
var import_typeorm9 = require("typeorm");

// src/entities/shared/entity-with-timestamps.ts
var import_typeorm = require("typeorm");
function _ts_decorate(decorators, target, key, desc) {
  var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function")
    r = Reflect.decorate(decorators, target, key, desc);
  else
    for (var i = decorators.length - 1; i >= 0; i--)
      if (d = decorators[i])
        r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
}
__name(_ts_decorate, "_ts_decorate");
function _ts_metadata(k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function")
    return Reflect.metadata(k, v);
}
__name(_ts_metadata, "_ts_metadata");
var getUnixTimestamp = /* @__PURE__ */ __name(() => Math.floor((/* @__PURE__ */ new Date()).getTime() / 1e3), "getUnixTimestamp");
var EntityWithTimestamps = class {
  constructor() {
    __publicField(this, "createdAt");
    __publicField(this, "updatedAt");
  }
  setCreatedAt() {
    const timestamp = getUnixTimestamp();
    this.createdAt = timestamp;
    this.updatedAt = timestamp;
  }
  setUpdatedAt() {
    this.updatedAt = getUnixTimestamp();
  }
};
__name(EntityWithTimestamps, "EntityWithTimestamps");
_ts_decorate([
  (0, import_typeorm.Column)({
    name: "created_at",
    type: "bigint"
  }),
  _ts_metadata("design:type", Number)
], EntityWithTimestamps.prototype, "createdAt", void 0);
_ts_decorate([
  (0, import_typeorm.Column)({
    name: "updated_at",
    type: "bigint"
  }),
  _ts_metadata("design:type", Number)
], EntityWithTimestamps.prototype, "updatedAt", void 0);
_ts_decorate([
  (0, import_typeorm.BeforeInsert)(),
  _ts_metadata("design:type", Function),
  _ts_metadata("design:paramtypes", [])
], EntityWithTimestamps.prototype, "setCreatedAt", null);
_ts_decorate([
  (0, import_typeorm.BeforeUpdate)(),
  _ts_metadata("design:type", Function),
  _ts_metadata("design:paramtypes", [])
], EntityWithTimestamps.prototype, "setUpdatedAt", null);

// src/decorators/DecimalColumn.ts
var import_decimal = __toESM(require("decimal.js"));
var import_typeorm2 = require("typeorm");
var DecimalTransformer = class {
  constructor(precision, defaultValue) {
    __publicField(this, "_precision");
    __publicField(this, "_default");
    this._precision = precision;
    this._default = defaultValue;
  }
  /**
  * Used to transform to decimal (as string) when writing to the DB.
  */
  to(decimal) {
    const Clone = import_decimal.default.clone({
      precision: this._precision
    });
    if (decimal !== void 0 && decimal !== null) {
      const value = new Clone(decimal);
      return value.toString();
    }
    if (this._default !== void 0 && this._default !== null) {
      const value = new Clone(this._default);
      return value.toString();
    }
    return null;
  }
  /**
  * Used to transform to number when reading from DB.
  */
  from(decimal) {
    if (decimal === void 0 || decimal === null)
      return null;
    const value = new import_decimal.default(decimal);
    return value;
  }
};
__name(DecimalTransformer, "DecimalTransformer");
var DecimalColumn = /* @__PURE__ */ __name((params) => {
  return (0, import_typeorm2.Column)(__spreadValues({
    type: "decimal",
    transformer: new DecimalTransformer(params.precision, params.default)
  }, params));
}, "DecimalColumn");

// src/entities/certificates/certificate-data.entity.ts
var import_decimal3 = require("decimal.js");

// src/enums/certificates/certificate-request-status.enum.ts
var CERTIFICATE_REQUEST_STATUS = {
  NOT_REQUESTED: "NOT_REQUESTED",
  CREATED: "CREATED",
  PENDING: "PENDING",
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
  DENIED: "DENIED",
  FAILED_IN_BLOCKCHAIN: "FAILED_IN_BLOCKCHAIN",
  NOT_FOUND: "NOT_FOUND"
};
var CertificateRequestStatusTypes = Object.values(CERTIFICATE_REQUEST_STATUS);
var CERTIFICATE_REQUEST_STATUS_FILTER_BASE = {
  PENDING: "PENDING",
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
  DENIED: "DENIED",
  FAILED_IN_BLOCKCHAIN: "FAILED_IN_BLOCKCHAIN"
};
var CertificateRequestStatusFilterBaseTypes = Object.values(CERTIFICATE_REQUEST_STATUS_FILTER_BASE);
var CERTIFICATE_REQUEST_STATUS_FILTER_ADMIN = __spreadProps(__spreadValues({}, CERTIFICATE_REQUEST_STATUS_FILTER_BASE), {
  NOT_FOUND: "NOT_FOUND"
});
var CertificateRequestStatusFilterAdminTypes = Object.values(CERTIFICATE_REQUEST_STATUS_FILTER_ADMIN);

// src/enums/certificates/certificate-status.enum.ts
var CERTIFICATE_STATUS = {
  VALID: "VALID",
  INVALID: "INVALID",
  EXPIRED: "EXPIRED"
};
var CertificateStatusTypes = Object.values(CERTIFICATE_STATUS);

// src/enums/certificates/raizen-metadata-products.enum.ts
var RAIZEN_METADATA_PRODUCTS = {
  ETHANOL: "ETHANOL",
  SUGAR: "SUGAR"
};
var RaizenMetadataProductTypes = Object.values(RAIZEN_METADATA_PRODUCTS);

// src/enums/certificates/use-types.enum.ts
var USE_TYPES = {
  REGULAR: "REGULAR",
  IRREGULAR: "IRREGULAR"
};
var UseTypes = Object.values(USE_TYPES);

// src/enums/certificates/certificate-data-green-factor.enum.ts
var GREEN_FACTOR_VALUES = {
  BRONZE: "BRONZE",
  SILVER: "SILVER",
  GOLD: "GOLD",
  STANDARD: "STANDARD"
};
var greenFactorTypes = Object.values(GREEN_FACTOR_VALUES);

// src/enums/certificates/certificate-rejection-reason.enum.ts
var CERTIFICATE_REJECTION_REASON = {
  INVOICE_DUPLICATION: "INVOICE_DUPLICATION",
  INVOICE_NOT_FOUND: "INVOICE_NOT_FOUND",
  OTHER: "OTHER"
};
var CertificateRejectionReasonTypes = Object.values(CERTIFICATE_REJECTION_REASON);

// src/enums/certificates/certificate-data-blockchain-status.enum.ts
var BLOCKCHAIN_STATUS_VALUES = {
  PENDING: "PENDING",
  PROCESSING: "PROCESSING",
  COMPLETED: "COMPLETED",
  FAILED: "FAILED"
};
var blockchainStatusTypes = Object.values(BLOCKCHAIN_STATUS_VALUES);

// src/enums/companies/company-types.enum.ts
var COMPANY_TYPES = {
  INTERNAL: "INTERNAL",
  EXTERNAL: "EXTERNAL"
};
var companyTypes = Object.values(COMPANY_TYPES);

// src/enums/companies/certificate-share-request-status.enum.ts
var CERTIFICATE_SHARE_REQUEST_STATUS = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  EXPIRED: "EXPIRED",
  REJECTED: "REJECTED",
  INVALID: "INVALID"
};
var certificateShareRequestStatusTypes = Object.values(CERTIFICATE_SHARE_REQUEST_STATUS);

// src/enums/users/user-roles.enum.ts
var VIEWER_USER_ROLES = {
  VIEWER_FULL: "VIEWER_FULL",
  VIEWER_RESTRICTED: "VIEWER_RESTRICTED"
};
var viewerUserRoles = Object.values(VIEWER_USER_ROLES);
var USER_ROLES = __spreadValues({
  ADMIN: "ADMIN",
  CLIENT: "CLIENT"
}, VIEWER_USER_ROLES);
var userRoles = Object.values(USER_ROLES);

// src/enums/users/user-status.enum.ts
var USER_STATUS = {
  INVITED: "INVITED",
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE"
};
var userStatus = Object.values(USER_STATUS);

// src/entities/certificates/certificate-request.entity.ts
var import_typeorm7 = require("typeorm");

// src/entities/users/user.entity.ts
var import_typeorm4 = require("typeorm");

// src/entities/companies/company.entity.ts
var import_typeorm3 = require("typeorm");
function _ts_decorate2(decorators, target, key, desc) {
  var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function")
    r = Reflect.decorate(decorators, target, key, desc);
  else
    for (var i = decorators.length - 1; i >= 0; i--)
      if (d = decorators[i])
        r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
}
__name(_ts_decorate2, "_ts_decorate");
function _ts_metadata2(k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function")
    return Reflect.metadata(k, v);
}
__name(_ts_metadata2, "_ts_metadata");
var Company = /* @__PURE__ */ __name(class Company2 extends EntityWithTimestamps {
  constructor() {
    super(...arguments);
    __publicField(this, "id");
    __publicField(this, "uuid");
    __publicField(this, "name");
    __publicField(this, "fiscalId");
    __publicField(this, "updatedByUserId");
    __publicField(this, "isActive");
    __publicField(this, "wallet");
    __publicField(this, "type");
    // ################ RELATIONS ################
    __publicField(this, "users");
    __publicField(this, "updatedByUser");
    __publicField(this, "certificates");
  }
}, "Company");
_ts_decorate2([
  (0, import_typeorm3.PrimaryGeneratedColumn)({
    type: "int",
    name: "id"
  }),
  _ts_metadata2("design:type", Number)
], Company.prototype, "id", void 0);
_ts_decorate2([
  (0, import_typeorm3.Column)("uuid", {
    name: "uuid",
    comment: "Company UUID."
  }),
  (0, import_typeorm3.Generated)("uuid"),
  _ts_metadata2("design:type", String)
], Company.prototype, "uuid", void 0);
_ts_decorate2([
  (0, import_typeorm3.Column)("varchar", {
    name: "name",
    length: 255,
    nullable: false,
    comment: "Company name."
  }),
  _ts_metadata2("design:type", String)
], Company.prototype, "name", void 0);
_ts_decorate2([
  (0, import_typeorm3.Column)("varchar", {
    name: "fiscalId",
    length: 50,
    nullable: false,
    comment: "Company fiscal number."
  }),
  _ts_metadata2("design:type", String)
], Company.prototype, "fiscalId", void 0);
_ts_decorate2([
  (0, import_typeorm3.Column)("int", {
    name: "updated_by_user_id",
    nullable: true,
    comment: "User ID responsible for the last update of the entity."
  }),
  _ts_metadata2("design:type", Number)
], Company.prototype, "updatedByUserId", void 0);
_ts_decorate2([
  (0, import_typeorm3.Column)("boolean", {
    name: "is_active",
    nullable: false,
    default: false,
    comment: "Is company active?"
  }),
  _ts_metadata2("design:type", Boolean)
], Company.prototype, "isActive", void 0);
_ts_decorate2([
  (0, import_typeorm3.Column)("varchar", {
    name: "wallet",
    length: 60,
    nullable: true,
    default: null,
    comment: "Blockchain wallet address. Nullable by default. Before approving the first certificate, it will check against Blockchain SDK to assign a wallet."
  }),
  _ts_metadata2("design:type", String)
], Company.prototype, "wallet", void 0);
_ts_decorate2([
  (0, import_typeorm3.Column)({
    type: "enum",
    name: "type",
    nullable: false,
    enum: COMPANY_TYPES,
    comment: "Defines whether the company is internal or external"
  }),
  _ts_metadata2("design:type", typeof CompanyType === "undefined" ? Object : CompanyType)
], Company.prototype, "type", void 0);
_ts_decorate2([
  (0, import_typeorm3.OneToMany)(() => User, (user) => user.company),
  _ts_metadata2("design:type", Array)
], Company.prototype, "users", void 0);
_ts_decorate2([
  (0, import_typeorm3.ManyToOne)(() => User, (user) => user.id, {
    onDelete: "SET NULL"
  }),
  (0, import_typeorm3.JoinColumn)([
    {
      name: "updated_by_user_id",
      referencedColumnName: "id"
    }
  ]),
  _ts_metadata2("design:type", typeof User === "undefined" ? Object : User)
], Company.prototype, "updatedByUser", void 0);
_ts_decorate2([
  (0, import_typeorm3.OneToMany)(() => Certificate, (certificate) => certificate.company),
  _ts_metadata2("design:type", Array)
], Company.prototype, "certificates", void 0);
Company = _ts_decorate2([
  (0, import_typeorm3.Unique)([
    "uuid"
  ]),
  (0, import_typeorm3.Entity)("companies")
], Company);

// src/entities/users/user.entity.ts
function _ts_decorate3(decorators, target, key, desc) {
  var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function")
    r = Reflect.decorate(decorators, target, key, desc);
  else
    for (var i = decorators.length - 1; i >= 0; i--)
      if (d = decorators[i])
        r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
}
__name(_ts_decorate3, "_ts_decorate");
function _ts_metadata3(k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function")
    return Reflect.metadata(k, v);
}
__name(_ts_metadata3, "_ts_metadata");
var User = /* @__PURE__ */ __name(class User2 extends EntityWithTimestamps {
  constructor() {
    super(...arguments);
    __publicField(this, "id");
    __publicField(this, "uuid");
    __publicField(this, "authUuid");
    __publicField(this, "firstName");
    __publicField(this, "lastName");
    __publicField(this, "email");
    __publicField(this, "companyId");
    __publicField(this, "updatedByUserId");
    __publicField(this, "lastLogin");
    __publicField(this, "termsAndConditionsAcceptedAt");
    __publicField(this, "versionTermsConditions");
    __publicField(this, "role");
    __publicField(this, "status");
    // ################ RELATIONS ################
    __publicField(this, "company");
    __publicField(this, "updatedByUser");
  }
}, "User");
_ts_decorate3([
  (0, import_typeorm4.PrimaryGeneratedColumn)({
    type: "int",
    name: "id"
  }),
  _ts_metadata3("design:type", Number)
], User.prototype, "id", void 0);
_ts_decorate3([
  (0, import_typeorm4.Column)("uuid", {
    name: "uuid",
    comment: "User UUID.",
    nullable: false
  }),
  (0, import_typeorm4.Generated)("uuid"),
  _ts_metadata3("design:type", String)
], User.prototype, "uuid", void 0);
_ts_decorate3([
  (0, import_typeorm4.Column)("uuid", {
    name: "auth_uuid",
    comment: "Cognito username.",
    nullable: false
  }),
  (0, import_typeorm4.Generated)("uuid"),
  _ts_metadata3("design:type", String)
], User.prototype, "authUuid", void 0);
_ts_decorate3([
  (0, import_typeorm4.Column)("varchar", {
    name: "first_name",
    length: 200,
    nullable: false,
    comment: "User's first name."
  }),
  _ts_metadata3("design:type", String)
], User.prototype, "firstName", void 0);
_ts_decorate3([
  (0, import_typeorm4.Column)("varchar", {
    name: "last_name",
    length: 200,
    nullable: false,
    comment: "User's last name."
  }),
  _ts_metadata3("design:type", String)
], User.prototype, "lastName", void 0);
_ts_decorate3([
  (0, import_typeorm4.Column)("varchar", {
    name: "email",
    length: 200,
    nullable: false,
    comment: "User's email."
  }),
  _ts_metadata3("design:type", String)
], User.prototype, "email", void 0);
_ts_decorate3([
  (0, import_typeorm4.Column)("int", {
    name: "company_id",
    nullable: false,
    comment: "User's Company ID."
  }),
  _ts_metadata3("design:type", Number)
], User.prototype, "companyId", void 0);
_ts_decorate3([
  (0, import_typeorm4.Column)("int", {
    name: "updated_by_user_id",
    nullable: true,
    comment: "User ID responsible for the last update of the entity."
  }),
  _ts_metadata3("design:type", Number)
], User.prototype, "updatedByUserId", void 0);
_ts_decorate3([
  (0, import_typeorm4.Column)({
    name: "last_login",
    type: "bigint",
    nullable: true,
    comment: "Timestamp in seconds of user's last login"
  }),
  _ts_metadata3("design:type", Number)
], User.prototype, "lastLogin", void 0);
_ts_decorate3([
  (0, import_typeorm4.Column)({
    name: "terms_and_conditions_accepted_at",
    type: "bigint",
    nullable: true,
    comment: "Timestamp in seconds of user's terms and conditions acceptance."
  }),
  _ts_metadata3("design:type", Number)
], User.prototype, "termsAndConditionsAcceptedAt", void 0);
_ts_decorate3([
  (0, import_typeorm4.Column)("varchar", {
    name: "version_terms_conditions",
    length: 200,
    nullable: true,
    comment: "Version of terms and conditions accepted by user."
  }),
  _ts_metadata3("design:type", String)
], User.prototype, "versionTermsConditions", void 0);
_ts_decorate3([
  (0, import_typeorm4.Column)({
    type: "enum",
    name: "role",
    nullable: true,
    enum: USER_ROLES,
    comment: "User role."
  }),
  _ts_metadata3("design:type", typeof UserRole === "undefined" ? Object : UserRole)
], User.prototype, "role", void 0);
_ts_decorate3([
  (0, import_typeorm4.Column)({
    type: "enum",
    name: "status",
    enum: USER_STATUS,
    comment: "User status.",
    default: USER_STATUS.INVITED
  }),
  _ts_metadata3("design:type", typeof UserStatus === "undefined" ? Object : UserStatus)
], User.prototype, "status", void 0);
_ts_decorate3([
  (0, import_typeorm4.ManyToOne)(() => Company, (company) => company.id, {
    onDelete: "RESTRICT"
  }),
  (0, import_typeorm4.JoinColumn)([
    {
      name: "company_id",
      referencedColumnName: "id"
    }
  ]),
  _ts_metadata3("design:type", typeof Company === "undefined" ? Object : Company)
], User.prototype, "company", void 0);
_ts_decorate3([
  (0, import_typeorm4.ManyToOne)(() => User, (user) => user.id, {
    onDelete: "SET NULL"
  }),
  (0, import_typeorm4.JoinColumn)([
    {
      name: "updated_by_user_id",
      referencedColumnName: "id"
    }
  ]),
  _ts_metadata3("design:type", Object)
], User.prototype, "updatedByUser", void 0);
User = _ts_decorate3([
  (0, import_typeorm4.Unique)([
    "uuid"
  ]),
  (0, import_typeorm4.Unique)([
    "email",
    "authUuid"
  ]),
  (0, import_typeorm4.Entity)("users")
], User);

// src/entities/certificates/certificate-share-request.entity.ts
var import_typeorm6 = require("typeorm");

// src/entities/certificates/company-shared-certificate.entity.ts
var import_typeorm5 = require("typeorm");
function _ts_decorate4(decorators, target, key, desc) {
  var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function")
    r = Reflect.decorate(decorators, target, key, desc);
  else
    for (var i = decorators.length - 1; i >= 0; i--)
      if (d = decorators[i])
        r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
}
__name(_ts_decorate4, "_ts_decorate");
function _ts_metadata4(k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function")
    return Reflect.metadata(k, v);
}
__name(_ts_metadata4, "_ts_metadata");
var CompanySharedCertificate = /* @__PURE__ */ __name(class CompanySharedCertificate2 {
  constructor() {
    __publicField(this, "id");
    __publicField(this, "uuid");
    __publicField(this, "companyId");
    __publicField(this, "userId");
    __publicField(this, "certificateId");
    __publicField(this, "sharedByUserId");
    __publicField(this, "sharedAt");
    __publicField(this, "isActive");
    __publicField(this, "certificateShareRequestId");
    // ################ RELATIONS ################
    __publicField(this, "company");
    __publicField(this, "user");
    __publicField(this, "certificate");
    __publicField(this, "sharedByUser");
    __publicField(this, "certificateShareRequest");
  }
}, "CompanySharedCertificate");
_ts_decorate4([
  (0, import_typeorm5.PrimaryGeneratedColumn)({
    type: "int"
  }),
  _ts_metadata4("design:type", Number)
], CompanySharedCertificate.prototype, "id", void 0);
_ts_decorate4([
  (0, import_typeorm5.Column)("uuid", {
    name: "uuid",
    comment: "Certificate UUID."
  }),
  (0, import_typeorm5.Generated)("uuid"),
  _ts_metadata4("design:type", String)
], CompanySharedCertificate.prototype, "uuid", void 0);
_ts_decorate4([
  (0, import_typeorm5.Column)("int", {
    name: "company_id",
    nullable: false,
    comment: "Company that can access to specific certificate, the id is from companies table."
  }),
  _ts_metadata4("design:type", Number)
], CompanySharedCertificate.prototype, "companyId", void 0);
_ts_decorate4([
  (0, import_typeorm5.Column)("int", {
    name: "user_id",
    nullable: false,
    comment: "User ID that can access to specific certificate, the id is from users table."
  }),
  _ts_metadata4("design:type", Number)
], CompanySharedCertificate.prototype, "userId", void 0);
_ts_decorate4([
  (0, import_typeorm5.Column)("int", {
    name: "certificate_id",
    nullable: false,
    comment: "Certificate that is shared, the id is from certificates table."
  }),
  _ts_metadata4("design:type", Number)
], CompanySharedCertificate.prototype, "certificateId", void 0);
_ts_decorate4([
  (0, import_typeorm5.Column)("int", {
    name: "shared_by_user_id",
    nullable: false,
    comment: "User ID that gave access to specific certificate, the id is from users table."
  }),
  _ts_metadata4("design:type", Number)
], CompanySharedCertificate.prototype, "sharedByUserId", void 0);
_ts_decorate4([
  (0, import_typeorm5.Column)("bigint", {
    name: "shared_at",
    nullable: false,
    comment: "Sharing date."
  }),
  _ts_metadata4("design:type", Number)
], CompanySharedCertificate.prototype, "sharedAt", void 0);
_ts_decorate4([
  (0, import_typeorm5.Column)("boolean", {
    name: "is_active",
    nullable: false,
    default: true
  }),
  _ts_metadata4("design:type", Boolean)
], CompanySharedCertificate.prototype, "isActive", void 0);
_ts_decorate4([
  (0, import_typeorm5.Column)("int", {
    name: "certificate_share_request_id",
    nullable: false,
    comment: "Certificate share request that was approved."
  }),
  _ts_metadata4("design:type", Number)
], CompanySharedCertificate.prototype, "certificateShareRequestId", void 0);
_ts_decorate4([
  (0, import_typeorm5.ManyToOne)(() => Company, (company) => company.id, {
    onDelete: "CASCADE"
  }),
  (0, import_typeorm5.JoinColumn)([
    {
      name: "company_id",
      referencedColumnName: "id"
    }
  ]),
  _ts_metadata4("design:type", typeof Company === "undefined" ? Object : Company)
], CompanySharedCertificate.prototype, "company", void 0);
_ts_decorate4([
  (0, import_typeorm5.ManyToOne)(() => User, (user) => user.id, {
    onDelete: "CASCADE"
  }),
  (0, import_typeorm5.JoinColumn)([
    {
      name: "user_id",
      referencedColumnName: "id"
    }
  ]),
  _ts_metadata4("design:type", typeof User === "undefined" ? Object : User)
], CompanySharedCertificate.prototype, "user", void 0);
_ts_decorate4([
  (0, import_typeorm5.ManyToOne)(() => Certificate, (certificate) => certificate.id, {
    onDelete: "CASCADE"
  }),
  (0, import_typeorm5.JoinColumn)([
    {
      name: "certificate_id",
      referencedColumnName: "id"
    }
  ]),
  _ts_metadata4("design:type", typeof Certificate === "undefined" ? Object : Certificate)
], CompanySharedCertificate.prototype, "certificate", void 0);
_ts_decorate4([
  (0, import_typeorm5.ManyToOne)(() => User, (user) => user.id, {
    onDelete: "CASCADE"
  }),
  (0, import_typeorm5.JoinColumn)([
    {
      name: "shared_by_user_id",
      referencedColumnName: "id"
    }
  ]),
  _ts_metadata4("design:type", typeof User === "undefined" ? Object : User)
], CompanySharedCertificate.prototype, "sharedByUser", void 0);
_ts_decorate4([
  (0, import_typeorm5.ManyToOne)(() => CertificateShareRequest, (certificateShareRequest) => certificateShareRequest.id, {
    onDelete: "CASCADE"
  }),
  (0, import_typeorm5.JoinColumn)([
    {
      name: "certificate_share_request_id",
      referencedColumnName: "id"
    }
  ]),
  _ts_metadata4("design:type", typeof CertificateShareRequest === "undefined" ? Object : CertificateShareRequest)
], CompanySharedCertificate.prototype, "certificateShareRequest", void 0);
CompanySharedCertificate = _ts_decorate4([
  (0, import_typeorm5.Unique)([
    "uuid"
  ]),
  (0, import_typeorm5.Unique)([
    "userId",
    "companyId",
    "certificateId"
  ]),
  (0, import_typeorm5.Entity)("company_shared_certificates")
], CompanySharedCertificate);

// src/entities/certificates/certificate-share-request.entity.ts
function _ts_decorate5(decorators, target, key, desc) {
  var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function")
    r = Reflect.decorate(decorators, target, key, desc);
  else
    for (var i = decorators.length - 1; i >= 0; i--)
      if (d = decorators[i])
        r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
}
__name(_ts_decorate5, "_ts_decorate");
function _ts_metadata5(k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function")
    return Reflect.metadata(k, v);
}
__name(_ts_metadata5, "_ts_metadata");
var CertificateShareRequest = /* @__PURE__ */ __name(class CertificateShareRequest2 extends EntityWithTimestamps {
  constructor() {
    super(...arguments);
    __publicField(this, "id");
    __publicField(this, "uuid");
    __publicField(this, "certificateRequestId");
    __publicField(this, "firstName");
    __publicField(this, "lastName");
    __publicField(this, "email");
    __publicField(this, "companyName");
    __publicField(this, "companyFiscalId");
    __publicField(this, "status");
    __publicField(this, "createdByUserId");
    __publicField(this, "rejectionMessage");
    // ################ RELATIONS ################
    __publicField(this, "certificateRequest");
    __publicField(this, "createdByUser");
    __publicField(this, "companySharedCertificates");
  }
}, "CertificateShareRequest");
_ts_decorate5([
  (0, import_typeorm6.PrimaryGeneratedColumn)({
    type: "int",
    name: "id"
  }),
  _ts_metadata5("design:type", Number)
], CertificateShareRequest.prototype, "id", void 0);
_ts_decorate5([
  (0, import_typeorm6.Column)("uuid", {
    name: "uuid",
    comment: "User's UUID."
  }),
  (0, import_typeorm6.Generated)("uuid"),
  _ts_metadata5("design:type", String)
], CertificateShareRequest.prototype, "uuid", void 0);
_ts_decorate5([
  (0, import_typeorm6.Column)("int", {
    name: "certificate_request_id",
    nullable: false,
    comment: "Certificate Request Id."
  }),
  _ts_metadata5("design:type", Number)
], CertificateShareRequest.prototype, "certificateRequestId", void 0);
_ts_decorate5([
  (0, import_typeorm6.Column)("varchar", {
    name: "first_name",
    length: 200,
    nullable: false,
    comment: "User's first name."
  }),
  _ts_metadata5("design:type", String)
], CertificateShareRequest.prototype, "firstName", void 0);
_ts_decorate5([
  (0, import_typeorm6.Column)("varchar", {
    name: "last_name",
    length: 200,
    nullable: false,
    comment: "User's last name."
  }),
  _ts_metadata5("design:type", String)
], CertificateShareRequest.prototype, "lastName", void 0);
_ts_decorate5([
  (0, import_typeorm6.Column)("varchar", {
    name: "email",
    length: 200,
    nullable: false,
    comment: "Invited User email."
  }),
  _ts_metadata5("design:type", String)
], CertificateShareRequest.prototype, "email", void 0);
_ts_decorate5([
  (0, import_typeorm6.Column)("varchar", {
    name: "company_name",
    length: 255,
    nullable: false,
    comment: "Company name."
  }),
  _ts_metadata5("design:type", String)
], CertificateShareRequest.prototype, "companyName", void 0);
_ts_decorate5([
  (0, import_typeorm6.Column)("varchar", {
    name: "company_fiscal_id",
    length: 50,
    nullable: false,
    comment: "Company fiscal number."
  }),
  _ts_metadata5("design:type", String)
], CertificateShareRequest.prototype, "companyFiscalId", void 0);
_ts_decorate5([
  (0, import_typeorm6.Column)({
    type: "enum",
    name: "status",
    nullable: false,
    enum: CERTIFICATE_SHARE_REQUEST_STATUS,
    comment: "Certificate share request status.",
    default: CERTIFICATE_SHARE_REQUEST_STATUS.PENDING
  }),
  _ts_metadata5("design:type", typeof CertificateShareRequestStatusType === "undefined" ? Object : CertificateShareRequestStatusType)
], CertificateShareRequest.prototype, "status", void 0);
_ts_decorate5([
  (0, import_typeorm6.Column)("int", {
    name: "created_by_user_id",
    nullable: false,
    comment: "User ID that created the invitation, it must have invite permission."
  }),
  _ts_metadata5("design:type", Number)
], CertificateShareRequest.prototype, "createdByUserId", void 0);
_ts_decorate5([
  (0, import_typeorm6.Column)("varchar", {
    name: "rejection_message",
    length: 600,
    nullable: true,
    comment: "Reason of the certificate share request to be rejected."
  }),
  _ts_metadata5("design:type", String)
], CertificateShareRequest.prototype, "rejectionMessage", void 0);
_ts_decorate5([
  (0, import_typeorm6.ManyToOne)(() => CertificateRequest, (certificateRequest) => certificateRequest.id, {
    onDelete: "CASCADE"
  }),
  (0, import_typeorm6.JoinColumn)([
    {
      name: "certificate_request_id",
      referencedColumnName: "id"
    }
  ]),
  _ts_metadata5("design:type", typeof CertificateRequest === "undefined" ? Object : CertificateRequest)
], CertificateShareRequest.prototype, "certificateRequest", void 0);
_ts_decorate5([
  (0, import_typeorm6.ManyToOne)(() => User, (user) => user.id, {
    onDelete: "CASCADE"
  }),
  (0, import_typeorm6.JoinColumn)([
    {
      name: "created_by_user_id",
      referencedColumnName: "id"
    }
  ]),
  _ts_metadata5("design:type", typeof User === "undefined" ? Object : User)
], CertificateShareRequest.prototype, "createdByUser", void 0);
_ts_decorate5([
  (0, import_typeorm6.OneToMany)(() => CompanySharedCertificate, (companySharedCertificate) => companySharedCertificate.certificateShareRequest, {
    onDelete: "CASCADE"
  }),
  _ts_metadata5("design:type", Array)
], CertificateShareRequest.prototype, "companySharedCertificates", void 0);
CertificateShareRequest = _ts_decorate5([
  (0, import_typeorm6.Unique)([
    "uuid"
  ]),
  (0, import_typeorm6.Unique)([
    "email",
    "companyFiscalId",
    "certificateRequestId"
  ]),
  (0, import_typeorm6.Entity)("certificate_share_requests")
], CertificateShareRequest);

// src/entities/certificates/certificate-request.entity.ts
function _ts_decorate6(decorators, target, key, desc) {
  var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function")
    r = Reflect.decorate(decorators, target, key, desc);
  else
    for (var i = decorators.length - 1; i >= 0; i--)
      if (d = decorators[i])
        r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
}
__name(_ts_decorate6, "_ts_decorate");
function _ts_metadata6(k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function")
    return Reflect.metadata(k, v);
}
__name(_ts_metadata6, "_ts_metadata");
var CertificateRequest = /* @__PURE__ */ __name(class CertificateRequest2 extends EntityWithTimestamps {
  constructor() {
    super(...arguments);
    __publicField(this, "id");
    __publicField(this, "uuid");
    __publicField(this, "createdByUserId");
    __publicField(this, "companyId");
    __publicField(this, "accessKey");
    __publicField(this, "updatedByUserId");
    __publicField(this, "status");
    __publicField(this, "rejectionReason");
    __publicField(this, "rejectionMessage");
    __publicField(this, "resolvedAt");
    // ################ RELATIONS ################
    __publicField(this, "createdByUser");
    __publicField(this, "company");
    __publicField(this, "updatedByUser");
    __publicField(this, "certificateData");
    __publicField(this, "certificateShareRequests");
  }
}, "CertificateRequest");
_ts_decorate6([
  (0, import_typeorm7.PrimaryGeneratedColumn)({
    type: "int",
    name: "id"
  }),
  _ts_metadata6("design:type", Number)
], CertificateRequest.prototype, "id", void 0);
_ts_decorate6([
  (0, import_typeorm7.Column)("uuid", {
    name: "uuid",
    comment: "Certificate Request UUID."
  }),
  (0, import_typeorm7.Generated)("uuid"),
  _ts_metadata6("design:type", String)
], CertificateRequest.prototype, "uuid", void 0);
_ts_decorate6([
  (0, import_typeorm7.Column)("int", {
    name: "created_by_user_id",
    nullable: true,
    comment: "User ID that requests a certificate."
  }),
  _ts_metadata6("design:type", Number)
], CertificateRequest.prototype, "createdByUserId", void 0);
_ts_decorate6([
  (0, import_typeorm7.Column)("int", {
    name: "company_id",
    nullable: false,
    comment: "Company ID that requests a certificate."
  }),
  _ts_metadata6("design:type", Number)
], CertificateRequest.prototype, "companyId", void 0);
_ts_decorate6([
  (0, import_typeorm7.Column)("varchar", {
    name: "access_key",
    length: 44,
    nullable: false,
    comment: "This field is a 44 digits number that acts as identifier for the certificate."
  }),
  _ts_metadata6("design:type", String)
], CertificateRequest.prototype, "accessKey", void 0);
_ts_decorate6([
  (0, import_typeorm7.Column)("int", {
    name: "updated_by_user_id",
    nullable: true,
    comment: "User ID that approves/rejects the request."
  }),
  _ts_metadata6("design:type", Number)
], CertificateRequest.prototype, "updatedByUserId", void 0);
_ts_decorate6([
  (0, import_typeorm7.Column)({
    type: "enum",
    name: "status",
    nullable: false,
    enum: CERTIFICATE_REQUEST_STATUS,
    default: CERTIFICATE_REQUEST_STATUS.CREATED,
    comment: "Certificate Request current status."
  }),
  _ts_metadata6("design:type", typeof CertificateRequestStatusType === "undefined" ? Object : CertificateRequestStatusType)
], CertificateRequest.prototype, "status", void 0);
_ts_decorate6([
  (0, import_typeorm7.Column)({
    type: "enum",
    name: "rejection_reason",
    nullable: true,
    enum: CERTIFICATE_REJECTION_REASON,
    default: null,
    comment: "Certificate Request rejection reason."
  }),
  _ts_metadata6("design:type", typeof CertificateRejectionReasonType === "undefined" ? Object : CertificateRejectionReasonType)
], CertificateRequest.prototype, "rejectionReason", void 0);
_ts_decorate6([
  (0, import_typeorm7.Column)("varchar", {
    name: "rejection_message",
    length: 600,
    nullable: true,
    comment: "Reason of the certificate request to be rejected."
  }),
  _ts_metadata6("design:type", String)
], CertificateRequest.prototype, "rejectionMessage", void 0);
_ts_decorate6([
  (0, import_typeorm7.Column)({
    name: "resolved_at",
    type: "bigint",
    nullable: true
  }),
  _ts_metadata6("design:type", Number)
], CertificateRequest.prototype, "resolvedAt", void 0);
_ts_decorate6([
  (0, import_typeorm7.ManyToOne)(() => User, (user) => user.id, {
    onDelete: "CASCADE"
  }),
  (0, import_typeorm7.JoinColumn)([
    {
      name: "created_by_user_id",
      referencedColumnName: "id"
    }
  ]),
  _ts_metadata6("design:type", typeof User === "undefined" ? Object : User)
], CertificateRequest.prototype, "createdByUser", void 0);
_ts_decorate6([
  (0, import_typeorm7.ManyToOne)(() => Company, (company) => company.id, {
    onDelete: "CASCADE"
  }),
  (0, import_typeorm7.JoinColumn)([
    {
      name: "company_id",
      referencedColumnName: "id"
    }
  ]),
  _ts_metadata6("design:type", typeof Company === "undefined" ? Object : Company)
], CertificateRequest.prototype, "company", void 0);
_ts_decorate6([
  (0, import_typeorm7.ManyToOne)(() => User, (user) => user.id, {
    onDelete: "CASCADE"
  }),
  (0, import_typeorm7.JoinColumn)([
    {
      name: "updated_by_user_id",
      referencedColumnName: "id"
    }
  ]),
  _ts_metadata6("design:type", typeof User === "undefined" ? Object : User)
], CertificateRequest.prototype, "updatedByUser", void 0);
_ts_decorate6([
  (0, import_typeorm7.OneToMany)(() => CertificateData, (certificateData) => certificateData.certificateRequest, {
    onDelete: "CASCADE"
  }),
  _ts_metadata6("design:type", Array)
], CertificateRequest.prototype, "certificateData", void 0);
_ts_decorate6([
  (0, import_typeorm7.OneToMany)(() => CertificateShareRequest, (certificateShareRequest) => certificateShareRequest.certificateRequest, {
    onDelete: "CASCADE"
  }),
  _ts_metadata6("design:type", Array)
], CertificateRequest.prototype, "certificateShareRequests", void 0);
CertificateRequest = _ts_decorate6([
  (0, import_typeorm7.Unique)([
    "uuid"
  ]),
  (0, import_typeorm7.Entity)("certificate_requests")
], CertificateRequest);

// src/entities/certificates/certificate-data-production-detail.entity.ts
var import_typeorm8 = require("typeorm");
var import_decimal2 = __toESM(require("decimal.js"));
function _ts_decorate7(decorators, target, key, desc) {
  var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function")
    r = Reflect.decorate(decorators, target, key, desc);
  else
    for (var i = decorators.length - 1; i >= 0; i--)
      if (d = decorators[i])
        r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
}
__name(_ts_decorate7, "_ts_decorate");
function _ts_metadata7(k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function")
    return Reflect.metadata(k, v);
}
__name(_ts_metadata7, "_ts_metadata");
var CertificateDataProductionDetail = /* @__PURE__ */ __name(class CertificateDataProductionDetail2 extends EntityWithTimestamps {
  constructor() {
    super(...arguments);
    __publicField(this, "id");
    __publicField(this, "uuid");
    __publicField(this, "certificateDataId");
    __publicField(this, "farmName");
    __publicField(this, "farmProductionQuantity");
    __publicField(this, "productionQuantityUnit");
    __publicField(this, "farmLatitude");
    __publicField(this, "farmLongitude");
    __publicField(this, "elo");
    __publicField(this, "historicalSoilCoverage");
    __publicField(this, "car");
    __publicField(this, "isRented");
    __publicField(this, "deforestationFree");
    __publicField(this, "useOfNativeTribesLands");
    __publicField(this, "useOfQuilombolaLands");
    __publicField(this, "bonsucroCertification");
    __publicField(this, "foodchainCertification");
    __publicField(this, "productCertifiedQuantity");
    __publicField(this, "zone");
    // ################ RELATIONS ################
    __publicField(this, "certificateData");
  }
}, "CertificateDataProductionDetail");
_ts_decorate7([
  (0, import_typeorm8.PrimaryGeneratedColumn)({
    type: "int",
    name: "id"
  }),
  _ts_metadata7("design:type", Number)
], CertificateDataProductionDetail.prototype, "id", void 0);
_ts_decorate7([
  (0, import_typeorm8.Column)("uuid", {
    name: "uuid",
    comment: "Certificate Data UUID."
  }),
  (0, import_typeorm8.Generated)("uuid"),
  _ts_metadata7("design:type", String)
], CertificateDataProductionDetail.prototype, "uuid", void 0);
_ts_decorate7([
  (0, import_typeorm8.Column)("int", {
    name: "certificate_data_id",
    nullable: false,
    comment: "Certificate data Id."
  }),
  _ts_metadata7("design:type", Number)
], CertificateDataProductionDetail.prototype, "certificateDataId", void 0);
_ts_decorate7([
  (0, import_typeorm8.Column)("varchar", {
    name: "farm_name",
    length: 100,
    nullable: true,
    comment: "Farm name."
  }),
  _ts_metadata7("design:type", String)
], CertificateDataProductionDetail.prototype, "farmName", void 0);
_ts_decorate7([
  DecimalColumn({
    name: "farm_production_quantity",
    precision: 16,
    scale: 4,
    nullable: true,
    comment: "Farm production quantity (in mass unit)."
  }),
  _ts_metadata7("design:type", typeof import_decimal2.default === "undefined" ? Object : import_decimal2.default)
], CertificateDataProductionDetail.prototype, "farmProductionQuantity", void 0);
_ts_decorate7([
  (0, import_typeorm8.Column)("varchar", {
    name: "production_quantity_unit",
    length: 100,
    nullable: true,
    comment: "Unit of measurement for the produced quantities (tonnes or m\xB3),origin of this data is the fiscal invoice."
  }),
  _ts_metadata7("design:type", String)
], CertificateDataProductionDetail.prototype, "productionQuantityUnit", void 0);
_ts_decorate7([
  DecimalColumn({
    name: "farm_latitude",
    precision: 11,
    scale: 8,
    nullable: true,
    comment: "Farm latitude (coordinates of the polygon centroid), origin of these data is PIMS and FME (map base) - Ra\xEDzen Shapefile Document."
  }),
  _ts_metadata7("design:type", typeof import_decimal2.default === "undefined" ? Object : import_decimal2.default)
], CertificateDataProductionDetail.prototype, "farmLatitude", void 0);
_ts_decorate7([
  DecimalColumn({
    name: "farm_longitude",
    precision: 11,
    scale: 8,
    nullable: true,
    comment: "Farm longitude (coordinates of the polygon centroid), origin of these data is PIMS and FME (map base) - Ra\xEDzen Shapefile Document."
  }),
  _ts_metadata7("design:type", typeof import_decimal2.default === "undefined" ? Object : import_decimal2.default)
], CertificateDataProductionDetail.prototype, "farmLongitude", void 0);
_ts_decorate7([
  (0, import_typeorm8.Column)("boolean", {
    name: "elo",
    default: false,
    comment: "Has ELO certification."
  }),
  _ts_metadata7("design:type", Boolean)
], CertificateDataProductionDetail.prototype, "elo", void 0);
_ts_decorate7([
  (0, import_typeorm8.Column)("varchar", {
    name: "historical_soil_coverage",
    length: 200,
    nullable: true,
    comment: "CAR-compliant Production History Report, the origin of this data is PDF provided by Agrotoken."
  }),
  _ts_metadata7("design:type", String)
], CertificateDataProductionDetail.prototype, "historicalSoilCoverage", void 0);
_ts_decorate7([
  (0, import_typeorm8.Column)("varchar", {
    name: "car",
    length: 200,
    nullable: true,
    comment: "Report with Area Polygon and ESG Certificates, the origin of this data is PDF provided by Agrotoken."
  }),
  _ts_metadata7("design:type", String)
], CertificateDataProductionDetail.prototype, "car", void 0);
_ts_decorate7([
  (0, import_typeorm8.Column)("boolean", {
    name: "is_rented",
    nullable: true,
    comment: "If Ra\xEDzen or Foca area: True or False."
  }),
  _ts_metadata7("design:type", Boolean)
], CertificateDataProductionDetail.prototype, "isRented", void 0);
_ts_decorate7([
  (0, import_typeorm8.Column)({
    type: "enum",
    name: "deforestation_free",
    nullable: true,
    enum: USE_TYPES,
    comment: "Deforested area: regular/irregular."
  }),
  _ts_metadata7("design:type", typeof UseType === "undefined" ? Object : UseType)
], CertificateDataProductionDetail.prototype, "deforestationFree", void 0);
_ts_decorate7([
  (0, import_typeorm8.Column)({
    type: "enum",
    name: "use_of_native_tribes_lands",
    nullable: true,
    enum: USE_TYPES,
    comment: "Use of an indigenous area: regular/irregular."
  }),
  _ts_metadata7("design:type", typeof UseType === "undefined" ? Object : UseType)
], CertificateDataProductionDetail.prototype, "useOfNativeTribesLands", void 0);
_ts_decorate7([
  (0, import_typeorm8.Column)({
    type: "enum",
    name: "use_of_quilombola_lands",
    nullable: true,
    enum: USE_TYPES,
    comment: "Use of an quilombola area: regular/irregular."
  }),
  _ts_metadata7("design:type", typeof UseType === "undefined" ? Object : UseType)
], CertificateDataProductionDetail.prototype, "useOfQuilombolaLands", void 0);
_ts_decorate7([
  (0, import_typeorm8.Column)("boolean", {
    name: "bonsucro_certification",
    default: false,
    comment: "Has BONSUCRO certification."
  }),
  _ts_metadata7("design:type", Boolean)
], CertificateDataProductionDetail.prototype, "bonsucroCertification", void 0);
_ts_decorate7([
  (0, import_typeorm8.Column)("boolean", {
    name: "foodchain_certification",
    default: false,
    comment: "Has FOODCHAIN certification."
  }),
  _ts_metadata7("design:type", Boolean)
], CertificateDataProductionDetail.prototype, "foodchainCertification", void 0);
_ts_decorate7([
  DecimalColumn({
    name: "product_certified_quantity",
    precision: 16,
    scale: 4,
    nullable: true,
    comment: "Certified product quantity purchased (by weight if its sugar by volume if its ethanol),origin of this data is the fiscal invoice."
  }),
  _ts_metadata7("design:type", typeof import_decimal2.default === "undefined" ? Object : import_decimal2.default)
], CertificateDataProductionDetail.prototype, "productCertifiedQuantity", void 0);
_ts_decorate7([
  (0, import_typeorm8.Column)("varchar", {
    name: "zone",
    length: 3,
    nullable: true,
    comment: `Farm's zone code .`
  }),
  _ts_metadata7("design:type", String)
], CertificateDataProductionDetail.prototype, "zone", void 0);
_ts_decorate7([
  (0, import_typeorm8.ManyToOne)(() => CertificateData, (certificateData) => certificateData.productionDetails, {
    onDelete: "RESTRICT"
  }),
  (0, import_typeorm8.JoinColumn)([
    {
      name: "certificate_data_id",
      referencedColumnName: "id"
    }
  ]),
  _ts_metadata7("design:type", typeof CertificateData === "undefined" ? Object : CertificateData)
], CertificateDataProductionDetail.prototype, "certificateData", void 0);
CertificateDataProductionDetail = _ts_decorate7([
  (0, import_typeorm8.Unique)([
    "uuid"
  ]),
  (0, import_typeorm8.Entity)("certificate_data_production_details")
], CertificateDataProductionDetail);

// src/entities/certificates/certificate-data.entity.ts
function _ts_decorate8(decorators, target, key, desc) {
  var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function")
    r = Reflect.decorate(decorators, target, key, desc);
  else
    for (var i = decorators.length - 1; i >= 0; i--)
      if (d = decorators[i])
        r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
}
__name(_ts_decorate8, "_ts_decorate");
function _ts_metadata8(k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function")
    return Reflect.metadata(k, v);
}
__name(_ts_metadata8, "_ts_metadata");
var CertificateData = /* @__PURE__ */ __name(class CertificateData2 extends EntityWithTimestamps {
  constructor() {
    super(...arguments);
    __publicField(this, "id");
    __publicField(this, "uuid");
    __publicField(this, "certificateRequestId");
    __publicField(this, "fiscalInvoiceNumber");
    __publicField(this, "accessKey");
    __publicField(this, "bioparkName");
    __publicField(this, "bioparkCityState");
    __publicField(this, "fiscalInvoiceDeliveryDate");
    // TODO: check if this value should be migrated to certificate_data_production_details
    __publicField(this, "productionDate");
    __publicField(this, "greenFactor");
    __publicField(this, "massBalance");
    __publicField(this, "productTotalQuantity");
    __publicField(this, "productCertifiedQuantity");
    __publicField(this, "productQuantityUnit");
    __publicField(this, "carbonFootprint");
    __publicField(this, "carbonFootprintQuantityUnit");
    __publicField(this, "productType");
    __publicField(this, "externalDataOrigin");
    __publicField(this, "blockchainStatus");
    __publicField(this, "farmProductionQuantity");
    // ################ RELATIONS ################
    __publicField(this, "certificate");
    __publicField(this, "certificateRequest");
    __publicField(this, "productionDetails");
  }
}, "CertificateData");
_ts_decorate8([
  (0, import_typeorm9.PrimaryGeneratedColumn)({
    type: "int",
    name: "id"
  }),
  _ts_metadata8("design:type", Number)
], CertificateData.prototype, "id", void 0);
_ts_decorate8([
  (0, import_typeorm9.Column)("uuid", {
    name: "uuid",
    comment: "Certificate Data UUID."
  }),
  (0, import_typeorm9.Generated)("uuid"),
  _ts_metadata8("design:type", String)
], CertificateData.prototype, "uuid", void 0);
_ts_decorate8([
  (0, import_typeorm9.Column)("int", {
    name: "certificate_request_id",
    nullable: false,
    comment: "ID of the original certificate request."
  }),
  _ts_metadata8("design:type", Number)
], CertificateData.prototype, "certificateRequestId", void 0);
_ts_decorate8([
  (0, import_typeorm9.Column)("varchar", {
    name: "fiscal_invoice_number",
    length: 100,
    nullable: false,
    comment: "Fiscal number that identifies the purchase of a certain product."
  }),
  _ts_metadata8("design:type", String)
], CertificateData.prototype, "fiscalInvoiceNumber", void 0);
_ts_decorate8([
  (0, import_typeorm9.Column)("varchar", {
    name: "access_key",
    length: 44,
    nullable: false,
    comment: "This field is a 44 digits number that acts as identifier for the certificate."
  }),
  _ts_metadata8("design:type", String)
], CertificateData.prototype, "accessKey", void 0);
_ts_decorate8([
  (0, import_typeorm9.Column)("varchar", {
    name: "biopark_name",
    length: 100,
    nullable: false,
    comment: "Producer Biopark name, origin of this data is the fiscal invoice."
  }),
  _ts_metadata8("design:type", String)
], CertificateData.prototype, "bioparkName", void 0);
_ts_decorate8([
  (0, import_typeorm9.Column)("varchar", {
    name: "biopark_city_state",
    length: 100,
    nullable: true,
    comment: "Producer Biopark City and State, origin of this data is the fiscal invoice."
  }),
  _ts_metadata8("design:type", String)
], CertificateData.prototype, "bioparkCityState", void 0);
_ts_decorate8([
  (0, import_typeorm9.Column)({
    name: "fiscal_invoice_delivery_date",
    type: "bigint",
    nullable: true,
    comment: "Fiscal Invoice Delivery Date, origin of this data is the fiscal invoice."
  }),
  _ts_metadata8("design:type", Number)
], CertificateData.prototype, "fiscalInvoiceDeliveryDate", void 0);
_ts_decorate8([
  (0, import_typeorm9.Column)({
    name: "production_date",
    type: "bigint",
    nullable: true,
    comment: "Production date."
  }),
  _ts_metadata8("design:type", Number)
], CertificateData.prototype, "productionDate", void 0);
_ts_decorate8([
  (0, import_typeorm9.Column)("varchar", {
    name: "green_factor",
    length: 100,
    nullable: true,
    comment: "Sustainability factor created by the sustainability team."
  }),
  _ts_metadata8("design:type", String)
], CertificateData.prototype, "greenFactor", void 0);
_ts_decorate8([
  (0, import_typeorm9.Column)("boolean", {
    name: "mass_balance",
    default: false,
    comment: "Has been processed with Mass Balance methodology"
  }),
  _ts_metadata8("design:type", Boolean)
], CertificateData.prototype, "massBalance", void 0);
_ts_decorate8([
  DecimalColumn({
    name: "product_total_quantity",
    precision: 16,
    scale: 4,
    nullable: true,
    comment: "Quantity of product purchased (by weight if its sugar by volume if its ethanol),origin of this data is the fiscal invoice."
  }),
  _ts_metadata8("design:type", typeof import_decimal3.Decimal === "undefined" ? Object : import_decimal3.Decimal)
], CertificateData.prototype, "productTotalQuantity", void 0);
_ts_decorate8([
  DecimalColumn({
    name: "product_certified_quantity",
    precision: 16,
    scale: 4,
    nullable: true,
    comment: "Certified product quantity purchased (by weight if its sugar by volume if its ethanol),origin of this data is the fiscal invoice."
  }),
  _ts_metadata8("design:type", typeof import_decimal3.Decimal === "undefined" ? Object : import_decimal3.Decimal)
], CertificateData.prototype, "productCertifiedQuantity", void 0);
_ts_decorate8([
  (0, import_typeorm9.Column)("varchar", {
    name: "product_quantity_unit",
    length: 100,
    nullable: true,
    comment: "Unit of measurement for the purchased quantities (tonnes or m\xB3),origin of this data is the fiscal invoice."
  }),
  _ts_metadata8("design:type", String)
], CertificateData.prototype, "productQuantityUnit", void 0);
_ts_decorate8([
  DecimalColumn({
    name: "carbon_footprint",
    precision: 16,
    scale: 4,
    nullable: true,
    comment: "Carbon footprint generated by product production and delivery the origin of this data is an excel spreadsheet sent by sustainability with the calculation."
  }),
  _ts_metadata8("design:type", typeof import_decimal3.Decimal === "undefined" ? Object : import_decimal3.Decimal)
], CertificateData.prototype, "carbonFootprint", void 0);
_ts_decorate8([
  (0, import_typeorm9.Column)("varchar", {
    name: "carbon_footprint_quantity_unit",
    length: 100,
    nullable: true,
    comment: "Standard unit of measurement for quantifying carbon footprint the origin of this data is an excel spreadsheet sent by sustainability with the calculation."
  }),
  _ts_metadata8("design:type", String)
], CertificateData.prototype, "carbonFootprintQuantityUnit", void 0);
_ts_decorate8([
  (0, import_typeorm9.Column)({
    type: "enum",
    name: "product_type",
    nullable: true,
    enum: RAIZEN_METADATA_PRODUCTS,
    comment: "Product name: Sugar, Ethanol"
  }),
  _ts_metadata8("design:type", typeof RaizenMetadataProductType === "undefined" ? Object : RaizenMetadataProductType)
], CertificateData.prototype, "productType", void 0);
_ts_decorate8([
  (0, import_typeorm9.Column)("boolean", {
    name: "external_data_origin",
    nullable: false,
    default: false,
    comment: "Indicates if there is external information (1 / true) or its just information from Raizen (0 / false)."
  }),
  _ts_metadata8("design:type", Boolean)
], CertificateData.prototype, "externalDataOrigin", void 0);
_ts_decorate8([
  (0, import_typeorm9.Column)({
    type: "enum",
    name: "blockchain_status",
    nullable: false,
    default: BLOCKCHAIN_STATUS_VALUES.PENDING,
    enum: BLOCKCHAIN_STATUS_VALUES,
    comment: "Blockchain status."
  }),
  _ts_metadata8("design:type", typeof BlockchainStatusType === "undefined" ? Object : BlockchainStatusType)
], CertificateData.prototype, "blockchainStatus", void 0);
_ts_decorate8([
  DecimalColumn({
    name: "farm_production_quantity",
    precision: 16,
    scale: 4,
    nullable: true,
    comment: "Farm production quantity (by weight or invoice total production percentage)."
  }),
  _ts_metadata8("design:type", typeof import_decimal3.Decimal === "undefined" ? Object : import_decimal3.Decimal)
], CertificateData.prototype, "farmProductionQuantity", void 0);
_ts_decorate8([
  (0, import_typeorm9.OneToOne)(() => Certificate, (certificate) => certificate.certificateData, {
    onDelete: "RESTRICT"
  }),
  _ts_metadata8("design:type", typeof Certificate === "undefined" ? Object : Certificate)
], CertificateData.prototype, "certificate", void 0);
_ts_decorate8([
  (0, import_typeorm9.ManyToOne)(() => CertificateRequest, (certificateRequest) => certificateRequest.certificateData, {
    onDelete: "RESTRICT"
  }),
  (0, import_typeorm9.JoinColumn)([
    {
      name: "certificate_request_id",
      referencedColumnName: "id"
    }
  ]),
  _ts_metadata8("design:type", typeof CertificateRequest === "undefined" ? Object : CertificateRequest)
], CertificateData.prototype, "certificateRequest", void 0);
_ts_decorate8([
  (0, import_typeorm9.OneToMany)(() => CertificateDataProductionDetail, (certificateDataProductionDetail) => certificateDataProductionDetail.certificateData, {
    onDelete: "RESTRICT"
  }),
  _ts_metadata8("design:type", Array)
], CertificateData.prototype, "productionDetails", void 0);
CertificateData = _ts_decorate8([
  (0, import_typeorm9.Unique)([
    "uuid"
  ]),
  (0, import_typeorm9.Entity)("certificate_data")
], CertificateData);

// src/entities/certificates/certificate.entity.ts
function _ts_decorate9(decorators, target, key, desc) {
  var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function")
    r = Reflect.decorate(decorators, target, key, desc);
  else
    for (var i = decorators.length - 1; i >= 0; i--)
      if (d = decorators[i])
        r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
}
__name(_ts_decorate9, "_ts_decorate");
function _ts_metadata9(k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function")
    return Reflect.metadata(k, v);
}
__name(_ts_metadata9, "_ts_metadata");
var Certificate = /* @__PURE__ */ __name(class Certificate2 extends EntityWithTimestamps {
  constructor() {
    super(...arguments);
    __publicField(this, "id");
    __publicField(this, "uuid");
    __publicField(this, "companyId");
    __publicField(this, "certificateDataId");
    __publicField(this, "root");
    __publicField(this, "txHash");
    __publicField(this, "userId");
    __publicField(this, "status");
    __publicField(this, "proof");
    // ################ RELATIONS ################
    __publicField(this, "certificateData");
    __publicField(this, "user");
    __publicField(this, "company");
    __publicField(this, "companySharedCertificates");
  }
}, "Certificate");
_ts_decorate9([
  (0, import_typeorm10.PrimaryGeneratedColumn)({
    type: "int"
  }),
  _ts_metadata9("design:type", Number)
], Certificate.prototype, "id", void 0);
_ts_decorate9([
  (0, import_typeorm10.Column)("uuid", {
    name: "uuid",
    comment: "Certificate UUID."
  }),
  (0, import_typeorm10.Generated)("uuid"),
  _ts_metadata9("design:type", String)
], Certificate.prototype, "uuid", void 0);
_ts_decorate9([
  (0, import_typeorm10.Column)("int", {
    name: "company_id",
    comment: "Id of the business owner of the certificate",
    nullable: false
  }),
  _ts_metadata9("design:type", Number)
], Certificate.prototype, "companyId", void 0);
_ts_decorate9([
  (0, import_typeorm10.Column)("int", {
    name: "certificate_data_id",
    comment: "Id from certificate_requests table",
    nullable: true
  }),
  _ts_metadata9("design:type", Number)
], Certificate.prototype, "certificateDataId", void 0);
_ts_decorate9([
  (0, import_typeorm10.Column)("varchar", {
    name: "root",
    length: 100,
    comment: "Hash that identify the certificate in blockchain.",
    nullable: false
  }),
  _ts_metadata9("design:type", String)
], Certificate.prototype, "root", void 0);
_ts_decorate9([
  (0, import_typeorm10.Column)("varchar", {
    name: "tx_hash",
    length: 100,
    comment: "Transaction hash.",
    nullable: false
  }),
  _ts_metadata9("design:type", String)
], Certificate.prototype, "txHash", void 0);
_ts_decorate9([
  (0, import_typeorm10.Column)("int", {
    name: "user_id",
    comment: "User ID who approves the certificates",
    nullable: false
  }),
  _ts_metadata9("design:type", Number)
], Certificate.prototype, "userId", void 0);
_ts_decorate9([
  (0, import_typeorm10.Column)({
    type: "enum",
    name: "status",
    nullable: false,
    enum: CERTIFICATE_STATUS,
    default: CERTIFICATE_STATUS.VALID,
    comment: "Certificate status."
  }),
  _ts_metadata9("design:type", typeof CertificateStatusType === "undefined" ? Object : CertificateStatusType)
], Certificate.prototype, "status", void 0);
_ts_decorate9([
  (0, import_typeorm10.Column)("varchar", {
    name: "proof",
    length: 600,
    nullable: true,
    comment: "Information required to create partial proofs."
  }),
  _ts_metadata9("design:type", Object)
], Certificate.prototype, "proof", void 0);
_ts_decorate9([
  (0, import_typeorm10.OneToOne)(() => CertificateData, (certificateData) => certificateData.id, {
    onDelete: "RESTRICT"
  }),
  (0, import_typeorm10.JoinColumn)([
    {
      name: "certificate_data_id",
      referencedColumnName: "id"
    }
  ]),
  _ts_metadata9("design:type", typeof CertificateData === "undefined" ? Object : CertificateData)
], Certificate.prototype, "certificateData", void 0);
_ts_decorate9([
  (0, import_typeorm10.ManyToOne)(() => User, (user) => user.id, {
    onDelete: "RESTRICT"
  }),
  (0, import_typeorm10.JoinColumn)([
    {
      name: "user_id",
      referencedColumnName: "id"
    }
  ]),
  _ts_metadata9("design:type", typeof User === "undefined" ? Object : User)
], Certificate.prototype, "user", void 0);
_ts_decorate9([
  (0, import_typeorm10.ManyToOne)(() => Company, (company) => company.id, {
    onDelete: "RESTRICT"
  }),
  (0, import_typeorm10.JoinColumn)([
    {
      name: "company_id",
      referencedColumnName: "id"
    }
  ]),
  _ts_metadata9("design:type", typeof Company === "undefined" ? Object : Company)
], Certificate.prototype, "company", void 0);
_ts_decorate9([
  (0, import_typeorm10.OneToMany)(() => CompanySharedCertificate, (companySharedCertificate) => companySharedCertificate.certificate),
  _ts_metadata9("design:type", Array)
], Certificate.prototype, "companySharedCertificates", void 0);
Certificate = _ts_decorate9([
  (0, import_typeorm10.Unique)([
    "uuid"
  ]),
  (0, import_typeorm10.Entity)("certificates")
], Certificate);

// src/entities/certificates/raizen_metadata.entity.ts
var import_typeorm11 = require("typeorm");
var import_decimal4 = require("decimal.js");
function _ts_decorate10(decorators, target, key, desc) {
  var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function")
    r = Reflect.decorate(decorators, target, key, desc);
  else
    for (var i = decorators.length - 1; i >= 0; i--)
      if (d = decorators[i])
        r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
}
__name(_ts_decorate10, "_ts_decorate");
function _ts_metadata10(k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function")
    return Reflect.metadata(k, v);
}
__name(_ts_metadata10, "_ts_metadata");
var RaizenMetadata = /* @__PURE__ */ __name(class RaizenMetadata2 extends EntityWithTimestamps {
  constructor() {
    super(...arguments);
    __publicField(this, "id");
    __publicField(this, "uuid");
    __publicField(this, "companyId");
    __publicField(this, "fiscalInvoiceNumber");
    __publicField(this, "accessKey");
    __publicField(this, "bioparkName");
    __publicField(this, "bioparkCityState");
    __publicField(this, "fiscalInvoiceDeliveryDate");
    __publicField(this, "productionDate");
    __publicField(this, "greenFactor");
    __publicField(this, "massBalance");
    __publicField(this, "productTotalQuantity");
    __publicField(this, "productCertifiedQuantity");
    __publicField(this, "productQuantityUnit");
    __publicField(this, "farmName");
    __publicField(this, "farmLatitude");
    __publicField(this, "farmLongitude");
    __publicField(this, "elo");
    __publicField(this, "historicalSoilCoverage");
    __publicField(this, "car");
    __publicField(this, "carbonFootprint");
    __publicField(this, "carbonFootprintQuantityUnit");
    __publicField(this, "isRented");
    __publicField(this, "productType");
    __publicField(this, "deforestationFree");
    __publicField(this, "useOfNativeTribesLands");
    __publicField(this, "useOfQuilombolaLands");
    __publicField(this, "farmProductionQuantity");
    __publicField(this, "bonsucroCertification");
    __publicField(this, "foodchainCertification");
    __publicField(this, "zone");
    // ################ RELATIONS ################
    __publicField(this, "company");
  }
}, "RaizenMetadata");
_ts_decorate10([
  (0, import_typeorm11.PrimaryGeneratedColumn)({
    type: "int",
    name: "id"
  }),
  _ts_metadata10("design:type", Number)
], RaizenMetadata.prototype, "id", void 0);
_ts_decorate10([
  (0, import_typeorm11.Column)("uuid", {
    name: "uuid",
    comment: "Metadata UUID."
  }),
  (0, import_typeorm11.Generated)("uuid"),
  _ts_metadata10("design:type", String)
], RaizenMetadata.prototype, "uuid", void 0);
_ts_decorate10([
  (0, import_typeorm11.Column)("int", {
    name: "company_id",
    nullable: false,
    comment: "Company ID to which the metadata belongs."
  }),
  _ts_metadata10("design:type", Number)
], RaizenMetadata.prototype, "companyId", void 0);
_ts_decorate10([
  (0, import_typeorm11.Column)("varchar", {
    name: "fiscal_invoice_number",
    length: 100,
    nullable: false,
    comment: "Fiscal number that identifies the purchase of a certain product."
  }),
  _ts_metadata10("design:type", String)
], RaizenMetadata.prototype, "fiscalInvoiceNumber", void 0);
_ts_decorate10([
  (0, import_typeorm11.Column)("varchar", {
    name: "access_key",
    length: 44,
    nullable: false,
    comment: "This field is a 44 digits number that acts as identifier for the certificate."
  }),
  _ts_metadata10("design:type", String)
], RaizenMetadata.prototype, "accessKey", void 0);
_ts_decorate10([
  (0, import_typeorm11.Column)("varchar", {
    name: "biopark_name",
    length: 100,
    nullable: false,
    comment: "Producer Biopark name, origin of this data is the fiscal invoice."
  }),
  _ts_metadata10("design:type", String)
], RaizenMetadata.prototype, "bioparkName", void 0);
_ts_decorate10([
  (0, import_typeorm11.Column)("varchar", {
    name: "biopark_city_state",
    length: 100,
    nullable: true,
    comment: "Producer Biopark City and State, origin of this data is the fiscal invoice."
  }),
  _ts_metadata10("design:type", String)
], RaizenMetadata.prototype, "bioparkCityState", void 0);
_ts_decorate10([
  (0, import_typeorm11.Column)({
    name: "fiscal_invoice_delivery_date",
    type: "bigint",
    nullable: true,
    comment: "Fiscal Invoice Delivery Date, origin of this data is the fiscal invoice."
  }),
  _ts_metadata10("design:type", Number)
], RaizenMetadata.prototype, "fiscalInvoiceDeliveryDate", void 0);
_ts_decorate10([
  (0, import_typeorm11.Column)({
    name: "production_date",
    type: "bigint",
    nullable: true,
    comment: "Production date."
  }),
  _ts_metadata10("design:type", Number)
], RaizenMetadata.prototype, "productionDate", void 0);
_ts_decorate10([
  (0, import_typeorm11.Column)("varchar", {
    name: "green_factor",
    length: 100,
    nullable: true,
    comment: "Sustainability factor created by the sustainability team."
  }),
  _ts_metadata10("design:type", String)
], RaizenMetadata.prototype, "greenFactor", void 0);
_ts_decorate10([
  (0, import_typeorm11.Column)("boolean", {
    name: "mass_balance",
    default: false,
    comment: "Has been processed with Mass Balance methodology"
  }),
  _ts_metadata10("design:type", Boolean)
], RaizenMetadata.prototype, "massBalance", void 0);
_ts_decorate10([
  DecimalColumn({
    name: "product_total_quantity",
    precision: 16,
    scale: 4,
    nullable: true,
    comment: "Quantity of product purchased (by weight if its sugar by volume if its ethanol),origin of this data is the fiscal invoice."
  }),
  _ts_metadata10("design:type", typeof import_decimal4.Decimal === "undefined" ? Object : import_decimal4.Decimal)
], RaizenMetadata.prototype, "productTotalQuantity", void 0);
_ts_decorate10([
  DecimalColumn({
    name: "product_certified_quantity",
    precision: 16,
    scale: 4,
    nullable: true,
    comment: "Certified product quantity purchased (by weight if its sugar by volume if its ethanol),origin of this data is the fiscal invoice."
  }),
  _ts_metadata10("design:type", typeof import_decimal4.Decimal === "undefined" ? Object : import_decimal4.Decimal)
], RaizenMetadata.prototype, "productCertifiedQuantity", void 0);
_ts_decorate10([
  (0, import_typeorm11.Column)("varchar", {
    name: "product_quantity_unit",
    length: 100,
    nullable: true,
    comment: "Unit of measurement for the purchased quantities (tonnes or m\xB3),origin of this data is the fiscal invoice."
  }),
  _ts_metadata10("design:type", String)
], RaizenMetadata.prototype, "productQuantityUnit", void 0);
_ts_decorate10([
  (0, import_typeorm11.Column)("varchar", {
    name: "farm_name",
    length: 100,
    nullable: true,
    comment: "Farm name."
  }),
  _ts_metadata10("design:type", String)
], RaizenMetadata.prototype, "farmName", void 0);
_ts_decorate10([
  DecimalColumn({
    name: "farm_latitude",
    precision: 11,
    scale: 8,
    nullable: true,
    comment: "Farm latitude (coordinates of the polygon centroid), origin of these data is PIMS and FME (map base) - Ra\xEDzen Shapefile Document."
  }),
  _ts_metadata10("design:type", typeof import_decimal4.Decimal === "undefined" ? Object : import_decimal4.Decimal)
], RaizenMetadata.prototype, "farmLatitude", void 0);
_ts_decorate10([
  DecimalColumn({
    name: "farm_longitude",
    precision: 11,
    scale: 8,
    nullable: true,
    comment: "Farm longitude (coordinates of the polygon centroid), origin of these data is PIMS and FME (map base) - Ra\xEDzen Shapefile Document."
  }),
  _ts_metadata10("design:type", typeof import_decimal4.Decimal === "undefined" ? Object : import_decimal4.Decimal)
], RaizenMetadata.prototype, "farmLongitude", void 0);
_ts_decorate10([
  (0, import_typeorm11.Column)("boolean", {
    name: "elo",
    default: false,
    comment: "Has ELO certification."
  }),
  _ts_metadata10("design:type", Boolean)
], RaizenMetadata.prototype, "elo", void 0);
_ts_decorate10([
  (0, import_typeorm11.Column)("varchar", {
    name: "historical_soil_coverage",
    length: 200,
    nullable: true,
    comment: "CAR-compliant Production History Report, the origin of this data is PDF provided by Agrotoken."
  }),
  _ts_metadata10("design:type", String)
], RaizenMetadata.prototype, "historicalSoilCoverage", void 0);
_ts_decorate10([
  (0, import_typeorm11.Column)("varchar", {
    name: "car",
    length: 200,
    nullable: true,
    comment: "Report with Area Polygon and ESG Certificates, the origin of this data is PDF provided by Agrotoken."
  }),
  _ts_metadata10("design:type", String)
], RaizenMetadata.prototype, "car", void 0);
_ts_decorate10([
  DecimalColumn({
    name: "carbon_footprint",
    precision: 16,
    scale: 4,
    nullable: true,
    comment: "Carbon footprint generated by product production and delivery the origin of this data is an excel spreadsheet sent by sustainability with the calculation."
  }),
  _ts_metadata10("design:type", typeof import_decimal4.Decimal === "undefined" ? Object : import_decimal4.Decimal)
], RaizenMetadata.prototype, "carbonFootprint", void 0);
_ts_decorate10([
  (0, import_typeorm11.Column)("varchar", {
    name: "carbon_footprint_quantity_unit",
    length: 100,
    nullable: true,
    comment: "Standard unit of measurement for quantifying carbon footprint the origin of this data is an excel spreadsheet sent by sustainability with the calculation."
  }),
  _ts_metadata10("design:type", String)
], RaizenMetadata.prototype, "carbonFootprintQuantityUnit", void 0);
_ts_decorate10([
  (0, import_typeorm11.Column)("boolean", {
    name: "is_rented",
    nullable: true,
    comment: "If Ra\xEDzen or Foca area: True or False."
  }),
  _ts_metadata10("design:type", Boolean)
], RaizenMetadata.prototype, "isRented", void 0);
_ts_decorate10([
  (0, import_typeorm11.Column)({
    type: "enum",
    name: "product_type",
    nullable: true,
    enum: RAIZEN_METADATA_PRODUCTS,
    comment: "Product name: Sugar, Ethanol"
  }),
  _ts_metadata10("design:type", typeof RaizenMetadataProductType === "undefined" ? Object : RaizenMetadataProductType)
], RaizenMetadata.prototype, "productType", void 0);
_ts_decorate10([
  (0, import_typeorm11.Column)({
    type: "enum",
    name: "deforestation_free",
    nullable: true,
    enum: USE_TYPES,
    comment: "Deforested area: regular/irregular."
  }),
  _ts_metadata10("design:type", typeof UseType === "undefined" ? Object : UseType)
], RaizenMetadata.prototype, "deforestationFree", void 0);
_ts_decorate10([
  (0, import_typeorm11.Column)({
    type: "enum",
    name: "use_of_native_tribes_lands",
    nullable: true,
    enum: USE_TYPES,
    comment: "Use of an indigenous area: regular/irregular."
  }),
  _ts_metadata10("design:type", typeof UseType === "undefined" ? Object : UseType)
], RaizenMetadata.prototype, "useOfNativeTribesLands", void 0);
_ts_decorate10([
  (0, import_typeorm11.Column)({
    type: "enum",
    name: "use_of_quilombola_lands",
    nullable: true,
    enum: USE_TYPES,
    comment: "Use of an quilombola area: regular/irregular."
  }),
  _ts_metadata10("design:type", typeof UseType === "undefined" ? Object : UseType)
], RaizenMetadata.prototype, "useOfQuilombolaLands", void 0);
_ts_decorate10([
  DecimalColumn({
    name: "farm_production_quantity",
    precision: 16,
    scale: 4,
    nullable: true,
    comment: "Farm production quantity (by weight or invoice total production percentage)."
  }),
  _ts_metadata10("design:type", typeof import_decimal4.Decimal === "undefined" ? Object : import_decimal4.Decimal)
], RaizenMetadata.prototype, "farmProductionQuantity", void 0);
_ts_decorate10([
  (0, import_typeorm11.Column)("boolean", {
    name: "bonsucro_certification",
    default: false,
    comment: "Has BONSUCRO certification."
  }),
  _ts_metadata10("design:type", Boolean)
], RaizenMetadata.prototype, "bonsucroCertification", void 0);
_ts_decorate10([
  (0, import_typeorm11.Column)("boolean", {
    name: "foodchain_certification",
    default: false,
    comment: "Has FOODCHAIN certification."
  }),
  _ts_metadata10("design:type", Boolean)
], RaizenMetadata.prototype, "foodchainCertification", void 0);
_ts_decorate10([
  (0, import_typeorm11.Column)("varchar", {
    name: "zone",
    length: 3,
    nullable: true,
    comment: `Farm's zone code .`
  }),
  _ts_metadata10("design:type", String)
], RaizenMetadata.prototype, "zone", void 0);
_ts_decorate10([
  (0, import_typeorm11.ManyToOne)(() => Company, (company) => company.id, {
    onDelete: "CASCADE"
  }),
  (0, import_typeorm11.JoinColumn)([
    {
      name: "company_id",
      referencedColumnName: "id"
    }
  ]),
  _ts_metadata10("design:type", typeof Company === "undefined" ? Object : Company)
], RaizenMetadata.prototype, "company", void 0);
RaizenMetadata = _ts_decorate10([
  (0, import_typeorm11.Unique)([
    "uuid"
  ]),
  (0, import_typeorm11.Entity)("raizen_metadata")
], RaizenMetadata);
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  BLOCKCHAIN_STATUS_VALUES,
  CERTIFICATE_REJECTION_REASON,
  CERTIFICATE_REQUEST_STATUS,
  CERTIFICATE_REQUEST_STATUS_FILTER_ADMIN,
  CERTIFICATE_REQUEST_STATUS_FILTER_BASE,
  CERTIFICATE_SHARE_REQUEST_STATUS,
  CERTIFICATE_STATUS,
  COMPANY_TYPES,
  Certificate,
  CertificateData,
  CertificateDataProductionDetail,
  CertificateRejectionReasonTypes,
  CertificateRequest,
  CertificateRequestStatusFilterAdminTypes,
  CertificateRequestStatusFilterBaseTypes,
  CertificateRequestStatusTypes,
  CertificateShareRequest,
  CertificateStatusTypes,
  Company,
  CompanySharedCertificate,
  DecimalColumn,
  DecimalTransformer,
  Entities,
  GREEN_FACTOR_VALUES,
  RAIZEN_METADATA_PRODUCTS,
  RaizenMetadata,
  RaizenMetadataProductTypes,
  USER_ROLES,
  USER_STATUS,
  USE_TYPES,
  UseTypes,
  User,
  VIEWER_USER_ROLES,
  blockchainStatusTypes,
  certificateShareRequestStatusTypes,
  companyTypes,
  greenFactorTypes,
  userRoles,
  userStatus,
  viewerUserRoles
});
