import { Flex } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { SubmitHandler } from 'react-hook-form';
import { useTranslation } from '@raizen/react-hooks';
import { useEffect } from 'react';
import { ContextField } from '@raizen/ui';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { CreateCertificateShareRequestDto } from '../../../services/certificateShareRequest/dtos/CreateCertificateShareRequestDto';
import { UseMutateFunction } from 'react-query';

const resolver = classValidatorResolver(CreateCertificateShareRequestDto);

interface Props {
  formId: string;
  mutate: UseMutateFunction<
    any,
    unknown,
    CreateCertificateShareRequestDto,
    unknown
  >;
  certificateRequestUuid: string;
}

export const RequestCertificateShareForm = ({
  formId,
  mutate,
  certificateRequestUuid,
}: Props) => {
  const { t } = useTranslation();
  const context = useForm<CreateCertificateShareRequestDto>({ resolver });
  const { handleSubmit, setValue } = context;

  const onSubmit: SubmitHandler<CreateCertificateShareRequestDto> = async (
    data,
  ) => {
    mutate(data);
  };

  useEffect(() => {
    setValue('certificateRequestUuid', certificateRequestUuid);
  }, [certificateRequestUuid, setValue]);

  return (
    <Flex
      as="form"
      id={formId}
      onSubmit={handleSubmit(onSubmit)}
      flexDir={'column'}
      gap={2}
      noValidate
    >
      <ContextField
        context={context}
        name="companyName"
        label={t(
          'certificate.share.request.modal.form.companyDetails.field.name',
        )}
        errorMessageFormatter={t}
        placeholder={t(
          'certificate.share.request.modal.form.companyDetails.field.name.placeholder',
        )}
      />
      <ContextField
        context={context}
        name="companyFiscalId"
        label={t(
          'certificate.share.request.modal.form.companyDetails.field.fiscalId',
        )}
        placeholder={t(
          'certificate.share.request.modal.form.companyDetails.field.fiscalId.placeholder',
        )}
        errorMessageFormatter={t}
      />
      <ContextField
        context={context}
        name="email"
        label={t(
          'certificate.share.request.modal.form.userDetails.field.email',
        )}
        placeholder={t(
          'certificate.share.request.modal.form.userDetails.field.email.placeholder',
        )}
        errorMessageFormatter={t}
      />
      <ContextField
        context={context}
        name="firstName"
        label={t(
          'certificate.share.request.modal.form.userDetails.field.firstName',
        )}
        placeholder={t(
          'certificate.share.request.modal.form.userDetails.field.firstName.placeholder',
        )}
        errorMessageFormatter={t}
      />
      <ContextField
        context={context}
        name="lastName"
        label={t(
          'certificate.share.request.modal.form.userDetails.field.lastName',
        )}
        placeholder={t(
          'certificate.share.request.modal.form.userDetails.field.lastName.placeholder',
        )}
        errorMessageFormatter={t}
      />
    </Flex>
  );
};
