import {
  SearchInput,
  TableWrapper,
  Table,
  Pagination,
  DateCalendar,
} from '@raizen/ui';
import { TableInstance } from 'react-table';
import { CERTIFICATE_REQUEST_STATUS, GREEN_FACTOR_VALUES } from '@raizen/db';
import { Box, Button, Flex, Select } from '@chakra-ui/react';
import { useTranslation } from '@raizen/react-hooks';
import MultiSelectDropdown from '../../shared/MultiSelectDropdown';
import { useCertificatesTableContext } from './CertificatesTableContext';

export function CertificatesTable(): React.ReactElement {
  const { t } = useTranslation();

  const {
    filterInput,
    handleFilterChange,
    handleFiltersReset,
    canFilterCertificateByGreenFactor,
    canFilterCertificateByStatus,
    canManageCertificateRequests,
    handleStatusFilterChange,
    fullDateFilter,
    setFullDateFilter,
    greenFactorFilter,
    setGreenFactorFilter,
    statusFilter,
    availableStatusFilters,
    tableInstance,
    isLoading,
    setPage,
    data,
  } = useCertificatesTableContext();

  return (
    <>
      <TableWrapper
        searchComponent={
          <SearchInput
            value={filterInput}
            onChange={handleFilterChange}
            placeholder={t('certificateRequests.table.filters.invoice')}
          />
        }
        quickFilters={
          <Flex
            gap={'16px'}
            flexDir={{ base: 'column', md: 'column', lg: 'row' }}
          >
            {canFilterCertificateByStatus ? (
              <Select
                minWidth={'fit-content'}
                placeholder={t(`certificateRequests.table.columns.status`)}
                textColor={'gray.500'}
                onChange={handleStatusFilterChange}
                value={statusFilter ?? ''}
              >
                {availableStatusFilters.map((status, key) => {
                  if (
                    !canManageCertificateRequests &&
                    status === CERTIFICATE_REQUEST_STATUS.PENDING
                  ) {
                    return (
                      <option value={status} key={key}>
                        {t(`certificateRequests.status.IN_REVIEW`)}
                      </option>
                    );
                  } else {
                    return (
                      <option value={status} key={key}>
                        {t(`certificateRequests.status.${status}`)}
                      </option>
                    );
                  }
                })}
              </Select>
            ) : null}

            <DateCalendar
              placeholder={t(`certificateRequests.table.columns.invoiceDate`)}
              onChange={setFullDateFilter}
              maxDate={new Date()}
              value={fullDateFilter}
            />

            {canFilterCertificateByGreenFactor ? (
              <MultiSelectDropdown
                options={[
                  {
                    value: GREEN_FACTOR_VALUES.GOLD,
                    text: t('greenFactor.GOLD'),
                  },
                  {
                    value: GREEN_FACTOR_VALUES.SILVER,
                    text: t('greenFactor.SILVER'),
                  },
                  {
                    value: GREEN_FACTOR_VALUES.BRONZE,
                    text: t('greenFactor.BRONZE'),
                  },
                  {
                    value: GREEN_FACTOR_VALUES.STANDARD,
                    text: t('greenFactor.STANDARD'),
                  },
                ]}
                selectedOptions={greenFactorFilter}
                setSelectedOptions={setGreenFactorFilter}
                placeholder={t('certificateRequests.table.columns.greenFactor')}
                allText={t('common.all')}
              />
            ) : null}

            <Button
              variant={'link'}
              minWidth={'fit-content'}
              size={'sm'}
              onClick={handleFiltersReset}
            >
              {t(`common.resetFilters`)}
            </Button>
          </Flex>
        }
      >
        <Table
          tableInstance={tableInstance as TableInstance<any>}
          isLoading={isLoading}
          emptyStateContent={{
            title: t('certificateRequests.table.emptyState.title'),
            description: t('certificateRequests.table.emptyState.description'),
          }}
        />
      </TableWrapper>
      <Box my="3">
        <Pagination
          currentPage={data?.paginationData.currentPage!}
          totalPageCount={data?.paginationData.totalPages!}
          previousButtonLabel={t('common.pagination.previous')}
          nextButtonLabel={t('common.pagination.next')}
          onPageChange={setPage}
        />
      </Box>
    </>
  );
}
