import { Alert } from '@raizen/ui';
import { CertificateRequestSerializer } from '@raizen/serializers';
import { useStatusAlert } from './useStatusAlert';
import { useNoCertificateDataAlert } from './useNoCertificateDataAlert';
import { useInvoiceDuplicatedAlert } from './useInvoiceDuplicatedAlert';

interface Props {
  certificateRequest: CertificateRequestSerializer;
}

export const CertificateAlerts = ({ certificateRequest }: Props) => {
  const {
    showStatusAlert,
    statusAlertVariant,
    statusAlertTitle,
    statusAlertDescription,
  } = useStatusAlert(certificateRequest);

  const {
    showNoCertificateDataAlert,
    noCertificateDataVariant,
    noCertificateDataTitle,
    noCertificateDataDescription,
  } = useNoCertificateDataAlert(certificateRequest);

  const {
    showInvoiceDuplicatedAlert,
    invoiceDuplicatedVariant,
    invoiceDuplicatedTitle,
    invoiceDuplicatedDescription,
  } = useInvoiceDuplicatedAlert(certificateRequest);

  return (
    <>
      {showStatusAlert && (
        <Alert
          className="remove-for-print-view"
          variant={statusAlertVariant}
          title={statusAlertTitle}
          description={statusAlertDescription}
          showCloseButton={false}
        />
      )}
      {showInvoiceDuplicatedAlert && (
        <Alert
          className="remove-for-print-view"
          variant={invoiceDuplicatedVariant}
          title={invoiceDuplicatedTitle}
          description={invoiceDuplicatedDescription}
          showCloseButton={false}
        />
      )}
      {showNoCertificateDataAlert && (
        <Alert
          className="remove-for-print-view"
          variant={noCertificateDataVariant}
          title={noCertificateDataTitle}
          description={noCertificateDataDescription}
          showCloseButton={false}
        />
      )}
    </>
  );
};
