import { Outlet, useNavigate } from 'react-router-dom';
import { ExplorerLogo, Navigation as BaseNavigation } from '@raizen/ui';
import { Box, Divider, Flex } from '@chakra-ui/react';
import { paths } from '../routes/consts/paths';
import { useUser } from '../contexts/UserContext';
import { useUserMenuOptions } from '../components/Layout/NavBar/useUserMenuOptions';
import { useNavbarLinks } from '../components/Layout/NavBar/useNavbarLinks';
import { DropdownMenu } from '../components/Layout/NavBar/DropdownMenu';
import { CurrentUserInfo } from '../components/Layout/NavBar/CurrentUserInfo';

export const Navigation = () => {
  const navigate = useNavigate();
  const userMenuOptions = useUserMenuOptions();
  const { isLoadingUser, user } = useUser();
  const { navigationLinks, currentNavigationIndex } = useNavbarLinks();

  return (
    <>
      <BaseNavigation
        currentNavigationIndex={currentNavigationIndex}
        logo={
          <Box
            onClick={() => {
              navigate(paths.home);
            }}
            cursor="pointer"
            className="explorer-logo-container"
          >
            <ExplorerLogo />
          </Box>
        }
        navigationLinks={navigationLinks}
        desktopRightElements={
          !isLoadingUser
            ? [<DropdownMenu userMenuOptions={userMenuOptions} />]
            : null
        }
        mobileOptions={[
          <CurrentUserInfo
            firstName={user?.firstName}
            lastName={user?.lastName}
            email={user?.email}
            companyName={user?.companyName}
          />,
          <Divider />,

          ...userMenuOptions.map(({ id, onClick, icon, label }, index) => (
            <Flex key={id + index} onClick={onClick} alignItems={'center'}>
              {icon}
              {label}
            </Flex>
          )),
        ]}
      />
      <Outlet />
    </>
  );
};
