import { Navigate, createBrowserRouter } from 'react-router-dom';
import { paths } from './consts/paths';
import { Navigation } from '../Navigation/Navigation';
import { Home } from '../Navigation/pages/Home';
import { StockReserves } from '../Navigation/pages/StockReserves';
import { PrivatePage, loginCallbackUrl, logoutRoute } from '../auth';
import { LoginCallback } from '@raizen/client-auth';
import { NavigationHash } from '../Navigation/HashWrapper';
import { CertificateRequestCreation } from '../Navigation/pages/CertificateRequestCreation';
import { CertificateRequestDetails } from '../Navigation/pages/CertificateRequestDetails';
import { Users } from '../Navigation/pages/Users';
import { Admins } from '../Navigation/pages/Admins';
import { NotFound } from '../Navigation/pages/NotFound';
import { SharingRequests } from '../Navigation/pages/SharingRequests';
import { UserProvider } from '../contexts/UserContext';
// import { RaizenWebSocketProvider } from '../contexts/RaizenWebSocketProvider';
import { RestrictedRoute } from './RestrictedRoute';
import { PERFORM_ACTION } from '@raizen/casl-authorization';
import { PrintingStateProvider } from '../contexts/PrintingStateProvider';
import { CertificatesTableProvider } from '../components/Home/CertificatesTable/CertificatesTableContext';

export const router = createBrowserRouter([
  {
    path: paths.home,
    element: (
      <PrivatePage>
        <UserProvider>
          {/* <RaizenWebSocketProvider> */}
          <PrintingStateProvider>
            <Navigation />
          </PrintingStateProvider>
          {/* </RaizenWebSocketProvider> */}
        </UserProvider>
      </PrivatePage>
    ),
    children: [
      {
        path: paths.home,
        element: (
          <CertificatesTableProvider>
            <Home />
          </CertificatesTableProvider>
        ),
      },
      {
        path: paths.certificates,
        element: <Home />,
      },
      {
        path: paths.newCertificate,
        element: <CertificateRequestCreation />,
      },
      {
        path: paths.stockReserves,
        element: <StockReserves />,
      },
      {
        path: paths.users,
        element: (
          <RestrictedRoute action={PERFORM_ACTION.Admin} subject="User">
            <Users />
          </RestrictedRoute>
        ),
      },
      {
        path: paths.admins,
        element: (
          <RestrictedRoute action={PERFORM_ACTION.Admin} subject="User">
            <Admins />
          </RestrictedRoute>
        ),
      },
      {
        path: paths.sharingRequests,
        element: (
          <RestrictedRoute
            action={PERFORM_ACTION.Admin}
            subject="CertificateShareRequest"
          >
            <SharingRequests />
          </RestrictedRoute>
        ),
      },
      {
        path: paths.certificateRequestDetails,
        element: <CertificateRequestDetails />,
      },
      logoutRoute,
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
  {
    path: loginCallbackUrl,
    element: (
      // This Wrapper component was needed since useLocation and Navigate can only be called from the element component
      // Here we take what we need and pass it down to our component
      <NavigationHash>
        {(hash) => (
          <LoginCallback
            homePath={paths.home}
            hash={hash}
            Navigate={Navigate}
          />
        )}
      </NavigationHash>
    ),
  },
]);
