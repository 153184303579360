"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.ts
var src_exports = {};
__export(src_exports, {
  AbilityFactory: () => AbilityFactory,
  PERFORM_ACTION: () => PERFORM_ACTION,
  actionTypes: () => actionTypes
});
module.exports = __toCommonJS(src_exports);

// src/actions.enum.ts
var PERFORM_ACTION = {
  Create: "create",
  Read: "read",
  ReadType: {
    Complete: "read:complete",
    Partial: "read:partial",
    GreenFactorOnPendingStatus: "read:greenFactorOnPendingStatus"
  },
  FilterBy: {
    greenFactor: "filter:greenFactor",
    status: "filter:status"
  },
  List: "list",
  ListOwnership: {
    All: "list:all",
    Own: "list:own",
    Shared: "list:shared"
  },
  SeeGreenFactor: "seeGreenFactor",
  Update: "update",
  Delete: "delete",
  Approve: "approve",
  Export: "export",
  Confirm: "confirm",
  Admin: "admin",
  Receive: "receive",
  Sync: "sync"
};
function generateActionType(actionTypes2) {
  return Object.values(actionTypes2).reduce((result, value) => {
    if (typeof value === "string") {
      result.push(value);
    } else {
      result.push(...generateActionType(value));
    }
    return result;
  }, []);
}
var actionTypes = generateActionType(PERFORM_ACTION);

// src/ability.factory.ts
var import_ability = require("@casl/ability");
var import_db = require("@raizen/db");
var AbilityFactory = class {
  createForUser(role, companyId) {
    const { can, build } = new import_ability.AbilityBuilder(import_ability.createMongoAbility);
    if (role === import_db.USER_ROLES.ADMIN) {
      can(PERFORM_ACTION.Admin, "CertificateRequest");
      can(PERFORM_ACTION.List, "CertificateRequest");
      can(PERFORM_ACTION.List, "RaizenMetadata");
      can(PERFORM_ACTION.ListOwnership.All, "CertificateRequest");
      can(PERFORM_ACTION.Read, "CertificateRequest");
      can(PERFORM_ACTION.ReadType.Complete, "CertificateRequest");
      can(PERFORM_ACTION.SeeGreenFactor, "CertificateRequest");
      can(
        PERFORM_ACTION.ReadType.GreenFactorOnPendingStatus,
        "CertificateRequest"
      );
      can(PERFORM_ACTION.Approve, "CertificateRequest");
      can(PERFORM_ACTION.Sync, "CertificateRequest");
      can(PERFORM_ACTION.Read, "Certificate");
      can(PERFORM_ACTION.Export, "Certificate");
      can(PERFORM_ACTION.ReadType.Complete, "Certificate");
      can(PERFORM_ACTION.FilterBy.greenFactor, "Certificate");
      can(PERFORM_ACTION.FilterBy.status, "Certificate");
      can(PERFORM_ACTION.Admin, "Company");
      can(PERFORM_ACTION.List, "Company");
      can(PERFORM_ACTION.ListOwnership.All, "Company");
      can(PERFORM_ACTION.Admin, "User");
      can(PERFORM_ACTION.Create, "User", {
        role: {
          $in: [import_db.USER_ROLES.ADMIN, import_db.USER_ROLES.CLIENT]
        }
      });
      can(PERFORM_ACTION.Read, "User", {
        role: {
          $in: [
            import_db.USER_ROLES.ADMIN,
            import_db.USER_ROLES.CLIENT,
            import_db.USER_ROLES.VIEWER_FULL,
            import_db.USER_ROLES.VIEWER_RESTRICTED
          ]
        }
      });
      can(PERFORM_ACTION.List, "User", {
        role: {
          $in: [
            import_db.USER_ROLES.ADMIN,
            import_db.USER_ROLES.CLIENT,
            import_db.USER_ROLES.VIEWER_FULL,
            import_db.USER_ROLES.VIEWER_RESTRICTED
          ]
        }
      });
      can(PERFORM_ACTION.Delete, "User");
      can(PERFORM_ACTION.Admin, "CertificateShareRequest");
      can(PERFORM_ACTION.List, "CertificateShareRequest");
      can(PERFORM_ACTION.Approve, "CertificateShareRequest");
    }
    if (role === import_db.USER_ROLES.CLIENT) {
      can(PERFORM_ACTION.List, "CertificateRequest");
      can(PERFORM_ACTION.ListOwnership.Own, "CertificateRequest");
      can(PERFORM_ACTION.SeeGreenFactor, "CertificateRequest", {
        status: { $eq: import_db.CERTIFICATE_REQUEST_STATUS.COMPLETED }
      });
      can(PERFORM_ACTION.Read, "CertificateRequest", {
        companyId: { $eq: companyId }
      });
      can(PERFORM_ACTION.ReadType.Partial, "CertificateRequest");
      can(PERFORM_ACTION.Create, "CertificateRequest", {
        companyId: { $eq: companyId }
      });
      can(PERFORM_ACTION.Confirm, "CertificateRequest", {
        companyId: { $eq: companyId }
      });
      can(PERFORM_ACTION.Read, "Certificate", {
        companyId: { $eq: companyId }
      });
      can(PERFORM_ACTION.ReadType.Complete, "Certificate");
      can(PERFORM_ACTION.FilterBy.greenFactor, "Certificate");
      can(PERFORM_ACTION.FilterBy.status, "Certificate");
      can(PERFORM_ACTION.ListOwnership.Shared, "Company");
      can(PERFORM_ACTION.List, "CertificateShareRequest");
      can(PERFORM_ACTION.Create, "CertificateShareRequest");
    }
    if (role === import_db.USER_ROLES.VIEWER_FULL) {
      can(PERFORM_ACTION.List, "CertificateRequest");
      can(PERFORM_ACTION.ListOwnership.Shared, "CertificateRequest");
      can(PERFORM_ACTION.SeeGreenFactor, "CertificateRequest", {
        status: { $eq: import_db.CERTIFICATE_REQUEST_STATUS.COMPLETED }
      });
      can(PERFORM_ACTION.Read, "Certificate", {
        companySharedCertificates: {
          $elemMatch: { companyId }
        }
      });
      can(PERFORM_ACTION.ReadType.Complete, "Certificate");
      can(PERFORM_ACTION.FilterBy.greenFactor, "Certificate");
      can(PERFORM_ACTION.Receive, "CertificateShareRequest");
    }
    if (role === import_db.USER_ROLES.VIEWER_RESTRICTED) {
      can(PERFORM_ACTION.List, "CertificateRequest");
      can(PERFORM_ACTION.ListOwnership.Shared, "CertificateRequest");
      can(PERFORM_ACTION.Read, "Certificate", {
        companySharedCertificates: {
          $elemMatch: { companyId }
        }
      });
      can(PERFORM_ACTION.ReadType.Partial, "Certificate");
      can(PERFORM_ACTION.Receive, "CertificateShareRequest");
    }
    return build();
  }
  createForAnon() {
    const { can, build } = new import_ability.AbilityBuilder(import_ability.createMongoAbility);
    return build();
  }
};
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  AbilityFactory,
  PERFORM_ACTION,
  actionTypes
});
