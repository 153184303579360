import React from 'react';
import {
  SearchInput,
  TableWrapper,
  Table,
  Pagination,
  DateCalendar,
} from '@raizen/ui';
import { TableInstance, useTable } from 'react-table';
import { usePagination } from 'react-table';
import { Box, Button, Flex, Select } from '@chakra-ui/react';
import { useTranslation } from '@raizen/react-hooks';
import { useViewers } from './useViewers';
import { certificateShareRequestStatusTypes } from '@raizen/db';

interface Props {
  uuid: string;
}

export const ViewersTable = ({ uuid }: Props) => {
  const { t } = useTranslation();

  const {
    data,
    isLoading,
    columns,
    filter,
    status,
    fullDateFilter,
    handleFilterChange,
    handleStatusFilterChange,
    handleFiltersReset,
    setFullDateFilter,
    setPage,
  } = useViewers(uuid);

  const tableData = React.useMemo(
    () => data?.data || [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(data)],
  );

  const tableInstance = useTable(
    {
      columns: columns as any,
      data: tableData,
    },
    usePagination,
  );

  return (
    <>
      <TableWrapper
        searchComponent={
          <SearchInput
            value={filter}
            onChange={handleFilterChange}
            placeholder={t('viewersTable.filter')}
          />
        }
        quickFilters={
          <Flex
            gap={'16px'}
            flexDir={{ base: 'column', md: 'column', lg: 'row' }}
          >
            <Select
              minWidth={'fit-content'}
              placeholder={t(`certificateRequests.table.columns.status`)}
              textColor={'gray.500'}
              onChange={handleStatusFilterChange}
              value={status ?? ''}
            >
              {certificateShareRequestStatusTypes.map((status, key) => (
                <option value={status} key={key}>
                  {t(`sharingRequests.status.${status}`)}
                </option>
              ))}
            </Select>

            <DateCalendar
              placeholder={t(`sharingRequests.table.columns.createdAt`)}
              onChange={setFullDateFilter}
              maxDate={new Date()}
              value={fullDateFilter}
            />

            <Button
              variant={'link'}
              minWidth={'fit-content'}
              size={'sm'}
              onClick={handleFiltersReset}
            >
              {t(`common.resetFilters`)}
            </Button>
          </Flex>
        }
      >
        <Table
          tableInstance={tableInstance as TableInstance<object>}
          isLoading={isLoading}
          emptyStateContent={{
            title: t('viewersTable.emptyState.title'),
            description: t('viewersTable.emptyState.description'),
          }}
        />
      </TableWrapper>
      <Box my="3">
        <Pagination
          currentPage={data?.paginationData.currentPage!}
          totalPageCount={data?.paginationData.totalPages!}
          previousButtonLabel={t('common.pagination.previous')}
          nextButtonLabel={t('common.pagination.next')}
          onPageChange={setPage}
        />
      </Box>
    </>
  );
};
