import { CertificateRequestSerializer } from '@raizen/serializers';
import { Card } from '@raizen/ui';
import { CertificateAlerts } from './Alerts';
import { GeneralInformationSection } from './Sections/GeneralInformationSection';
import { ProductInformationSection } from './Sections/ProductInformationSection';

interface Props {
  certificateRequest: CertificateRequestSerializer;
}

export const RestrictedView = ({ certificateRequest }: Props) => {
  const { companyName, userName, accessKey, certificateData } =
    certificateRequest;

  const data = certificateData?.[0];

  return (
    <>
      <CertificateAlerts certificateRequest={certificateRequest} />
      <Card
        p={8}
        gap={12}
        className="certificate-request-detail-container remove-py-for-print-view"
      >
        <GeneralInformationSection
          companyName={companyName}
          userName={userName}
          invoiceAccessKey={accessKey}
          certificateData={data}
        />

        <ProductInformationSection certificateData={data} />
      </Card>
    </>
  );
};
