import { httpClient } from '@raizen/client-auth';
import { ListCertificateSharedSerializer } from '@raizen/serializers';

export const getAvailableCompaniesToShare = async (uuid: string) => {
  const { data } = await httpClient.get<ListCertificateSharedSerializer[]>(
    `certificate-requests/${uuid}/companies`,
  );

  return data;
};
