import { formatDate } from '@raizen/common-utils';
import { useTranslation } from '@raizen/react-hooks';
import { LocaleKeys } from '@raizen/translations';
import { AlertIcon, Badge } from '@raizen/ui';
import { useMemo } from 'react';
import { CellProps } from 'react-table';
import {
  certificateRequestsStatusBadgeColors,
  greenFactorBadgeColors,
} from '@raizen/frontend-utils';
import { useUser } from '../../../contexts/UserContext';
import { PERFORM_ACTION } from '@raizen/casl-authorization';
import { ListCertificateRequestsSerializer } from '@raizen/serializers';
import { Box, Button, Flex } from '@chakra-ui/react';
import { CERTIFICATE_REQUEST_STATUS } from '@raizen/db';
import { TextTruncate } from '../../shared/TextTruncate';
import { CopyToClipboardButton } from '../../shared/CopyToClipboardButton';
import { useNavigate } from 'react-router-dom';

export const useColumns = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { ability } = useUser();

  const canListAll = ability?.can(
    PERFORM_ACTION.ListOwnership.All,
    'CertificateRequest',
  );
  const canListOwn = ability?.can(
    PERFORM_ACTION.ListOwnership.Own,
    'CertificateRequest',
  );
  const canListShared = ability?.can(
    PERFORM_ACTION.ListOwnership.Shared,
    'CertificateRequest',
  );
  const canReadCompleteCertificate = ability?.can(
    PERFORM_ACTION.ReadType.Complete,
    'Certificate',
  );

  const showCompanies = canListAll || canListShared;
  const showStatus = canListAll || canListOwn;
  const showGreenFactor = canReadCompleteCertificate;

  const columns = useMemo(
    () =>
      [
        {
          Header: t('certificateRequests.table.columns.accessKey'),
          accessor: 'accessKey',
          Cell: (props: CellProps<ListCertificateRequestsSerializer>) => {
            const accessKey = props.row.original.accessKey;
            const certRequestUuid = props.row.original.uuid;
            return (
              <Flex
                gap={2}
                alignItems={'center'}
                float="left"
                position="relative"
              >
                <Button
                  alignSelf={'start'}
                  variant="link"
                  onClick={() => navigate(`/certificates/${certRequestUuid}`)}
                  color="primary"
                  size={'sm'}
                  mt={1}
                >
                  #<TextTruncate text={accessKey} max={24} />
                </Button>
                <CopyToClipboardButton text={accessKey} />
              </Flex>
            );
          },
        },
        showCompanies
          ? {
              Header: t('common.company'),
              accessor: 'companyName',
            }
          : null,
        {
          Header: t('certificateRequests.table.columns.productType'),
          accessor: 'productType',
          Cell: (props: CellProps<ListCertificateRequestsSerializer>) => {
            const productType = props.row.original.productType;
            return productType ? t(`productType.${productType}`) : '-';
          },
        },
        showGreenFactor
          ? {
              Header: t('certificateRequests.table.columns.greenFactor'),
              accessor: 'greenFactor',
              Cell: (props: CellProps<ListCertificateRequestsSerializer>) => {
                const greenFactor = props.row.original.greenFactor || [];
                const filteredGreenFactor = greenFactor.filter(Boolean);

                return filteredGreenFactor.length ? (
                  <Flex gap={2}>
                    {greenFactor.map((greenFactor) => {
                      return (
                        <Badge
                          colorScheme={greenFactorBadgeColors[greenFactor]}
                        >
                          {t(`greenFactor.${greenFactor}` as LocaleKeys)}
                        </Badge>
                      );
                    })}
                  </Flex>
                ) : (
                  '-'
                );
              },
            }
          : null,
        {
          Header: t('certificateRequests.table.columns.invoiceDate'),
          accessor: 'createdAt',
          Cell: (props: CellProps<ListCertificateRequestsSerializer>) => {
            if (props.row.original.invoiceDate) {
              return formatDate(Number(props.row.original.invoiceDate));
            } else {
              return '-';
            }
          },
        },
        showStatus
          ? {
              Header: t('certificateRequests.table.columns.status'),
              accessor: 'status',
              Cell: (props: CellProps<ListCertificateRequestsSerializer>) => {
                const status = props.row.original.status;
                switch (status) {
                  case CERTIFICATE_REQUEST_STATUS.PENDING:
                    if (!canListAll) {
                      return (
                        status && (
                          <Badge
                            colorScheme={
                              certificateRequestsStatusBadgeColors[status]
                            }
                          >
                            {t(`certificateRequests.status.IN_REVIEW`)}
                          </Badge>
                        )
                      );
                    } else {
                      return (
                        status && (
                          <Badge
                            colorScheme={
                              certificateRequestsStatusBadgeColors[status]
                            }
                          >
                            {t(`certificateRequests.status.${status}`)}
                          </Badge>
                        )
                      );
                    }
                  case CERTIFICATE_REQUEST_STATUS.NOT_FOUND:
                    if (canListAll) {
                      return (
                        status && (
                          <Badge
                            colorScheme={
                              certificateRequestsStatusBadgeColors[status]
                            }
                          >
                            <Flex alignItems={'center'}>
                              <AlertIcon color={'#AB6900'} />
                              <Box ml={'1'}>
                                {t(`certificateRequests.status.${status}`)}
                              </Box>
                            </Flex>
                          </Badge>
                        )
                      );
                    } else {
                      return (
                        status && (
                          <Badge
                            colorScheme={
                              certificateRequestsStatusBadgeColors[
                                CERTIFICATE_REQUEST_STATUS.PENDING
                              ]
                            }
                          >
                            {t(`certificateRequests.status.IN_REVIEW`)}
                          </Badge>
                        )
                      );
                    }

                  default:
                    return (
                      status && (
                        <Badge
                          colorScheme={
                            certificateRequestsStatusBadgeColors[status]
                          }
                        >
                          {t(`certificateRequests.status.${status}`)}
                        </Badge>
                      )
                    );
                }
              },
            }
          : null,
      ].filter(Boolean),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showCompanies, showStatus],
  );

  return { columns };
};
