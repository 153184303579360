import { httpClient } from '@raizen/client-auth';
import { GetCurrentUserResponse } from '@raizen/types';

export const getCurrentUserPath = '/users/current';

export const getCurrentUser = async () => {
  const { data } =
    await httpClient.get<GetCurrentUserResponse>(getCurrentUserPath);

  return data;
};
