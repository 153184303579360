import React from 'react';
import ReactDOM from 'react-dom/client';
import '@raizen/ui/src/theme/fonts/index.css';
import { RouterProvider } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { TranslationsProvider } from '@raizen/react-hooks';
import { ThemeProvider } from '@raizen/ui';
import { router } from './routes/router';
import { AuthProvider } from '@raizen/client-auth';
import { createStandaloneToast } from '@chakra-ui/toast';
import { ReactQueryProvider } from './contexts/ReactQueryProvider';
const { ToastContainer } = createStandaloneToast();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    {/* //TODO: try to migrate this to the component library. We tried but didnt get to work. */}
    <ToastContainer />
    <ThemeProvider>
      <ReactQueryProvider>
        <TranslationsProvider>
          <AuthProvider>
            <RouterProvider router={router} />
          </AuthProvider>
        </TranslationsProvider>
      </ReactQueryProvider>
    </ThemeProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
