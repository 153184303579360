import {
  Box,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  StepperProps,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useTranslation } from '@raizen/react-hooks';

interface props {
  actualStep: number;
}

export const CertificateRequestCreationStepper = (props: props) => {
  const { t } = useTranslation();
  const steps = [
    { title: '', description: t('certificateRequestCreation.stepper.step1') },
    { title: '', description: t('certificateRequestCreation.stepper.step2') },
    { title: '', description: t('certificateRequestCreation.stepper.step3') },
  ];

  const orientation = useBreakpointValue(
    { base: 'vertical', sm: 'vertical', md: 'horizontal' },
    {
      fallback: 'md',
    }
  ) as StepperProps['orientation'];

  return (
    <Stepper
      index={props.actualStep}
      size={'sm'}
      colorScheme="gray"
      orientation={orientation}
    >
      {steps.map((step, index) => (
        <Step key={index}>
          <StepIndicator>
            <StepStatus
              complete={<StepIcon />}
              incomplete={<StepNumber />}
              active={<StepNumber />}
            />
          </StepIndicator>

          <Box flexShrink="0">
            <StepTitle>{step.title}</StepTitle>
            <StepDescription>{step.description}</StepDescription>
          </Box>

          <StepSeparator />
        </Step>
      ))}
    </Stepper>
  );
};
