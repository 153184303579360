import React from 'react';
import { useLocation } from 'react-router-dom';

interface WithHashProps {
  children: (hash: string) => React.ReactNode;
}

export const NavigationHash: React.FC<WithHashProps> = ({ children }) => {
  const location = useLocation();
  const hash = location.hash;

  return <>{children(hash)}</>;
};
