"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.ts
var src_exports = {};
__export(src_exports, {
  locales_EN: () => locales_EN,
  locales_PT: () => locales_PT
});
module.exports = __toCommonJS(src_exports);

// src/locales/en.ts
var locales_EN = {
  "errors.email.invalid": "Email must be an email",
  "errors.username.invalid": "Username must have between 2 and 30 characters",
  "auth.login": "Login",
  "auth.logout": "Logout",
  "greenFactor.BRONZE": "Bronze",
  "greenFactor.SILVER": "Silver",
  "greenFactor.GOLD": "Gold",
  "greenFactor.STANDARD": "Standard",
  "productType.SUGAR": "Sugar",
  "productType.ETHANOL": "Ethanol",
  "certificateRequests.table.requestedTab": "Requested",
  "certificateRequests.table.notRequestedTab": "Not requested",
  "certificateRequests.table.columns.id": "ID",
  "certificateRequests.table.columns.accessKey": "Access key",
  "certificateRequests.table.columns.productType": "Product",
  "certificateRequests.table.columns.greenFactor": "Green Factor",
  "certificateRequests.table.columns.createdAt": "Requested at",
  "certificateRequests.table.columns.requestedAt": "Requested Date",
  "certificateRequests.table.columns.status": "Status",
  "certificateRequests.table.columns.invoiceDate": "Invoice Date",
  "certificateRequests.table.filters.invoice": "Search by access key...",
  "certificateRequests.table.emptyState.title": "No results found",
  "certificateRequests.table.emptyState.description": "Try adjusting your search options or filters to find what you're looking for.",
  "certificateRequests.status.CREATED": "Created",
  "certificateRequests.status.PENDING": "Pending",
  "certificateRequests.status.NOT_REQUESTED": "Not requested",
  "certificateRequests.status.IN_PROGRESS": "Processing",
  "certificateRequests.status.COMPLETED": "Issued",
  "certificateRequests.status.DENIED": "Rejected",
  "certificateRequests.status.FAILED_IN_BLOCKCHAIN": "Failed",
  "certificateRequests.status.NOT_FOUND": "Not found",
  "certificateRequests.status.IN_REVIEW": "In review",
  "home.title": "Welcome, {{name}}",
  "home.subtitle": "Manage your Sustainability Certificates here.",
  "home.subtitle.shared": "Access certificates shared with you here.",
  "home.table.title": "My certificates",
  "home.table.title.shared": "Shared certificates",
  "home.requestNewCertificateButton.label": "Request new Sustainability Certificate",
  "users.title": "User management",
  "users.inviteNewUser": "Invite new user",
  "users.table.filter": "Search by company, email...",
  "users.table.emptyState.title": "No results found",
  "users.table.emptyState.description": "Try adjusting your search options or filters to find what you're looking for.",
  "users.table.columns.company": "Company",
  "users.table.columns.role": "Role",
  "users.table.columns.email": "Email",
  "users.table.columns.lastLogin": "Last login",
  "users.table.columns.status": "Status",
  "users.table.columns.actions": "Actions",
  "users.role.ADMIN": "Admin",
  "users.role.CLIENT": "Client",
  "users.role.VIEWER_FULL": "Viewer",
  "users.role.VIEWER_RESTRICTED": "Restricted Viewer",
  "users.status.INVITED": "Invited",
  "users.status.ACTIVE": "Active",
  "users.status.INACTIVE": "Inactive",
  "users.newUserModal.title": "Invite new user",
  "users.newUserModal.description": "This user will be able to request sustainability certificates.",
  "users.newUserModal.cancelButton": "Cancel",
  "users.newUserModal.confirmButton": "Invite user",
  "users.newUserForm.fields.firstName.label": "First name",
  "users.newUserForm.fields.firstName.placeholder": "John",
  "users.newUserForm.fields.lastName.label": "Last name",
  "users.newUserForm.fields.lastName.placeholder": "Doe",
  "users.newUserForm.fields.email.label": "Email",
  "users.newUserForm.fields.email.placeholder": "johndoe@raizenexplorer.com",
  "users.newUserForm.fields.company.label": "Company",
  "users.newUserForm.fields.company.placeholder": "Search by name...",
  "users.newUserForm.fields.company.hasNotSharedYet": "Try inviting a new company with the button below",
  "users.newUserForm.fields.company.noCompaniesFound": "Your search \u201C{{search}}\u201D did not match any company. Please try again or invite a new company.",
  "users.newAdminModal.title": "Invite new Raizen admin",
  "users.newAdminModal.cancelButton": "Cancel",
  "users.newAdminModal.confirmButton": "Invite user",
  "newUserForm.successToast.description": "User created successfully.",
  "newUserForm.errorToast.description": "We could not create the user. Try again later.",
  "newAdminForm.successToast.description": "Admin created successfully.",
  "newAdminForm.errorToast.description": "We could not create the Admin. Try again later.",
  "users.deactivateModal.title": "Are you sure you want to delete {{userFullName}} from {{companyName}}?",
  "users.deactivateModal.text": "This action cannot be undone.",
  "users.deactivateModal.cancelButton": "Cancel",
  "users.deactivateModal.confirmButton": "Delete",
  "users.deactivate.successToast.description": "User '{{userFullName}}' from '{{companyName}}' deleted successfully.",
  "users.deactivate.errorToast.description": "Error deleting user. Try again later.",
  "admins.title": "Admin management",
  "admins.description": "Manage internal Raizen admins and their accounts here.",
  "admins.inviteNewAdmin": "Invite new admin",
  "admins.table.filter": "Search by name, email...",
  "admins.table.emptyState.title": "No results found",
  "admins.table.emptyState.description": "Try adjusting your search options or filters to find what you're looking for.",
  "admins.table.columns.name": "Name",
  "admins.table.columns.email": "Email",
  "admins.table.columns.status": "Status",
  "admins.table.columns.actions": "Actions",
  "table.actions.deleteUser": "Delete user",
  //Common
  "common.users": "Users",
  "common.usersManagement": "User management",
  "common.adminsManagement": "Admin management",
  "common.certificates": "Certificates",
  "common.certificateRequests": "Certificate requests",
  "common.sharingRequests": "Sharing requests",
  "common.company": "Company",
  "common.accessKey": "Access key",
  "common.invoiceNumber": "Invoice number",
  "common.companyName": "Company name",
  "common.certificateRequester": "Certificate Requester",
  "common.product": "Product",
  "common.bioparkId": "Biopark ID",
  "common.bioparkCityAndState": "Biopark city and state",
  "common.createdAt": "Created at",
  "common.massBalance": "Mass balance",
  "common.productTotalQuantity": "Product total quantity",
  "common.carbonFootprint": "Carbon footprint",
  "common.bonsucroCertification": "Bonsucro certification",
  "common.foodchainCertification": "Foodchain certification",
  "common.certificate": "Certificate",
  "common.invoiceDate": "Invoice Date",
  "common.issued": "Issued",
  "common.transactionHash": "Tx Hash",
  "common.network": "Network",
  "common.productCertifiedQuantity": "Product certified quantity",
  "common.farmName": "Farm",
  "common.zone": "Zone",
  "common.farmLatitude": "Latitude",
  "common.farmLongitude": "Longitude",
  "common.elos": "ELOS",
  "common.isRented": "Is rented",
  "common.car": "CAR",
  "common.deforestationFree": "Deforestation Free",
  "common.useOfNativeTribesLands": "Use of Native Tribes Lands",
  "common.useOfQuilombolaLands": "Use of Quilombola Lands",
  "common.historicalSoilCoverage": "Historical soil coverage",
  "common.generalInformation": "General information",
  "common.blockchainInformation": "Blockchain information",
  "common.productInformation": "Product information",
  "common.farmInformation": "Farm Information",
  "common.tooltip.transactionHash": "Registration number for the certificate on Blockchain",
  "common.tooltip.isRented": "If the farm where the sugar cane was planted belongs to Raizen or not (Foca).",
  "common.tooltip.useOfNativeTribesLands": "If the farm where the sugar cane was planted has not belonged in the past or belongs to Native Tribe Lands. The data source for this information is Incra.",
  "common.tooltip.deforestationFree": "If the farm where the sugar cane was planted is free from any deforestation process. The data source for this information is Ibama.",
  "common.tooltip.useOfQuilombolaLands": "If the farm where the sugar cane was planted has not belonged in the past or belongs to Quilombola Lands. The data source for this information is Incra.",
  "common.tooltip.historicalSoilCoverage": "Historical baseline of which was planted in the area.",
  "common.tooltip.productCertifiedQuantity": "Quantity of product with socio-environmental attributes.",
  "common.tooltip.massBalance": "The measurement and tracking of the quantity of sugar cane entering the process compared to the amount of subproducts obtained, ensuring efficient utilization and minimal waste.",
  "common.tooltip.carbonFootprint": "The total amount of greenhouse gases, primarily carbon dioxide, produced directly or indirectly by an individual, organization, event, or product, representing its impact on climate change.",
  "common.tooltip.foodchainCertification": "A certification which indicates de product derived from sugar cane which indicates sugar cane is Non-GMO.",
  "common.tooltip.bonsucroCertification": "A sustainability certification for sugarcane and its products, ensuring they are produced in an environmentally and socially responsible way.",
  "common.tooltip.car": "The CAR is a national electronic register in Brazil, mandatory for rural properties. It integrates environmental information to facilitate management, planning, and efforts to combat deforestation.\n\n*Raizen is not responsible for the full area of the CAR due to produce in some of the property.",
  "common.certifiedBadge.BRONZE": "Bronze Certified",
  "common.certifiedBadge.SILVER": "Silver Certified",
  "common.certifiedBadge.GOLD": "Gold Certified",
  "common.certifiedBadge.STANDARD": "Standard Certified",
  "common.pagination.previous": "Previous",
  "common.pagination.next": "Next",
  "common.navbar.tabs.certificates": "Certificates",
  "common.errors.pageNotFound": "404 Page Not Found",
  "common.goHome": "Go Home",
  "common.copiedToClipboard": "Copied to clipboard!",
  "common.resetFilters": "Reset filters",
  "common.yes": "Yes",
  "common.no": "No",
  "common.farm": "Farm",
  "common.all": "All",
  "common.certificateHash": "Certificate Hash",
  "breadcrumb.certificates": "Certificates",
  "breadcrumb.certificateRequestsDetails": "Sustainability certificate",
  //Certificate Request Details
  "certificateRequestDetails.action.approve": "Issue",
  "certificateRequestDetails.action.approve.success": "Sustainability certificate approved successfully",
  "certificateRequestDetails.action.approve.modal.title": "Are you sure you want to issue this certificate?",
  "certificateRequestDetails.action.approve.modal.description": "Issuing this certificate will grant the user access to view their certificate. This action cannot be undone.",
  "certificateRequestDetails.action.reject": "Reject",
  "certificateRequestDetails.action.reject.modal.title": "Are you sure you want to reject certificate request #{{uuid}}?",
  "certificateRequestDetails.action.reject.modal.subtitle": "This action cannot be undone.",
  "certificateRequestDetails.action.reject.modal.form.reasonPlaceholder": "Please select a reason.",
  "certificateRequestDetails.action.reject.modal.form.reasonDuplicated": "Invoice duplication.",
  "certificateRequestDetails.action.reject.modal.form.reasonNotFound": "Invoice not found.",
  "certificateRequestDetails.action.reject.modal.form.reasonOther": "Other.",
  "certificateRequestDetails.action.reject.modal.form.messagePlaceholder": `Please provide a brief explanation for the certificate's rejection..`,
  "certificateRequestDetails.action.reject.modal.form.messageLabel": "Comment or observation (optional)",
  "certificateRequestDetails.action.reject.success": "Sustainability certificate rejected successfully",
  "certificateRequestDetails.action.cancel": "Cancel",
  "certificateRequestDetails.alert.COMPLETED.title": "Congratulations! Your certificate request was issued",
  "certificateRequestDetails.alert.COMPLETED.description": "",
  "certificateRequestDetails.alert.DENIED.INVOICE_DUPLICATION.title": "Sustainability certificate was rejected due to invoice duplication",
  "certificateRequestDetails.alert.DENIED.INVOICE_NOT_FOUND.title": "Sustainability certificate was rejected due to invoice not being found",
  "certificateRequestDetails.alert.DENIED.OTHER.title": "Sustainability certificate was rejected",
  "certificateRequestDetails.alert.PENDING.title": "Your certificate request is in review",
  "certificateRequestDetails.alert.PENDING.description": "We are currently reviewing your certification request. Once it's issued, we'll send you an email notification, so you can access your certificate right away.",
  "certificateRequestDetails.alert.IN_PROGRESS.title": "Your certificate request is in review",
  "certificateRequestDetails.alert.IN_PROGRESS.description": "We are currently reviewing your certification request. Once it's approved, we'll send you an email notification, so you can access your certificate right away.",
  "certificateRequestDetails.alert.FAILED_IN_BLOCKCHAIN.title": "Your certificate request failed in Blockchain",
  "certificateRequestDetails.alert.FAILED_IN_BLOCKCHAIN.description": "We are currently facing issues in Blockchain. Our team has been notified and once the issue has been resolved, they will contact you to provide further instructions.",
  "backoffice.certificateRequestDetails.alert.COMPLETED.title": "Sustainability certificate was issued",
  "backoffice.certificateRequestDetails.alert.COMPLETED.description": "",
  "backoffice.certificateRequestDetails.alert.DENIED.INVOICE_DUPLICATION.title": "Sustainability certificate was rejected due to invoice duplication",
  "backoffice.certificateRequestDetails.alert.DENIED.INVOICE_NOT_FOUND.title": "Sustainability certificate was rejected due to invoice not being found",
  "backoffice.certificateRequestDetails.alert.DENIED.OTHER.title": "Sustainability certificate was rejected",
  "backoffice.certificateRequestDetails.alert.PENDING.title": "Your certificate request is in review",
  "backoffice.certificateRequestDetails.alert.PENDING.description": "We are currently reviewing your certification request. Once it's approved, we'll send you an email notification, so you can access your certificate right away.",
  "backoffice.certificateRequestDetails.alert.IN_PROGRESS.title": "The emission of the certificate is being processed in Blockchain",
  "backoffice.certificateRequestDetails.alert.IN_PROGRESS.description": "This might take a few minutes.",
  "backoffice.certificateRequestDetails.alert.FAILED_IN_BLOCKCHAIN.title": "Your certificate request failed in Blockchain",
  "backoffice.certificateRequestDetails.alert.FAILED_IN_BLOCKCHAIN.description": "We are currently facing issues in Blockchain. Our team has been notified and once the issue has been resolved, they will contact you to provide further instructions.",
  "certificateRequestDetails.approvedOn": "",
  "certificateRequestDetails.rejectedOn": "",
  "backoffice.certificateRequestDetails.approvedOn": "Issued on {{date}}.",
  "backoffice.certificateRequestDetails.rejectedOn": "Rejected on {{date}}.",
  "certificateRequestDetails.alert.noCertificateData.title": "Access key couldn\u2019t be located in the database, so you can't view a complete preview.",
  "certificateRequestDetails.alert.noCertificateData.description": "Contact raizen-support@agrotoken.io to verify the access key's existence. If found, a button will appear for database synchronization. If not found, you will be notified to reject the request.",
  "certificateRequestDetails.alert.accessKeyDuplicated.title": "Invoice \u2018{{accessKey}}\u2019 has already been used to emit a certificate.",
  "certificateRequestDetails.alert.accessKeyDuplicated.description": "Please note that this action will outdate the previous certificate.",
  "certificateRequestDetails.heading": "Sustainability certificate",
  "certificateRequestDetails.viewInBlockchainButton": "View in Blockchain",
  "certificateRequestDetails.downloadPDF": "Download PDF",
  "certificateRequestDetails.shareButton": "Share",
  "certificateRequestDetails.requestedBy": "Certificate requested by {{name}}",
  "certificateRequestDetails.certifyText": "We certify that {{companyName}} purchased {{quantity}} {{quantity_unit}} of {{productType}} through invoice #{{invoiceNumber}} on {{date}} sourced from Biopark {{biopark}}, which holds \u201C{{greenFactor}} Certification\u201D.",
  "certificateRequestDetails.tabs.certificate": "Certificate",
  "certificateRequestDetails.tabs.viewers": "Viewers",
  "certificateRequestDetails.tabs.GOLD": "Gold Certification",
  "certificateRequestDetails.tabs.SILVER": "Silver Certification",
  "certificateRequestDetails.tabs.BRONZE": "Bronze Certification",
  "certificateRequestDetails.tabs.STANDARD": "Standard Certification",
  // Viewers table
  "viewersTable.columns.companyName": "Company",
  "viewersTable.columns.requestedAt": "Requested date",
  "viewersTable.columns.accessType": "Access type",
  "viewersTable.columns.status": "Status",
  "viewersTable.accessType.VIEWER_FULL": "Full access viewer",
  "viewersTable.accessType.VIEWER_RESTRICTED": "Restricted viewer",
  "viewersTable.status.PENDING": "Pending",
  "viewersTable.status.APPROVED": "Approved",
  "viewersTable.status.REJECTED": "Rejected",
  "viewersTable.status.EXPIRED": "Expired",
  "viewersTable.filter": "Search by company...",
  "viewersTable.emptyState.title": "Your viewers list is empty",
  "viewersTable.emptyState.description": "Start sharing your certificate to find viewers here.",
  //Certificate Request Creation
  "certificateRequestCreation.heading": "Request new sustainability certificate",
  "certificateRequestCreation.subheading": "Follow these steps to request your sustainability certificate.",
  "certificateRequestCreation.stepper.step1": "Enter the access key",
  "certificateRequestCreation.stepper.step2": "Preview certificate",
  "certificateRequestCreation.stepper.step3": "Certificate requested",
  "certificateRequestCreation.searchInvoiceForm.title": "Enter the access key",
  "certificateRequestCreation.searchInvoiceForm.input.placeholder": "Digit access key number",
  "certificateRequestCreation.searchInvoiceForm.input.helpText": `Find the 44-digit access key below the bar code on your invoice.`,
  "certificateRequestCreation.searchInvoiceForm.previewButton": "Preview certificate",
  "certificateRequestCreation.certificatePreview.heading": "Certificate Preview",
  "certificateRequestCreation.certificatePreview.subHeading": `If you agree with the following information, click the "Request
  Certificate" button.`,
  "certificateRequestCreation.certificatePreview.generalInformation": `General Information`,
  "certificateRequestCreation.certificatePreview.productInformation": `Product Information`,
  "certificateRequestCreation.certificatePreview.noData.heading": `We couldn't locate the access key \u2018{{accessKey}}\u2019, so you can't view a complete preview.`,
  "certificateRequestCreation.certificatePreview.noData.subHeading": `Please verify the access key, and if it's accurate, you can request the certificate, and we'll review it.`,
  "certificateRequestCreation.certificatePreview.certificateIssued.heading": `Access key \u2018{{accessKey}}\u2019 has already issued a certificate`,
  "certificateRequestCreation.certificatePreview.goBack": "Go back",
  "certificateRequestCreation.certificatePreview.confirm": "Request certificate",
  "certificateRequestCreation.certificateRequested.successfullyRequested": "was requested successfully",
  "certificateRequestCreation.certificateRequested.reviewing": "We are currently reviewing your certification request.",
  "certificateRequestCreation.certificateRequested.subHeading": `Once it's approved, we'll send you an email notification, so you can
    access your certificate right away.`,
  "certificateRequestCreation.certificateRequested.viewCertificates": `View certificates`,
  // Certificate sharing:
  "certificate.share.modal.title": `Share certificate`,
  "certificate.share.modal.inviteCompany": "Invite new company",
  "certificate.share.modal.inviteCompanyDescription": "Can't find the company you are looking for?",
  "certificate.share.request.modal.title": "Certificate sharing request",
  "certificate.share.request.modal.description": "After clicking \u2018Request\u2019, it will undergo review. Upon approval, your certificate will be shared with the requested company.",
  "certificate.share.request.modal.form.companyDetails.title": "Company details",
  "certificate.share.request.modal.form.companyDetails.field.name": "Name",
  "certificate.share.request.modal.form.companyDetails.field.name.placeholder": "Ra\xEDzen",
  "certificate.share.request.modal.form.companyDetails.field.fiscalId": "Fiscal ID",
  "certificate.share.request.modal.form.companyDetails.field.fiscalId.placeholder": "Ex: 40626610000110",
  "certificate.share.request.modal.form.userDetails.title": "User details",
  "certificate.share.request.modal.form.userDetails.field.email": "Email",
  "certificate.share.request.modal.form.userDetails.field.email.placeholder": "johndoe@raizenexplorer.com",
  "certificate.share.request.modal.form.userDetails.field.firstName": "First name",
  "certificate.share.request.modal.form.userDetails.field.firstName.placeholder": "John",
  "certificate.share.request.modal.form.userDetails.field.lastName": "Last name",
  "certificate.share.request.modal.form.userDetails.field.lastName.placeholder": "Doe",
  "certificate.share.request.modal.form.primaryAction": "Request",
  "certificate.share.request.modal.form.cancel": "Cancel",
  "certificate.share.request.modal.form.primaryAction.success": "Certificate sharing requested successfully",
  // ERROR CODES
  "errorCode.GENERIC": "Oops, something went wrong...",
  "errorCode.CERTIFICATE_REQUEST_ALREADY_IN_PROGRESS": "There is already a certificate request in progress for this access key.",
  "errorCode.INCORRECT_INVOICE_NUMBER": "Incorrect invoice number.",
  "errorCode.INCORRECT_ACCESS_KEY": "Incorrect access key.",
  "errorCode.EMAIL_ALREADY_EXISTS": "This email is already in use",
  "errorCode.createUser.companyName.isNotEmpty": "Please enter a company name.",
  "errorCode.createUser.firstName.minLength": "At least 2 characters long.",
  "errorCode.createUser.firstName.isNotEmpty": "Please enter a first name.",
  "errorCode.createUser.lastName.minLength": "At least 2 characters long.",
  "errorCode.createUser.lastName.isNotEmpty": "Please enter a last name.",
  "errorCode.createUser.isEmail": "Please enter a valid email.",
  "errorCode.createUser.isNotEmpty": "Please enter an email address.",
  "errorCode.createUser.company.isNotEmpty": "Please select a company.",
  "common.messages.success": "Success",
  "common.messages.error": "Error",
  "common.messages.warning": "Warning",
  "common.messages.delete": "Delete",
  "common.messages.information": "Information",
  "common.messages.approve": "Approve",
  "common.messages.reject": "Reject",
  // Sharing requests
  "sharingRequests.title": "Sharing requests",
  "sharingRequests.table.columns.accessKey": "Access Key",
  "sharingRequests.table.columns.owner": "Certificate owner",
  "sharingRequests.table.columns.viewer": "Certificate viewer",
  "sharingRequests.table.columns.createdAt": "Requested date",
  "sharingRequests.table.columns.status": "Status",
  "sharingRequests.table.columns.actions": "Actions",
  "sharingRequests.table.filter": "Search by access key, owner, viewer...",
  "sharingRequests.table.emptyState.title": "No sharing requests found",
  "sharingRequests.table.emptyState.description": "It appears there are currently no sharing requests.",
  "sharingRequests.status.PENDING": "Pending review",
  "sharingRequests.status.APPROVED": "Approved",
  "sharingRequests.status.EXPIRED": "Expired",
  "sharingRequests.status.REJECTED": "Rejected",
  "sharingRequests.status.INVALID": "Invalid",
  // ***************************************************
  // Sharing requests: Reject modal
  // ***************************************************
  "sharingRequests.rejectModal.title": "Are you sure you want to reject this sharing?",
  "sharingRequests.rejectModal.text": "{{ownerCompanyName}} has requested to share a certificate with {{viewerCompanyName}} with the access key '{{accessKey}}'.",
  "sharingRequests.rejectModal.confirmButton": "Reject",
  "sharingRequests.rejectModal.cancelButton": "Cancel",
  "sharingRequests.rejectModal.rejectToast.title": "Sharing request rejected",
  "sharingRequests.rejectModal.rejectToast.description": "{{viewerCompanyName}} was not granted access to {{ownerCompanyName}}'s certificate with access key '{{accessKey}}'",
  "sharingRequests.rejectModal.errorToast.description": "Error rejecting sharing request. Try again later.",
  "sharingRequests.rejectModal.rejectionMessage.label": "Comment or observation",
  "sharingRequests.rejectModal.rejectionMessage.placeholder": "Please provide a brief explanation for the sharing's rejection.",
  "sharingRequests.rejectModal.rejectionMessage.isNotEmpty": "Enter a comment or observation",
  "sharingRequests.rejectModal.rejectionMessage.maxLength": "Maximum 600 characters",
  // ***************************************************
  // Sharing requests: Approval modal
  // ***************************************************
  "sharingRequests.approvalModal.title": "Approve sharing",
  "sharingRequests.approvalModal.text": "{{ownerCompanyName}} has requested to share a certificate with {{viewerCompanyName}} with the access key '{{accessKey}}'.",
  "sharingRequests.approvalModal.existentCompany.alert.title.VIEWER_FULL": "This company was already given access as 'Full Access Viewer'.",
  "sharingRequests.approvalModal.existentCompany.alert.title.VIEWER_RESTRICTED": "This company was already given access as 'Restricted Viewer'.",
  "sharingRequests.approvalModal.newCompany.alert.title": "To access the certificate, this company must be signed up.",
  "sharingRequests.approvalModal.newCompany.alert.text": "After clicking the 'Approve' button, you will simultaneously invite the user to Raizen Explorer.",
  "sharingRequests.approvalModal.newCompany.sections.email": "Email: {{email}}",
  "sharingRequests.approvalModal.newCompany.sections.companyFiscalId": "Company's fiscal ID",
  "sharingRequests.approvalModal.newCompany.sections.companyFiscalId.empty": "Please complete the Company Fiscal ID field",
  "sharingRequests.approvalModal.newCompany.sections.companyName": "Company name",
  "sharingRequests.approvalModal.newCompany.sections.companyName.empty": "Please complete the Company name field",
  "sharingRequests.approvalModal.newCompany.sections.accessType": "Select the viewer's access type",
  "sharingRequests.approvalModal.newCompany.sections.accessType.VIEWER_FULL": "Full Access Viewer",
  "sharingRequests.approvalModal.newCompany.sections.accessType.VIEWER_RESTRICTED": "Restricted Viewer",
  "sharingRequests.approvalModal.newCompany.sections.accessType.VIEWER_RESTRICTED.tooltip": "Visualization for: Access Key, Invoice date, Company name, Product, Biopark ID, Biopark city and state, Production date, Mass Balance, Product total quantity, Carbon Footprint,Bonsucro Certification and Foodchain certification.",
  "sharingRequests.approvalModal.confirmButton": "Approve",
  "sharingRequests.approvalModal.cancelButton": "Cancel",
  "sharingRequests.approvalModal.successToast.title": "Sharing approved successfully",
  "sharingRequests.approvalModal.successToast.text": "{{viewerCompanyName}} was granted access to {{ownerCompanyName}}'s certificate access key '{{accessKey}}'.",
  "sharingRequests.approvalModal.errorToast.description": "Error while aproving sharing request. Try again later.",
  // sync database
  "syncDatabase.title": "Sync database",
  "syncDatabase.description": "New database updates detected, please sync now to incorporate the latest changes.",
  "syncDatabase.success": "Database synced successfully.",
  "syncDatabase.error": "Error syncing database."
};

// src/locales/pt.ts
var locales_PT = {
  "errors.email.invalid": "Email deve ser um email",
  "errors.username.invalid": "O nome de usu\xE1rio deve ter entre 2 e 30 caracteres",
  "auth.login": "Iniciar sess\xE3o",
  "auth.logout": "Sair",
  "greenFactor.BRONZE": "Bronze",
  "greenFactor.SILVER": "Prata",
  "greenFactor.GOLD": "Ouro",
  "greenFactor.STANDARD": "Padr\xE3o",
  "productType.SUGAR": "A\xE7\xFAcar",
  "productType.ETHANOL": "Etanol",
  "certificateRequests.table.requestedTab": "Solicitado",
  "certificateRequests.table.notRequestedTab": "N\xE3o solicitado",
  "certificateRequests.table.columns.id": "ID",
  "certificateRequests.table.columns.accessKey": "Chave do acesso",
  "certificateRequests.table.columns.productType": "Product",
  "certificateRequests.table.columns.greenFactor": "Green Factor",
  "certificateRequests.table.columns.createdAt": "Data da fatura",
  "certificateRequests.table.columns.requestedAt": "Data do pedido",
  "certificateRequests.table.columns.status": "Status",
  "certificateRequests.table.columns.invoiceDate": "Data da fatura",
  "certificateRequests.table.filters.invoice": "Pesquisar por fatura...",
  "certificateRequests.table.emptyState.title": "Nenhum resultado encontrado",
  "certificateRequests.table.emptyState.description": "Tente ajustar suas op\xE7\xF5es de pesquisa ou filtros para encontrar o que procura.",
  "certificateRequests.status.CREATED": "Criado",
  "certificateRequests.status.PENDING": "Pendente",
  "certificateRequests.status.NOT_REQUESTED": "N\xE3o solicitado",
  "certificateRequests.status.IN_PROGRESS": "Em processamento",
  "certificateRequests.status.COMPLETED": "Emitido",
  "certificateRequests.status.DENIED": "Rejeitado",
  "certificateRequests.status.FAILED_IN_BLOCKCHAIN": "Erro",
  "certificateRequests.status.NOT_FOUND": "N\xE3o encontrado",
  "certificateRequests.status.IN_REVIEW": "Em revis\xE3o",
  "home.title": "Bem-vindo, {{name}}",
  "home.subtitle": "Gerencie seus Certificados de Sustentabilidade aqui.",
  "home.subtitle.shared": "Acesse os certificados compartilhados com voc\xEA aqui.",
  "home.table.title": "Meus certificados",
  "home.table.title.shared": "Certificados compartilhados",
  "home.requestNewCertificateButton.label": "Solicitar novo Certificado de Sustentabilidade",
  "users.title": "Gerenciamento de usu\xE1rios",
  "users.inviteNewUser": "Convidar novo usu\xE1rio",
  "users.table.filter": "Pesquise por empresa, e-mail...",
  "users.table.emptyState.title": "Nenhum resultado encontrado",
  "users.table.emptyState.description": "Tente ajustar suas op\xE7\xF5es de pesquisa ou filtros para encontrar o que procura.",
  "users.table.columns.company": "Company",
  "users.table.columns.role": "Role",
  "users.table.columns.email": "Email",
  "users.table.columns.lastLogin": "\xDAltimo login",
  "users.table.columns.status": "Status",
  "users.table.columns.actions": "A\xE7\xF5es",
  "users.role.ADMIN": "Admin",
  "users.role.CLIENT": "Cliente",
  "users.role.VIEWER_FULL": "Visualizador",
  "users.role.VIEWER_RESTRICTED": "Visualizador restrito",
  "users.status.INVITED": "Convidado",
  "users.status.ACTIVE": "Ativo",
  "users.status.INACTIVE": "Inativo",
  "users.newUserModal.title": "Convidar novo usu\xE1rio",
  "users.newUserModal.description": "Este usu\xE1rio poder\xE1 solicitar certificados de sustentabilidade.",
  "users.newUserModal.cancelButton": "Cancelar",
  "users.newUserModal.confirmButton": "Convidar usu\xE1rio",
  "users.newUserForm.fields.firstName.label": "Nome",
  "users.newUserForm.fields.firstName.placeholder": "John",
  "users.newUserForm.fields.lastName.label": "Sobrenome",
  "users.newUserForm.fields.lastName.placeholder": "Doe",
  "users.newUserForm.fields.email.label": "Email",
  "users.newUserForm.fields.email.placeholder": "johndoe@raizenexplorer.com",
  "users.newUserForm.fields.company.label": "Empresa",
  "users.newUserForm.fields.company.placeholder": "Procura por nome",
  "users.newUserForm.fields.company.hasNotSharedYet": "Experimente convidar uma nova empresa com o bot\xE3o abaixo",
  "users.newUserForm.fields.company.noCompaniesFound": "Sua pesquisa \u201C{{search}}\u201D n\xE3o corresponde a nenhuma empresa. Tente novamente ou convide uma nova empresa.",
  "users.newAdminModal.title": "Convidar novo usu\xE1rio",
  "users.newAdminModal.cancelButton": "Cancelar",
  "users.newAdminModal.confirmButton": "Convidar usu\xE1rio",
  "newUserForm.successToast.description": "Usu\xE1rio criado com sucesso.",
  "newUserForm.errorToast.description": "N\xE3o foi poss\xEDvel criar o usu\xE1rio. Tente mais tarde.",
  "newAdminForm.successToast.description": "Administrador criado com sucesso.",
  "newAdminForm.errorToast.description": "N\xE3o foi poss\xEDvel criar o Admin. Tente mais tarde.",
  "users.deactivateModal.title": "Tem certeza de que deseja excluir a {{userFullName}} de {{companyName}}?",
  "users.deactivateModal.text": "Essa a\xE7\xE3o n\xE3o pode ser desfeita.",
  "users.deactivateModal.cancelButton": "Cancelar",
  "users.deactivateModal.confirmButton": "Excluir",
  "users.deactivate.successToast.description": "Usu\xE1rio '{{userFullName}}' de '{{companyName}}' exclu\xEDdo com sucesso.",
  "users.deactivate.errorToast.description": "",
  "admins.title": "Gerenciamento de administradores",
  "admins.description": "Gerencie administradores internos da Ra\xEDzen e suas contas aqui.",
  "admins.inviteNewAdmin": "Convidar novo administrador",
  "admins.table.filter": "Pesquise por nome, e-mail...",
  "admins.table.emptyState.title": "Nenhum resultado encontrado",
  "admins.table.emptyState.description": "Tente ajustar suas op\xE7\xF5es de pesquisa ou filtros para encontrar o que procura.",
  "admins.table.columns.name": "Nome",
  "admins.table.columns.email": "Email",
  "admins.table.columns.status": "Status",
  "admins.table.columns.actions": "A\xE7\xF5es",
  "table.actions.deleteUser": "Deletar usu\xE1rio",
  //Common
  "common.users": "Usu\xE1rios",
  "common.usersManagement": "Gerenciamento de usu\xE1rios",
  "common.adminsManagement": "Gerenciamento de administradores",
  "common.certificates": "Certificados",
  "common.certificateRequests": "Solicita\xE7\xF5es de certificado",
  "common.sharingRequests": "Solicita\xE7\xF5es de compartilhamento",
  "common.company": "Empresa",
  "common.accessKey": "Chave de acesso",
  "common.invoiceNumber": "N\xFAmero da fatura",
  "common.companyName": "Nome da empresa",
  "common.certificateRequester": "Solicitante do certificado",
  "common.product": "Produto",
  "common.bioparkId": "ID do Bioparque",
  "common.bioparkCityAndState": "Cidade e estado do Bioparque",
  "common.createdAt": "Criado em",
  "common.massBalance": "Balan\xE7o de massa",
  "common.productTotalQuantity": "Quantidade total do produto",
  "common.carbonFootprint": "Pegada de carbono",
  "common.bonsucroCertification": "Certifica\xE7\xE3o Bonsucro",
  "common.foodchainCertification": "Certifica\xE7\xE3o Foodchain",
  "common.certificate": "Certificado",
  "common.invoiceDate": "Data da fatura",
  "common.issued": "Publicado",
  "common.transactionHash": "Tx Hash",
  "common.network": "Rede",
  "common.productCertifiedQuantity": "Quantidade certificada do produtok",
  "common.farmName": "Fazenda",
  "common.zone": "Zona",
  "common.farmLatitude": "Latitude",
  "common.farmLongitude": "Longitude",
  "common.elos": "ELOS",
  "common.isRented": "\xC9 alugado",
  "common.car": "CAR",
  "common.deforestationFree": "Livre de desmatamento",
  "common.useOfNativeTribesLands": "Uso de terras de tribos nativas",
  "common.useOfQuilombolaLands": "Uso das Terras Quilombolas",
  "common.historicalSoilCoverage": "Cobertura hist\xF3rica do solo",
  "common.generalInformation": "Informa\xE7\xF5es gerais",
  "common.blockchainInformation": "Informa\xE7\xF5es sobre blockchain",
  "common.productInformation": "Informa\xE7\xE3o do produto",
  "common.farmInformation": "Informa\xE7\xF5es da fazenda",
  "common.tooltip.transactionHash": "N\xFAmero de registro do certificado em Blockchain",
  "common.tooltip.isRented": "Se a fazenda onde foi plantada a cana-de-a\xE7\xFAcar pertence ou n\xE3o \xE0 Ra\xEDzen (Foca).",
  "common.tooltip.useOfNativeTribesLands": "Se a fazenda onde foi plantada a cana-de-a\xE7\xFAcar n\xE3o pertenceu no passado ou pertence a Terras Tribais. A fonte de dados para essas informa\xE7\xF5es \xE9 o Incra.",
  "common.tooltip.deforestationFree": "Se a fazenda onde foi plantada a cana-de-a\xE7\xFAcar estiver livre de qualquer processo de desmatamento. A fonte de dados para essas informa\xE7\xF5es \xE9 o Ibama.",
  "common.tooltip.useOfQuilombolaLands": "Se a fazenda onde foi plantada a cana-de-a\xE7\xFAcar n\xE3o pertenceu no passado ou pertence a Terras Quilombolas. A fonte de dados para essas informa\xE7\xF5es \xE9 o Incra.",
  "common.tooltip.historicalSoilCoverage": "Base hist\xF3rica da qual foi plantada na \xE1rea.",
  "common.tooltip.productCertifiedQuantity": "Quantidade de produto com atributos socioambientais.",
  "common.tooltip.massBalance": "Medi\xE7\xE3o e acompanhamento da quantidade de cana que entra no processo em rela\xE7\xE3o \xE0 quantidade de subprodutos obtidos, garantindo aproveitamento eficiente e m\xEDnimo desperd\xEDcio.",
  "common.tooltip.carbonFootprint": "A quantidade total de gases com efeito de estufa, principalmente di\xF3xido de carbono, produzidos direta ou indiretamente por um indiv\xEDduo, organiza\xE7\xE3o, evento ou produto, representando o seu impacto nas altera\xE7\xF5es clim\xE1ticas.",
  "common.tooltip.foodchainCertification": "Uma certifica\xE7\xE3o que indica o produto derivado da cana-de-a\xE7\xFAcar que indica que a cana-de-a\xE7\xFAcar n\xE3o \xE9 OGM.",
  "common.tooltip.bonsucroCertification": "Certifica\xE7\xE3o de sustentabilidade para a cana-de-a\xE7\xFAcar e seus produtos, garantindo que sejam produzidos de forma ambiental e socialmente respons\xE1vel.",
  "common.tooltip.car": "O CAR \xE9 um cadastro eletr\xF4nico nacional no Brasil, obrigat\xF3rio para propriedades rurais. Integra informa\xE7\xF5es ambientais para facilitar o gerenciamento, o planejamento e os esfor\xE7os de combate ao desmatamento.\n\n*A Raizen n\xE3o se responsabiliza por toda a \xE1rea do CAR que produzir\xE1 em algumas propriedades.",
  "common.certifiedBadge.BRONZE": "Certificado Bronze",
  "common.certifiedBadge.SILVER": "Certificado Prata",
  "common.certifiedBadge.GOLD": "Certificado Ouro",
  "common.certifiedBadge.STANDARD": "Certificado padr\xE3o",
  "common.pagination.previous": "Anterior",
  "common.pagination.next": "Pr\xF3xima",
  "common.navbar.tabs.certificates": "Certificados",
  "common.errors.pageNotFound": "404 P\xE1gina n\xE3o encontrada",
  "common.goHome": "Ir para o in\xEDcio",
  "common.copiedToClipboard": "Copiado para a \xE1rea de transfer\xEAncia!",
  "common.resetFilters": "Redefinir filtros",
  "common.yes": "Sim",
  "common.no": "N\xE3o",
  "common.farm": "Fazenda",
  "common.all": "Tudos",
  "common.certificateHash": "Hash do Certificado",
  "breadcrumb.certificates": "Certificados",
  "breadcrumb.certificateRequestsDetails": "Certificado de sustentabilidade",
  //Certificate Request Details
  "certificateRequestDetails.action.approve": "Emitir",
  "certificateRequestDetails.action.approve.success": "Certificado de sustentabilidade aprovado com sucesso",
  "certificateRequestDetails.action.approve.modal.title": "Tem certeza de que deseja emitir este certificado?",
  "certificateRequestDetails.action.approve.modal.description": "A emiss\xE3o deste certificado conceder\xE1 ao usu\xE1rio acesso para visualizar seu certificado. Essa a\xE7\xE3o n\xE3o pode ser desfeita.",
  "certificateRequestDetails.action.reject": "Rejeitar",
  "certificateRequestDetails.action.reject.success": "Certificado de sustentabilidade rejeitado com sucesso",
  "certificateRequestDetails.action.reject.modal.title": "Tem certeza de que deseja rejeitar a solicita\xE7\xE3o de certificado #{{uuid}}?",
  "certificateRequestDetails.action.reject.modal.subtitle": "Essa a\xE7\xE3o n\xE3o pode ser desfeita.",
  "certificateRequestDetails.action.reject.modal.form.reasonPlaceholder": "Selecione um motivo.",
  "certificateRequestDetails.action.reject.modal.form.reasonDuplicated": "Duplica\xE7\xE3o de fatura.",
  "certificateRequestDetails.action.reject.modal.form.reasonNotFound": "Fatura n\xE3o encontrada.",
  "certificateRequestDetails.action.reject.modal.form.reasonOther": "Outro.",
  "certificateRequestDetails.action.reject.modal.form.messagePlaceholder": `Forne\xE7a uma breve explica\xE7\xE3o sobre a rejei\xE7\xE3o do certificado.`,
  "certificateRequestDetails.action.reject.modal.form.messageLabel": "Coment\xE1rio ou observa\xE7\xE3o (opcional)",
  "certificateRequestDetails.action.cancel": "Cancelar",
  "certificateRequestDetails.alert.COMPLETED.title": "Parab\xE9ns! Sua solicita\xE7\xE3o de certificado foi emitida",
  "certificateRequestDetails.alert.COMPLETED.description": "",
  "certificateRequestDetails.alert.DENIED.INVOICE_DUPLICATION.title": "Certificado de sustentabilidade foi rejeitado por duplica\xE7\xE3o de fatura",
  "certificateRequestDetails.alert.DENIED.INVOICE_NOT_FOUND.title": "Certificado de sustentabilidade foi rejeitado por n\xE3o ter sido encontrada fatura",
  "certificateRequestDetails.alert.DENIED.OTHER.title": "Certificado de sustentabilidade foi rejeitado",
  "certificateRequestDetails.alert.PENDING.title": "Sua solicita\xE7\xE3o de certificado est\xE1 em an\xE1lise",
  "certificateRequestDetails.alert.PENDING.description": "No momento, estamos analisando sua solicita\xE7\xE3o de certifica\xE7\xE3o. Assim que for emitido, enviaremos uma notifica\xE7\xE3o por e-mail para que voc\xEA possa acessar seu certificado imediatamente.",
  "certificateRequestDetails.alert.IN_PROGRESS.title": "Sua solicita\xE7\xE3o de certificado est\xE1 em an\xE1lise",
  "certificateRequestDetails.alert.IN_PROGRESS.description": "No momento, estamos analisando sua solicita\xE7\xE3o de certifica\xE7\xE3o. Assim que for aprovado, enviaremos uma notifica\xE7\xE3o por e-mail para que voc\xEA possa acessar seu certificado imediatamente.",
  "certificateRequestDetails.alert.FAILED_IN_BLOCKCHAIN.title": "Sua solicita\xE7\xE3o de certificado falhou no Blockchain",
  "certificateRequestDetails.alert.FAILED_IN_BLOCKCHAIN.description": "Estamos atualmente enfrentando problemas em Blockchain. Nossa equipe j\xE1 foi notificada e, assim que o problema for resolvido, eles entrar\xE3o em contato con voc\xEA para fornecer instru\xE7\xF5es adicionais.",
  "backoffice.certificateRequestDetails.alert.COMPLETED.title": "Certificado de sustentabilidade foi emitido",
  "backoffice.certificateRequestDetails.alert.COMPLETED.description": "",
  "backoffice.certificateRequestDetails.alert.DENIED.INVOICE_DUPLICATION.title": "Certificado de sustentabilidade foi rejeitado por duplica\xE7\xE3o de fatura",
  "backoffice.certificateRequestDetails.alert.DENIED.INVOICE_NOT_FOUND.title": "Certificado de sustentabilidade foi rejeitado por n\xE3o ter sido encontrada fatura",
  "backoffice.certificateRequestDetails.alert.DENIED.OTHER.title": "Certificado de sustentabilidade foi rejeitado",
  "backoffice.certificateRequestDetails.alert.PENDING.title": "Sua solicita\xE7\xE3o de certificado est\xE1 em an\xE1lise",
  "backoffice.certificateRequestDetails.alert.PENDING.description": "No momento, estamos analisando sua solicita\xE7\xE3o de certifica\xE7\xE3o. Assim que for aprovado, enviaremos uma notifica\xE7\xE3o por e-mail para que voc\xEA possa acessar seu certificado imediatamente.",
  "backoffice.certificateRequestDetails.alert.IN_PROGRESS.title": "Sua solicita\xE7\xE3o de certificado est\xE1 em an\xE1lise",
  "backoffice.certificateRequestDetails.alert.IN_PROGRESS.description": "No momento, estamos analisando sua solicita\xE7\xE3o de certifica\xE7\xE3o. Assim que for aprovado, enviaremos uma notifica\xE7\xE3o por e-mail para que voc\xEA possa acessar seu certificado imediatamente.",
  "backoffice.certificateRequestDetails.alert.FAILED_IN_BLOCKCHAIN.title": "Sua solicita\xE7\xE3o de certificado falhou no Blockchain",
  "backoffice.certificateRequestDetails.alert.FAILED_IN_BLOCKCHAIN.description": "Estamos atualmente enfrentando problemas em Blockchain. Nossa equipe j\xE1 foi notificada e, assim que o problema for resolvido, eles entrar\xE3o em contato con voc\xEA para fornecer instru\xE7\xF5es adicionais.",
  "certificateRequestDetails.approvedOn": "",
  "certificateRequestDetails.rejectedOn": "",
  "backoffice.certificateRequestDetails.approvedOn": "Emitido em {{date}}.",
  "backoffice.certificateRequestDetails.rejectedOn": "Rejeitado em {{date}}.",
  "certificateRequestDetails.alert.noCertificateData.title": "A chave de acesso n\xE3o p\xF4de ser localizada no banco de dados, portanto, voc\xEA n\xE3o pode visualizar uma pr\xE9via completa.",
  "certificateRequestDetails.alert.noCertificateData.description": "Entre em contato com raizen-support@agrotoken.io para verificar a exist\xEAncia da chave de acesso. Se encontrada, um bot\xE3o aparecer\xE1 para sincroniza\xE7\xE3o do banco de dados. Se n\xE3o for encontrada, voc\xEA ser\xE1 notificado para rejeitar a solicita\xE7\xE3o.",
  "certificateRequestDetails.alert.accessKeyDuplicated.title": "A fatura '{{accessKey}}' j\xE1 foi utilizada para emitir um certificado.",
  "certificateRequestDetails.alert.accessKeyDuplicated.description": "Por favor, note que esta a\xE7\xE3o ir\xE1 tornar obsoleto o certificado anterior.",
  "certificateRequestDetails.heading": "Certificado de sustentabilidade",
  "certificateRequestDetails.viewInBlockchainButton": "Ver em Blockchain",
  "certificateRequestDetails.downloadPDF": "Baixar PDF",
  "certificateRequestDetails.shareButton": "Compartilhar",
  "certificateRequestDetails.requestedBy": "Certificado solicitado por {{name}}",
  "certificateRequestDetails.certifyText": 'Certificamos que {{companyName}} comprou {{quantity}} {{quantity_unit}} de {{productType}} atrav\xE9s da fatura #{{invoiceNumber}} em {{date}} proveniente do Biopark {{biopark}}, que det\xE9m \u201C{{greenFactor }} Certifica\xE7\xE3o".',
  "certificateRequestDetails.tabs.certificate": "Certificado",
  "certificateRequestDetails.tabs.viewers": "Espectadores",
  "certificateRequestDetails.tabs.GOLD": "Certificado ouro",
  "certificateRequestDetails.tabs.SILVER": "Certificado prata",
  "certificateRequestDetails.tabs.BRONZE": "Certificado bronze",
  "certificateRequestDetails.tabs.STANDARD": "Certifica\xE7\xE3o Padr\xE3o",
  // Viewers table
  "viewersTable.columns.companyName": "Empresa",
  "viewersTable.columns.requestedAt": "Data do Pedido",
  "viewersTable.columns.accessType": "Tipo de acesso",
  "viewersTable.columns.status": "Status",
  "viewersTable.accessType.VIEWER_FULL": "Visualizador de acesso total",
  "viewersTable.accessType.VIEWER_RESTRICTED": "Visualizador restrito",
  "viewersTable.status.PENDING": "Pendente",
  "viewersTable.status.APPROVED": "Aprovado",
  "viewersTable.status.REJECTED": "Rejeitado",
  "viewersTable.status.EXPIRED": "Expirado",
  "viewersTable.filter": "Pesquise por empresa...",
  "viewersTable.emptyState.title": "Sua lista de espectadores est\xE1 vazia",
  "viewersTable.emptyState.description": "Comece a compartilhar seu certificado para encontrar espectadores aqui.",
  //Certificate Request Creation
  "certificateRequestCreation.heading": "Solicite novo certificado de sustentabilidade",
  "certificateRequestCreation.subheading": "Siga estes passos para solicitar seu certificado de sustentabilidade.",
  "certificateRequestCreation.stepper.step1": "Insira a fatura",
  "certificateRequestCreation.stepper.step2": "Pr\xE9-visualizar certificado",
  "certificateRequestCreation.stepper.step3": "Certificado solicitado",
  "certificateRequestCreation.searchInvoiceForm.title": "Insira o chave do acesso",
  "certificateRequestCreation.searchInvoiceForm.input.placeholder": "N\xFAmero da chave de acesso de d\xEDgito",
  "certificateRequestCreation.searchInvoiceForm.input.helpText": `Encontre a chave de acesso de 44 d\xEDgitos abaixo do c\xF3digo de barras da sua fatura.`,
  "certificateRequestCreation.searchInvoiceForm.previewButton": "Pr\xE9-visualizar certificado",
  "certificateRequestCreation.certificatePreview.heading": "Pr\xE9-visualiza\xE7\xE3o do certificado",
  "certificateRequestCreation.certificatePreview.subHeading": `Se voc\xEA concorda com as informa\xE7\xF5es a seguir, clique no bot\xE3o "Solicitar Certificado".`,
  "certificateRequestCreation.certificatePreview.generalInformation": `Informa\xE7\xF5es Gerais`,
  "certificateRequestCreation.certificatePreview.productInformation": `Informa\xE7\xE3o do Produto`,
  "certificateRequestCreation.certificatePreview.noData.heading": `N\xE3o foi poss\xEDvel localizar a fatura \u2018{{accessKey}}\u2019, portanto voc\xEA n\xE3o pode ver uma visualiza\xE7\xE3o completa.`,
  "certificateRequestCreation.certificatePreview.noData.subHeading": `Verifique o n\xFAmero da fatura e, se estiver correto, voc\xEA poder\xE1 solicitar o certificado e n\xF3s o analisaremos.`,
  "certificateRequestCreation.certificatePreview.certificateIssued.heading": `O n\xFAmero da fatura \u2018{{accessKey}}\u2019 j\xE1 tem um certificado emitido`,
  "certificateRequestCreation.certificatePreview.goBack": "Regresar",
  "certificateRequestCreation.certificatePreview.confirm": "Solicitar certificado",
  "certificateRequestCreation.certificateRequested.successfullyRequested": "foi solicitado com sucesso",
  "certificateRequestCreation.certificateRequested.reviewing": "No momento, estamos analisando sua solicita\xE7\xE3o de certifica\xE7\xE3o.",
  "certificateRequestCreation.certificateRequested.subHeading": `Uma vez aprovado, enviaremos uma notifica\xE7\xE3o por e-mail para que voc\xEA possa acessar seu certificado imediatamente.`,
  "certificateRequestCreation.certificateRequested.viewCertificates": `Ver certificados`,
  // Certificate sharing:
  "certificate.share.modal.title": `Compartilhar certificado`,
  "certificate.share.modal.inviteCompany": "Convidar nova empresa",
  "certificate.share.modal.inviteCompanyDescription": "Sua busca n\xE3o encontrou nenhuma empresa. Por favor, tente novamente ou convide uma nova empresa.",
  "certificate.share.request.modal.title": "Solicita\xE7\xE3o de compartilhamento de certificado",
  "certificate.share.request.modal.description": "Ap\xF3s clicar em \u2018Solicitar\u2019, a solicita\xE7\xE3o ser\xE1 revisada. Uma vez aprovada, seu certificado ser\xE1 compartilhado com a empresa solicitada.",
  "certificate.share.request.modal.form.companyDetails.title": "Detalhes da empresa",
  "certificate.share.request.modal.form.companyDetails.field.name": "Nome",
  "certificate.share.request.modal.form.companyDetails.field.name.placeholder": "Ra\xEDzen",
  "certificate.share.request.modal.form.companyDetails.field.fiscalId": "CNPJ",
  "certificate.share.request.modal.form.companyDetails.field.fiscalId.placeholder": "Ex: 40626610000110",
  "certificate.share.request.modal.form.userDetails.title": "Detalhes do usu\xE1rio",
  "certificate.share.request.modal.form.userDetails.field.email": "E-mail",
  "certificate.share.request.modal.form.userDetails.field.email.placeholder": "johndoe@raizenexplorer.com",
  "certificate.share.request.modal.form.userDetails.field.firstName": "Nome",
  "certificate.share.request.modal.form.userDetails.field.firstName.placeholder": "John",
  "certificate.share.request.modal.form.userDetails.field.lastName": "Sobrenome",
  "certificate.share.request.modal.form.userDetails.field.lastName.placeholder": "Doe",
  "certificate.share.request.modal.form.primaryAction": "Solicitar",
  "certificate.share.request.modal.form.cancel": "Cancelar",
  "certificate.share.request.modal.form.primaryAction.success": "Compartilhamento de certificado solicitado com sucesso",
  // ERROR CODES
  "errorCode.GENERIC": "Ops, algo deu errado...",
  "errorCode.CERTIFICATE_REQUEST_ALREADY_IN_PROGRESS": "J\xE1 existe uma solicita\xE7\xE3o de certificado em andamento para este n\xFAmero de fatura.",
  "errorCode.INCORRECT_INVOICE_NUMBER": "N\xFAmero da fatura incorreto.",
  "errorCode.INCORRECT_ACCESS_KEY": "Chave de acesso incorreto.",
  "errorCode.EMAIL_ALREADY_EXISTS": "Esse email j\xE1 est\xE1 em uso",
  "errorCode.createUser.companyName.isNotEmpty": "Por favor insira um nome da empresa.",
  "errorCode.createUser.firstName.minLength": "Pelo menos 2 caracteres.",
  "errorCode.createUser.firstName.isNotEmpty": "Por favor insira um nome.",
  "errorCode.createUser.lastName.minLength": "Pelo menos 2 caracteres.",
  "errorCode.createUser.lastName.isNotEmpty": "Por favor insira um sobrenome.",
  "errorCode.createUser.isEmail": "Por favor digite um email v\xE1lido.",
  "errorCode.createUser.isNotEmpty": "Por favor insira um e-mail.",
  "errorCode.createUser.company.isNotEmpty": "Selecione uma empresa.",
  "common.messages.success": "Sucesso",
  "common.messages.error": "Erro",
  "common.messages.warning": "Aviso",
  "common.messages.delete": "Excluir",
  "common.messages.information": "Informa\xE7\xE3o",
  "common.messages.approve": "Aprovar",
  "common.messages.reject": "Rejeitar",
  // Sharing requests
  "sharingRequests.title": "Sharing requests",
  "sharingRequests.table.columns.accessKey": "Chave do acesso",
  "sharingRequests.table.columns.owner": "Propriet\xE1rio do certificado",
  "sharingRequests.table.columns.viewer": "Visualizador de certificado",
  "sharingRequests.table.columns.createdAt": "Data do pedido",
  "sharingRequests.table.columns.status": "Status",
  "sharingRequests.table.columns.actions": "Actions",
  "sharingRequests.table.filter": "Pesquise por chave de acesso, propriet\xE1rio, visualizador...",
  "sharingRequests.table.emptyState.title": "Nenhuma solicita\xE7\xE3o de compartilhamento encontrada",
  "sharingRequests.table.emptyState.description": "Parece que atualmente n\xE3o h\xE1 solicita\xE7\xF5es de compartilhamento.",
  "sharingRequests.status.PENDING": "Revis\xE3o pendente",
  "sharingRequests.status.APPROVED": "Aprovado",
  "sharingRequests.status.EXPIRED": "Expirado",
  "sharingRequests.status.REJECTED": "Rejeitado",
  "sharingRequests.status.INVALID": "Inv\xE1lido",
  // ***************************************************
  // Sharing requests: Reject modal
  // ***************************************************
  "sharingRequests.rejectModal.title": "Tem certeza de que deseja rejeitar este compartilhamento?",
  "sharingRequests.rejectModal.text": "A {{fromCompanyName}} solicitou o compartilhamento de um certificado com o {{toCompanyName}} com o n\xFAmero da fatura '{{accessKey}}'.",
  "sharingRequests.rejectModal.confirmButton": "Rejeitar",
  "sharingRequests.rejectModal.cancelButton": "Cancelar",
  "sharingRequests.rejectModal.rejectToast.title": "Solicita\xE7\xE3o de compartilhamento rejeitada",
  "sharingRequests.rejectModal.rejectToast.description": "{{viewerCompanyName}} n\xE3o recebeu acesso ao certificado de {{ownerCompanyName}} com n\xFAmero de fatura '{{accessKey}}'",
  "sharingRequests.rejectModal.errorToast.description": "Erro ao rejeitar solicita\xE7\xE3o de compartilhamento. Tente mais tarde.",
  "sharingRequests.rejectModal.rejectionMessage.label": "Coment\xE1rio ou observa\xE7\xE3o",
  "sharingRequests.rejectModal.rejectionMessage.placeholder": "Forne\xE7a uma breve explica\xE7\xE3o para a rejei\xE7\xE3o do compartilhamento.",
  "sharingRequests.rejectModal.rejectionMessage.isNotEmpty": "Insira um coment\xE1rio ou observa\xE7\xE3o",
  "sharingRequests.rejectModal.rejectionMessage.maxLength": "M\xE1ximo de 600 caracteres",
  // ***************************************************
  // Sharing requests: Approval modal
  // ***************************************************
  "sharingRequests.approvalModal.title": "Aprovar compartilhamento",
  "sharingRequests.approvalModal.text": "A {{ownerCompanyName}} solicitou compartilhar um certificado com {{viewerCompanyName}} com o n\xFAmero da fatura '{{accessKey}}'.",
  "sharingRequests.approvalModal.existentCompany.alert.title.VIEWER_FULL": "Esta empresa j\xE1 possui acesso como 'Visualizador de Acesso Completo'.",
  "sharingRequests.approvalModal.existentCompany.alert.title.VIEWER_RESTRICTED": "Esta empresa j\xE1 possui acesso como 'Visualizador Restrito'.",
  "sharingRequests.approvalModal.newCompany.alert.title": "Para acessar o certificado, esta empresa deve estar cadastrada.",
  "sharingRequests.approvalModal.newCompany.alert.text": "Ap\xF3s clicar no bot\xE3o 'Aprovar', voc\xEA simultaneamente convidar\xE1 o usu\xE1rio para o Raizen Explorer.",
  "sharingRequests.approvalModal.newCompany.sections.email": "E-mail: {{email}}",
  "sharingRequests.approvalModal.newCompany.sections.companyFiscalId": "CNPJ da empresa",
  "sharingRequests.approvalModal.newCompany.sections.companyFiscalId.empty": "Por favor, preencha o campo CNPJ da empresa",
  "sharingRequests.approvalModal.newCompany.sections.companyName": "Nome da empresa",
  "sharingRequests.approvalModal.newCompany.sections.companyName.empty": "Por favor, preencha o campo Nome da empresa",
  "sharingRequests.approvalModal.newCompany.sections.accessType": "Selecione o tipo de acesso do visualizador",
  "sharingRequests.approvalModal.newCompany.sections.accessType.VIEWER_FULL": "Visualizador de Acesso Completo",
  "sharingRequests.approvalModal.newCompany.sections.accessType.VIEWER_RESTRICTED": "Visualizador Restrito",
  "sharingRequests.approvalModal.newCompany.sections.accessType.VIEWER_RESTRICTED.tooltip": "Visualiza\xE7\xE3o para: N\xFAmero da fatura, Data da fatura, Nome da empresa, Produto, Biopark ID, Cidade e estado do Biopark, Data de produ\xE7\xE3o, Balan\xE7o de massa, Quantidade total do produto, Pegada de carbono, Certifica\xE7\xE3o Bonsucro e Certifica\xE7\xE3o Foodchain.",
  "sharingRequests.approvalModal.confirmButton": "Aprovar",
  "sharingRequests.approvalModal.cancelButton": "Cancelar",
  "sharingRequests.approvalModal.successToast.title": "Compartilhamento aprovado com sucesso",
  "sharingRequests.approvalModal.successToast.text": "{{viewerCompanyName}} recebeu acesso ao certificado da {{ownerCompanyName}} com n\xFAmero da fatura '{{accessKey}}'.",
  "sharingRequests.approvalModal.errorToast.description": "Erro ao aprovar solicita\xE7\xE3o de compartilhamento. Tente novamente mais tarde.",
  // sync database
  "syncDatabase.title": "Sincronizar dados",
  "syncDatabase.description": "Detectadas novas atualiza\xE7\xF5es no banco de dados, por favor, sincronize agora para incorporar as \xFAltimas altera\xE7\xF5es.",
  "syncDatabase.success": "Banco de dados sincronizado com sucesso.",
  "syncDatabase.error": "Erro ao sincronizar o banco de dados."
};
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  locales_EN,
  locales_PT
});
