import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react';
import { Dropdown, Textarea } from '@raizen/ui';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { rejectCertRequest } from '../../services/certificateRequests/rejectCertificateRequest';
import { useTranslation } from '@raizen/react-hooks';
import { useCertificateRejectionOptions } from './useCertificateRejectionOptions';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  refetchData: () => void;
}

export const RejectCertificateRequestModal = ({
  isOpen,
  onClose,
  refetchData,
}: Props) => {
  const { t } = useTranslation();
  const options = useCertificateRejectionOptions();
  const [rejectionReason, setRejectReason] = useState<string>('');
  const [rejectionMessage, setRejectMessage] = useState('');
  const { uuid = '' } = useParams();
  const toast = useToast();
  const { mutate: mutateRejectCertRequest, isLoading } = useMutation(
    async () =>
      rejectionReason &&
      rejectCertRequest(uuid || '', {
        rejectionReason,
        rejectionMessage,
      }),
    {
      onSuccess: () => {
        toast({
          title: t('certificateRequestDetails.action.reject.success'),
          status: 'success',
          position: 'top-right',
          duration: 9000,
          isClosable: true,
        });
        refetchData();
        onClose();
      },
    },
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent as="form">
        <ModalHeader>
          {t('certificateRequestDetails.action.reject.modal.title').replace(
            '{{uuid}}',
            uuid,
          )}
          <br />
          <Text as="small">
            {t('certificateRequestDetails.action.reject.modal.subtitle')}
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction="column" gap="6">
            <Dropdown
              options={options}
              value={rejectionReason}
              onChange={setRejectReason}
              placeholder={t(
                'certificateRequestDetails.action.reject.modal.form.reasonPlaceholder',
              )}
            />
            <Textarea
              id="observation"
              name="observation"
              label={t(
                'certificateRequestDetails.action.reject.modal.form.messageLabel',
              )}
              placeholder={t(
                'certificateRequestDetails.action.reject.modal.form.messagePlaceholder',
              )}
              value={rejectionMessage}
              onChange={(ev) => setRejectMessage(ev.target.value)}
            />
          </Flex>
        </ModalBody>

        <ModalFooter>
          <Flex gap="12px">
            <Button variant="outline" onClick={onClose}>
              {t('certificateRequestDetails.action.cancel')}
            </Button>
            <Button
              variant="destructive"
              mr={3}
              onClick={() => mutateRejectCertRequest()}
              isLoading={isLoading}
            >
              {t('certificateRequestDetails.action.reject')}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
