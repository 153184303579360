import {
  Divider,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useBreakpointValue,
} from '@chakra-ui/react';
import { CertificateRequestSerializer } from '@raizen/serializers';
import { Tab } from './Tab';
import { useTranslation } from '@raizen/react-hooks';
import { PERFORM_ACTION } from '@raizen/casl-authorization';
import { Can } from '../../contexts/PermissionsContext';
import { CertificateRequestCard } from './CertificateRequestCard';
import { ViewersTable } from './ViewersTable/ViewersTable';
import { CERTIFICATE_REQUEST_STATUS, GREEN_FACTOR_VALUES } from '@raizen/db';
import { useUser } from '../../contexts/UserContext';
import { usePrintingState } from '../../contexts/PrintingStateProvider';
import React from 'react';

interface Props {
  certificateRequest: CertificateRequestSerializer;
}

export const FullView = ({ certificateRequest }: Props) => {
  const { t } = useTranslation();
  const { ability } = useUser();
  const { isPrinting } = usePrintingState();

  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
    xl: false,
  });

  const isNotRequestedCertificate =
    certificateRequest.status === CERTIFICATE_REQUEST_STATUS.COMPLETED;
  const isCompleted =
    certificateRequest.status === CERTIFICATE_REQUEST_STATUS.COMPLETED;

  const shouldShowCompleteInfo = isCompleted || isNotRequestedCertificate;

  const canListCertificateShareRequests = ability?.can(
    PERFORM_ACTION.List,
    'CertificateShareRequest',
  );

  const showTabs =
    (shouldShowCompleteInfo && canListCertificateShareRequests) ||
    certificateRequest?.certificateData?.length > 1;

  const orderedCertificateDatas =
    certificateRequest?.certificateData.sort((a, b) => {
      if (a.greenFactor === GREEN_FACTOR_VALUES.GOLD) return -1;
      if (b.greenFactor === GREEN_FACTOR_VALUES.GOLD) return 1;
      if (a.greenFactor === GREEN_FACTOR_VALUES.SILVER) return -1;
      return 1;
    }) ?? [];

  return showTabs ? (
    <Tabs>
      <TabList
        gap={isMobile ? 4 : 8}
        className="remove-for-print-view remove-pt-for-print-view"
        overflowX={'auto'}
      >
        {orderedCertificateDatas.map((e) => (
          <Tab key={`${e.greenFactor}-tab`}>
            {orderedCertificateDatas.length === 1
              ? t('certificateRequestDetails.tabs.certificate')
              : t(`certificateRequestDetails.tabs.${e.greenFactor!}`)}
          </Tab>
        ))}
        {shouldShowCompleteInfo && (
          <Can I={PERFORM_ACTION.List} a="CertificateShareRequest">
            <Tab key={'viewers-tab'}>
              {t('certificateRequestDetails.tabs.viewers')}
            </Tab>
          </Can>
        )}
      </TabList>

      <TabPanels py="8" className="remove-pt-for-print-view">
        {certificateRequest?.certificateData.map(
          (certificateData, index, { length }) => {
            const isLastElement = length - 1 === index;
            const isNotLastElement = !isLastElement;
            return isPrinting ? (
              <React.Fragment key={certificateData.uuid}>
                <CertificateRequestCard
                  certificateRequest={certificateRequest}
                  certificateData={certificateData}
                />
                {isNotLastElement ? (
                  <div className="page-break-after-this-fro-print-view" />
                ) : null}
              </React.Fragment>
            ) : (
              <TabPanel
                px="0"
                className="remove-pt-for-print-view"
                key={certificateData.uuid}
              >
                <CertificateRequestCard
                  certificateRequest={certificateRequest}
                  certificateData={certificateData}
                />
              </TabPanel>
            );
          },
        )}
        {shouldShowCompleteInfo && (
          <Can I={PERFORM_ACTION.List} a="CertificateShareRequest">
            <TabPanel px="0" className="remove-for-print-view">
              {certificateRequest?.uuid && (
                <ViewersTable uuid={certificateRequest?.uuid} />
              )}
            </TabPanel>
          </Can>
        )}
      </TabPanels>
    </Tabs>
  ) : (
    <>
      <Divider />
      <CertificateRequestCard
        certificateRequest={certificateRequest}
        certificateData={certificateRequest.certificateData[0]}
      />
    </>
  );
};
