import { User } from '@raizen/db';
import { useTranslation } from '@raizen/react-hooks';
import { Button, Modal, AlertTriangleIcon } from '@raizen/ui';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  handleDeactivateUser: () => void;
  isLoading: boolean;
  user: User | undefined;
}

export const DeactivateUserModal = ({
  isOpen,
  onClose,
  handleDeactivateUser,
  isLoading,
  user,
}: Props) => {
  const { t } = useTranslation();

  const userFullName = `${user?.firstName} ${user?.lastName}`;
  const companyName = user?.company?.name ?? '';

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t('users.deactivateModal.title')
        .replace('{{userFullName}}', userFullName)
        .replace('{{companyName}}', companyName)}
      text={t('users.deactivateModal.text')}
      confirmButton={
        <Button
          variant="destructive"
          isLoading={isLoading}
          onClick={handleDeactivateUser}
        >
          {t('users.deactivateModal.confirmButton')}
        </Button>
      }
      cancelButton={
        <Button variant="secondary" onClick={onClose} disabled={isLoading}>
          {t('users.deactivateModal.cancelButton')}
        </Button>
      }
      icon={AlertTriangleIcon}
      size="lg"
    />
  );
};
