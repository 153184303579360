import { httpClient } from '@raizen/client-auth';

export const shouldSyncCertificateRequests = async (uuid?: string) => {
  let url = `/certificate-requests/should-sync`;
  if (uuid) {
    url += `?uuid=${uuid}`;
  }
  const { data } = await httpClient.get<boolean>(url);
  return data;
};
