import { useMemo, ReactNode } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import useToastsHook from '../hooks/useToastsHook';
import { useTranslation } from '@raizen/react-hooks';

export const ReactQueryProvider = ({ children }: { children: ReactNode }) => {
  const { showErrorToast } = useToastsHook();
  const { t } = useTranslation();
  const queryClientConfig = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: 3,
            onError: (error: any) => {
              const errorMessage: string =
                error?.response?.data?.message || t('errorCode.GENERIC');
              showErrorToast({ title: '', description: errorMessage });
            },
          },
        },
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return (
    <QueryClientProvider client={queryClientConfig}>
      {children}
    </QueryClientProvider>
  );
};
