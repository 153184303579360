import { httpClient } from '@raizen/client-auth';
import { PendingCertificateShareRequestsResponseType } from '@raizen/types';

export const getPendingCertificateShareRequests = async () => {
  const { data } =
    await httpClient.get<PendingCertificateShareRequestsResponseType>(
      '/certificate-share-requests/pending',
      {},
    );
  return data;
};
