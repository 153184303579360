import { useTranslation } from '@raizen/react-hooks';
import {
  FiHash,
  FiUser,
  FiMapPin,
  FiCalendar,
  FiFileText,
  FiGlobe,
  FiKey,
} from 'react-icons/fi';
import {
  PackageIcon,
  LeafProtectIcon,
  BuildingIcon,
  TreeIcon,
  PlantIcon,
  FieldIcon,
} from '@raizen/ui';
import { ScalesIcon, BoxIcon } from '@raizen/ui';
import { formatDate } from '@raizen/common-utils';
import { RaizenMetadataProductType } from '@raizen/db';
import { HashRow } from './HashRow';
import { usePrintingState } from '../../contexts/PrintingStateProvider';
import { AccessKeyRow } from './AccessKeyRow';

export const useRowsMap = () => {
  const { t } = useTranslation();
  const { isPrinting } = usePrintingState();
  return {
    // General Info
    accessKey: (accessKey: string) => {
      return {
        id: 'accessKeyRow',
        data: {
          icon: FiHash,
          label: t('common.accessKey'),
          value: <AccessKeyRow text={accessKey} />,
        },
      };
    },
    invoiceNumber: (invoiceNumber?: string) => {
      if (invoiceNumber === undefined) return null;
      return {
        id: 'invoiceNumberRow',
        data: {
          icon: FiHash,
          label: t('common.invoiceNumber'),
          value: `#${invoiceNumber}`,
        },
      };
    },
    invoiceDate: (invoiceDate?: number) => {
      if (invoiceDate === undefined) return null;
      return {
        id: 'invoiceDateRow',
        data: {
          icon: FiCalendar,
          label: t('common.invoiceDate'),
          value: invoiceDate ? formatDate(Number(invoiceDate)) : '-',
        },
      };
    },
    issuedAt: (issuedAt?: number) => {
      if (issuedAt === undefined) return null;
      return {
        id: 'issuedAtRow',
        data: {
          icon: FiCalendar,
          label: t('common.issued'),
          value: issuedAt ? formatDate(Number(issuedAt)) : '-',
        },
      };
    },
    companyName: (companyName?: string) => {
      if (companyName === undefined) return null;
      return {
        id: 'companyNameRow',
        data: {
          icon: BuildingIcon,
          label: t('common.companyName'),
          value: companyName,
        },
      };
    },
    productType: (productType?: RaizenMetadataProductType) => {
      if (productType === undefined) return null;
      return {
        id: 'productTypeRow',
        data: {
          icon: BoxIcon,
          label: t('common.product'),
          value: t(`productType.${productType}`),
        },
      };
    },
    requesterFullName: (fullName?: string) => {
      if (fullName === undefined) return null;
      return {
        id: 'certificateRequesterRow',
        data: {
          icon: FiUser,
          label: t('common.certificateRequester'),
          value: fullName,
        },
      };
    },
    // Blockchain
    transactionHash: (root?: string) => {
      if (root === undefined) return null;
      return {
        id: 'transactionHashRow',
        data: {
          icon: isPrinting ? null : FiHash,
          label: t('common.certificateHash'),
          value: <HashRow root={root} />,
          tooltip: isPrinting ? '' : t('common.tooltip.transactionHash'),
        },
      };
    },
    network: (network?: string, hash?: string) => {
      const isInBlockChain = Boolean(hash);
      if (network === undefined || !isInBlockChain) return null;
      return {
        id: 'networkRow',
        data: {
          icon: BoxIcon,
          label: t('common.network'),
          value: network,
        },
      };
    },
    // Product info
    bioparkName: (name?: string) => {
      if (name === undefined) return null;
      return {
        id: 'bioparkNameRow',
        data: {
          icon: FiHash,
          label: t('common.bioparkId'),
          value: name,
        },
      };
    },
    bioparkCityAndState: (value?: string) => {
      if (value === undefined) return null;
      return {
        id: 'bioparkCityAndStateRow',
        data: {
          icon: FiMapPin,
          label: t('common.bioparkCityAndState'),
          value: value,
        },
      };
    },
    productionDate: (date?: number) => {
      if (date === undefined) return null;
      return {
        id: 'productionDateRow',
        data: {
          icon: FiCalendar,
          label: t('common.createdAt'),
          value: date ? formatDate(Number(date)) : '-',
        },
      };
    },
    productTotalQuantity: (quantity?: string, unit?: string) => {
      if (quantity === undefined && unit === undefined) return null;
      return {
        id: 'productTotalQuantityRow',
        data: {
          icon: PackageIcon,
          label: t('common.productTotalQuantity'),
          value: !quantity ? '-' : `${quantity} ${unit || '-'}`,
        },
      };
    },
    productCertifiedQuantity: (quantity?: string, unit?: string) => {
      if (quantity === undefined && unit === undefined) return null;
      return {
        id: 'productCertifiedQuantityRow',
        data: {
          icon: PackageIcon,
          label: t('common.productCertifiedQuantity'),
          value: !quantity ? '-' : `${quantity} ${unit || '-'}`,
          tooltip: t('common.tooltip.productCertifiedQuantity'),
        },
      };
    },
    massBalance: (value?: boolean) => {
      if (value === undefined) return null;
      return {
        id: 'massBalanceRow',
        data: {
          icon: ScalesIcon,
          label: t('common.massBalance'),
          value: value ? t('common.yes') : t('common.no'),
          tooltip: t('common.tooltip.massBalance'),
        },
      };
    },
    carbonFootprint: (value?: string, unit?: string) => {
      if (value === undefined && unit === undefined) return null;
      return {
        id: 'carbonFootprintRow',
        data: {
          icon: LeafProtectIcon,
          label: t('common.carbonFootprint'),
          value: !value ? '-' : `${value} ${unit || '-'}`,
          tooltip: t('common.tooltip.carbonFootprint'),
        },
      };
    },
    bonsucroCertification: (value?: boolean) => {
      if (value === undefined) return null;
      return {
        id: 'bonsucroCertificationRow',
        data: {
          icon: FiFileText,
          label: t('common.bonsucroCertification'),
          value: value ? t('common.yes') : t('common.no'),
          tooltip: t('common.tooltip.bonsucroCertification'),
        },
      };
    },
    foodchainCertification: (value?: boolean) => {
      if (value === undefined) return null;
      return {
        id: 'foodchainCertificationRow',
        data: {
          icon: FiFileText,
          label: t('common.foodchainCertification'),
          value: value ? t('common.yes') : t('common.no'),
          tooltip: t('common.tooltip.foodchainCertification'),
        },
      };
    },
    // Farm Info
    farmName: (name?: string) => {
      if (name === undefined) return null;
      return {
        id: 'farmNameRow',
        data: {
          icon: FieldIcon,
          label: t('common.farmName'),
          value: name ?? '-',
        },
      };
    },
    zone: (zone?: string) => {
      if (zone === undefined) return null;
      return {
        id: 'zoneRow',
        data: {
          icon: FiHash,
          label: t('common.zone'),
          value: zone ?? '-',
        },
      };
    },
    farmLatitude: (latitude?: string) => {
      if (latitude === undefined) return null;
      return {
        id: 'farmLatitudeRow',
        data: {
          icon: FiGlobe,
          label: t('common.farmLatitude'),
          value: latitude ?? '-',
        },
      };
    },
    farmLongitude: (longitude?: string) => {
      if (longitude === undefined) return null;
      return {
        id: 'farmLongitudeRow',
        data: {
          icon: FiGlobe,
          label: t('common.farmLongitude'),
          value: longitude ?? '-',
        },
      };
    },
    elo: (elo?: boolean) => {
      if (elo === undefined) return null;
      return {
        id: 'farmEloRow',
        data: {
          icon: FiGlobe,
          label: t('common.elos'),
          value: elo ? t('common.yes') : t('common.no'),
        },
      };
    },
    isRented: (value?: boolean) => {
      if (value === undefined) return null;
      return {
        id: 'isRentedRow',
        data: {
          icon: FiKey,
          label: t('common.isRented'),
          value: value ? t('common.yes') : t('common.no'),
          tooltip: t('common.tooltip.isRented'),
        },
      };
    },
    car: (value?: string) => {
      if (value === undefined) return null;
      return {
        id: 'carRow',
        data: {
          icon: FiFileText,
          label: t('common.car'),
          value: value ?? '-',
          tooltip: t('common.tooltip.car'),
        },
      };
    },
    deforestationFree: (value?: string) => {
      if (value === undefined) return null;
      return {
        id: 'deforestationFreeRow',
        data: {
          icon: TreeIcon,
          label: t('common.deforestationFree'),
          value: value ?? '-',
          tooltip: t('common.tooltip.deforestationFree'),
        },
      };
    },
    useOfNativeTribesLands: (value?: string) => {
      if (value === undefined) return null;
      return {
        id: 'useOfNativeTribesLandsRow',
        data: {
          icon: FieldIcon,
          label: t('common.useOfNativeTribesLands'),
          value: value ?? '-',
          tooltip: t('common.tooltip.useOfNativeTribesLands'),
        },
      };
    },
    useOfQuilombolaLands: (value?: string) => {
      if (value === undefined) return null;
      return {
        id: 'useOfQuilombolaLandsRow',
        data: {
          icon: FieldIcon,
          label: t('common.useOfQuilombolaLands'),
          value: value ?? '-',
          tooltip: t('common.tooltip.useOfQuilombolaLands'),
        },
      };
    },
    historicalSoilCoverage: (value?: string) => {
      if (value === undefined) return null;
      return {
        id: 'historicalSoilCoverageRow',
        data: {
          icon: PlantIcon,
          label: t('common.historicalSoilCoverage'),
          value: value ? (
            <a
              href="/pdf/historical_soil_field.pdf"
              target="_blank"
              style={{ textDecoration: 'underline' }}
            >
              {value}
            </a>
          ) : (
            '-'
          ),
          tooltip: t('common.tooltip.historicalSoilCoverage'),
        },
      };
    },
  };
};
