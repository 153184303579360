import { Box, Flex } from '@chakra-ui/react';
import { HeaderActions, HeaderActionProps } from './HeaderActions';
import { HeaderTitle } from './HeaderTitle';
import { HeaderDescription } from './HeaderDescription';
import { Badge } from '@raizen/ui';

interface BadgeProps {
  text: string;
  colorScheme?:
    | 'gray'
    | 'error'
    | 'warning'
    | 'success'
    | 'blue-light'
    | 'blue'
    | 'indigo'
    | 'purple'
    | 'pink'
    | 'rose'
    | 'orange'
    | 'blue-gray';
}

interface Props {
  title: string;
  description?: string;
  actions?: HeaderActionProps[];
  badge?: BadgeProps;
}

export const Header = ({ title, description, actions, badge }: Props) => {
  const { text: badgeText, colorScheme: badgeColorSheme } = badge ?? {};

  const displayMobile = { base: 'flex', md: 'none' };
  const displayDesktop = { base: 'none', md: 'flex' };

  return (
    <Flex direction={'column'} gap={4}>
      <Flex direction={'column'} gap={1}>
        {badgeText && (
          <Badge
            colorScheme={badgeColorSheme}
            marginBottom={2}
            size={'sm'}
            display={displayMobile}
          >
            {badgeText}
          </Badge>
        )}
        <Flex
          justifyContent={'space-between'}
          alignItems={{ base: 'normal', md: 'center' }}
          direction={{ base: 'column', md: 'row' }}
        >
          <Flex alignItems={'center'} gap={4}>
            <HeaderTitle title={title} />
            {badgeText && (
              <Badge
                colorScheme={badgeColorSheme}
                marginRight={4}
                size="md"
                display={displayDesktop}
              >
                {badgeText}
              </Badge>
            )}
          </Flex>
          {actions && (
            <Box display={displayDesktop}>
              <HeaderActions actions={actions} />
            </Box>
          )}
        </Flex>
        {description && <HeaderDescription description={description} />}
      </Flex>
      {actions && (
        <Box display={displayMobile}>
          <HeaderActions actions={actions} />
        </Box>
      )}
    </Flex>
  );
};
