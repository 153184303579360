import { Flex, Text } from '@chakra-ui/react';

interface Props {
  firstName?: string;
  lastName?: string;
  email?: string;
  companyName?: string;
}

export const CurrentUserInfo = ({
  firstName = '',
  lastName = '',
  email = '',
  companyName = '',
}: Props) => {
  return (
    <Flex py="6px" px="12px" direction="column">
      <Text color="gray.700" fontSize="sm" fontFamily="Larsseit-Medium">
        {firstName} {lastName}
      </Text>
      <Text color="gray.500" fontSize="sm">
        {email}
      </Text>
      <Text color="gray.500" fontSize="sm">
        {companyName}
      </Text>
    </Flex>
  );
};
