"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.tsx
var src_exports = {};
__export(src_exports, {
  certificateRequestsAlertVariant: () => certificateRequestsAlertVariant,
  certificateRequestsStatusBadgeColors: () => certificateRequestsStatusBadgeColors,
  certificateShareRequestsStatusBadgeColors: () => certificateShareRequestsStatusBadgeColors,
  greenFactorBadgeColors: () => greenFactorBadgeColors,
  greenFactorRibbons: () => greenFactorRibbons,
  userStatusBadgeColors: () => userStatusBadgeColors
});
module.exports = __toCommonJS(src_exports);

// src/utils/green-factor-badge-colors.ts
var import_db = require("@raizen/db");
var greenFactorBadgeColors = {
  [import_db.GREEN_FACTOR_VALUES.BRONZE]: "orange",
  [import_db.GREEN_FACTOR_VALUES.SILVER]: "gray",
  [import_db.GREEN_FACTOR_VALUES.GOLD]: "warning",
  [import_db.GREEN_FACTOR_VALUES.STANDARD]: "gray"
};

// src/utils/certificate-requests-status-badge-colors.ts
var import_db2 = require("@raizen/db");
var certificateRequestsStatusBadgeColors = {
  [import_db2.CERTIFICATE_REQUEST_STATUS.COMPLETED]: "success",
  [import_db2.CERTIFICATE_REQUEST_STATUS.CREATED]: "blue-light",
  [import_db2.CERTIFICATE_REQUEST_STATUS.DENIED]: "error",
  [import_db2.CERTIFICATE_REQUEST_STATUS.IN_PROGRESS]: "blue-light",
  [import_db2.CERTIFICATE_REQUEST_STATUS.NOT_REQUESTED]: "gray",
  [import_db2.CERTIFICATE_REQUEST_STATUS.PENDING]: "gray",
  [import_db2.CERTIFICATE_REQUEST_STATUS.FAILED_IN_BLOCKCHAIN]: "error",
  [import_db2.CERTIFICATE_REQUEST_STATUS.NOT_FOUND]: "warning"
};

// src/utils/green-factor-ribbons.tsx
var import_db3 = require("@raizen/db");

// src/utils/BronzeRibbon.tsx
var import_jsx_runtime = require("react/jsx-runtime");
var BronzeRibbon = () => /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: "82",
    height: "112",
    viewBox: "0 0 82 112",
    fill: "none",
    children: [
      /* @__PURE__ */ (0, import_jsx_runtime.jsx)("g", { filter: "url(#filter0_i_605_3813)", children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(
        "path",
        {
          d: "M69.094 108.784C69.2514 110.154 67.9606 111.241 66.6374 110.853L40.7565 103.258L14.8756 110.853C13.5524 111.241 12.2616 110.154 12.4191 108.784L19.1491 50.2325C19.2609 49.26 20.0842 48.5259 21.0631 48.5259L40.7565 48.5259L60.4499 48.5259C61.4288 48.5259 62.2521 49.26 62.3639 50.2325L69.094 108.784Z",
          fill: "#BF46BF"
        }
      ) }),
      /* @__PURE__ */ (0, import_jsx_runtime.jsx)(
        "path",
        {
          d: "M40.7565 103.258L66.6374 110.853C67.9606 111.241 69.2514 110.154 69.094 108.784L62.3639 50.2325C62.2521 49.26 61.4288 48.5259 60.4499 48.5259L40.7565 48.5259M40.7565 103.258L14.8756 110.853C13.5524 111.241 12.2616 110.154 12.4191 108.784L19.1491 50.2325C19.2609 49.26 20.0842 48.5259 21.0631 48.5259L40.7565 48.5259M40.7565 103.258L40.7565 48.5259",
          stroke: "#101828",
          strokeWidth: "1.20905"
        }
      ),
      /* @__PURE__ */ (0, import_jsx_runtime.jsx)("g", { filter: "url(#filter1_i_605_3813)", children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)("circle", { cx: "41", cy: "41.3784", r: "41", fill: "#F2B36C" }) }),
      /* @__PURE__ */ (0, import_jsx_runtime.jsx)(
        "circle",
        {
          cx: "41",
          cy: "41.3784",
          r: "40.3955",
          stroke: "#101828",
          strokeWidth: "1.20905"
        }
      ),
      /* @__PURE__ */ (0, import_jsx_runtime.jsx)("g", { filter: "url(#filter2_i_605_3813)", children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)("circle", { cx: "39.3722", cy: "41.3785", r: "30.2262", fill: "#E97E21" }) }),
      /* @__PURE__ */ (0, import_jsx_runtime.jsx)(
        "circle",
        {
          cx: "39.3722",
          cy: "41.3785",
          r: "29.6216",
          stroke: "#101828",
          strokeWidth: "1.20905"
        }
      ),
      /* @__PURE__ */ (0, import_jsx_runtime.jsx)(
        "path",
        {
          d: "M25.042 50.4788C25.8004 51.1938 26.6963 51.7472 27.6751 52.1055C28.654 52.4637 29.6954 52.6192 30.7362 52.5626C31.777 52.506 32.7955 52.2385 33.7297 51.7763C34.664 51.3141 35.4945 50.6668 36.171 49.8738C36.8474 49.0808 37.3556 48.1585 37.6648 47.1631C37.9739 46.1676 38.0775 45.1198 37.9693 44.0831C37.8611 43.0463 37.5433 42.0425 37.0352 41.1323C36.5271 40.2222 35.8394 39.4248 35.0137 38.7886C31.8125 36.044 21.2598 37.1645 21.2598 37.1645C21.2598 37.1645 21.3296 46.9789 25.042 50.4788Z",
          fill: "#F2B36C",
          stroke: "#101828",
          strokeWidth: "1.20905",
          strokeLinecap: "round",
          strokeLinejoin: "round"
        }
      ),
      /* @__PURE__ */ (0, import_jsx_runtime.jsx)(
        "path",
        {
          d: "M56.3224 42.1932C54.9807 43.9017 53.0432 45.0404 50.8977 45.3812C48.7523 45.7221 46.5572 45.24 44.752 44.0315C42.9469 42.823 41.6648 40.9772 41.1625 38.8637C40.6602 36.7502 40.9747 34.5249 42.0431 32.6335C44.9941 27.4107 56.4185 26.7337 56.4185 26.7337C56.4185 26.7337 60.1215 37.4469 56.3224 42.1932Z",
          fill: "#F2B36C",
          stroke: "#101828",
          strokeWidth: "1.20905",
          strokeLinecap: "round",
          strokeLinejoin: "round"
        }
      ),
      /* @__PURE__ */ (0, import_jsx_runtime.jsx)(
        "path",
        {
          d: "M30.4991 44.4265C33.9214 46.941 35.8991 48.5231 37.7977 51.2919C39.6963 54.0606 40.4028 56.4687 40.5832 58.9781C40.5832 51.7886 43.1715 43.47 51.0117 35.5728",
          stroke: "#101828",
          strokeWidth: "1.20905",
          strokeLinecap: "round",
          strokeLinejoin: "round"
        }
      ),
      /* @__PURE__ */ (0, import_jsx_runtime.jsxs)("defs", { children: [
        /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(
          "filter",
          {
            id: "filter0_i_605_3813",
            x: "11.8013",
            y: "47.9214",
            width: "57.9106",
            height: "63.6157",
            filterUnits: "userSpaceOnUse",
            colorInterpolationFilters: "sRGB",
            children: [
              /* @__PURE__ */ (0, import_jsx_runtime.jsx)("feFlood", { floodOpacity: "0", result: "BackgroundImageFix" }),
              /* @__PURE__ */ (0, import_jsx_runtime.jsx)(
                "feBlend",
                {
                  mode: "normal",
                  in: "SourceGraphic",
                  in2: "BackgroundImageFix",
                  result: "shape"
                }
              ),
              /* @__PURE__ */ (0, import_jsx_runtime.jsx)(
                "feColorMatrix",
                {
                  in: "SourceAlpha",
                  type: "matrix",
                  values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
                  result: "hardAlpha"
                }
              ),
              /* @__PURE__ */ (0, import_jsx_runtime.jsx)("feOffset", { dx: "-3" }),
              /* @__PURE__ */ (0, import_jsx_runtime.jsx)("feComposite", { in2: "hardAlpha", operator: "arithmetic", k2: "-1", k3: "1" }),
              /* @__PURE__ */ (0, import_jsx_runtime.jsx)(
                "feColorMatrix",
                {
                  type: "matrix",
                  values: "0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 1 0"
                }
              ),
              /* @__PURE__ */ (0, import_jsx_runtime.jsx)(
                "feBlend",
                {
                  mode: "normal",
                  in2: "shape",
                  result: "effect1_innerShadow_605_3813"
                }
              )
            ]
          }
        ),
        /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(
          "filter",
          {
            id: "filter1_i_605_3813",
            x: "0",
            y: "0.378418",
            width: "82",
            height: "82",
            filterUnits: "userSpaceOnUse",
            colorInterpolationFilters: "sRGB",
            children: [
              /* @__PURE__ */ (0, import_jsx_runtime.jsx)("feFlood", { floodOpacity: "0", result: "BackgroundImageFix" }),
              /* @__PURE__ */ (0, import_jsx_runtime.jsx)(
                "feBlend",
                {
                  mode: "normal",
                  in: "SourceGraphic",
                  in2: "BackgroundImageFix",
                  result: "shape"
                }
              ),
              /* @__PURE__ */ (0, import_jsx_runtime.jsx)(
                "feColorMatrix",
                {
                  in: "SourceAlpha",
                  type: "matrix",
                  values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
                  result: "hardAlpha"
                }
              ),
              /* @__PURE__ */ (0, import_jsx_runtime.jsx)("feOffset", { dx: "-3", dy: "-0.897039" }),
              /* @__PURE__ */ (0, import_jsx_runtime.jsx)("feComposite", { in2: "hardAlpha", operator: "arithmetic", k2: "-1", k3: "1" }),
              /* @__PURE__ */ (0, import_jsx_runtime.jsx)(
                "feColorMatrix",
                {
                  type: "matrix",
                  values: "0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 1 0"
                }
              ),
              /* @__PURE__ */ (0, import_jsx_runtime.jsx)(
                "feBlend",
                {
                  mode: "normal",
                  in2: "shape",
                  result: "effect1_innerShadow_605_3813"
                }
              )
            ]
          }
        ),
        /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(
          "filter",
          {
            id: "filter2_i_605_3813",
            x: "9.146",
            y: "11.1523",
            width: "60.4521",
            height: "60.4521",
            filterUnits: "userSpaceOnUse",
            colorInterpolationFilters: "sRGB",
            children: [
              /* @__PURE__ */ (0, import_jsx_runtime.jsx)("feFlood", { floodOpacity: "0", result: "BackgroundImageFix" }),
              /* @__PURE__ */ (0, import_jsx_runtime.jsx)(
                "feBlend",
                {
                  mode: "normal",
                  in: "SourceGraphic",
                  in2: "BackgroundImageFix",
                  result: "shape"
                }
              ),
              /* @__PURE__ */ (0, import_jsx_runtime.jsx)(
                "feColorMatrix",
                {
                  in: "SourceAlpha",
                  type: "matrix",
                  values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
                  result: "hardAlpha"
                }
              ),
              /* @__PURE__ */ (0, import_jsx_runtime.jsx)("feOffset", { dx: "3" }),
              /* @__PURE__ */ (0, import_jsx_runtime.jsx)("feComposite", { in2: "hardAlpha", operator: "arithmetic", k2: "-1", k3: "1" }),
              /* @__PURE__ */ (0, import_jsx_runtime.jsx)(
                "feColorMatrix",
                {
                  type: "matrix",
                  values: "0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 1 0"
                }
              ),
              /* @__PURE__ */ (0, import_jsx_runtime.jsx)(
                "feBlend",
                {
                  mode: "normal",
                  in2: "shape",
                  result: "effect1_innerShadow_605_3813"
                }
              )
            ]
          }
        )
      ] })
    ]
  }
);

// src/utils/SilverRibbon.tsx
var import_jsx_runtime2 = require("react/jsx-runtime");
var SilverRibbon = () => /* @__PURE__ */ (0, import_jsx_runtime2.jsxs)(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: "83",
    height: "112",
    viewBox: "0 0 83 112",
    fill: "none",
    children: [
      /* @__PURE__ */ (0, import_jsx_runtime2.jsx)("g", { filter: "url(#filter0_i_605_9091)", children: /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(
        "path",
        {
          d: "M69.6179 108.76C69.7753 110.13 68.4846 111.217 67.1614 110.828L41.2804 103.233L15.3995 110.828C14.0763 111.217 12.7855 110.13 12.943 108.76L19.673 50.2082C19.7848 49.2356 20.6081 48.5015 21.587 48.5015L41.2804 48.5015L60.9738 48.5015C61.9527 48.5015 62.776 49.2356 62.8878 50.2082L69.6179 108.76Z",
          fill: "#BF46BF"
        }
      ) }),
      /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(
        "path",
        {
          d: "M41.2804 103.233L67.1614 110.828C68.4846 111.217 69.7753 110.13 69.6179 108.76L62.8878 50.2082C62.776 49.2356 61.9527 48.5015 60.9738 48.5015L41.2804 48.5015M41.2804 103.233L15.3995 110.828C14.0763 111.217 12.7855 110.13 12.943 108.76L19.673 50.2082C19.7848 49.2356 20.6081 48.5015 21.587 48.5015L41.2804 48.5015M41.2804 103.233L41.2804 48.5015",
          stroke: "#101828",
          strokeWidth: "1.20905"
        }
      ),
      /* @__PURE__ */ (0, import_jsx_runtime2.jsx)("g", { filter: "url(#filter1_i_605_9091)", children: /* @__PURE__ */ (0, import_jsx_runtime2.jsx)("ellipse", { cx: "41.5239", cy: "41.354", rx: "41", ry: "41", fill: "#EFEFEF" }) }),
      /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(
        "path",
        {
          d: "M81.9194 41.354C81.9194 63.6638 63.8337 81.7494 41.5239 81.7494C19.2141 81.7494 1.12845 63.6638 1.12845 41.354C1.12845 19.0442 19.2141 0.958527 41.5239 0.958527C63.8337 0.958527 81.9194 19.0442 81.9194 41.354Z",
          stroke: "#101828",
          strokeWidth: "1.20905"
        }
      ),
      /* @__PURE__ */ (0, import_jsx_runtime2.jsx)("g", { filter: "url(#filter2_i_605_9091)", children: /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(
        "ellipse",
        {
          cx: "39.8958",
          cy: "41.3541",
          rx: "30.2262",
          ry: "30.2261",
          fill: "#D0D5DD"
        }
      ) }),
      /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(
        "path",
        {
          d: "M69.5175 41.3541C69.5175 57.7136 56.2554 70.9757 39.8958 70.9757C23.5363 70.9757 10.2742 57.7136 10.2742 41.3541C10.2742 24.9945 23.5363 11.7325 39.8958 11.7325C56.2554 11.7325 69.5175 24.9945 69.5175 41.3541Z",
          stroke: "#101828",
          strokeWidth: "1.20905"
        }
      ),
      /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(
        "path",
        {
          d: "M25.5654 50.4549C26.3239 51.1699 27.2197 51.7233 28.1985 52.0815C29.1774 52.4397 30.2188 52.5953 31.2596 52.5387C32.3004 52.4821 33.3189 52.2146 34.2531 51.7524C35.1874 51.2901 36.018 50.6429 36.6944 49.8499C37.3709 49.0568 37.8791 48.1346 38.1882 47.1392C38.4974 46.1437 38.601 45.0958 38.4927 44.0591C38.3845 43.0224 38.0667 42.0185 37.5586 41.1084C37.0506 40.1983 36.3628 39.4009 35.5372 38.7647C32.3359 36.0201 21.7833 37.1406 21.7833 37.1406C21.7833 37.1406 21.853 46.955 25.5654 50.4549Z",
          fill: "#EFEFEF",
          stroke: "#101828",
          strokeWidth: "1.20905",
          strokeLinecap: "round",
          strokeLinejoin: "round"
        }
      ),
      /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(
        "path",
        {
          d: "M56.8461 42.1692C55.5044 43.8777 53.5669 45.0164 51.4214 45.3573C49.2759 45.6982 47.0809 45.2161 45.2757 44.0076C43.4705 42.799 42.1885 40.9532 41.6862 38.8397C41.1839 36.7262 41.4984 34.501 42.5668 32.6095C45.5178 27.3867 56.9422 26.7098 56.9422 26.7098C56.9422 26.7098 60.6452 37.4229 56.8461 42.1692Z",
          fill: "#EFEFEF",
          stroke: "#101828",
          strokeWidth: "1.20905",
          strokeLinecap: "round",
          strokeLinejoin: "round"
        }
      ),
      /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(
        "path",
        {
          d: "M31.0226 44.4026C34.4449 46.917 36.4226 48.4992 38.3211 51.2679C40.2197 54.0367 40.9263 56.4447 41.1066 58.9541C41.1066 51.7646 43.695 43.4461 51.5352 35.5488",
          stroke: "#101828",
          strokeWidth: "1.20905",
          strokeLinecap: "round",
          strokeLinejoin: "round"
        }
      ),
      /* @__PURE__ */ (0, import_jsx_runtime2.jsxs)("defs", { children: [
        /* @__PURE__ */ (0, import_jsx_runtime2.jsxs)(
          "filter",
          {
            id: "filter0_i_605_9091",
            x: "12.3252",
            y: "47.897",
            width: "57.9104",
            height: "63.6157",
            filterUnits: "userSpaceOnUse",
            colorInterpolationFilters: "sRGB",
            children: [
              /* @__PURE__ */ (0, import_jsx_runtime2.jsx)("feFlood", { floodOpacity: "0", result: "BackgroundImageFix" }),
              /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(
                "feBlend",
                {
                  mode: "normal",
                  in: "SourceGraphic",
                  in2: "BackgroundImageFix",
                  result: "shape"
                }
              ),
              /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(
                "feColorMatrix",
                {
                  in: "SourceAlpha",
                  type: "matrix",
                  values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
                  result: "hardAlpha"
                }
              ),
              /* @__PURE__ */ (0, import_jsx_runtime2.jsx)("feOffset", { dx: "-3" }),
              /* @__PURE__ */ (0, import_jsx_runtime2.jsx)("feComposite", { in2: "hardAlpha", operator: "arithmetic", k2: "-1", k3: "1" }),
              /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(
                "feColorMatrix",
                {
                  type: "matrix",
                  values: "0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 1 0"
                }
              ),
              /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(
                "feBlend",
                {
                  mode: "normal",
                  in2: "shape",
                  result: "effect1_innerShadow_605_9091"
                }
              )
            ]
          }
        ),
        /* @__PURE__ */ (0, import_jsx_runtime2.jsxs)(
          "filter",
          {
            id: "filter1_i_605_9091",
            x: "0.523926",
            y: "0.354004",
            width: "82",
            height: "82",
            filterUnits: "userSpaceOnUse",
            colorInterpolationFilters: "sRGB",
            children: [
              /* @__PURE__ */ (0, import_jsx_runtime2.jsx)("feFlood", { floodOpacity: "0", result: "BackgroundImageFix" }),
              /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(
                "feBlend",
                {
                  mode: "normal",
                  in: "SourceGraphic",
                  in2: "BackgroundImageFix",
                  result: "shape"
                }
              ),
              /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(
                "feColorMatrix",
                {
                  in: "SourceAlpha",
                  type: "matrix",
                  values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
                  result: "hardAlpha"
                }
              ),
              /* @__PURE__ */ (0, import_jsx_runtime2.jsx)("feOffset", { dx: "-3", dy: "-0.897039" }),
              /* @__PURE__ */ (0, import_jsx_runtime2.jsx)("feComposite", { in2: "hardAlpha", operator: "arithmetic", k2: "-1", k3: "1" }),
              /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(
                "feColorMatrix",
                {
                  type: "matrix",
                  values: "0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 1 0"
                }
              ),
              /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(
                "feBlend",
                {
                  mode: "normal",
                  in2: "shape",
                  result: "effect1_innerShadow_605_9091"
                }
              )
            ]
          }
        ),
        /* @__PURE__ */ (0, import_jsx_runtime2.jsxs)(
          "filter",
          {
            id: "filter2_i_605_9091",
            x: "9.66968",
            y: "11.1279",
            width: "60.4524",
            height: "60.4521",
            filterUnits: "userSpaceOnUse",
            colorInterpolationFilters: "sRGB",
            children: [
              /* @__PURE__ */ (0, import_jsx_runtime2.jsx)("feFlood", { floodOpacity: "0", result: "BackgroundImageFix" }),
              /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(
                "feBlend",
                {
                  mode: "normal",
                  in: "SourceGraphic",
                  in2: "BackgroundImageFix",
                  result: "shape"
                }
              ),
              /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(
                "feColorMatrix",
                {
                  in: "SourceAlpha",
                  type: "matrix",
                  values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
                  result: "hardAlpha"
                }
              ),
              /* @__PURE__ */ (0, import_jsx_runtime2.jsx)("feOffset", { dx: "3" }),
              /* @__PURE__ */ (0, import_jsx_runtime2.jsx)("feComposite", { in2: "hardAlpha", operator: "arithmetic", k2: "-1", k3: "1" }),
              /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(
                "feColorMatrix",
                {
                  type: "matrix",
                  values: "0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 1 0"
                }
              ),
              /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(
                "feBlend",
                {
                  mode: "normal",
                  in2: "shape",
                  result: "effect1_innerShadow_605_9091"
                }
              )
            ]
          }
        )
      ] })
    ]
  }
);

// src/utils/GoldRibbon.tsx
var import_jsx_runtime3 = require("react/jsx-runtime");
var GoldRibbon = () => /* @__PURE__ */ (0, import_jsx_runtime3.jsxs)(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: "82",
    height: "112",
    viewBox: "0 0 82 112",
    fill: "none",
    children: [
      /* @__PURE__ */ (0, import_jsx_runtime3.jsx)("g", { filter: "url(#filter0_i_605_7267)", children: /* @__PURE__ */ (0, import_jsx_runtime3.jsx)(
        "path",
        {
          d: "M69.094 108.406C69.2514 109.776 67.9606 110.863 66.6374 110.474L40.7565 102.879L14.8756 110.474C13.5524 110.863 12.2616 109.776 12.4191 108.406L19.1491 49.8541C19.2609 48.8816 20.0842 48.1475 21.0631 48.1475L40.7565 48.1475L60.4499 48.1475C61.4288 48.1475 62.2521 48.8816 62.3639 49.8541L69.094 108.406Z",
          fill: "#BF46BF"
        }
      ) }),
      /* @__PURE__ */ (0, import_jsx_runtime3.jsx)(
        "path",
        {
          d: "M40.7565 102.879L66.6374 110.474C67.9606 110.863 69.2514 109.776 69.094 108.406L62.3639 49.8541C62.2521 48.8816 61.4288 48.1475 60.4499 48.1475L40.7565 48.1475M40.7565 102.879L14.8756 110.474C13.5524 110.863 12.2616 109.776 12.4191 108.406L19.1491 49.8541C19.2609 48.8816 20.0842 48.1475 21.0631 48.1475L40.7565 48.1475M40.7565 102.879L40.7565 48.1475",
          stroke: "#101828",
          strokeWidth: "1.20905"
        }
      ),
      /* @__PURE__ */ (0, import_jsx_runtime3.jsx)("g", { filter: "url(#filter1_i_605_7267)", children: /* @__PURE__ */ (0, import_jsx_runtime3.jsx)("circle", { cx: "41", cy: "41", r: "41", fill: "#FFE248" }) }),
      /* @__PURE__ */ (0, import_jsx_runtime3.jsx)(
        "circle",
        {
          cx: "41",
          cy: "41",
          r: "40.3955",
          stroke: "#101828",
          strokeWidth: "1.20905"
        }
      ),
      /* @__PURE__ */ (0, import_jsx_runtime3.jsx)("g", { filter: "url(#filter2_i_605_7267)", children: /* @__PURE__ */ (0, import_jsx_runtime3.jsx)("circle", { cx: "39.3719", cy: "41.0001", r: "30.2262", fill: "#F3C10F" }) }),
      /* @__PURE__ */ (0, import_jsx_runtime3.jsx)(
        "circle",
        {
          cx: "39.3719",
          cy: "41.0001",
          r: "29.6216",
          stroke: "#101828",
          strokeWidth: "1.20905"
        }
      ),
      /* @__PURE__ */ (0, import_jsx_runtime3.jsx)(
        "path",
        {
          d: "M25.0416 50.1004C25.8001 50.8154 26.6959 51.3688 27.6747 51.727C28.6536 52.0852 29.695 52.2408 30.7358 52.1842C31.7766 52.1276 32.7951 51.8601 33.7293 51.3979C34.6636 50.9357 35.4942 50.2884 36.1706 49.4954C36.8471 48.7023 37.3553 47.7801 37.6644 46.7847C37.9736 45.7892 38.0772 44.7413 37.9689 43.7046C37.8607 42.6679 37.5429 41.664 37.0348 40.7539C36.5268 39.8438 35.839 39.0464 35.0134 38.4102C31.8121 35.6656 21.2595 36.7861 21.2595 36.7861C21.2595 36.7861 21.3292 46.6005 25.0416 50.1004Z",
          fill: "#FFE248",
          stroke: "#101828",
          strokeWidth: "1.20905",
          strokeLinecap: "round",
          strokeLinejoin: "round"
        }
      ),
      /* @__PURE__ */ (0, import_jsx_runtime3.jsx)(
        "path",
        {
          d: "M56.3223 41.8147C54.9806 43.5233 53.0431 44.6619 50.8976 45.0028C48.7521 45.3437 46.5571 44.8616 44.7519 43.6531C42.9467 42.4446 41.6647 40.5988 41.1624 38.4853C40.6601 36.3718 40.9746 34.1465 42.043 32.255C44.994 27.0322 56.4184 26.3553 56.4184 26.3553C56.4184 26.3553 60.1214 37.0685 56.3223 41.8147Z",
          fill: "#FFE248",
          stroke: "#101828",
          strokeWidth: "1.20905",
          strokeLinecap: "round",
          strokeLinejoin: "round"
        }
      ),
      /* @__PURE__ */ (0, import_jsx_runtime3.jsx)(
        "path",
        {
          d: "M30.4987 44.0481C33.9211 46.5626 35.8988 48.1447 37.7973 50.9134C39.6959 53.6822 40.4024 56.0903 40.5828 58.5996C40.5828 51.4101 43.1712 43.0916 51.0114 35.1943",
          stroke: "#101828",
          strokeWidth: "1.20905",
          strokeLinecap: "round",
          strokeLinejoin: "round"
        }
      ),
      /* @__PURE__ */ (0, import_jsx_runtime3.jsxs)("defs", { children: [
        /* @__PURE__ */ (0, import_jsx_runtime3.jsxs)(
          "filter",
          {
            id: "filter0_i_605_7267",
            x: "11.8013",
            y: "47.543",
            width: "57.9104",
            height: "63.6157",
            filterUnits: "userSpaceOnUse",
            colorInterpolationFilters: "sRGB",
            children: [
              /* @__PURE__ */ (0, import_jsx_runtime3.jsx)("feFlood", { floodOpacity: "0", result: "BackgroundImageFix" }),
              /* @__PURE__ */ (0, import_jsx_runtime3.jsx)(
                "feBlend",
                {
                  mode: "normal",
                  in: "SourceGraphic",
                  in2: "BackgroundImageFix",
                  result: "shape"
                }
              ),
              /* @__PURE__ */ (0, import_jsx_runtime3.jsx)(
                "feColorMatrix",
                {
                  in: "SourceAlpha",
                  type: "matrix",
                  values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
                  result: "hardAlpha"
                }
              ),
              /* @__PURE__ */ (0, import_jsx_runtime3.jsx)("feOffset", { dx: "-3" }),
              /* @__PURE__ */ (0, import_jsx_runtime3.jsx)("feComposite", { in2: "hardAlpha", operator: "arithmetic", k2: "-1", k3: "1" }),
              /* @__PURE__ */ (0, import_jsx_runtime3.jsx)(
                "feColorMatrix",
                {
                  type: "matrix",
                  values: "0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 1 0"
                }
              ),
              /* @__PURE__ */ (0, import_jsx_runtime3.jsx)(
                "feBlend",
                {
                  mode: "normal",
                  in2: "shape",
                  result: "effect1_innerShadow_605_7267"
                }
              )
            ]
          }
        ),
        /* @__PURE__ */ (0, import_jsx_runtime3.jsxs)(
          "filter",
          {
            id: "filter1_i_605_7267",
            x: "0",
            y: "0",
            width: "82",
            height: "82",
            filterUnits: "userSpaceOnUse",
            colorInterpolationFilters: "sRGB",
            children: [
              /* @__PURE__ */ (0, import_jsx_runtime3.jsx)("feFlood", { floodOpacity: "0", result: "BackgroundImageFix" }),
              /* @__PURE__ */ (0, import_jsx_runtime3.jsx)(
                "feBlend",
                {
                  mode: "normal",
                  in: "SourceGraphic",
                  in2: "BackgroundImageFix",
                  result: "shape"
                }
              ),
              /* @__PURE__ */ (0, import_jsx_runtime3.jsx)(
                "feColorMatrix",
                {
                  in: "SourceAlpha",
                  type: "matrix",
                  values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
                  result: "hardAlpha"
                }
              ),
              /* @__PURE__ */ (0, import_jsx_runtime3.jsx)("feOffset", { dx: "-3", dy: "-0.897039" }),
              /* @__PURE__ */ (0, import_jsx_runtime3.jsx)("feComposite", { in2: "hardAlpha", operator: "arithmetic", k2: "-1", k3: "1" }),
              /* @__PURE__ */ (0, import_jsx_runtime3.jsx)(
                "feColorMatrix",
                {
                  type: "matrix",
                  values: "0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 1 0"
                }
              ),
              /* @__PURE__ */ (0, import_jsx_runtime3.jsx)(
                "feBlend",
                {
                  mode: "normal",
                  in2: "shape",
                  result: "effect1_innerShadow_605_7267"
                }
              )
            ]
          }
        ),
        /* @__PURE__ */ (0, import_jsx_runtime3.jsxs)(
          "filter",
          {
            id: "filter2_i_605_7267",
            x: "9.14575",
            y: "10.7739",
            width: "60.4524",
            height: "60.4521",
            filterUnits: "userSpaceOnUse",
            colorInterpolationFilters: "sRGB",
            children: [
              /* @__PURE__ */ (0, import_jsx_runtime3.jsx)("feFlood", { floodOpacity: "0", result: "BackgroundImageFix" }),
              /* @__PURE__ */ (0, import_jsx_runtime3.jsx)(
                "feBlend",
                {
                  mode: "normal",
                  in: "SourceGraphic",
                  in2: "BackgroundImageFix",
                  result: "shape"
                }
              ),
              /* @__PURE__ */ (0, import_jsx_runtime3.jsx)(
                "feColorMatrix",
                {
                  in: "SourceAlpha",
                  type: "matrix",
                  values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
                  result: "hardAlpha"
                }
              ),
              /* @__PURE__ */ (0, import_jsx_runtime3.jsx)("feOffset", { dx: "3" }),
              /* @__PURE__ */ (0, import_jsx_runtime3.jsx)("feComposite", { in2: "hardAlpha", operator: "arithmetic", k2: "-1", k3: "1" }),
              /* @__PURE__ */ (0, import_jsx_runtime3.jsx)(
                "feColorMatrix",
                {
                  type: "matrix",
                  values: "0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 1 0"
                }
              ),
              /* @__PURE__ */ (0, import_jsx_runtime3.jsx)(
                "feBlend",
                {
                  mode: "normal",
                  in2: "shape",
                  result: "effect1_innerShadow_605_7267"
                }
              )
            ]
          }
        )
      ] })
    ]
  }
);

// src/utils/green-factor-ribbons.tsx
var import_jsx_runtime4 = require("react/jsx-runtime");
var greenFactorRibbons = {
  [import_db3.GREEN_FACTOR_VALUES.BRONZE]: /* @__PURE__ */ (0, import_jsx_runtime4.jsx)(BronzeRibbon, {}),
  [import_db3.GREEN_FACTOR_VALUES.SILVER]: /* @__PURE__ */ (0, import_jsx_runtime4.jsx)(SilverRibbon, {}),
  [import_db3.GREEN_FACTOR_VALUES.GOLD]: /* @__PURE__ */ (0, import_jsx_runtime4.jsx)(GoldRibbon, {}),
  [import_db3.GREEN_FACTOR_VALUES.STANDARD]: null
};

// src/utils/certificate-requests-alert-variants.ts
var import_db4 = require("@raizen/db");
var certificateRequestsAlertVariant = {
  [import_db4.CERTIFICATE_REQUEST_STATUS.COMPLETED]: "success",
  [import_db4.CERTIFICATE_REQUEST_STATUS.DENIED]: "error",
  [import_db4.CERTIFICATE_REQUEST_STATUS.NOT_REQUESTED]: "gray",
  [import_db4.CERTIFICATE_REQUEST_STATUS.PENDING]: "gray",
  [import_db4.CERTIFICATE_REQUEST_STATUS.IN_PROGRESS]: "gray",
  [import_db4.CERTIFICATE_REQUEST_STATUS.CREATED]: "warning",
  [import_db4.CERTIFICATE_REQUEST_STATUS.FAILED_IN_BLOCKCHAIN]: "error",
  [import_db4.CERTIFICATE_REQUEST_STATUS.NOT_FOUND]: "warning"
};

// src/utils/user-status-badge-colors.ts
var import_db5 = require("@raizen/db");
var userStatusBadgeColors = {
  [import_db5.USER_STATUS.INVITED]: "gray",
  [import_db5.USER_STATUS.ACTIVE]: "success",
  [import_db5.USER_STATUS.INACTIVE]: "error"
};

// src/utils/certificate-share-requests-status-badge-colors.ts
var import_db6 = require("@raizen/db");
var certificateShareRequestsStatusBadgeColors = {
  [import_db6.CERTIFICATE_SHARE_REQUEST_STATUS.PENDING]: "warning",
  [import_db6.CERTIFICATE_SHARE_REQUEST_STATUS.APPROVED]: "success",
  [import_db6.CERTIFICATE_SHARE_REQUEST_STATUS.EXPIRED]: "error",
  [import_db6.CERTIFICATE_SHARE_REQUEST_STATUS.REJECTED]: "error",
  [import_db6.CERTIFICATE_SHARE_REQUEST_STATUS.INVALID]: "error"
};
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  certificateRequestsAlertVariant,
  certificateRequestsStatusBadgeColors,
  certificateShareRequestsStatusBadgeColors,
  greenFactorBadgeColors,
  greenFactorRibbons,
  userStatusBadgeColors
});
