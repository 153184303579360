import {
  CERTIFICATE_REJECTION_REASON,
  CERTIFICATE_REQUEST_STATUS,
  ValueOf,
} from '@raizen/db';
import { useTranslation } from '@raizen/react-hooks';
import { CertificateRequestSerializer } from '@raizen/serializers';
import { useUser } from '../../../contexts/UserContext';
import { formatDate, unixTimestampToDate } from '@raizen/common-utils';
import { certificateRequestsAlertVariant } from '@raizen/frontend-utils';
import { PERFORM_ACTION } from '@raizen/casl-authorization';

export const useStatusAlert = (data: CertificateRequestSerializer) => {
  const { t } = useTranslation();
  const { canManageCertificateRequests, ability } = useUser();

  const { status, rejectionMessage, resolvedAt } = data ?? {};

  interface Response {
    showStatusAlert: boolean;
    statusAlertVariant: ValueOf<typeof certificateRequestsAlertVariant>;
    statusAlertTitle: string;
    statusAlertDescription: string;
  }
  const values: Response = {
    showStatusAlert: false,
    statusAlertVariant: certificateRequestsAlertVariant[status],
    statusAlertTitle: '',
    statusAlertDescription: '',
  };

  const canReadCertificateRequest = ability?.can(
    PERFORM_ACTION.Read,
    'CertificateRequest',
  );

  if (!canReadCertificateRequest) return values;

  values.showStatusAlert = true;

  const isBackoffice = canManageCertificateRequests;

  const tPrefix = isBackoffice ? 'backoffice.' : '';

  switch (status) {
    case CERTIFICATE_REQUEST_STATUS.CREATED:
      values.showStatusAlert = false;
      break;
    case CERTIFICATE_REQUEST_STATUS.PENDING:
    case CERTIFICATE_REQUEST_STATUS.NOT_FOUND:
      values.showStatusAlert = !isBackoffice;
      values.statusAlertTitle = t(
        `certificateRequestDetails.alert.${CERTIFICATE_REQUEST_STATUS.PENDING}.title`,
      );
      values.statusAlertDescription = t(
        `certificateRequestDetails.alert.${CERTIFICATE_REQUEST_STATUS.PENDING}.description`,
      );
      break;
    case CERTIFICATE_REQUEST_STATUS.IN_PROGRESS:
      values.statusAlertTitle = t(
        `${tPrefix}certificateRequestDetails.alert.${status}.title`,
      );
      values.statusAlertDescription = isBackoffice
        ? t(`${tPrefix}certificateRequestDetails.alert.${status}.description`)
        : '';
      break;
    case CERTIFICATE_REQUEST_STATUS.DENIED:
      const rejectionReason =
        data?.rejectionReason ?? CERTIFICATE_REJECTION_REASON.OTHER;
      values.statusAlertTitle = t(
        `${tPrefix}certificateRequestDetails.alert.${status}.${rejectionReason}.title`,
      );

      const rejectedDateMessage = resolvedAt
        ? '\n\n' +
          t(`${tPrefix}certificateRequestDetails.rejectedOn`).replace(
            '{{date}}',
            formatDate(unixTimestampToDate(resolvedAt)),
          )
        : null;
      values.statusAlertDescription = `${rejectionMessage ?? ''}${
        rejectedDateMessage ?? ''
      }`;
      break;
    case CERTIFICATE_REQUEST_STATUS.COMPLETED:
      values.statusAlertTitle = t(
        `${tPrefix}certificateRequestDetails.alert.${status}.title`,
      );
      const approvedDateMessage = resolvedAt
        ? t(`${tPrefix}certificateRequestDetails.approvedOn`).replace(
            '{{date}}',
            formatDate(unixTimestampToDate(resolvedAt)),
          )
        : '';
      values.statusAlertDescription = isBackoffice ? approvedDateMessage : '';
      break;
    case CERTIFICATE_REQUEST_STATUS.FAILED_IN_BLOCKCHAIN:
      values.statusAlertTitle = t(
        `${tPrefix}certificateRequestDetails.alert.${status}.title`,
      );
      values.statusAlertDescription = t(
        `${tPrefix}certificateRequestDetails.alert.${status}.description`,
      );
      break;
    default:
      break;
  }

  return values;
};
