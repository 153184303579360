"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.tsx
var src_exports = {};
__export(src_exports, {
  EMAIL_TEMPLATE_IDS: () => EMAIL_TEMPLATE_IDS,
  ERROR_CODES: () => ERROR_CODES,
  WEB_SOCKET_EVENTS: () => WEB_SOCKET_EVENTS,
  dateToUnixTimestamp: () => dateToUnixTimestamp,
  formatDate: () => formatDate,
  getUnixTimestamp: () => getUnixTimestamp,
  unixTimestampToDate: () => unixTimestampToDate
});
module.exports = __toCommonJS(src_exports);

// src/utils/format-date.ts
var import_date_fns = require("date-fns");
var DEFAULT_FORMAT = "LLL dd, yyyy";
var formatDate = (date, format) => {
  return (0, import_date_fns.format)(
    typeof date === "number" ? date * 1e3 : date,
    format != null ? format : DEFAULT_FORMAT
  );
};

// src/utils/get-unix-timestamp.ts
var getUnixTimestamp = () => Math.floor((/* @__PURE__ */ new Date()).getTime() / 1e3);

// src/exceptions/error-codes.ts
var ERROR_CODES = {
  // 400
  VALIDATION_ERROR: "VALIDATION_ERROR",
  CANNOT_SHARE_CERTIFICATES_FROM_ANOTHER_COMPANY: "CANNOT_SHARE_CERTIFICATES_FROM_ANOTHER_COMPANY",
  CANNOT_SHARE_CERTIFICATES_WITH_YOURSELF: "CANNOT_SHARE_CERTIFICATES_WITH_YOURSELF",
  // 401
  MISSING_CREDENTIALS: "MISSING_CREDENTIALS",
  // 403
  FORBIDDEN_RESOURCE: "FORBIDDEN_RESOURCE",
  // 404
  RECORD_NOT_FOUND: "RECORD_NOT_FOUND",
  // 409
  EMAIL_ALREADY_EXISTS: "EMAIL_ALREADY_EXISTS",
  FISCAL_ID_IN_USE: "FISCAL_ID_IN_USE",
  ACCESS_KEY_IN_USE: "ACCESS_KEY_IN_USE",
  DUPLICATED_ENTITY: "DUPLICATED_ENTITY",
  // 422
  MALFORMED_REQUEST: "MALFORMED_REQUEST",
  PROCESSING_ERROR: "PROCESSING_ERROR",
  NOT_YET_SUPPORTED: "NOT_YET_SUPPORTED",
  CERTIFICATE_REQUEST_ALREADY_IN_PROGRESS: "CERTIFICATE_REQUEST_ALREADY_IN_PROGRESS",
  INCORRECT_INVOICE_NUMBER: "INCORRECT_INVOICE_NUMBER",
  INCORRECT_ACCESS_KEY: "INCORRECT_ACCESS_KEY",
  // 500
  INTERNAL_SERVER_ERROR: "INTERNAL_SERVER_ERROR"
};

// src/email-template-ids/index.ts
var EMAIL_TEMPLATE_IDS = {
  CERTIFICATE_SHARE_RECEIVED: "certificate-share-received-v2",
  CERTIFICATE_SHARE_REQUEST_APPROVED: "certificate-share-request-approved-v2",
  CERTIFICATE_SHARE_REQUEST_REJECTED: "certificate-share-request-rejected-v2",
  NEW_CERTIFICATE_REQUEST: "new-certificate-request-v2",
  FAILED_IN_BLOCKCHAIN: "failed-in-blockchain-v2",
  CERTIFICATE_REQUEST_APPROVED: "certificate-request-approved",
  CERTIFICATE_REQUEST_REJECTED: "certificate-request-rejected",
  NEW_CERTIFICATE_SHARING_REQUEST: "new-certificate-sharing-request-v2",
  CERTIFICATE_SHARE_CREATED: "certificate-share-created-v2"
};

// src/web-socket-events/index.ts
var WEB_SOCKET_EVENTS = {
  PENDING_CERTIFICATE_REQUESTS_COUNT: "pendingCertificateRequestsCount",
  PENDING_CERTIFICATE_SHARE_REQUESTS_COUNT: "pendingCertificateShareRequestsCount"
};

// src/utils/time-conversions.ts
var dateToUnixTimestamp = (date) => {
  return (date != null ? date : /* @__PURE__ */ new Date()).getTime() / 1e3;
};
var unixTimestampToDate = (unixTimestamp) => {
  return new Date(unixTimestamp * 1e3);
};
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  EMAIL_TEMPLATE_IDS,
  ERROR_CODES,
  WEB_SOCKET_EVENTS,
  dateToUnixTimestamp,
  formatDate,
  getUnixTimestamp,
  unixTimestampToDate
});
