import { CERTIFICATE_REJECTION_REASON } from '@raizen/db';
import { useTranslation } from '@raizen/react-hooks';

export const useCertificateRejectionOptions = () => {
  const { t } = useTranslation();

  const options = [
    {
      label: t(
        'certificateRequestDetails.action.reject.modal.form.reasonDuplicated',
      ),
      value: CERTIFICATE_REJECTION_REASON.INVOICE_DUPLICATION,
    },
    {
      label: t(
        'certificateRequestDetails.action.reject.modal.form.reasonNotFound',
      ),
      value: CERTIFICATE_REJECTION_REASON.INVOICE_NOT_FOUND,
    },
    {
      label: t(
        'certificateRequestDetails.action.reject.modal.form.reasonOther',
      ),
      value: CERTIFICATE_REJECTION_REASON.OTHER,
    },
  ];

  return options;
};
