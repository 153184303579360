import { httpClient } from '@raizen/client-auth';
import { ApproveCertificateShareRequestDto } from './dtos/ApproveCertificateShareRequestDto';

export const approveCertificateShareRequest = async (
  uuid: string,
  params: ApproveCertificateShareRequestDto,
) => {
  const { data } = await httpClient.patch(
    `certificate-share-requests/${uuid}/approve`,
    params,
  );

  return data;
};
