import { useTranslation } from '@raizen/react-hooks';
import { Button, Modal } from '@raizen/ui';
import { RejectForm } from './RejectForm';
import { rejectCertificateShareRequest } from '../../../services/certificateShareRequest/rejectCertificateShareRequest';
import { useMutation } from 'react-query';
import useToastsHook from '../../../hooks/useToastsHook';
import { RejectCertificateShareRequestDto } from '../../../services/certificateShareRequest/dtos/RejectCertificateShareRequestDto';
import { ListCertificateShareRequestsSerializer } from '@raizen/serializers';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  selectedShareRequest: ListCertificateShareRequestsSerializer;
  setSelectedShareRequest: any;
  refetch: () => void;
}

export const RejectModal = ({
  isOpen,
  onClose,
  selectedShareRequest,
  setSelectedShareRequest,
  refetch,
}: Props) => {
  const { t } = useTranslation();
  const { showSuccessToast, showErrorToast } = useToastsHook();

  const {
    certificateOwnerCompanyName,
    certificateViewerCompanyName,
    accessKey,
    certificateShareRequestUuid,
  } = selectedShareRequest;

  const { mutate, isLoading } = useMutation<
    any,
    unknown,
    RejectCertificateShareRequestDto,
    unknown
  >(
    async (data) =>
      rejectCertificateShareRequest(certificateShareRequestUuid, data),
    {
      onSuccess: () => {
        onClose();
        refetch();
        showSuccessToast({
          title: t('sharingRequests.rejectModal.rejectToast.title'),
          description: t('sharingRequests.rejectModal.rejectToast.description')
            .replace('{{ownerCompanyName}}', certificateOwnerCompanyName ?? '')
            .replace(
              '{{viewerCompanyName}}',
              certificateViewerCompanyName ?? '',
            )
            .replace('{{accessKey}}', accessKey ?? ''),
        });
        setSelectedShareRequest(undefined);
      },
      onError: () => {
        showErrorToast({
          title: t('common.messages.error'),
          description: t('sharingRequests.rejectModal.errorToast.description'),
        });
      },
    },
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      title={t('sharingRequests.rejectModal.title')}
      text={t('sharingRequests.rejectModal.text')
        .replace('{{ownerCompanyName}}', certificateOwnerCompanyName)
        .replace('{{viewerCompanyName}}', certificateViewerCompanyName)
        .replace('{{accessKey}}', accessKey ?? '')}
      confirmButton={
        <Button
          variant="destructive"
          isLoading={isLoading}
          type="submit"
          form="reject-form"
        >
          {t('sharingRequests.rejectModal.confirmButton')}
        </Button>
      }
      cancelButton={
        <Button variant="secondary" onClick={onClose} isDisabled={isLoading}>
          {t('sharingRequests.rejectModal.cancelButton')}
        </Button>
      }
      body={<RejectForm mutate={mutate} />}
    />
  );
};
