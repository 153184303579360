import { Center } from '@chakra-ui/react';

export const IllustrationColumn = () => {
  return (
    <Center
      display={{ base: 'none', md: 'none', lg: 'flex' }}
      bg="#EDFAE9"
      ml="64px"
      maxW={'532px'}
      top={8}
      position={'sticky'}
      flex={1}
      height={'532px'}
    >
      <img src="/svg/Certificate.svg" alt="Green Sustainability Certificate" />
    </Center>
  );
};
