import { Button, Flex, Tooltip } from '@chakra-ui/react';

export interface HeaderActionProps {
  key: string;
  label: string;
  variant?: string;
  icon?: any;
  onClick: () => void;
  isDisabled?: boolean;
  tooltip?: string;
  isLoading?: boolean;
}

interface HeaderActionsProps {
  actions: HeaderActionProps[];
}

export const HeaderActions = ({ actions }: HeaderActionsProps) => {
  return (
    <Flex
      w={{ base: 'full', md: 'auto' }}
      direction={{ base: 'column', md: 'row' }}
      gap={4}
      className="remove-for-print-view"
    >
      {actions.map(
        ({
          key,
          label,
          variant = 'primary',
          icon,
          onClick,
          isDisabled,
          tooltip,
          isLoading,
        }) => {
          const button = (
            <Button
              key={key}
              leftIcon={icon}
              variant={variant}
              onClick={onClick}
              w={{ base: 'full', md: 'auto' }}
              isDisabled={isDisabled}
              isLoading={isLoading}
            >
              {label}
            </Button>
          );

          return tooltip ? (
            <Tooltip key={key} label={tooltip} aria-label={tooltip}>
              {button}
            </Tooltip>
          ) : (
            button
          );
        },
      )}
    </Flex>
  );
};
