import { useTranslation } from '@raizen/react-hooks';
import { CertificateRequestSerializer } from '@raizen/serializers';
import { CertificateRequestDetailsSection } from '../CertificateRequestDetails/CertificateRequestDetailsList';
import { CertificateRequestDetailRow } from '../CertificateRequestDetails/CertificateRequestDetailsListSection';
import { useRowsMap } from '../CertificateRequestDetails/useRowsMap';

export const useCertificatePreview = (
  data: CertificateRequestSerializer | undefined,
): CertificateRequestDetailsSection[] | undefined => {
  const { t } = useTranslation();
  const rowsMap = useRowsMap();

  if (!data) return;

  const certificateData =
    (data.certificateData ?? []).length > 0
      ? data.certificateData[0]
      : undefined;

  const sections: CertificateRequestDetailsSection[] = [
    {
      id: 'generalInformationSection',
      data: {
        title: t('common.generalInformation'),
        rows: [
          rowsMap.accessKey(data.accessKey),
          rowsMap.invoiceNumber(certificateData?.fiscalInvoiceNumber),
          rowsMap.invoiceDate(certificateData?.fiscalInvoiceDeliveryDate),
          rowsMap.companyName(data.companyName),
          rowsMap.productType(certificateData?.productType),
        ].filter((e) => e !== null) as CertificateRequestDetailRow[],
      },
    },
    {
      id: 'productInformationSection',
      data: {
        title: t('common.productInformation'),
        rows: [
          rowsMap.bioparkName(certificateData?.bioparkName),
          rowsMap.bioparkCityAndState(certificateData?.bioparkCityState),
          rowsMap.productionDate(certificateData?.productionDate),
          rowsMap.massBalance(certificateData?.massBalance),
          rowsMap.productTotalQuantity(
            certificateData?.productTotalQuantity,
            certificateData?.productQuantityUnit,
          ),
          rowsMap.carbonFootprint(
            certificateData?.carbonFootprint,
            certificateData?.carbonFootprintQuantityUnit,
          ),
        ].filter((e) => e !== null) as CertificateRequestDetailRow[],
      },
    },
  ].filter((s) => s.data?.rows?.length);

  return sections;
};
