import { ListCertificateShareRequestsSerializer } from '@raizen/serializers';
import { useTranslation } from '@raizen/react-hooks';
import { Button, Modal } from '@raizen/ui';
import { useMutation } from 'react-query';
import useToastsHook from '../../../hooks/useToastsHook';
import { ApproveCertificateShareRequestDto } from '../../../services/certificateShareRequest/dtos/ApproveCertificateShareRequestDto';
import { NewCompanyApprovalForm } from './NewCompanyApprovalForm';
import { approveCertificateShareRequest } from '../../../services/certificateShareRequest/approveCertificateShareRequest';
import { SharedCompanyApprovalForm } from './SharedCompanyApprovalForm';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  selectedShareRequest: ListCertificateShareRequestsSerializer;
  setSelectedShareRequest: any;
  refetch: () => void;
}

export const ApprovalModal = ({
  isOpen,
  onClose,
  selectedShareRequest,
  setSelectedShareRequest,
  refetch,
}: Props) => {
  const { t } = useTranslation();
  const { showSuccessToast, showErrorToast } = useToastsHook();

  const {
    certificateOwnerCompanyName,
    certificateViewerCompanyName,
    accessKey,
    certificateShareRequestUuid,
    companyHasBeenSharedBefore,
  } = selectedShareRequest;

  const { mutate, isLoading } = useMutation<
    any,
    unknown,
    ApproveCertificateShareRequestDto,
    unknown
  >(
    async (data) => {
      await approveCertificateShareRequest(certificateShareRequestUuid, data);
    },
    {
      onSuccess: () => {
        onClose();
        refetch();
        showSuccessToast({
          title: t('sharingRequests.approvalModal.successToast.title'),
          description: t('sharingRequests.approvalModal.successToast.text')
            .replace('{{ownerCompanyName}}', certificateOwnerCompanyName ?? '')
            .replace(
              '{{viewerCompanyName}}',
              certificateViewerCompanyName ?? '',
            )
            .replace('{{accessKey}}', accessKey ?? ''),
        });
        setSelectedShareRequest(undefined);
      },
      onError: () => {
        showErrorToast({
          title: t('common.messages.error'),
          description: t(
            'sharingRequests.approvalModal.errorToast.description',
          ),
        });
      },
    },
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      title={t('sharingRequests.approvalModal.title')}
      text={t('sharingRequests.approvalModal.text')
        .replace('{{ownerCompanyName}}', certificateOwnerCompanyName)
        .replace('{{viewerCompanyName}}', certificateViewerCompanyName)
        .replace('{{accessKey}}', accessKey ?? '')}
      confirmButton={
        <Button
          variant="primary"
          isLoading={isLoading}
          type="submit"
          form="approve-form"
        >
          {t('sharingRequests.approvalModal.confirmButton')}
        </Button>
      }
      cancelButton={
        <Button variant="secondary" onClick={onClose} isDisabled={isLoading}>
          {t('sharingRequests.approvalModal.cancelButton')}
        </Button>
      }
      body={
        companyHasBeenSharedBefore ? (
          <SharedCompanyApprovalForm
            mutate={mutate}
            selectedShareRequest={selectedShareRequest}
          />
        ) : (
          <NewCompanyApprovalForm
            mutate={mutate}
            selectedShareRequest={selectedShareRequest}
          />
        )
      }
    />
  );
};
