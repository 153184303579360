import { Text } from '@chakra-ui/react';
import { useTranslation } from '@raizen/react-hooks';
import { LogoutIcon } from '@raizen/ui';

const COGNITO_LOGOUT_URL = process.env.REACT_APP_COGNITO_LOGOUT_URL;

export const useUserMenuOptions = (): userMenuOption[] => {
  const { t } = useTranslation();
  const userMenuOption = [
    {
      id: 0,
      label: (
        <Text
          color="gray.700"
          fontSize="sm"
          fontFamily="Larsseit-Medium"
          ml={1}
        >
          {t('auth.logout')}
        </Text>
      ),
      icon: <LogoutIcon h="16px" w="16px" />,
      onClick: () => {
        window.location.replace(COGNITO_LOGOUT_URL ?? '');
      },
    },
  ];

  return userMenuOption;
};

export interface userMenuOption {
  id: number;
  label: JSX.Element;
  icon: JSX.Element;
  onClick: () => void;
}
