import { CertificateDataSerializer } from '@raizen/serializers';
import { CertificateRequestDetailRow } from '../CertificateRequestDetailsListSection';
import { useRowsMap } from '../useRowsMap';
import { useTranslation } from '@raizen/react-hooks';
import { Section } from './Section';
import { List } from './List';

interface Props {
  certificateData?: CertificateDataSerializer;
}

export const BlockchainInformationSection = ({ certificateData }: Props) => {
  const { t } = useTranslation();
  const rowsMap = useRowsMap();

  const rows = [
    rowsMap.transactionHash(certificateData?.certificate?.root),
    rowsMap.network(
      certificateData?.certificate?.network,
      certificateData?.certificate?.root,
    ),
  ].filter((e) => e !== null) as CertificateRequestDetailRow[];

  return rows.length ? (
    <Section title={t('common.blockchainInformation')}>
      <List rows={rows} />
    </Section>
  ) : null;
};
