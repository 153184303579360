import { httpClient } from '@raizen/client-auth';
import { GetCertificateViewersDto } from '../types/queryParams';
import {
  GetCertificateViewersBackofficeSerializer,
  GetCertificateViewersSerializer,
} from '@raizen/serializers';
import { PaginationDataSerializer } from '../types/serializers';

export const getViewers = async (
  uuid: string,
  params: GetCertificateViewersDto,
) => {
  const { data } = await httpClient.get<{
    data: GetCertificateViewersSerializer[];
    paginationData: PaginationDataSerializer;
  }>(`/certificate-requests/${uuid}/viewers`, {
    params,
  });
  return data;
};

export const getViewersFromBackoffice = async (
  uuid: string,
  params: GetCertificateViewersDto,
) => {
  const { data } = await httpClient.get<{
    data: GetCertificateViewersBackofficeSerializer[];
    paginationData: PaginationDataSerializer;
  }>(`/certificate-requests/${uuid}/viewers/backoffice`, {
    params,
  });
  return data;
};
