import { httpClient } from '@raizen/client-auth';
import { GetUsersResponse } from '@raizen/types';
import { GetUsersDto } from '../types/queryParams';

export const getUsers = async (params: GetUsersDto) => {
  // TODO: centralize api paths
  const { data } = await httpClient.get<GetUsersResponse>('/users', { params });

  return data;
};
