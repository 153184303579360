import { httpClient } from '@raizen/client-auth';
import { RejectCertificateShareRequestDto } from './dtos/RejectCertificateShareRequestDto';

export const rejectCertificateShareRequest = async (
  uuid: string,
  params: RejectCertificateShareRequestDto,
) => {
  const { data } = await httpClient.patch(
    `certificate-share-requests/${uuid}/reject`,
    params,
  );

  return data;
};
