import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { useTranslation } from '@raizen/react-hooks';
import { Alert } from '@raizen/ui';
import { SubmitHandler, useForm } from 'react-hook-form';
import { UseMutateFunction } from 'react-query';
import { ApproveCertificateShareRequestDto } from '../../../services/certificateShareRequest/dtos/ApproveCertificateShareRequestDto';
import { Flex } from '@chakra-ui/react';
import { ListCertificateShareRequestsSerializer } from '@raizen/serializers';
import { VIEWER_USER_ROLES, ViewerUserRoles } from '@raizen/db';
import { useEffect, useState } from 'react';

const resolver = classValidatorResolver(ApproveCertificateShareRequestDto);

interface Props {
  selectedShareRequest: ListCertificateShareRequestsSerializer;
  mutate: UseMutateFunction<
    any,
    unknown,
    ApproveCertificateShareRequestDto,
    unknown
  >;
}

export const SharedCompanyApprovalForm = ({
  selectedShareRequest,
  mutate,
}: Props) => {
  const { t } = useTranslation();

  const {
    certificateShareRequestEmail: userEmail,
    certificateShareRequestCompanyFiscalId: companyFiscalId,
    certificateViewerCompanyName: companyName,
    companyAccessType: defaultAccessType,
  } = selectedShareRequest;

  const [accessType] = useState<ViewerUserRoles>(
    (defaultAccessType as ViewerUserRoles) || VIEWER_USER_ROLES.VIEWER_FULL,
  );

  const context = useForm<ApproveCertificateShareRequestDto>({
    mode: 'onChange',
    resolver,
    defaultValues: {
      userEmail,
      companyFiscalId,
      accessType,
      companyName,
    },
  });
  const { handleSubmit, setValue } = context;

  const onSubmit: SubmitHandler<any> = (data) => {
    mutate(data);
  };

  useEffect(() => {
    setValue('accessType', accessType as ViewerUserRoles);
  }, [accessType, setValue]);

  return (
    <Flex
      as="form"
      flexDir="column"
      id="approve-form"
      gap={6}
      onSubmit={(ev) => {
        ev.preventDefault();
        handleSubmit(onSubmit)(ev);
      }}
    >
      <Alert
        variant="warning"
        title={t(
          `sharingRequests.approvalModal.existentCompany.alert.title.${accessType}`,
        )}
        showCloseButton={false}
      />
    </Flex>
  );
};
