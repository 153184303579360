import { useTranslation } from '@raizen/react-hooks';
import { LocaleKeys } from '@raizen/translations';
import { Badge } from '@raizen/ui';
import { useMemo } from 'react';
import { CellProps } from 'react-table';
import { greenFactorBadgeColors } from '@raizen/frontend-utils';
import { ListCertificateRequestsSerializer } from '@raizen/serializers';
import { Flex } from '@chakra-ui/react';
import { TextTruncate } from '../../shared/TextTruncate';
import { CopyToClipboardButton } from '../../shared/CopyToClipboardButton';

export const useColumns = () => {
  const { t } = useTranslation();

  const columns = useMemo(
    () => [
      {
        Header: t('certificateRequests.table.columns.accessKey'),
        accessor: 'accessKey',
        Cell: (props: CellProps<ListCertificateRequestsSerializer>) => {
          const accessKey = props.row.original.accessKey;

          return (
            <Flex
              gap={2}
              alignItems={'center'}
              float="left"
              position="relative"
            >
              #<TextTruncate text={accessKey} max={24} />
              <CopyToClipboardButton text={accessKey} />
            </Flex>
          );
        },
      },

      {
        Header: t('common.company'),
        accessor: 'companyName',
      },

      {
        Header: t('certificateRequests.table.columns.productType'),
        accessor: 'productType',
        Cell: (props: CellProps<ListCertificateRequestsSerializer>) => {
          const productType = props.row.original.productType;
          return productType ? t(`productType.${productType}`) : '-';
        },
      },

      {
        Header: t('certificateRequests.table.columns.greenFactor'),
        accessor: 'greenFactor',
        Cell: (props: CellProps<ListCertificateRequestsSerializer>) => {
          const greenFactor = props.row.original.greenFactor || [];
          return greenFactor.length ? (
            <Flex gap={2}>
              {greenFactor.map((e) => (
                <Badge colorScheme={greenFactorBadgeColors[e]}>
                  {t(`greenFactor.${e}` as LocaleKeys)}
                </Badge>
              ))}
            </Flex>
          ) : (
            '-'
          );
        },
      },
    ],
    [],
  );

  return { columns };
};
