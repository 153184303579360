import { useState } from 'react';
import { useTranslation } from '@raizen/react-hooks';
import { Modal, Button } from '@raizen/ui';
import { ShareCertificateForm } from './ShareCertificateForm';
import { RequestCertificateShareForm } from './ShareRequestForm';
import { useMutation } from 'react-query';
import useToastsHook from '../../../hooks/useToastsHook';
import { createCertificateShareRequest } from '../../../services/certificateShareRequest/createCertificateShareRequest';
import { AxiosError } from '@raizen/client-auth';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  certificateRequestUuid: string;
}

export const ShareCertificateModal = ({
  isOpen,
  onClose,
  certificateRequestUuid,
}: Props) => {
  const handleClose = () => {
    onClose();
    setIsShareCertificateRequestForm(false);
  };
  const { t } = useTranslation();
  const [isShareCertificateRequestForm, setIsShareCertificateRequestForm] =
    useState(false);
  const [selectedCompany, setSelectedCompany] = useState('');
  const { showSuccessToast, showErrorToast } = useToastsHook();
  const {
    mutate: mutateCreateCertificateShareRequest,
    isLoading: isLoadingCreateCertificateShareRequest,
  } = useMutation(createCertificateShareRequest, {
    onSuccess: () => {
      handleClose();
      showSuccessToast({
        title: t('common.messages.success'),
        description: t(
          'certificate.share.request.modal.form.primaryAction.success',
        ),
      });
    },
    onError: (e: AxiosError<{ message: string }>) => {
      const message = e?.response?.data?.message ?? t('errorCode.GENERIC');
      showErrorToast({
        title: t('common.messages.error'),
        description: message,
      });
    },
  });
  const shareCertificateFormId = 'share-certificate-form';
  const requestShareCertificateFormId = 'request-share-certificate-form';
  const modalContent = isShareCertificateRequestForm
    ? {
        title: t('certificate.share.request.modal.title'),
        description: t('certificate.share.request.modal.description'),
        confirmButtonText: t(
          'certificate.share.request.modal.form.primaryAction',
        ),
        formId: requestShareCertificateFormId,
      }
    : {
        title: t('certificate.share.modal.title'),
        description: '',
        confirmButtonText: t('users.newUserModal.confirmButton'),
        formId: shareCertificateFormId,
      };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      size="lg"
      title={modalContent.title}
      text={modalContent.description}
      body={
        isShareCertificateRequestForm ? (
          <RequestCertificateShareForm
            formId={modalContent.formId}
            mutate={mutateCreateCertificateShareRequest}
            certificateRequestUuid={certificateRequestUuid}
          />
        ) : (
          <ShareCertificateForm
            setShareCertificateRequestForm={setIsShareCertificateRequestForm}
            formId={shareCertificateFormId}
            mutate={mutateCreateCertificateShareRequest}
            setSelectedCompany={setSelectedCompany}
            certificateRequestUuid={certificateRequestUuid}
          />
        )
      }
      confirmButton={
        <Button
          variant="primary"
          type="submit"
          form={modalContent.formId}
          isLoading={isLoadingCreateCertificateShareRequest}
          isDisabled={!isShareCertificateRequestForm && !selectedCompany}
        >
          {modalContent.confirmButtonText}
        </Button>
      }
      cancelButton={
        <Button
          variant="secondary"
          onClick={handleClose}
          disabled={isLoadingCreateCertificateShareRequest}
        >
          {t('users.newUserModal.cancelButton')}
        </Button>
      }
    />
  );
};
