import { authSetup } from '@raizen/client-auth';
import { paths } from './routes/consts/paths';

const auth = authSetup({
  COGNITO_LOGIN_URL: process.env.REACT_APP_COGNITO_LOGIN_URL || '',
  COGNITO_LOGOUT_URL: process.env.REACT_APP_COGNITO_LOGOUT_URL || '',
  loginCallbackUrl: paths.loginCallback,
  homePath: paths.home,
  logoutPath: paths.logoutCallback,
  logoutCallbackUrl: paths.logoutCallback,
  baseApiUrl: process.env.REACT_APP_BASE_API_URL || '',
});

const { PrivatePage, loginCallbackUrl, LogoutLink, logoutRoute } = auth;

export { PrivatePage, loginCallbackUrl, LogoutLink, logoutRoute };
