import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from 'react';

interface PrintingStateContextProps {
  isPrinting: boolean;
  handleBeforePrint: () => void;
  handleAfterPrint: () => void;
}

const PrintingStateContext = createContext<PrintingStateContextProps>({
  isPrinting: false,
  handleBeforePrint: () => {},
  handleAfterPrint: () => {},
});

interface Props {
  children: ReactNode;
}

export const PrintingStateProvider: React.FC<Props> = ({ children }) => {
  const [isPrinting, setIsPrinting] = useState(false);
  const handleBeforePrint = () => setIsPrinting(true);
  const handleAfterPrint = () => setIsPrinting(false);

  useEffect(() => {
    let badImplementationTimeoutId: number;
    if (isPrinting) {
      // This is a bad implementation, don't do this, do not copy this and forget it
      // Esto esta mal, no lo hagas, no lo copies y olvidate de haberlo visto
      badImplementationTimeoutId = window.setTimeout(() => {
        window.print();
        handleAfterPrint();
      }, 500);
    }
    return () => {
      clearTimeout(badImplementationTimeoutId);
    };
  }, [isPrinting]);

  return (
    <PrintingStateContext.Provider
      value={{ isPrinting, handleBeforePrint, handleAfterPrint }}
    >
      {children}
    </PrintingStateContext.Provider>
  );
};

export const usePrintingState = () => {
  const context = useContext(PrintingStateContext);
  if (context === undefined) {
    throw new Error(
      'usePrintingState must be used within a PrintingStateProvider',
    );
  }
  return context;
};
