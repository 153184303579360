import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { useTranslation } from '@raizen/react-hooks';
import { FormControl, FormErrorMessage, FormLabel } from '@raizen/ui';
import { SubmitHandler, useForm } from 'react-hook-form';
import { UseMutateFunction } from 'react-query';
import { RejectCertificateShareRequestDto } from '../../../services/certificateShareRequest/dtos/RejectCertificateShareRequestDto';
import { Flex, Textarea } from '@chakra-ui/react';
import { LocaleKeys } from '@raizen/translations';

const resolver = classValidatorResolver(RejectCertificateShareRequestDto);

interface Props {
  mutate: UseMutateFunction<
    any,
    unknown,
    RejectCertificateShareRequestDto,
    unknown
  >;
}

export const RejectForm = ({ mutate }: Props) => {
  const { t } = useTranslation();

  const context = useForm<RejectCertificateShareRequestDto>({ resolver });
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = context;

  const errorMessage = t(errors.rejectionMessage?.message as LocaleKeys);
  const isInvalid = Boolean(errors.rejectionMessage);

  const onSubmit: SubmitHandler<any> = (data) => {
    mutate(data);
  };

  return (
    <Flex
      as="form"
      flexDir="column"
      id="reject-form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormControl isInvalid={isInvalid} isRequired={true}>
        <FormLabel
          htmlFor="rejectionMessage"
          variant="medium"
          size="sm"
          color="gray.700"
        >
          {t('sharingRequests.rejectModal.rejectionMessage.label')}
        </FormLabel>
        <Textarea
          id="rejectionMessage"
          placeholder={t(
            'sharingRequests.rejectModal.rejectionMessage.placeholder',
          )}
          rows={5}
          maxLength={600}
          {...register('rejectionMessage')}
          {...(isInvalid
            ? {
                borderColor: 'border-color-destructive',
                _focus: { borderColor: 'border-color-destructive' },
              }
            : {})}
        />
        <FormErrorMessage>{errorMessage}</FormErrorMessage>
      </FormControl>
    </Flex>
  );
};
