import { GetCurrentUserResponse } from '@raizen/types';
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from 'react';
import { useQuery } from 'react-query';
import { getCurrentUser } from '../services/users/getCurrentUser';
import { useAuthClient } from '@raizen/client-auth';
import {
  AppAbility,
  AbilityFactory,
  PERFORM_ACTION,
} from '@raizen/casl-authorization';
import { AbilityContext } from './PermissionsContext';
import { Center, Spinner } from '@chakra-ui/react';

type UserContextType = {
  user: GetCurrentUserResponse | null;
  isLoadingUser: boolean;
  ability?: AppAbility;
  canManageCertificateRequests: boolean;
  canManageUsers: boolean;
  canManageCertificateShareRequests: boolean;
  canFilterCertificateByGreenFactor: Boolean;
  canFilterCertificateByStatus: Boolean;
};

const UserContext = createContext<UserContextType | undefined>(undefined);
type UserProviderProps = {
  children: ReactNode;
};

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  useAuthClient();
  const [user, setUser] = useState<GetCurrentUserResponse>({
    uuid: '',
    email: '',
    firstName: '',
    lastName: '',
    companyName: '',
    companyFiscalId: '',
    role: undefined,
  });
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const [ability, setAbility] = useState<AppAbility>(
    new AbilityFactory().createForAnon(),
  );

  const { data, isLoading, isSuccess } = useQuery(['current-user'], () =>
    getCurrentUser(),
  );

  useEffect(() => {
    if (!isLoading && isSuccess) {
      setUser(data);
      setIsLoadingUser(false);
    }
  }, [data, isLoading, isSuccess]);

  useEffect(() => {
    if (!isLoading && user.role) {
      setAbility(
        new AbilityFactory().createForUser(
          user.role,
          Number(user.companyFiscalId),
        ),
      );
    }
  }, [isLoading, user]);

  if (isLoadingUser)
    return (
      <Center height="100vh" data-testid="isLoadingUser-loader">
        <Spinner />
      </Center>
    );

  const canManageCertificateRequests = ability.can(
    PERFORM_ACTION.Admin,
    'CertificateRequest',
  );
  const canManageUsers = ability.can(PERFORM_ACTION.Admin, 'User');
  const canManageCertificateShareRequests = ability.can(
    PERFORM_ACTION.Admin,
    'CertificateShareRequest',
  );

  const canFilterCertificateByStatus = ability.can(
    PERFORM_ACTION.FilterBy.status,
    'Certificate',
  );
  const canFilterCertificateByGreenFactor = ability.can(
    PERFORM_ACTION.FilterBy.greenFactor,
    'Certificate',
  );

  const value: UserContextType = {
    user,
    isLoadingUser,
    ability,
    canManageCertificateRequests,
    canManageUsers,
    canManageCertificateShareRequests,
    canFilterCertificateByStatus,
    canFilterCertificateByGreenFactor,
  };

  return (
    <UserContext.Provider value={value}>
      <AbilityContext.Provider value={ability}>
        {children}
      </AbilityContext.Provider>
    </UserContext.Provider>
  );
};

export const useUser = (): UserContextType => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
