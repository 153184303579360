import { httpClient } from '@raizen/client-auth';
import { PendingCertificateRequestsResponseType } from '@raizen/types';

export const getPendingCertificateRequests = async () => {
  const { data } = await httpClient.get<PendingCertificateRequestsResponseType>(
    '/certificate-requests/pending',
    {},
  );
  return data;
};
