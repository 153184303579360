import { Box, Button, Flex, FormLabel, Text } from '@chakra-ui/react';
import { FormErrorMessage, FormControl, PlusIcon, Card } from '@raizen/ui';
import { Controller, useForm } from 'react-hook-form';
import { UseMutateFunction, useQuery } from 'react-query';
import { SubmitHandler } from 'react-hook-form';
import { useTranslation } from '@raizen/react-hooks';
import { LocaleKeys } from '@raizen/translations';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { Select } from 'chakra-react-select';
import { CreateCertificateShareRequestDto } from '../../../services/certificateShareRequest/dtos/CreateCertificateShareRequestDto';
import { CustomControl } from './CustomControl';
import { getAvailableCompaniesToShare } from '../../../services/certificateRequests/getAvailableCompaniesToShare';

interface Props {
  setShareCertificateRequestForm: Dispatch<SetStateAction<boolean>>;
  formId: string;
  setSelectedCompany: Dispatch<SetStateAction<string>>;
  mutate: UseMutateFunction<
    any,
    unknown,
    CreateCertificateShareRequestDto,
    unknown
  >;
  certificateRequestUuid: string;
}

export const ShareCertificateForm = ({
  setShareCertificateRequestForm,
  formId,
  setSelectedCompany,
  mutate,
  certificateRequestUuid,
}: Props) => {
  const { t } = useTranslation();
  const context = useForm<CreateCertificateShareRequestDto>();
  const {
    handleSubmit,
    formState: { errors },
    control,

    watch,
    setValue,
  } = context;

  const onSubmit: SubmitHandler<CreateCertificateShareRequestDto> = async (
    data,
  ) => {
    mutate(data);
  };

  const { data: companies, isLoading: isLoadingCompanies } = useQuery(
    ['companies', certificateRequestUuid],
    () => getAvailableCompaniesToShare(certificateRequestUuid),
  );

  const selectedCompanyFiscalId = watch('companyFiscalId');

  useEffect(() => {
    if (selectedCompanyFiscalId && companies) {
      const selectedCompanyData = companies.find(
        ({ companyFiscalId }) => companyFiscalId === selectedCompanyFiscalId,
      );
      setValue('certificateRequestUuid', certificateRequestUuid);
      setValue('companyFiscalId', selectedCompanyData?.companyFiscalId || '');
      setValue('companyName', selectedCompanyData?.companyName || '');
      setValue('firstName', selectedCompanyData?.firstName || '');
      setValue('lastName', selectedCompanyData?.lastName || '');
      setValue('email', selectedCompanyData?.email || '');
    }
  }, [selectedCompanyFiscalId, certificateRequestUuid, companies, setValue]);

  const defaultOption = {
    label: t('users.newUserForm.fields.company.placeholder'),
    value: '',
  };

  const options =
    companies?.map((c) => ({
      label: c.companyName,
      value: c.companyFiscalId,
    })) ?? [];

  return (
    <form
      id={formId}
      onSubmit={(ev) => {
        ev.preventDefault();
        handleSubmit(onSubmit)(ev);
      }}
    >
      <Flex gap={8} flexDir="column" mt={6}>
        <FormControl isInvalid={Boolean(errors?.companyFiscalId)}>
          <FormLabel htmlFor="companyFiscalId">
            {t('users.newUserForm.fields.company.label')}
          </FormLabel>
          <Controller // TODO: Make a component to handle RHF logic for this contolled component
            control={control}
            defaultValue={defaultOption.value}
            name="companyFiscalId"
            render={({ field: { onChange, value, name, ref } }) => (
              <Select
                ref={ref}
                name={name}
                options={options}
                value={options.find((c) => c.value === value)}
                placeholder={defaultOption.label}
                onChange={(val) => {
                  setSelectedCompany(val?.value ?? '');
                  onChange(val?.value);
                }}
                selectedOptionStyle="check"
                isInvalid={Boolean(errors?.companyFiscalId)}
                isClearable={true}
                isLoading={isLoadingCompanies}
                components={{ Control: CustomControl }}
                noOptionsMessage={({ inputValue }) => {
                  const text = inputValue
                    ? t(
                        'users.newUserForm.fields.company.noCompaniesFound',
                      ).replace('{{search}}', inputValue)
                    : t('users.newUserForm.fields.company.hasNotSharedYet');
                  return (
                    <Box textAlign="start" px="4">
                      {text}
                    </Box>
                  );
                }}
              />
            )}
          />
          <FormErrorMessage>
            {t(errors?.companyFiscalId?.message as LocaleKeys)}
          </FormErrorMessage>
        </FormControl>
        <Card>
          <Flex w="full" flexDir={'column'} alignItems="flex-start" gap={1}>
            <Text color="content-terciary">
              {t('certificate.share.modal.inviteCompanyDescription')}
            </Text>
            <Button
              alignSelf={'felx-start'}
              variant={'link'}
              color="link-secondary-default"
              leftIcon={<PlusIcon w="min" />}
              onClick={() => setShareCertificateRequestForm(true)}
            >
              {t('certificate.share.modal.inviteCompany')}
            </Button>
          </Flex>
        </Card>
      </Flex>
    </form>
  );
};
