import { Button, Flex } from '@chakra-ui/react';
import { ContextField, Text, Card } from '@raizen/ui';
import { useForm, SubmitHandler } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { useTranslation } from '@raizen/react-hooks';
import { CreateCertificateRequestDto } from '../../../services/certificateRequests/dtos/createCertificateRequestDto';
import { AxiosError } from '@raizen/client-auth';
import { useMutation } from 'react-query';
import { createCertRequest } from '../../../services/certificateRequests/createCertificateRequest';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { ERROR_CODES } from '@raizen/common-utils';
import { CertificateRequestSerializer } from '@raizen/serializers';

interface SearchInvoiceFormProps {
  setActualStep: Dispatch<SetStateAction<number>>;
  setAccessKey: Dispatch<SetStateAction<string | undefined>>;
  setCertificateRequest: Dispatch<
    SetStateAction<CertificateRequestSerializer | undefined>
  >;
}

export const SearchInvoiceForm = ({
  setActualStep,
  setAccessKey,
  setCertificateRequest,
}: SearchInvoiceFormProps) => {
  const resolver = classValidatorResolver(CreateCertificateRequestDto);

  const context = useForm<CreateCertificateRequestDto>({ resolver });

  const { data, isLoading, isIdle, isSuccess, isError, mutate, ...rest } =
    useMutation(createCertRequest);

  const error = rest.error as AxiosError<{ message: string; code: string }>; // TODO: check for error messages using useMutation hook.

  const { handleSubmit } = context;

  const { t } = useTranslation();

  useEffect(() => {
    if (isError) {
      switch (error?.response?.data?.code) {
        case ERROR_CODES.CERTIFICATE_REQUEST_ALREADY_IN_PROGRESS:
          context.setError('accessKey', {
            message: t(`errorCode.CERTIFICATE_REQUEST_ALREADY_IN_PROGRESS`),
          });
          break;
        case ERROR_CODES.INCORRECT_ACCESS_KEY:
          context.setError('accessKey', {
            message: t(`errorCode.INCORRECT_ACCESS_KEY`),
          });
          break;

        default:
          context.setError('accessKey', {
            message: t('errorCode.GENERIC'),
          });
          break;
      }
    }
  }, [context, error?.response?.data?.code, isError, t]);

  useEffect(() => {
    if (isSuccess && data) {
      setActualStep(() => 1);
      setCertificateRequest(data);
    }
  }, [data, isSuccess, setActualStep, setCertificateRequest]);

  const onSubmit: SubmitHandler<CreateCertificateRequestDto> = (data) => {
    setAccessKey(data.accessKey);
    mutate(data);
  };

  return (
    <Card mt={8} py={6} width={'100%'}>
      <Flex as="form" flexDir="column" onSubmit={handleSubmit(onSubmit)}>
        <Text size={'sm'}>
          {t('certificateRequestCreation.searchInvoiceForm.title')}
        </Text>
        <ContextField
          context={context}
          name="accessKey"
          label=""
          errorMessageFormatter={t}
          placeholder={t(
            'certificateRequestCreation.searchInvoiceForm.input.placeholder',
          )}
          helperText={t(
            'certificateRequestCreation.searchInvoiceForm.input.helpText',
          )}
        />

        <Flex mt={6} alignItems={'center'} gap={4}>
          <Button
            size="sm"
            variant="primary"
            type="submit"
            w="fit-content"
            isLoading={isLoading}
          >
            {t('certificateRequestCreation.searchInvoiceForm.previewButton')}
          </Button>
        </Flex>
      </Flex>
    </Card>
  );
};
