import { IconButton, useClipboard } from '@chakra-ui/react';
import { useTranslation } from '@raizen/react-hooks';
import { useEffect } from 'react';
import { FiCopy } from 'react-icons/fi';
import useToastsHook from '../../hooks/useToastsHook';

interface Props {
  text: string;
}

export const CopyToClipboardButton = ({ text }: Props) => {
  const { onCopy, setValue, hasCopied } = useClipboard('');
  const { t } = useTranslation();
  const { showInfoToast } = useToastsHook();

  useEffect(() => {
    setValue(text);
    if (hasCopied) {
      showInfoToast({
        title: t('common.messages.information'),
        description: t('common.copiedToClipboard'),
      });
    }
  }, [hasCopied, onCopy, setValue, showInfoToast, t, text]);

  return (
    <IconButton
      aria-label="copy to clipboard"
      variant="link"
      onClick={onCopy}
      minW={'fit-content'}
    >
      <FiCopy />
    </IconButton>
  );
};
