import { NavLink as ReactRouterLink } from 'react-router-dom';
import { Link as Flex } from '@chakra-ui/react';
import { Badge } from '@raizen/ui';

interface Props {
  label: string;
  path: string;
  counter?: string;
  setActive?: any;
}

export const NavigationLink = ({ label, path, counter, setActive }: Props) => {
  return (
    <ReactRouterLink
      to={path}
      style={{
        height: '100%',
      }}
    >
      {({ isActive }: { isActive: boolean }) => {
        isActive && setActive();

        return (
          <Flex
            style={{
              height: '100%',
              alignItems: 'center',
              display: 'flex',
              gap: '8px',
              fontWeight: '500',
              ...(isActive
                ? {
                    color: 'var(--chakra-colors-content-primary)',
                    fontWeight: '500',
                  }
                : {}),
            }}
          >
            {label}
            {counter && <Badge colorScheme="error">{counter}</Badge>}
          </Flex>
        );
      }}
    </ReactRouterLink>
  );
};
