import React from 'react';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  Button,
  Checkbox,
  MenuItem,
  Text,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';

interface Props<T extends string> {
  options: { value: T; text: string }[];
  selectedOptions: T[];
  setSelectedOptions: React.Dispatch<React.SetStateAction<T[]>>;
  placeholder: string;
  allText: string;
}

function MultiSelectDropdown<ElementType extends string>({
  options,
  selectedOptions,
  setSelectedOptions,
  placeholder,
  allText,
}: Props<ElementType>) {
  const toggleOption = (option: ElementType) => {
    setSelectedOptions((prevSelectedOptions) => {
      if (prevSelectedOptions.includes(option)) {
        return prevSelectedOptions.filter(
          (currentOption) => currentOption !== option,
        );
      } else {
        return [...prevSelectedOptions, option];
      }
    });
  };

  const handleAllClick = () => {
    if (selectedOptions.length === options.length) {
      setSelectedOptions([]);
    } else {
      setSelectedOptions(options.map((option) => option.value));
    }
  };

  return (
    <Menu closeOnSelect={false}>
      <MenuButton
        as={Button}
        rightIcon={<ChevronDownIcon boxSize="5" />}
        width="auto"
        minWidth="200px"
        textAlign={'left'}
      >
        <Text color={'gray.500'}>{placeholder}</Text>
      </MenuButton>
      <MenuList minWidth="240px" zIndex={5}>
        <MenuOptionGroup type="checkbox" value={selectedOptions}>
          <MenuItem onClick={handleAllClick} gap="2" id="all-options-check">
            <Checkbox
              as="label"
              htmlFor="all-options-check"
              isChecked={selectedOptions.length === options.length}
              isReadOnly
            ></Checkbox>
            {allText}
          </MenuItem>
          <hr />
          {options.map((option) => (
            <MenuItem
              key={option.value}
              onClick={() => toggleOption(option.value)}
              gap="2"
              id={'option-' + option.value}
            >
              <Checkbox
                as="label"
                htmlFor={'option-' + option.value}
                isChecked={selectedOptions.includes(option.value)}
                isReadOnly
              ></Checkbox>
              {option.text}
            </MenuItem>
          ))}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
}

export default MultiSelectDropdown;
