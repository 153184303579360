import {
  CertificateRequestStatusType,
  GreenFactorType,
  GREEN_FACTOR_VALUES,
  CertificateRequestStatusFilterAdminTypes,
  CertificateRequestStatusFilterBaseTypes,
} from '@raizen/db';
import { ListCertificateRequestsSerializer } from '@raizen/serializers';
import React, { Dispatch, SetStateAction, useContext } from 'react';
import { ReactNode, createContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useTable, usePagination } from 'react-table';
import { useUser } from '../../../contexts/UserContext';
import { getCertRequests } from '../../../services/certificateRequests/getCertificateRequests';
import { useColumns } from './columns';

type CertificatesTableContextType = {
  filterInput: string;
  handleFilterChange: (e: any) => void;
  handleFiltersReset: () => void;
  canFilterCertificateByGreenFactor: Boolean;
  canFilterCertificateByStatus: Boolean;
  canManageCertificateRequests: Boolean;
  handleStatusFilterChange: (e: any) => void;
  fullDateFilter: Date | undefined;
  setFullDateFilter: Dispatch<SetStateAction<Date | undefined>>;
  greenFactorFilter: GreenFactorType[];
  setGreenFactorFilter: Dispatch<SetStateAction<GreenFactorType[]>>;
  statusFilter: string | undefined;
  availableStatusFilters: CertificateRequestStatusType[];
  tableInstance: any;
  isLoading: boolean;
  setPage: (page: number) => void;
  data: any;
  refetchCertificateRequests: () => void;
};

const CertificatestableContext = createContext<
  CertificatesTableContextType | undefined
>(undefined);
type CertificatesTableProviderProps = {
  children: ReactNode;
};

export const CertificatesTableProvider: React.FC<
  CertificatesTableProviderProps
> = ({ children }) => {
  const {
    canManageCertificateRequests,
    canFilterCertificateByGreenFactor,
    canFilterCertificateByStatus,
  } = useUser();

  const [filterInput, setFilterInput] = useState('');

  const [statusFilter, setStatusFilter] = useState<
    CertificateRequestStatusType | undefined
  >(undefined);
  const [greenFactorFilter, setGreenFactorFilter] = useState<GreenFactorType[]>(
    [],
  );

  const [fullDateFilter, setFullDateFilter] = useState<Date | undefined>(
    undefined,
  );
  const [dateFilter, setDateFilter] = useState<number | undefined>(undefined);

  const [page, setPage] = useState(1);

  const pageSize = canManageCertificateRequests ? 50 : 10;

  const {
    data,
    isLoading,
    refetch: refetchCertificateRequests,
  } = useQuery(
    [
      'certificate-requests',
      filterInput,
      statusFilter,
      dateFilter,
      greenFactorFilter,
      page,
      pageSize,
    ],
    () =>
      getCertRequests({
        accessKey: filterInput,
        statusFilter,
        dateFilter,
        greenFactorFilter,
        page,
        pageSize,
      }),
  );

  const { columns } = useColumns();

  const tableData = React.useMemo(
    () => data?.data || [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(data)],
  );
  const tableInstance = useTable<ListCertificateRequestsSerializer>(
    {
      columns: columns as any,
      data: tableData,
      initialState: { pageSize } as object,
    },
    usePagination,
  );

  useEffect(() => {
    (tableInstance as any).setPageSize(pageSize);
  }, [pageSize, tableInstance]);

  const handleFilterChange = (e: any) => {
    const value = e.target.value || '';
    setFilterInput(value);
    setPage(1);
  };

  const handleStatusFilterChange = (e: any) => {
    const value = e.target.value || undefined;
    setStatusFilter(value);
    setPage(1);
  };

  const handleFiltersReset = () => {
    setFilterInput('');
    setStatusFilter(undefined);
    setFullDateFilter(undefined);
    setGreenFactorFilter([
      GREEN_FACTOR_VALUES.GOLD,
      GREEN_FACTOR_VALUES.SILVER,
      GREEN_FACTOR_VALUES.BRONZE,
      GREEN_FACTOR_VALUES.STANDARD,
    ]);
    setPage(1);
  };

  const availableStatusFilters = canManageCertificateRequests
    ? CertificateRequestStatusFilterAdminTypes
    : CertificateRequestStatusFilterBaseTypes;

  useEffect(() => {
    if (fullDateFilter) {
      setDateFilter(fullDateFilter.getTime() / 1000);
    } else {
      setDateFilter(undefined);
    }
  }, [fullDateFilter, dateFilter]);

  const value: CertificatesTableContextType = {
    filterInput,
    handleFilterChange,
    handleFiltersReset,
    canFilterCertificateByGreenFactor,
    canFilterCertificateByStatus,
    canManageCertificateRequests,
    handleStatusFilterChange,
    fullDateFilter,
    setFullDateFilter,
    greenFactorFilter,
    setGreenFactorFilter,
    statusFilter,
    availableStatusFilters,
    tableInstance,
    isLoading,
    setPage,
    data,
    refetchCertificateRequests,
  };

  return (
    <CertificatestableContext.Provider value={value}>
      {children}
    </CertificatestableContext.Provider>
  );
};

export const useCertificatesTableContext = (): CertificatesTableContextType => {
  const context = useContext(CertificatestableContext);
  if (context === undefined) {
    throw new Error(
      'useCertificatesTable must be used within a CertificatesTableProvider',
    );
  }
  return context;
};
