import { Box, Center, Divider, Flex } from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from '@raizen/react-hooks';
import { Heading, Text } from '@raizen/ui';
import { IllustrationColumn } from '../../components/CertificateRequestCreation/IllustrationColumn';
import { CertificateRequestCreationStepper } from '../../components/CertificateRequestCreation/CertificateRequestCreationStepper';
import { SearchInvoiceForm } from '../../components/CertificateRequestCreation/forms/SearchInvoiceForm';
import { CertificatePreview } from '../../components/CertificateRequestCreation/CertificatePreview';
import { CertificateRequestActions } from '../../components/CertificateRequestCreation/forms/CertificateRequestActions';
import { CertificateRequested } from '../../components/CertificateRequestCreation/CertificateRequested';
import { CertificateRequestSerializer } from '@raizen/serializers';

export const CertificateRequestCreation = () => {
  const { t } = useTranslation();
  const [actualStep, setActualStep] = useState(0);
  const [accessKey, setAccessKey] = useState<string>();
  const [
    createCertificateRequestResponse,
    setCreateCertificateRequestResponse,
  ] = useState<CertificateRequestSerializer>();

  return (
    <Center w="100%" px={8}>
      <Flex w="full" maxW={'1280px'} pt="8">
        <Flex flex={1} flexDir={'column'} overflow={'auto'} width={'100%'}>
          <Heading size={'sm'}>
            {t('certificateRequestCreation.heading')}
          </Heading>
          <Text size={'md'} py="1">
            {t('certificateRequestCreation.subheading')}
          </Text>
          <Box py="6">
            <CertificateRequestCreationStepper actualStep={actualStep} />
          </Box>
          <Divider />

          {actualStep === 0 && (
            <SearchInvoiceForm
              setActualStep={setActualStep}
              setAccessKey={setAccessKey}
              setCertificateRequest={setCreateCertificateRequestResponse}
            />
          )}
          {actualStep === 1 && (
            <Flex flexDir={'column'} gap={6} mb={6}>
              <CertificatePreview
                invoiceAccessKey={accessKey}
                data={createCertificateRequestResponse}
                hasIssuedCertificate={
                  createCertificateRequestResponse?.hasAccessKeyIssuedCertificate ??
                  false
                }
              />
              <CertificateRequestActions
                setActualStep={setActualStep}
                requestUuid={createCertificateRequestResponse?.uuid}
              />
            </Flex>
          )}
          {actualStep === 4 &&
            createCertificateRequestResponse !== undefined && (
              <Box my={8}>
                <CertificateRequested
                  requestUuid={createCertificateRequestResponse.uuid}
                />
              </Box>
            )}
        </Flex>

        <IllustrationColumn />
      </Flex>
    </Center>
  );
};
