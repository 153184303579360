import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { SearchInput, TableWrapper, Table, Pagination } from '@raizen/ui';
import { TableInstance, useTable } from 'react-table';
import { User } from '@raizen/db';
import { usePagination } from 'react-table';
import { Box, useDisclosure } from '@chakra-ui/react';
import { useTranslation } from '@raizen/react-hooks';
import { getUsers } from '../../../services/users/getUsers';
import { useColumns } from './columns';
import { DeactivateUserModal } from '../Deactivate/DeactivateUserModal';
import { deactivateUser } from '../../../services/users/deactivateUser';
import useToastsHook from '../../../hooks/useToastsHook';

export const UsersTable = () => {
  const { t } = useTranslation();
  const { showSuccessToast, showErrorToast } = useToastsHook();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User>();

  const onClickDelete = (user: User) => {
    setSelectedUser(user);
    onOpen();
  };

  const userFullName = `${selectedUser?.firstName} ${selectedUser?.lastName}`;
  const companyName = selectedUser?.company?.name ?? '';

  const handleDeactivateUser = async () => {
    if (!selectedUser) return;
    setIsLoadingDelete(true);
    try {
      await deactivateUser(selectedUser.uuid);
      showSuccessToast({
        title: t('common.messages.success'),
        description: t('users.deactivate.successToast.description')
          .replace('{{userFullName}}', userFullName)
          .replace('{{companyName}}', companyName),
      });
      refetch();
      onClose();
    } catch (error) {
      showErrorToast({
        title: t('common.messages.error'),
        description: t('users.deactivate.errorToast.description'),
      });
    }
    setIsLoadingDelete(false);
  };

  const { columns } = useColumns(onClickDelete);

  const [filter, setFilter] = useState('');
  const [page, setPage] = useState(1);

  const { data, isLoading, refetch } = useQuery(['users', filter, page], () => {
    return getUsers({ filter, page });
  });

  const tableData = React.useMemo(
    () => data?.users || [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(data)],
  );
  const tableInstance = useTable<User>(
    {
      columns: columns as any,
      data: tableData,
    },
    usePagination,
  );

  const handleFilterChange = (e: any) => {
    const value = e.target.value || '';
    setFilter(value);
    setPage(1);
  };

  return (
    <>
      <TableWrapper
        searchComponent={
          <SearchInput
            value={filter}
            onChange={handleFilterChange}
            placeholder={t('users.table.filter')}
          />
        }
      >
        <Table
          tableInstance={tableInstance as TableInstance<object>}
          isLoading={isLoading}
          emptyStateContent={{
            title: t('users.table.emptyState.title'),
            description: t('users.table.emptyState.description'),
          }}
        />
      </TableWrapper>
      <Box my="3">
        <Pagination
          currentPage={data?.pagination.currentPage!}
          totalPageCount={data?.pagination.totalPages!}
          previousButtonLabel={t('common.pagination.previous')}
          nextButtonLabel={t('common.pagination.next')}
          onPageChange={setPage}
        />
      </Box>
      <DeactivateUserModal
        isOpen={isOpen}
        onClose={onClose}
        handleDeactivateUser={handleDeactivateUser}
        isLoading={isLoadingDelete}
        user={selectedUser}
      />
    </>
  );
};
