import { httpClient } from '@raizen/client-auth';
import { GetNoRequestedCertificateRequestsDto } from './dtos/getNoRequestedCertificateRequestDto';
import { GetNoRequestedCertificateRequestsResponse } from '@raizen/types';

export const getNotRequestedCertRequestsPath =
  '/certificate-requests/not-requested';

export const getNoRequestedCertRequests = async (
  params: GetNoRequestedCertificateRequestsDto,
) => {
  const { data } =
    await httpClient.get<GetNoRequestedCertificateRequestsResponse>(
      getNotRequestedCertRequestsPath,
      {
        params,
      },
    );

  return data;
};
