import { Button, Flex, Tooltip } from '@chakra-ui/react';
import {
  CERTIFICATE_SHARE_REQUEST_STATUS,
  CertificateShareRequestStatusType,
} from '@raizen/db';
import { useTranslation } from '@raizen/react-hooks';
import { useMemo } from 'react';
import { CellProps } from 'react-table';
import { TableActions } from '../../Users/TableActions';
import { formatDate } from '@raizen/common-utils';
import { AlertIcon, Badge } from '@raizen/ui';
import { certificateShareRequestsStatusBadgeColors } from '@raizen/frontend-utils';
import { LocaleKeys } from '@raizen/translations';
import { PERFORM_ACTION } from '@raizen/casl-authorization';
import { Can } from '../../../contexts/PermissionsContext';
import { ListCertificateShareRequestsSerializer } from '@raizen/serializers';
import { TextTruncate } from '../../shared/TextTruncate';
import { CopyToClipboardButton } from '../../shared/CopyToClipboardButton';
import { useNavigate } from 'react-router-dom';

interface Props {
  onClickApprove: (request: ListCertificateShareRequestsSerializer) => void;
  onClickReject: (request: ListCertificateShareRequestsSerializer) => void;
}

export const useColumns = ({ onClickApprove, onClickReject }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const columns = useMemo(
    () => [
      {
        id: 'accessKey',
        Header: t('sharingRequests.table.columns.accessKey'),
        Cell: (props: CellProps<ListCertificateShareRequestsSerializer>) => {
          const accessKey = props.row.original.accessKey ?? '';
          const certRequestUuid = props.row.original.certificateRequestUuid;
          return (
            <Flex
              gap={2}
              alignItems={'center'}
              float="left"
              position="relative"
            >
              <Button
                alignSelf={'start'}
                variant="link"
                onClick={() => navigate(`/certificates/${certRequestUuid}`)}
                color="primary"
                size={'sm'}
                mt={1}
              >
                #<TextTruncate text={accessKey} max={24} />
              </Button>
              <CopyToClipboardButton text={accessKey} />
            </Flex>
          );
        },
      },
      {
        Header: t('sharingRequests.table.columns.owner'),
        accessor: 'companyName',
        Cell: (props: CellProps<ListCertificateShareRequestsSerializer>) =>
          props.row.original.certificateOwnerCompanyName ?? '-',
      },
      {
        id: 'certificateViewer',
        Header: t('sharingRequests.table.columns.viewer'),
        Cell: (props: CellProps<ListCertificateShareRequestsSerializer>) => {
          return (
            <Flex gap={1}>
              {props.row.original.certificateViewerCompanyName ?? '-'}
            </Flex>
          );
        },
      },
      {
        Header: t('sharingRequests.table.columns.createdAt'),
        accessor: 'createdAt',
        Cell: (props: CellProps<ListCertificateShareRequestsSerializer>) =>
          formatDate(
            Number(props.row.original.certificateShareRequestCreatedAt),
          ),
      },
      {
        Header: t('sharingRequests.table.columns.status'),
        accessor: 'status',
        Cell: (props: CellProps<ListCertificateShareRequestsSerializer>) => {
          const status = props.row.original
            .certificateShareRequestStatus as CertificateShareRequestStatusType;
          const tooltip = props.row.original.certificateShareRequestReason;
          return (
            <Tooltip label={tooltip} hasArrow={true}>
              <Flex gap={1}>
                <Badge
                  colorScheme={
                    certificateShareRequestsStatusBadgeColors[status]
                  }
                >
                  {t(`sharingRequests.status.${status}` as LocaleKeys)}
                </Badge>
                {Boolean(tooltip) && (
                  <Flex alignItems="center">
                    <AlertIcon color="#995E00" />
                  </Flex>
                )}
              </Flex>
            </Tooltip>
          );
        },
      },
      {
        id: 'actions',
        Header: (
          <Flex
            w="full"
            justifyContent={{ base: 'start', lg: 'end' }}
            data-header-text={t('admins.table.columns.actions')}
          >
            {t('admins.table.columns.actions')}
          </Flex>
        ),
        Cell: (props: CellProps<ListCertificateShareRequestsSerializer>) => {
          const status = props.row.original.certificateShareRequestStatus;
          const showActions =
            status === CERTIFICATE_SHARE_REQUEST_STATUS.PENDING;
          return (
            showActions && (
              <Can I={PERFORM_ACTION.Approve} a="CertificateShareRequest">
                <TableActions>
                  <Button
                    variant="linkDestructive"
                    onClick={() => onClickReject(props.row.original)}
                    size="sm"
                  >
                    {t('common.messages.reject')}
                  </Button>
                  <Button
                    variant="linkPrimary"
                    onClick={() => onClickApprove(props.row.original)}
                    size="sm"
                  >
                    {t('common.messages.approve')}
                  </Button>
                </TableActions>
              </Can>
            )
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return { columns };
};
