"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.ts
var src_exports = {};
__export(src_exports, {
  TranslationsProvider: () => TranslationsProvider,
  useLocalStorageState: () => useLocalStorageState_default,
  useTranslation: () => useTranslation
});
module.exports = __toCommonJS(src_exports);

// src/hooks/translations/TranslationsProvider.tsx
var import_react_i18next2 = require("react-i18next");

// src/hooks/translations/i18n.ts
var import_translations = require("@raizen/translations");
var import_i18next = __toESM(require("i18next"));
var import_react_i18next = require("react-i18next");
import_i18next.default.use(import_react_i18next.initReactI18next).init({
  interpolation: { escapeValue: false },
  fallbackLng: "en",
  resources: {
    en: {
      translation: import_translations.locales_EN
    },
    pt: {
      translation: import_translations.locales_PT
    }
  }
});
var i18n_default = import_i18next.default;

// src/hooks/translations/TranslationsProvider.tsx
var import_jsx_runtime = require("react/jsx-runtime");
var TranslationsProvider = ({ children }) => {
  return /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_react_i18next2.I18nextProvider, { i18n: i18n_default, children });
};

// src/hooks/translations/useTranslations.ts
var import_react = require("react");
var import_react_i18next3 = require("react-i18next");
var useTranslation = () => {
  const _a = (0, import_react_i18next3.useTranslation)(), { t } = _a, rest = __objRest(_a, ["t"]);
  const customTranslationFunction = (0, import_react.useCallback)(
    (key, ...otherOptions) => {
      return t(key, ...otherOptions);
    },
    [t]
  );
  return __spreadValues({
    t: customTranslationFunction
  }, rest);
};

// src/hooks/useLocalStorageState.ts
var import_react2 = require("react");
var useLocalStorageState = (key, defaultValue = null, options = {
  serialize: JSON.stringify,
  deserialize: JSON.parse
}) => {
  const { serialize, deserialize } = options;
  const [state, setState] = (0, import_react2.useState)(() => {
    const valueInLocalStorage = window.localStorage.getItem(key);
    if (valueInLocalStorage && valueInLocalStorage !== "undefined") {
      return deserialize(valueInLocalStorage);
    }
    return typeof defaultValue === "function" ? defaultValue() : defaultValue;
  });
  const prevKeyRef = (0, import_react2.useRef)(key);
  (0, import_react2.useEffect)(() => {
    const prevKey = prevKeyRef.current;
    if (prevKey !== key) {
      window.localStorage.removeItem(prevKey);
      prevKeyRef.current = key;
    }
    window.localStorage.setItem(key, serialize(state));
  }, [key, serialize, state]);
  return [state, setState];
};
var useLocalStorageState_default = useLocalStorageState;
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  TranslationsProvider,
  useLocalStorageState,
  useTranslation
});
