import { CERTIFICATE_REQUEST_STATUS, ValueOf } from '@raizen/db';
import { useTranslation } from '@raizen/react-hooks';
import { CertificateRequestSerializer } from '@raizen/serializers';
import { useUser } from '../../../contexts/UserContext';
import { certificateRequestsAlertVariant } from '@raizen/frontend-utils';

interface Response {
  showNoCertificateDataAlert: boolean;
  noCertificateDataVariant: ValueOf<typeof certificateRequestsAlertVariant>;
  noCertificateDataTitle: string;
  noCertificateDataDescription: string;
}

export const useNoCertificateDataAlert = (
  certificateRequest: CertificateRequestSerializer,
) => {
  const { t } = useTranslation();

  const { canManageCertificateRequests } = useUser();

  const { accessKey } = certificateRequest;

  const notFoundCertificateDataStatus =
    certificateRequest.status === CERTIFICATE_REQUEST_STATUS.NOT_FOUND;

  const shouldShowNoCertificateDataWarn =
    canManageCertificateRequests &&
    !certificateRequest?.certificateData?.length &&
    notFoundCertificateDataStatus;

  const values: Response = {
    showNoCertificateDataAlert: shouldShowNoCertificateDataWarn,
    noCertificateDataVariant: 'warning',
    noCertificateDataTitle: t(
      'certificateRequestDetails.alert.noCertificateData.title',
    ).replace('{{accessKey}}', accessKey || ''),
    noCertificateDataDescription: t(
      'certificateRequestDetails.alert.noCertificateData.description',
    ),
  };
  return values;
};
