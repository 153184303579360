import {
  Box,
  Center,
  Divider,
  Flex,
  Spinner,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import { Breadcrumb } from '@raizen/ui';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { RejectCertificateRequestModal } from '../../../components/CertificateRequestDetails/RejectCertificateRequestModal';
import { ApproveCertificateRequestModal } from '../../../components/CertificateRequestDetails/ApproveCertificateRequestModal';
import { ShareCertificateModal } from '../../../components/CertificateRequestDetails/ShareCertificate/ShareCertificateModal';
import { useState } from 'react';
import { Header } from '../../../components/Header/Header';
import { useCertificateRequestDetails } from './useCertificateRequestDetails';
import { FullView } from '../../../components/CertificateRequestDetails/FullView';
import { RestrictedView } from '../../../components/CertificateRequestDetails/RestrictedView';
import './printable.css';

export const CertificateRequestDetails = () => {
  const {
    isOpen: isApproveOpen,
    onOpen: onOpenApprove,
    onClose: onCloseApprove,
  } = useDisclosure();
  const {
    isOpen: isRejectOpen,
    onOpen: onOpenReject,
    onClose: onCloseReject,
  } = useDisclosure();

  const showHomeOnFirstItem = useBreakpointValue(
    { base: true, md: true, lg: false },
    {
      fallback: 'md',
    },
  );

  const { uuid = '' } = useParams();
  const [params] = useSearchParams();
  const isNotRequested = Boolean(params.get('isNotRequested'));

  const navigate = useNavigate();
  const [isShareCertificateOpen, setIsShareCertificateOpen] = useState(false);

  const {
    certificateRequest,
    isLoadingCertificateRequest,
    refetchCertificateRequest,
    breadcrumbOptions,
    pageTitle,
    statusBadge,
    actions,
    pageDescription,
    hasFullAccess,
  } = useCertificateRequestDetails({
    uuid,
    onOpenApprove,
    onOpenReject,
    setIsShareCertificateOpen,
    isNotRequested,
  });

  if (isLoadingCertificateRequest)
    return (
      <Center mt="8">
        <Spinner />
      </Center>
    );

  return (
    <Center w="full" px="4">
      <Flex
        w="full"
        maxW={'1280px'}
        py={8}
        flexDir={'column'}
        gap={8}
        className="remove-pt-for-print-view"
      >
        <Box className="remove-for-print-view">
          <Breadcrumb
            onNavigate={navigate}
            options={breadcrumbOptions}
            showHomeOnFirstItem={showHomeOnFirstItem}
          />
        </Box>
        <Box className="remove-for-print-view">
          <Header
            title={pageTitle}
            description={pageDescription}
            badge={statusBadge}
            actions={actions}
          />
        </Box>

        {hasFullAccess && certificateRequest ? (
          <FullView certificateRequest={certificateRequest} />
        ) : (
          <>
            <Divider />
            {certificateRequest && (
              <RestrictedView certificateRequest={certificateRequest} />
            )}
          </>
        )}
      </Flex>

      <ApproveCertificateRequestModal
        isOpen={isApproveOpen}
        onClose={onCloseApprove}
        refetchData={refetchCertificateRequest}
      />
      <RejectCertificateRequestModal
        isOpen={isRejectOpen}
        onClose={onCloseReject}
        refetchData={refetchCertificateRequest}
      />
      <ShareCertificateModal
        isOpen={isShareCertificateOpen}
        onClose={() => setIsShareCertificateOpen(false)}
        certificateRequestUuid={certificateRequest?.uuid || ''}
      />
    </Center>
  );
};
