interface Props {
  text: string;
  max?: number;
}

export const TextTruncate = ({ text = '', max = 6 }: Props) => {
  let truncatedText = text.slice(0, max);
  if (text.length > max) {
    truncatedText += `...`;
  }
  return truncatedText;
};
