import { Flex } from '@chakra-ui/react';
import { Text } from '@raizen/ui';
import { PropsWithChildren } from 'react';

interface Props extends PropsWithChildren {
  title: string;
}

export const Section = ({ title, children }: Props) => {
  return (
    <Flex flexDir={'column'} gap={2} w="full">
      <Text size={'lg'} variant={'medium'}>
        {title}
      </Text>
      {children}
    </Flex>
  );
};
