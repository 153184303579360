import { Center, Flex } from '@chakra-ui/react';

export const PageContainer = ({ children }: any) => {
  return (
    <Center w="full" px={8}>
      <Flex w="full" maxW={'1280px'} flexDir={'column'} py="8" gap={6}>
        {children}
      </Flex>
    </Center>
  );
};
