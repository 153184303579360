import { httpClient } from '@raizen/client-auth';
import { CreateCertificateRequestDto } from './dtos/createCertificateRequestDto';
import { CertificateRequestSerializer } from '@raizen/serializers';

export const createCertRequest = async (
  params: CreateCertificateRequestDto,
) => {
  const { data } = await httpClient.post<CertificateRequestSerializer>(
    '/certificate-requests',
    params,
  );

  return data;
};
