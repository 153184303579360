import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { approveCertRequest } from '../../services/certificateRequests/approveCertificateRequest';
import { useTranslation } from '@raizen/react-hooks';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  refetchData: () => void;
}

export const ApproveCertificateRequestModal = ({
  isOpen,
  onClose,
  refetchData,
}: Props) => {
  const { uuid = '' } = useParams();
  const toast = useToast();
  const { t } = useTranslation();
  const {
    isLoading: isLoadingCertRequestApprove,
    mutate: mutateApproveCertificateRequest,
  } = useMutation(approveCertRequest, {
    onSuccess: () => {
      toast({
        title: t('certificateRequestDetails.action.approve.success'),
        status: 'success',
        position: 'top-right',
        duration: 9000,
        isClosable: true,
      });
      onClose();
      refetchData();
    },
  });
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent as="form">
        <ModalHeader>
          {t('certificateRequestDetails.action.approve.modal.title')}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {t('certificateRequestDetails.action.approve.modal.description')}
        </ModalBody>
        <ModalFooter>
          <Flex gap="12px">
            <Button variant="outline" onClick={onClose}>
              {t('certificateRequestDetails.action.cancel')}
            </Button>
            <Button
              variant="primary"
              mr={3}
              onClick={() => mutateApproveCertificateRequest(uuid)}
              isLoading={isLoadingCertRequestApprove}
            >
              {t('certificateRequestDetails.action.approve')}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
