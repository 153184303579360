import { httpClient } from '@raizen/client-auth';
import { CertificateRequestSerializer } from '@raizen/serializers';

export const getNotRequestedCertificateRequestsDataByAccessKeyBasePath =
  '/certificate-requests/not-requested/';

export const getNotRequestedCertificateRequestsDataByAccessKey = async (
  accessKey: string,
) => {
  const getNotRequestedCertificateRequestsDataByAccessKeyPath =
    getNotRequestedCertificateRequestsDataByAccessKeyBasePath + accessKey;
  const { data } = await httpClient.get<CertificateRequestSerializer>(
    getNotRequestedCertificateRequestsDataByAccessKeyPath,
  );

  return data;
};
