import { FiPlus } from 'react-icons/fi';
import { useTranslation } from '@raizen/react-hooks';
import { AdminsTable } from '../../components/Users/AdminsTable';
import { HeaderActionProps } from '../../components/Header/HeaderActions';
import { Header } from '../../components/Header/Header';
import { PageContainer } from '../../components/PageContainer/PageContainer';
import { useDisclosure } from '@chakra-ui/react';
import { NewAdminModal } from '../../components/Users/NewAdmin/NewAdminModal';

export const Admins = () => {
  const { t } = useTranslation();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const actions: HeaderActionProps[] = [
    {
      key: 'inviteNewAdmin',
      label: t('admins.inviteNewAdmin'),
      icon: <FiPlus />,
      variant: 'primary',
      onClick: onOpen,
    },
  ];

  return (
    <PageContainer>
      <Header
        title={t('admins.title')}
        description={t('admins.description')}
        actions={actions}
      />
      <AdminsTable />
      <NewAdminModal isOpen={isOpen} onClose={onClose} />
    </PageContainer>
  );
};
