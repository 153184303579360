import {
  Tab as ChakraTab,
  Flex,
  useBreakpointValue,
  TabProps as ChakraTabProps,
} from '@chakra-ui/react';
import { Badge } from '@raizen/ui';
import { FC } from 'react';

// TODO: move this customization to theme definition at UI Library @facu

interface TabProps extends ChakraTabProps {
  showBadge?: boolean;
  badgeText?: string;
}

export const Tab: FC<TabProps> = ({
  showBadge = false,
  badgeText,
  children,
  ...otherProps
}: TabProps) => {
  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
  });
  return (
    <ChakraTab
      color="gray.500"
      paddingX={isMobile ? '4' : '0'}
      paddingTop="0"
      paddingBottom={isMobile ? '3' : '2'}
      fontSize={isMobile ? 'md' : 'sm'}
      _active={{
        backgroundColor: 'transparent',
      }}
      _focus={{
        outline: 'none',
        backgroundColor: 'none',
      }}
      _focusVisible={{
        border: '2px solid',
        borderRadius: '4px',
        borderColor: 'gray.900',
      }}
      _hover={{
        borderBottom: '2px solid',
        textColor: 'gray.900',
        borderColor: 'brand.200',
      }}
      _selected={{
        paddingBottom: '2',
        color: 'gray.900',
        fontFamily: 'Larsseit-Medium',
        bg: 'white',
        borderBottom: '2px solid',
        borderColor: 'brand.500',
        _hover: {
          borderColor: 'brand.500',
        },
      }}
      width={isMobile ? '100%' : ''}
      {...otherProps}
    >
      <Flex justify="center" width="100%" align="center" gap={2}>
        {children}
        {showBadge && (
          <Badge size="sm" colorScheme="gray" type="default">
            {badgeText}
          </Badge>
        )}
      </Flex>
    </ChakraTab>
  );
};
