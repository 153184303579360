import { PERFORM_ACTION } from '@raizen/casl-authorization';
import { useUser } from '../../../contexts/UserContext';
import { useTranslation } from '@raizen/react-hooks';
import { HeaderActionProps } from '../../../components/Header/HeaderActions';
import { Dispatch, SetStateAction, useState } from 'react';
import { certificateRequestsStatusBadgeColors } from '@raizen/frontend-utils';
import { useQuery } from 'react-query';
import { getCertificateRequest } from '../../../services/certificateRequests/getCertificateRequest';
import { CERTIFICATE_REQUEST_STATUS } from '@raizen/db';
import { getNotRequestedCertificateRequestsDataByAccessKey } from '../../../services/certificateRequests/getNotRequestedCertificateRequestsDataByAccessKey';
import { usePrintingState } from '../../../contexts/PrintingStateProvider';
import { shouldSyncCertificateRequests } from '../../../services/shouldSyncCertificateRequests/shouldSyncCertificateRequests';
import { RefreshCWIcon } from '@raizen/ui';
import { syncCertificateRequests } from '../../../services/shouldSyncCertificateRequests/syncCertificateRequests';
import useToastsHook from '../../../hooks/useToastsHook';

interface Props {
  uuid: string;
  onOpenApprove: () => void;
  onOpenReject: () => void;
  setIsShareCertificateOpen: Dispatch<SetStateAction<boolean>>;
  isNotRequested?: boolean;
}
export const useCertificateRequestDetails = ({
  uuid,
  onOpenApprove,
  onOpenReject,
  setIsShareCertificateOpen,
  isNotRequested = true,
}: Props) => {
  const { t } = useTranslation();
  const { showErrorToast, showSuccessToast } = useToastsHook();
  const { ability, canManageCertificateRequests } = useUser();

  const { handleBeforePrint } = usePrintingState();

  const canReadCertificateRequests = ability?.can(
    PERFORM_ACTION.Read,
    'CertificateRequest',
  );
  const canCreateCertificateShareRequest = ability?.can(
    PERFORM_ACTION.Create,
    'CertificateShareRequest',
  );
  const canApproveCertificateRequest = ability?.can(
    PERFORM_ACTION.Approve,
    'CertificateRequest',
  );
  const canExportCertificate = ability?.can(
    PERFORM_ACTION.Export,
    'Certificate',
  );
  const canReadCompleteCertificate = ability?.can(
    PERFORM_ACTION.ReadType.Complete,
    'Certificate',
  );
  const canReadCompleteCertificateRequest = ability?.can(
    PERFORM_ACTION.ReadType.Complete,
    'CertificateRequest',
  );
  const canSyncCertificateRequests = ability?.can(
    PERFORM_ACTION.Sync,
    'CertificateRequest',
  );

  const {
    data: certificateRequest,
    isLoading: isLoadingCertificateRequest,
    refetch: refetchCertificateRequest,
  } = useQuery(
    ['certificate-details', uuid],
    () => {
      if (uuid) {
        if (isNotRequested) {
          const accessKey = uuid; // it's the same param, the isNotRequested flag will tell if its an uuid or an accesskey
          return getNotRequestedCertificateRequestsDataByAccessKey(accessKey);
        }
        return getCertificateRequest(uuid);
      }
    },
    { enabled: !!uuid },
  );

  const {
    data: shouldSyncDatabase,
    isLoading: isLoadingShouldSync,
    refetch: refetchShouldSync,
  } = useQuery(
    ['shouldSyncDatabase', canSyncCertificateRequests, uuid],
    async () => {
      return shouldSyncCertificateRequests(uuid);
    },
    { enabled: canSyncCertificateRequests },
  );

  const [isSyncingDatabase, setIsSyncingDatabase] = useState(false);

  const handleSyncDatabaseClick = async () => {
    setIsSyncingDatabase(true);
    syncCertificateRequests(uuid)
      .then(() => {
        showSuccessToast({
          title: '',
          description: t('syncDatabase.success'),
        });
        refetchShouldSync();
        refetchCertificateRequest();
      })
      .catch(() => {
        showErrorToast({
          title: '',
          description: t('syncDatabase.error'),
        });
      })
      .finally(() => setIsSyncingDatabase(false));
  };

  const status = certificateRequest?.status;
  const isPendingStatus = status === 'PENDING';
  const isCompleted = status === 'COMPLETED';
  const certificateRequester = certificateRequest?.userName;

  const breadcrumbOptions = [
    { label: t('breadcrumb.certificates'), to: '/' },
    {
      label: t('breadcrumb.certificateRequestsDetails'),
      to: '',
    },
  ];

  const shouldDisableApproval =
    certificateRequest && certificateRequest?.certificateData?.length === 0;

  const showStatusBadge =
    status && (canReadCertificateRequests || canManageCertificateRequests);

  const hasFullAccess =
    (canReadCompleteCertificate &&
      certificateRequest?.status === CERTIFICATE_REQUEST_STATUS.COMPLETED) ||
    canReadCompleteCertificateRequest;

  const pageTitle = t('certificateRequestDetails.heading');
  const pageDescription =
    canReadCertificateRequests && certificateRequester
      ? t('certificateRequestDetails.requestedBy').replace(
          '{{name}}',
          certificateRequester ?? '',
        )
      : '';

  let statusBadge = undefined;
  if (showStatusBadge) {
    switch (status) {
      case CERTIFICATE_REQUEST_STATUS.NOT_FOUND:
        if (!canManageCertificateRequests) {
          statusBadge = {
            text: t(`certificateRequests.status.IN_REVIEW`),
            colorScheme:
              certificateRequestsStatusBadgeColors[
                CERTIFICATE_REQUEST_STATUS.PENDING
              ],
          };
        } else {
          statusBadge = {
            text: t(`certificateRequests.status.${status}`),
            colorScheme: certificateRequestsStatusBadgeColors[status],
          };
        }
        break;
      case CERTIFICATE_REQUEST_STATUS.PENDING:
        if (!canManageCertificateRequests) {
          statusBadge = {
            text: t(`certificateRequests.status.IN_REVIEW`),
            colorScheme:
              certificateRequestsStatusBadgeColors[
                CERTIFICATE_REQUEST_STATUS.PENDING
              ],
          };
        } else {
          statusBadge = {
            text: t(`certificateRequests.status.${status}`),
            colorScheme: certificateRequestsStatusBadgeColors[status],
          };
        }
        break;
      default:
        statusBadge = {
          text: t(`certificateRequests.status.${status}`),
          colorScheme: certificateRequestsStatusBadgeColors[status],
        };
        break;
    }
  }

  const printButton: HeaderActionProps = {
    key: 'printButton',
    label: t('certificateRequestDetails.downloadPDF'),
    variant: 'secondary',
    onClick: handleBeforePrint,
  };
  const shareAction: HeaderActionProps = {
    key: 'shareAction',
    label: t('certificateRequestDetails.shareButton'),
    variant: 'primary',
    onClick: () => setIsShareCertificateOpen(true),
  };
  const rejectAction: HeaderActionProps = {
    key: 'rejectAction',
    label: t('certificateRequestDetails.action.reject'),
    variant: 'destructive',
    onClick: onOpenReject,
  };
  const approveAction: HeaderActionProps = {
    key: 'approveAction',
    label: t('certificateRequestDetails.action.approve'),
    variant: 'primary',
    onClick: onOpenApprove,
    isDisabled: shouldDisableApproval,
  };
  const syncDatabaseAction: HeaderActionProps = {
    key: 'syncDatabase',
    label: t('syncDatabase.title'),
    variant: 'secondary',
    icon: <RefreshCWIcon size="sm" />,
    onClick: () => handleSyncDatabaseClick(),
    tooltip: t('syncDatabase.description'),
    isLoading: isSyncingDatabase,
    isDisabled: isSyncingDatabase,
  };

  const actions: HeaderActionProps[] = [];
  if (isCompleted) {
    if (canExportCertificate) {
      actions.push(printButton);
    }
    if (canCreateCertificateShareRequest) {
      actions.push(shareAction);
    }
  } else if (isPendingStatus && canApproveCertificateRequest) {
    actions.push(rejectAction);
    actions.push(approveAction);
  } else if (
    canSyncCertificateRequests &&
    shouldSyncDatabase &&
    !isLoadingShouldSync
  ) {
    actions.push(syncDatabaseAction);
  }

  return {
    certificateRequest,
    isLoadingCertificateRequest,
    refetchCertificateRequest,
    breadcrumbOptions,
    pageTitle,
    statusBadge,
    actions,
    pageDescription,
    isCompleted,
    isPendingStatus,
    hasFullAccess,
  };
};
