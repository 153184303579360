import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import {
  getViewers,
  getViewersFromBackoffice,
} from '../../../services/certificateRequests/getViewers';
import { useUser } from '../../../contexts/UserContext';
import { PERFORM_ACTION } from '@raizen/casl-authorization';
import { useExplorerColumns } from './useExplorerColumns';
import { useBackofficeColumns } from './useBackofficeColumns';
import { CertificateShareRequestStatusType } from '@raizen/db';

export const useViewers = (uuid: string) => {
  const { ability } = useUser();

  const [filter, setFilter] = useState('');
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState<
    CertificateShareRequestStatusType | undefined
  >(undefined);

  const [fullDateFilter, setFullDateFilter] = useState<Date | undefined>(
    undefined,
  );
  const [dateFilter, setDateFilter] = useState<number | undefined>(undefined);

  const isBackoffice = ability?.can(
    PERFORM_ACTION.Admin,
    'CertificateShareRequest',
  );

  const { data: explorerData, isLoading: explorerIsLoading } = useQuery(
    ['certificate-viewers', filter, status, dateFilter, page],
    () => getViewers(uuid, { filter, status, dateFilter, page }),
    { enabled: !isBackoffice },
  );

  const { data: backofficeData, isLoading: backofficeIsLoading } = useQuery(
    ['backoffice-certificate-viewers', filter, status, dateFilter, page],
    () => getViewersFromBackoffice(uuid, { filter, status, dateFilter, page }),
    { enabled: isBackoffice },
  );

  const handleFilterChange = (e: any) => {
    const value = e.target.value || '';
    setFilter(value);
    setPage(1);
  };

  const handleStatusFilterChange = (e: any) => {
    const value = e.target.value || undefined;
    setStatus(value);
    setPage(1);
  };

  const handleFiltersReset = () => {
    setFilter('');
    setStatus(undefined);
    setFullDateFilter(undefined);
    setPage(1);
  };

  useEffect(() => {
    if (fullDateFilter) {
      setDateFilter(fullDateFilter.getTime() / 1000);
    } else {
      setDateFilter(undefined);
    }
  }, [fullDateFilter, dateFilter]);

  const { columns: explorerColumns } = useExplorerColumns();
  const { columns: backofficeColumns } = useBackofficeColumns();

  const columns = isBackoffice ? backofficeColumns : explorerColumns;
  const data = isBackoffice ? backofficeData : explorerData;
  const isLoading = isBackoffice ? backofficeIsLoading : explorerIsLoading;

  return {
    data,
    isLoading,
    columns,
    filter,
    status,
    fullDateFilter,
    dateFilter,
    handleFilterChange,
    handleStatusFilterChange,
    handleFiltersReset,
    setFullDateFilter,
    setPage,
  };
};
