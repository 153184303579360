import { Flex } from '@chakra-ui/react';
import { SearchIcon } from '@raizen/ui';
import { components } from 'chakra-react-select';

export const CustomControl = ({ children, ...props }: any) => {
  return (
    <components.Control {...props}>
      <Flex alignItems="center" px="3" w="full">
        <SearchIcon />
        {children}
      </Flex>
    </components.Control>
  );
};
