import { CertificateDataSerializer } from '@raizen/serializers';
import { useRowsMap } from '../useRowsMap';
import { useTranslation } from '@raizen/react-hooks';
import { Accordion, Text } from '@raizen/ui';
import { Section } from './Section';
import { CertificateRequestDetailRow, List } from './List';
import { usePrintingState } from '../../../contexts/PrintingStateProvider';
import React from 'react';

interface Props {
  certificateData?: CertificateDataSerializer;
}

export const FarmInformationSection = ({ certificateData }: Props) => {
  const { t } = useTranslation();
  const rowsMap = useRowsMap();
  const { isPrinting } = usePrintingState();

  const { productionDetails } = certificateData ?? {};

  if (!productionDetails?.length) return null;

  const isFromSingleProductionPlace = productionDetails.length === 1;
  const shouldShowExpandedData = isFromSingleProductionPlace || isPrinting;

  return (
    <Section title={t('common.farmInformation')}>
      {productionDetails.map((productionDetail) => {
        const rows = [
          ...(isFromSingleProductionPlace
            ? [rowsMap.farmName(productionDetail.farmName)]
            : []),
          productionDetail?.zone ? rowsMap.zone(productionDetail.zone) : null,
          rowsMap.farmLatitude(productionDetail.farmLatitude),
          rowsMap.farmLongitude(productionDetail.farmLongitude),
          rowsMap.elo(productionDetail.elo),
          rowsMap.bonsucroCertification(productionDetail.bonsucroCertification),
          rowsMap.foodchainCertification(
            productionDetail.foodchainCertification,
          ),
          rowsMap.productTotalQuantity(
            productionDetail?.farmProductionQuantity,
            productionDetail?.productionQuantityUnit,
          ),
          rowsMap.productCertifiedQuantity(
            productionDetail?.productCertifiedQuantity,
            productionDetail?.productionQuantityUnit,
          ),
          rowsMap.isRented(productionDetail.isRented),
          rowsMap.car(productionDetail.car),
          rowsMap.deforestationFree(productionDetail.deforestationFree),
          rowsMap.useOfNativeTribesLands(
            productionDetail.useOfNativeTribesLands,
          ),
          rowsMap.useOfQuilombolaLands(productionDetail.useOfQuilombolaLands),
          rowsMap.historicalSoilCoverage(
            productionDetail.historicalSoilCoverage,
          ),
        ].filter((e) => e !== null) as CertificateRequestDetailRow[];
        return shouldShowExpandedData ? (
          <React.Fragment key={productionDetail.uuid}>
            {isPrinting ? (
              <Text>{`${t('common.farm')}: ${
                productionDetail.farmName ?? '-'
              }`}</Text>
            ) : null}
            <List rows={rows} />
            {isPrinting ? <br /> : null}
          </React.Fragment>
        ) : (
          <Accordion
            key={productionDetail.uuid}
            title={`${t('common.farm')} ${productionDetail.farmName ?? '-'}`}
          >
            <List rows={rows} />
          </Accordion>
        );
      })}
    </Section>
  );
};
