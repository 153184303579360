import { FormLabel, Grid, GridItem } from '@chakra-ui/react';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { ContextField, FormErrorMessage, FormControl } from '@raizen/ui';
import { CreateUserDto } from '../../../services/users/dtos/createUserDto';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { SubmitHandler } from 'react-hook-form';
import { createUser } from '../../../services/users/createUser';
import { useTranslation } from '@raizen/react-hooks';
import { getCompanies } from '../../../services/companies/getCompanies';
import { LocaleKeys } from '@raizen/translations';
import { Dispatch, SetStateAction } from 'react';
import { Select } from 'chakra-react-select';
import { ERROR_CODES } from '@raizen/common-utils';
import useToastsHook from '../../../hooks/useToastsHook';

interface Props {
  setIsLoadingSubmit: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
}
const resolver = classValidatorResolver(CreateUserDto);

export const NewClientUserForm = ({ setIsLoadingSubmit, onClose }: Props) => {
  const { t } = useTranslation();
  const { showSuccessToast, showErrorToast } = useToastsHook();

  const context = useForm<CreateUserDto>({ resolver });
  const {
    handleSubmit,
    formState: { errors },
    control,
    setError,
  } = context;

  const { mutateAsync } = useMutation(createUser);

  const onSubmit: SubmitHandler<CreateUserDto> = async (data) => {
    setIsLoadingSubmit(true);
    try {
      await mutateAsync(data);
      showSuccessToast({
        title: t('common.messages.success'),
        description: t('newUserForm.successToast.description'),
      });
      onClose();
    } catch (error: any) {
      // TODO: type
      const { code } = error?.response?.data;
      switch (code) {
        case ERROR_CODES.EMAIL_ALREADY_EXISTS:
          setError('email', { message: t('errorCode.EMAIL_ALREADY_EXISTS') });
          break;

        default:
          showErrorToast({
            title: t('common.messages.error'),
            description: t('newUserForm.errorToast.description'),
          });
          break;
      }
    }
    setIsLoadingSubmit(false);
  };

  const { data: companies, isLoading: isLoadingCompanies } = useQuery(
    ['companies'],
    () => {
      return getCompanies({});
      // We get all companies in the first request. We are filtering in frontend.
      // TODO: use search value to filter from backend? Pagination?
    },
  );

  const defaultOption = {
    label: t('users.newUserForm.fields.company.placeholder'),
    value: '',
  };

  const options =
    companies?.map((c) => ({ label: c.name, value: c.fiscalId })) ?? [];

  return (
    <form
      id="new-client-user-form"
      onSubmit={(ev) => {
        ev.preventDefault();
        handleSubmit(onSubmit)(ev);
      }}
    >
      <Grid gap={6} templateColumns="repeat(2, 1fr)" mt={6}>
        <ContextField
          context={context}
          name="firstName"
          label={t('users.newUserForm.fields.firstName.label')}
          placeholder={t('users.newUserForm.fields.firstName.placeholder')}
          errorMessageFormatter={t}
        />
        <ContextField
          context={context}
          name="lastName"
          label={t('users.newUserForm.fields.lastName.label')}
          placeholder={t('users.newUserForm.fields.lastName.placeholder')}
          errorMessageFormatter={t}
        />
        <GridItem colSpan={2}>
          <ContextField
            context={context}
            name="email"
            label={t('users.newUserForm.fields.email.label')}
            placeholder={t('users.newUserForm.fields.email.placeholder')}
            type="email"
            errorMessageFormatter={t}
          />
        </GridItem>
        <GridItem colSpan={2}>
          <FormControl isInvalid={Boolean(errors?.companyFiscalId)}>
            <FormLabel htmlFor="companyFiscalId">
              {t('users.newUserForm.fields.company.label')}
            </FormLabel>
            <Controller
              control={control}
              defaultValue={defaultOption.value}
              name="companyFiscalId"
              render={({ field: { onChange, value, name, ref } }) => (
                <Select
                  ref={ref}
                  name={name}
                  options={options}
                  value={options.find((c) => c.value === value)}
                  placeholder={defaultOption.label}
                  onChange={(val) => onChange(val?.value)}
                  selectedOptionStyle="check"
                  isInvalid={Boolean(errors?.companyFiscalId)}
                  isClearable={true}
                  isLoading={isLoadingCompanies}
                />
              )}
            />
            <FormErrorMessage>
              {t(errors?.companyFiscalId?.message as LocaleKeys)}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>
    </form>
  );
};
