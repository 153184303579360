import { CERTIFICATE_REQUEST_STATUS, ValueOf } from '@raizen/db';
import { useTranslation } from '@raizen/react-hooks';
import { CertificateRequestSerializer } from '@raizen/serializers';
import { useUser } from '../../../contexts/UserContext';
import { certificateRequestsAlertVariant } from '@raizen/frontend-utils';

interface Response {
  showInvoiceDuplicatedAlert: boolean;
  invoiceDuplicatedVariant: ValueOf<typeof certificateRequestsAlertVariant>;
  invoiceDuplicatedTitle: string;
  invoiceDuplicatedDescription: string;
}

export const useInvoiceDuplicatedAlert = (
  certificateRequest: CertificateRequestSerializer,
) => {
  const { t } = useTranslation();

  const { canManageCertificateRequests } = useUser();

  const { accessKey } = certificateRequest;

  const isPendingStatus =
    certificateRequest.status === CERTIFICATE_REQUEST_STATUS.PENDING;

  const isInvoiceDuplicated = certificateRequest?.isAccessKeyDuplicated;

  const shouldShowInvoiceDuplicatedWarn =
    canManageCertificateRequests && isInvoiceDuplicated && isPendingStatus;

  const values: Response = {
    showInvoiceDuplicatedAlert: shouldShowInvoiceDuplicatedWarn,
    invoiceDuplicatedVariant: 'warning',
    invoiceDuplicatedTitle: t(
      'certificateRequestDetails.alert.accessKeyDuplicated.title',
    ).replace('{{accessKey}}', accessKey || ''),
    invoiceDuplicatedDescription: t(
      'certificateRequestDetails.alert.accessKeyDuplicated.description',
    ),
  };
  return values;
};
