import {
  Divider,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { useUser } from '../../../contexts/UserContext';
import { useState } from 'react';
import { CurrentUserInfo } from './CurrentUserInfo';
import { userMenuOption } from './useUserMenuOptions';

interface Props {
  userMenuOptions: userMenuOption[];
}

export const DropdownMenu = ({ userMenuOptions }: Props) => {
  const { user } = useUser();
  const [isOpen, setIsOpen] = useState(false);

  const userInitials = (user?.firstName[0] ?? '') + (user?.lastName[0] ?? '');

  return (
    <Menu isOpen={isOpen}>
      <MenuButton
        onClick={() => setIsOpen(!isOpen)}
        color="primary.800"
        fontSize="sm"
        fontFamily="Larsseit-Medium"
        bg="primary.50"
        rounded="full"
        w="12"
        h="12"
        border="6px"
        borderColor="white"
        borderStyle="solid"
        alignItems="center"
        _hover={{
          bg: 'primary.100',
        }}
        _active={{
          border: '6px',
          borderColor: 'primary.50',
          borderStyle: 'solid',
        }}
      >
        {userInitials}
      </MenuButton>
      {isOpen ? (
        <MenuList
          boxShadow={
            '0px 0px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05)'
          }
          borderRadius="md"
          py={0}
          overflow="auto"
        >
          <CurrentUserInfo
            firstName={user?.firstName}
            lastName={user?.lastName}
            email={user?.email}
            companyName={user?.companyName}
          />
          <Divider />
          <Flex direction="column">
            {userMenuOptions.map((item, index) => (
              <MenuItem
                fontSize="sm"
                key={item.id + index}
                onClick={item.onClick}
                px={3}
                py={2}
                _focus={{ bg: 'white' }}
                _hover={{ bg: 'gray.50' }}
              >
                {item.icon}
                {item.label}
              </MenuItem>
            ))}
          </Flex>
        </MenuList>
      ) : null}
    </Menu>
  );
};
