import { IconButton, Tooltip } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@raizen/ui';

interface Props {
  text: string;
  url: string;
  target?: '_blank' | '_self';
}

export const ExternalLinkButton = ({ text, url, target = '_blank' }: Props) => {
  return (
    <Tooltip label={text}>
      <IconButton
        aria-label="copy to clipboard"
        variant="link"
        onClick={() => {
          window.open(url, '_blank');
        }}
        minW={'fit-content'}
      >
        <ExternalLinkIcon width={18} height={18} color="#667085" />
      </IconButton>
    </Tooltip>
  );
};
