import { NewClientUserForm } from './NewClientUserForm';
import { useState } from 'react';
import { useTranslation } from '@raizen/react-hooks';
import { Modal, Button } from '@raizen/ui';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const NewClientUserModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t('users.newUserModal.title')}
      text={t('users.newUserModal.description')}
      body={
        <NewClientUserForm
          setIsLoadingSubmit={setIsLoading}
          onClose={onClose}
        />
      }
      confirmButton={
        <Button
          variant="primary"
          type="submit"
          form="new-client-user-form"
          isLoading={isLoading}
        >
          {t('users.newUserModal.confirmButton')}
        </Button>
      }
      cancelButton={
        <Button variant="secondary" onClick={onClose} disabled={isLoading}>
          {t('users.newUserModal.cancelButton')}
        </Button>
      }
    />
  );
};
