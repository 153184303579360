import { httpClient } from '@raizen/client-auth';
import { GetCertificateShareRequestsDto } from '../types/queryParams';
import { GetCertificateShareRequestsResponse } from '@raizen/types';

export const getCertificateShareRequests = async (
  params: GetCertificateShareRequestsDto,
) => {
  const { data } = await httpClient.get<GetCertificateShareRequestsResponse>(
    '/certificate-share-requests',
    { params },
  );
  return data;
};
