import { useUser } from '../contexts/UserContext';
import { ReactNode } from 'react';
import { NotFound } from '../Navigation/pages/NotFound';
import { AbilitySubjects, ActionType } from '@raizen/casl-authorization';

interface Props {
  children: ReactNode;
  action: ActionType;
  subject: AbilitySubjects;
}

export const RestrictedRoute = ({ children, action, subject }: Props) => {
  const { ability } = useUser();
  const hasPermission = ability?.can(action, subject);

  if (!hasPermission) return <NotFound />;
  return <>{children}</>;
};
