import { Flex } from '@chakra-ui/react';
import { User, UserStatus } from '@raizen/db';
import { useTranslation } from '@raizen/react-hooks';
import { useMemo } from 'react';
import { CellProps } from 'react-table';
import { FiTrash2 } from 'react-icons/fi';
import { Badge } from '@raizen/ui';
import { userStatusBadgeColors } from '@raizen/frontend-utils';
import { TableActions } from '../TableActions';
import { TableActionsButton } from '../TableActionsButton';
import { useUser } from '../../../contexts/UserContext';

export const useColumns = (onClickDelete: (user: User) => void) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const columns = useMemo(
    () => [
      {
        Header: t('admins.table.columns.name'),
        accessor: 'firstName',
        Cell: (props: CellProps<User>) => {
          const { firstName, lastName } = props.row.original;
          const name = `${firstName} ${lastName}`;
          return <>{name}</>;
        },
      },
      {
        Header: t('admins.table.columns.email'),
        accessor: 'email',
      },
      {
        Header: t('admins.table.columns.status'),
        accessor: 'status',
        Cell: (props: CellProps<User>) => {
          const status: UserStatus = props.row.original.status;
          return (
            <Badge colorScheme={userStatusBadgeColors[status]}>
              {t(`users.status.${status}`)}
            </Badge>
          );
        },
      },
      {
        id: 'actions',
        Header: (
          <Flex
            w="full"
            justifyContent={{ base: 'start', lg: 'end' }}
            data-header-text={t('admins.table.columns.actions')}
          >
            {t('admins.table.columns.actions')}
          </Flex>
        ),
        Cell: (props: CellProps<User>) => (
          <TableActions>
            {props.row.original.uuid !== user?.uuid && (
              <TableActionsButton
                ariaLabel={t('table.actions.deleteUser')}
                icon={<FiTrash2 />}
                onClick={() => onClickDelete(props.row.original)}
                tooltip={t('common.messages.delete')}
              />
            )}
          </TableActions>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user],
  );
  return { columns };
};
