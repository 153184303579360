import { Grid, GridItem } from '@chakra-ui/react';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { ContextField } from '@raizen/ui';
import { CreateUserDto } from '../../../services/users/dtos/createUserDto';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { SubmitHandler } from 'react-hook-form';
import { useTranslation } from '@raizen/react-hooks';
import { createAdmin } from '../../../services/users/createAdmin';
import { CreateAdminDto } from '../../../services/users/dtos/createAdminDto';
import { Dispatch, SetStateAction } from 'react';
import { ERROR_CODES } from '@raizen/common-utils';
import useToastsHook from '../../../hooks/useToastsHook';

interface Props {
  setIsLoadingSubmit: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
}
const resolver = classValidatorResolver(CreateAdminDto);

export const NewAdminForm = ({ setIsLoadingSubmit, onClose }: Props) => {
  const { t } = useTranslation();
  const { showSuccessToast, showErrorToast } = useToastsHook();

  const context = useForm<CreateUserDto>({ resolver });
  const { handleSubmit, setError } = context;

  const { mutateAsync } = useMutation(createAdmin);

  const onSubmit: SubmitHandler<CreateUserDto> = async (data) => {
    setIsLoadingSubmit(true);
    try {
      await mutateAsync(data);
      showSuccessToast({
        title: t('common.messages.success'),
        description: t('newAdminForm.successToast.description'),
      });
      onClose();
    } catch (error: any) {
      // TODO: type
      const { code } = error?.response?.data;
      switch (code) {
        case ERROR_CODES.EMAIL_ALREADY_EXISTS:
          setError('email', { message: t('errorCode.EMAIL_ALREADY_EXISTS') });
          break;

        default:
          showErrorToast({
            title: t('common.messages.error'),
            description: t('newAdminForm.errorToast.description'),
          });
          break;
      }
    }
    setIsLoadingSubmit(false);
  };

  return (
    <form
      id="new-admin-form"
      onSubmit={(ev) => {
        ev.preventDefault();
        handleSubmit(onSubmit)(ev);
      }}
    >
      <Grid gap={6} templateColumns="repeat(2, 1fr)" mt={6}>
        <ContextField
          context={context}
          name="firstName"
          label={t('users.newUserForm.fields.firstName.label')}
          placeholder={t('users.newUserForm.fields.firstName.placeholder')}
          errorMessageFormatter={t}
        />
        <ContextField
          context={context}
          name="lastName"
          label={t('users.newUserForm.fields.lastName.label')}
          placeholder={t('users.newUserForm.fields.lastName.placeholder')}
          errorMessageFormatter={t}
        />
        <GridItem colSpan={2}>
          <ContextField
            context={context}
            name="email"
            label={t('users.newUserForm.fields.email.label')}
            placeholder={t('users.newUserForm.fields.email.placeholder')}
            type="email"
            errorMessageFormatter={t}
          />
        </GridItem>
      </Grid>
    </form>
  );
};
