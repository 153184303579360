import { httpClient } from '@raizen/client-auth';
import { CertificateRequestSerializer } from '@raizen/serializers';

export const getCertificateRequest = async (uuid: string) => {
  const { data } = await httpClient.get<CertificateRequestSerializer>(
    `/certificate-requests/${uuid}`,
  );

  return data;
};
