export const paths = {
  home: '/',
  users: '/users',
  admins: '/admins',
  certificates: '/certificates',
  newCertificate: '/certificates/new',
  certificateRequestDetails: '/certificates/:uuid',
  stockReserves: '/stock-reserves',
  sampleFormWithValidations: '/sample-form',
  loginCallback: '/auth/callback',
  logoutCallback: '/auth/logout/',
  sharingRequests: '/sharing-requests',
} as const;
