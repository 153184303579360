import { Button, Flex, useToast } from '@chakra-ui/react';
import { AxiosError } from '@raizen/client-auth';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useMutation } from 'react-query';
import { confirmCertRequest } from '../../../services/certificateRequests/confirmCertificateRequest';
import { useTranslation } from '@raizen/react-hooks';

interface CertificateRequestActionsProps {
  setActualStep: Dispatch<SetStateAction<number>>;
  requestUuid: string | undefined;
}

export const CertificateRequestActions = ({
  setActualStep,
  requestUuid,
}: CertificateRequestActionsProps) => {
  const toast = useToast();
  const { t } = useTranslation();
  const { data, isLoading, isIdle, isSuccess, isError, mutate, ...rest } =
    useMutation(confirmCertRequest);

  const error = rest.error as AxiosError<{ message: string }>; // TODO: check for error messages using useMutation hook.

  const handleConfirmation = () => {
    if (requestUuid) {
      mutate(requestUuid);
    }
  };

  const handleGoBack = () => {
    setActualStep(() => 0);
  };

  useEffect(() => {
    if (isSuccess && data) {
      setActualStep(() => 4);
    }
  }, [data, isSuccess, setActualStep]);

  useEffect(() => {
    if (isError) {
      toast({
        status: 'error',
        title: 'Error:',
        description: error?.message ?? 'Server error',
        duration: 3000,
        isClosable: true,
        position: 'bottom',
      });
    }
  }, [error?.message, isError, toast]);

  return (
    <Flex flexDir={'row'} gap={4}>
      <Button
        size="md"
        variant="secondary"
        w="fit-content"
        onClick={() => {
          handleGoBack();
        }}
      >
        {t('certificateRequestCreation.certificatePreview.goBack')}
      </Button>

      <Button
        size="md"
        variant="primary"
        w="fit-content"
        isLoading={isLoading}
        onClick={() => handleConfirmation()}
      >
        {t('certificateRequestCreation.certificatePreview.confirm')}
      </Button>
    </Flex>
  );
};
