import { useMediaQuery } from '@chakra-ui/react';
import { Flex } from '@chakra-ui/react';
import { PolygonIcon } from '@raizen/ui';
import { CopyToClipboardButton } from '../shared/CopyToClipboardButton';
import { TextTruncate } from '../shared/TextTruncate';
import { usePrintingState } from '../../contexts/PrintingStateProvider';
import { ExternalLinkButton } from '../shared/ExternalLinkButton';
import { useTranslation } from '@raizen/react-hooks';

interface Props {
  root: string;
}

export const HashRow = ({ root }: Props) => {
  const { t } = useTranslation();
  const { isPrinting } = usePrintingState();
  const [isLargerThan600] = useMediaQuery('(min-width: 600px)');
  const [isLargerThan1200] = useMediaQuery('(min-width: 1200px)');

  const truncateSize = isLargerThan1200 ? 36 : isLargerThan600 ? 22 : 6;

  const blockchainScanBaseUrl =
    process.env.REACT_APP_BLOCKCHAIN_SCAN_BASE_URL ?? '';
  const contractAddress =
    process.env.REACT_APP_BLOCKCHAIN_CONTRACT_ADDRESS ?? '';

  const intRoot = BigInt(root);

  const explorerUrl =
    blockchainScanBaseUrl + 'nft/' + contractAddress + '/' + intRoot;

  const shouldShowIcons = !isPrinting;
  if (root !== '') {
    return (
      <Flex gap={2} alignItems={'center'} float="left" position="relative">
        {shouldShowIcons ? <PolygonIcon /> : null}
        {isPrinting ? root : <TextTruncate max={truncateSize} text={root} />}
        {shouldShowIcons ? (
          <Flex>
            <CopyToClipboardButton text={root} />
            <Flex ml={2}>
              <ExternalLinkButton
                text={t('certificateRequestDetails.viewInBlockchainButton')}
                url={explorerUrl}
              />
            </Flex>
          </Flex>
        ) : null}
      </Flex>
    );
  } else {
    return <>-</>;
  }
};
