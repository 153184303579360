import { Flex, useMediaQuery } from '@chakra-ui/react';
import { CopyToClipboardButton } from '../shared/CopyToClipboardButton';
import { TextTruncate } from '../shared/TextTruncate';
import { usePrintingState } from '../../contexts/PrintingStateProvider';

interface Props {
  text: string;
}

export const AccessKeyRow = ({ text }: Props) => {
  const { isPrinting } = usePrintingState();

  const [isLargerThan600] = useMediaQuery('(min-width: 600px)');
  const [isLargerThan1200] = useMediaQuery('(min-width: 1200px)');

  const truncateSize = isLargerThan1200 ? 36 : isLargerThan600 ? 22 : 6;

  const shouldShowIcons = !isPrinting;

  const accessKeyToShowInvoice =
    process.env.REACT_APP_ACCESS_KEY_TO_SHOW_INVOICE;

  const shouldShowInvoiceHyperlink = String(accessKeyToShowInvoice) === text;

  if (text !== '') {
    return (
      <Flex gap={2} alignItems={'center'} float="left" position="relative">
        {shouldShowInvoiceHyperlink ? (
          <a
            href="/pdf/35221108070508006532550010003942142443551224.pdf"
            target="_blank"
            style={{ textDecoration: 'underline' }}
          >
            <>
              #
              {isPrinting ? (
                text
              ) : (
                <TextTruncate text={text} max={truncateSize} />
              )}
            </>
          </a>
        ) : (
          <>
            #
            {isPrinting ? (
              text
            ) : (
              <TextTruncate text={text} max={truncateSize} />
            )}
          </>
        )}

        {shouldShowIcons ? <CopyToClipboardButton text={text} /> : null}
      </Flex>
    );
  } else {
    return <>-</>;
  }
};
