import { Box, Flex, Spacer, Tooltip } from '@chakra-ui/react';
import { FeaturedIcons, Text, HintIcon } from '@raizen/ui';
import React, { FC } from 'react';

export interface PreviewDetailRowProps {
  icon: FC<object>;
  label: string | undefined;
  value: string | JSX.Element | undefined;
  tooltip?: string;
}
export const PreviewDetailRow = ({
  icon,
  label,
  value,
  tooltip,
}: PreviewDetailRowProps) => {
  return (
    <Flex flexDir="row" alignItems="center" width={'100%'}>
      {icon ? (
        <FeaturedIcons shape="circle" variant="primary" size="sm" icon={icon} />
      ) : null}
      <Text size="md" variant="medium" ml={2} align={'start'}>
        {label}:
      </Text>
      {!!tooltip && (
        <Tooltip label={tooltip} hasArrow={true} whiteSpace="break-spaces">
          <Box ml={1}>
            <HintIcon />
          </Box>
        </Tooltip>
      )}
      <Spacer />
      <Text as="div" size="md" variant="regular" align={'end'}>
        {value}
      </Text>
    </Flex>
  );
};
