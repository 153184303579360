import { useTranslation } from '@raizen/react-hooks';
import { useMemo } from 'react';
import { CellProps } from 'react-table';
import { Badge } from '@raizen/ui';
import { formatDate } from '@raizen/common-utils';
import { LocaleKeys } from '@raizen/translations';
import { certificateShareRequestsStatusBadgeColors } from '@raizen/frontend-utils';
import { CertificateShareRequestStatusType } from '@raizen/db';
import { GetCertificateViewersBackofficeSerializer } from '@raizen/serializers';

export const useBackofficeColumns = () => {
  const { t } = useTranslation();

  const columns = useMemo(
    () => [
      {
        Header: t('viewersTable.columns.companyName'),
        accessor: 'companyName',
        Cell: (props: CellProps<GetCertificateViewersBackofficeSerializer>) => {
          const { companyName } = props.row.original;
          return companyName ?? '-';
        },
      },
      {
        Header: t('viewersTable.columns.requestedAt'),
        accessor: 'requestedAt',
        Cell: (props: CellProps<GetCertificateViewersBackofficeSerializer>) => {
          const { requestedAt } = props.row.original;
          return requestedAt ? formatDate(requestedAt) : '-';
        },
      },
      {
        Header: t('viewersTable.columns.accessType'),
        accessor: 'accessType',
        Cell: (props: CellProps<GetCertificateViewersBackofficeSerializer>) => {
          const { accessType } = props.row.original;
          return accessType
            ? t(`viewersTable.accessType.${accessType}` as LocaleKeys)
            : '-';
        },
      },
      {
        Header: t('admins.table.columns.status'),
        accessor: 'status',
        Cell: (props: CellProps<GetCertificateViewersBackofficeSerializer>) => {
          const status: CertificateShareRequestStatusType =
            props.row.original.status;
          return (
            <Badge
              colorScheme={certificateShareRequestsStatusBadgeColors[status]}
            >
              {t(`viewersTable.status.${status}` as LocaleKeys)}
            </Badge>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return { columns };
};
