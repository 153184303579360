import { useTranslation } from '@raizen/react-hooks';
import { useUser } from '../../../contexts/UserContext';
import { NavigationLink } from '../../../Navigation/NavigationLink';
import { PERFORM_ACTION } from '@raizen/casl-authorization';
import { paths } from '../../../routes/consts/paths';
import { USER_ROLES, User } from '@raizen/db';
import {
  PendingCertificateRequestsResponseType,
  PendingCertificateShareRequestsResponseType,
} from '@raizen/types';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { getPendingCertificateRequests } from '../../../services/certificateRequests/getPendingCertificateRequests';
import { getPendingCertificateShareRequests } from '../../../services/certificateRequests/getPendingCertificateShareRequests';

export const useNavbarLinks = () => {
  const { t } = useTranslation();
  const { ability, canManageCertificateRequests } = useUser();
  const adminUser = new User();
  adminUser.role = USER_ROLES.ADMIN;
  const [currentNavigationIndex, setCurrentNavigationIndex] = useState(0);

  // TODO: Uncomment this when websockets are available
  // const { count: pendingCertificatesCounter } =
  //   useWebSocket<PendingCertificateRequestsResponseType>(
  //     WEB_SOCKET_EVENTS.PENDING_CERTIFICATE_REQUESTS_COUNT,
  //     user?.role,
  //   ) ?? {};
  // const { count: pendingCertificateShareRequestsCounter } =
  //   useWebSocket<PendingCertificateShareRequestsResponseType>(
  //     WEB_SOCKET_EVENTS.PENDING_CERTIFICATE_SHARE_REQUESTS_COUNT,
  //     user?.role,
  //   ) ?? {};

  const canAdminCertificateRequest = ability?.can(
    PERFORM_ACTION.Admin,
    'CertificateRequest',
  );

  const canListCertificateShareRequest = ability?.can(
    PERFORM_ACTION.Admin,
    'CertificateShareRequest',
  );
  const canListUser = ability?.can(PERFORM_ACTION.List, 'User');
  const canListAdminUser = ability?.can(PERFORM_ACTION.List, adminUser);

  const { data: pendingCertificatesCounterData } =
    useQuery<PendingCertificateRequestsResponseType>(
      ['pending-certificate-requests'],
      () => getPendingCertificateRequests(),
      { refetchInterval: 5000, enabled: canAdminCertificateRequest },
    );

  const { count: pendingCertificatesCounter } =
    pendingCertificatesCounterData ?? {};

  const { data: pendingCertificateShareRequestsCounterData } =
    useQuery<PendingCertificateShareRequestsResponseType>(
      ['pending-certificate-share-requests'],
      () => getPendingCertificateShareRequests(),
      { refetchInterval: 5000, enabled: canListCertificateShareRequest },
    );

  const { count: pendingCertificateShareRequestsCounter } =
    pendingCertificateShareRequestsCounterData ?? {};

  const navigationLinksProps = [
    {
      label: canManageCertificateRequests
        ? t('common.certificateRequests')
        : t('common.certificates'),
      path: paths.home,
      counter:
        pendingCertificatesCounter && pendingCertificatesCounter > 0
          ? pendingCertificatesCounter > 99
            ? '99+'
            : pendingCertificatesCounter.toString()
          : '',
    },
    canListCertificateShareRequest
      ? {
          label: t('common.sharingRequests'),
          path: paths.sharingRequests,
          counter:
            pendingCertificateShareRequestsCounter &&
            pendingCertificateShareRequestsCounter > 0
              ? pendingCertificateShareRequestsCounter > 99
                ? '99+'
                : pendingCertificateShareRequestsCounter.toString()
              : '',
        }
      : null,
    canListUser
      ? { label: t('common.usersManagement'), path: paths.users }
      : null,
    canListAdminUser
      ? {
          label: t('common.adminsManagement'),
          path: paths.admins,
        }
      : null,
  ].filter(Boolean);

  const navigationLinks = navigationLinksProps.map((props, index) => (
    <NavigationLink
      key={props?.label || '' + props?.path + ''}
      label={props?.label || ''}
      path={props?.path || ''}
      counter={props?.counter || ''}
      setActive={() => setCurrentNavigationIndex(index)}
    />
  ));

  return { navigationLinks, currentNavigationIndex };
};
