import { Button, Center, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from '@raizen/react-hooks';
import { paths } from '../../routes/consts/paths';
import { useNavigate } from 'react-router-dom';

export const NotFound = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Center w="full" px="8">
      <Flex w="full" maxW={'1280px'} flexDir={'column'} py="8" gap="4">
        <Text>{t('common.errors.pageNotFound')}</Text>
        <Button variant="primary" w="min" onClick={() => navigate(paths.home)}>
          {t('common.goHome')}
        </Button>
      </Flex>
    </Center>
  );
};
