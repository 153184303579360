import {
  CertificateRequestDetailsListSection,
  CertificateRequestDetailsListSectionProps,
} from './CertificateRequestDetailsListSection';
import { Box, Flex } from '@chakra-ui/react';

interface CertificateRequestDetailsListProps {
  sections: CertificateRequestDetailsSection[];
}

export interface CertificateRequestDetailsSection {
  id: string;
  data: CertificateRequestDetailsListSectionProps;
}

export const CertificateRequestDetailsList = ({
  sections,
}: CertificateRequestDetailsListProps) => {
  return (
    <Flex flexDir={'column'} gap={12}>
      {sections.map(({ id, data }) => (
        <Box
          key={id}
          id={id}
          className={
            id === 'blockchainInformationSection'
              ? 'page-break-after-this-fro-print-view'
              : ''
          }
        >
          <CertificateRequestDetailsListSection
            title={data.title}
            rows={data.rows}
          />
        </Box>
      ))}
    </Flex>
  );
};
