import { Flex } from '@chakra-ui/react';
import { Text, Heading, Card, Alert } from '@raizen/ui';

import { useTranslation } from '@raizen/react-hooks';
import { CertificateRequestDetailsList } from '../CertificateRequestDetails/CertificateRequestDetailsList';
import { useCertificatePreview } from './useCertificatePreview';
import { CertificateRequestSerializer } from '@raizen/serializers';

interface CertificateProps {
  invoiceAccessKey: string | undefined;
  data: CertificateRequestSerializer | undefined;
  hasIssuedCertificate: boolean;
}

export const CertificatePreview = ({
  invoiceAccessKey,
  data,
  hasIssuedCertificate,
}: CertificateProps) => {
  const { t } = useTranslation();
  const sections = useCertificatePreview(data);
  return (
    <Flex flexDir={'column'} mb={4}>
      <Heading size={'xs'} mt={6}>
        {t('certificateRequestCreation.certificatePreview.heading')}
      </Heading>

      <Text size={'md'}>
        {t('certificateRequestCreation.certificatePreview.subHeading')}
      </Text>

      {!data?.certificateData ||
        (data?.certificateData.length === 0 && (
          <Alert
            variant={'warning'}
            title={t(
              'certificateRequestCreation.certificatePreview.noData.heading',
            ).replace('{{accessKey}}', invoiceAccessKey ?? '')}
            description={t(
              'certificateRequestCreation.certificatePreview.noData.subHeading',
            )}
            showCloseButton={false}
            my={4}
          />
        ))}

      {hasIssuedCertificate && (
        <Alert
          variant={'warning'}
          title={t(
            'certificateRequestCreation.certificatePreview.certificateIssued.heading',
          ).replace('{{accessKey}}', invoiceAccessKey ?? '')}
          description={t(
            'certificateRequestCreation.certificatePreview.noData.subHeading',
          )}
          showCloseButton={false}
          my={4}
        />
      )}

      {sections && (
        <Card p={4} mt={4}>
          <CertificateRequestDetailsList sections={sections} />
        </Card>
      )}
    </Flex>
  );
};
