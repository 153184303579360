import { Flex } from '@chakra-ui/react';
import { formatDate } from '@raizen/common-utils';
import { User, UserStatus } from '@raizen/db';
import { useTranslation } from '@raizen/react-hooks';
import { useMemo } from 'react';
import { CellProps } from 'react-table';
import { FiTrash2 } from 'react-icons/fi';
import { Badge } from '@raizen/ui';
import { userStatusBadgeColors } from '@raizen/frontend-utils';
import { TableActions } from '../TableActions';
import { TableActionsButton } from '../TableActionsButton';

export const useColumns = (onClickDelete: (user: User) => void) => {
  const { t } = useTranslation();

  const columns = useMemo(
    () => [
      {
        Header: t('users.table.columns.company'),
        accessor: 'company.name',
      },
      {
        Header: t('users.table.columns.role'),
        accessor: 'role',
        Cell: (props: CellProps<User>) => {
          const role = props.row.original.role;
          return role ? t(`users.role.${role}`) : '-';
        },
      },
      {
        Header: t('users.table.columns.email'),
        accessor: 'email',
      },
      {
        Header: t('users.table.columns.lastLogin'),
        accessor: 'lastLogin',
        Cell: (props: CellProps<User>) => {
          const lastLogin = Number(props.row.original.lastLogin);
          return lastLogin ? formatDate(lastLogin) : '-';
        },
      },
      {
        Header: t('users.table.columns.status'),
        accessor: 'status',
        Cell: (props: CellProps<User>) => {
          const status: UserStatus = props.row.original.status;
          return (
            <Badge colorScheme={userStatusBadgeColors[status]}>
              {t(`users.status.${status}`)}
            </Badge>
          );
        },
      },
      {
        id: 'actions',
        Header: (
          <Flex
            w="full"
            justifyContent={{ base: 'start', lg: 'end' }}
            data-header-text={t('users.table.columns.actions')}
          >
            {t('users.table.columns.actions')}
          </Flex>
        ),
        Cell: (props: CellProps<User>) => (
          <TableActions>
            <TableActionsButton
              ariaLabel={t('table.actions.deleteUser')}
              icon={<FiTrash2 />}
              onClick={() => onClickDelete(props.row.original)}
              tooltip={t('common.messages.delete')}
            />
          </TableActions>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return { columns };
};
