import {
  Center,
  Flex,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { RefreshCWIcon, Text } from '@raizen/ui';
import { CertificatesTable } from '../../components/Home/CertificatesTable';
import { useTranslation } from '@raizen/react-hooks';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../routes/consts/paths';
import { useUser } from '../../contexts/UserContext';
import { FiPlus } from 'react-icons/fi';
import { PERFORM_ACTION } from '@raizen/casl-authorization';
import { Header } from '../../components/Header/Header';
import { NotRequestedCertificatesTable } from '../../components/Home/NotRequestedCertificatesTable';
import { Tab } from '../../components/CertificateRequestDetails/Tab';
import { useQuery } from 'react-query';
import { shouldSyncCertificateRequests } from '../../services/shouldSyncCertificateRequests/shouldSyncCertificateRequests';
import { useMemo, useState } from 'react';
import { syncCertificateRequests } from '../../services/shouldSyncCertificateRequests/syncCertificateRequests';
import useToastsHook from '../../hooks/useToastsHook';
import { useCertificatesTableContext } from '../../components/Home/CertificatesTable/CertificatesTableContext';

export const Home = () => {
  const { user, ability } = useUser();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { showErrorToast, showSuccessToast } = useToastsHook();

  const canListNotRequestedCertificateRequestData = ability?.can(
    PERFORM_ACTION.List,
    'RaizenMetadata',
  );

  const canCreateCertificateRequest = ability?.can(
    PERFORM_ACTION.Create,
    'CertificateRequest',
  );

  const canListShared = ability?.can(
    PERFORM_ACTION.ListOwnership.Shared,
    'CertificateRequest',
  );

  const canSyncCertificateRequests = ability?.can(
    PERFORM_ACTION.Sync,
    'CertificateRequest',
  );

  const tableTitle = canListShared
    ? t('home.table.title.shared')
    : t('home.table.title');
  const pageSubtitle = canListShared
    ? t('home.subtitle.shared')
    : t('home.subtitle');

  const pageTitle = t('home.title').replace(
    '{{name}}',
    `${user?.firstName} ${user?.lastName}` || '',
  );

  const { refetchCertificateRequests } = useCertificatesTableContext();

  const {
    data: shouldSyncDatabase,
    isLoading: isLoadingShouldSync,
    refetch: refetchShouldSync,
  } = useQuery(
    ['shouldSyncDatabase', canSyncCertificateRequests],
    async () => {
      return shouldSyncCertificateRequests();
    },
    { enabled: canSyncCertificateRequests },
  );

  const [isSyncingDatabase, setIsSyncingDatabase] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSyncDatabaseClick = async () => {
    setIsSyncingDatabase(true);
    syncCertificateRequests()
      .then(() => {
        showSuccessToast({
          title: '',
          description: t('syncDatabase.success'),
        });
        refetchShouldSync();
        refetchCertificateRequests();
      })
      .catch(() => {
        showErrorToast({
          title: '',
          description: t('syncDatabase.error'),
        });
      })
      .finally(() => setIsSyncingDatabase(false));
  };

  const actions = useMemo(() => {
    const actions = [];
    if (canCreateCertificateRequest) {
      actions?.push({
        key: 'requestNewCertificate',
        label: t('home.requestNewCertificateButton.label'),
        variant: 'primary',
        icon: <FiPlus />,
        onClick: () => navigate(paths.newCertificate),
      });
    }

    if (
      canSyncCertificateRequests &&
      shouldSyncDatabase &&
      !isLoadingShouldSync
    ) {
      actions?.push({
        key: 'syncDatabase',
        label: t('syncDatabase.title'),
        variant: 'secondary',
        icon: <RefreshCWIcon size="sm" />,
        onClick: () => handleSyncDatabaseClick(),
        tooltip: t('syncDatabase.description'),
        isLoading: isSyncingDatabase,
        isDisabled: isSyncingDatabase,
      });
    }

    return actions;
  }, [
    canSyncCertificateRequests,
    canCreateCertificateRequest,
    handleSyncDatabaseClick,
    isLoadingShouldSync,
    isSyncingDatabase,
    navigate,
    shouldSyncDatabase,
    t,
  ]);

  return (
    <Center w="full" px={8}>
      <Flex w="full" maxW={'1280px'} flexDir={'column'} py="8" gap={4}>
        <Header
          title={pageTitle}
          description={pageSubtitle}
          actions={actions}
        />

        <Flex flexDir="column" gap={3}>
          <Text size={'md'} variant="medium">
            {tableTitle}
          </Text>

          {canListNotRequestedCertificateRequestData ? (
            <Tabs>
              <TabList gap={{ md: 4, lg: 8 }} overflowX={'auto'}>
                <Tab>{t('certificateRequests.table.requestedTab')}</Tab>
                <Tab>{t('certificateRequests.table.notRequestedTab')}</Tab>
              </TabList>
              <TabPanels py="6">
                <TabPanel px="0">
                  <CertificatesTable />
                </TabPanel>
                <TabPanel px="0">
                  <NotRequestedCertificatesTable />
                </TabPanel>
              </TabPanels>
            </Tabs>
          ) : (
            <CertificatesTable />
          )}
        </Flex>
      </Flex>
    </Center>
  );
};
