import { FiPlus } from 'react-icons/fi';
import { useTranslation } from '@raizen/react-hooks';
import { UsersTable } from '../../components/Users/UsersTable';
import { HeaderActionProps } from '../../components/Header/HeaderActions';
import { PageContainer } from '../../components/PageContainer/PageContainer';
import { Header } from '../../components/Header/Header';
import { useDisclosure } from '@chakra-ui/react';
import { NewClientUserModal } from '../../components/Users/NewClientUser/NewClientUserModal';

export const Users = () => {
  const { t } = useTranslation();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const actions: HeaderActionProps[] = [
    {
      key: 'inviteNewUser',
      label: t('users.inviteNewUser'),
      icon: <FiPlus />,
      variant: 'primary',
      onClick: onOpen,
    },
  ];

  return (
    <PageContainer>
      <Header title={t('users.title')} actions={actions} />
      <UsersTable />
      <NewClientUserModal isOpen={isOpen} onClose={onClose} />
    </PageContainer>
  );
};
