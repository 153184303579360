"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};

// src/index.ts
var src_exports = {};
__export(src_exports, {
  AccessKey: () => AccessKey,
  CertificateRequestsSortBy: () => CertificateRequestsSortBy,
  CertificateShareRequestsSortBy: () => CertificateShareRequestsSortBy,
  CertificateViewersSortBy: () => CertificateViewersSortBy,
  SortOrder: () => SortOrder,
  TRANSACTION_STATUS: () => TRANSACTION_STATUS,
  User: () => User,
  getApproveCertificateShareRequestDto: () => getApproveCertificateShareRequestDto,
  getCertificateRequestCreationTransactionResponseDto: () => getCertificateRequestCreationTransactionResponseDto,
  getCompaniesDto: () => getCompaniesDto,
  getConfirmCertificateRequestDto: () => getConfirmCertificateRequestDto,
  getCreateAdminDto: () => getCreateAdminDto,
  getCreateCertificateRequestDto: () => getCreateCertificateRequestDto,
  getCreateCertificateShareRequestDto: () => getCreateCertificateShareRequestDto,
  getCreateUserDto: () => getCreateUserDto,
  getGetCertificateRequestsDto: () => getGetCertificateRequestsDto,
  getGetCertificateShareRequestsDto: () => getGetCertificateShareRequestsDto,
  getGetCertificateViewersDto: () => getGetCertificateViewersDto,
  getGetNoRequestedCertificateRequestsDto: () => getGetNoRequestedCertificateRequestsDto,
  getRejectCertificateRequestDto: () => getRejectCertificateRequestDto,
  getRejectCertificateShareRequestDto: () => getRejectCertificateShareRequestDto,
  getUsersDto: () => getUsersDto,
  transactionStatus: () => transactionStatus
});
module.exports = __toCommonJS(src_exports);

// src/user/User.ts
var import_class_validator = require("class-validator");
var usernameErrorMessage = "errors.username.invalid";
var emailErrorMessage = "errors.email.invalid";
var User = class {
};
__decorateClass([
  (0, import_class_validator.Length)(2, 30, { message: usernameErrorMessage })
], User.prototype, "username", 2);
__decorateClass([
  (0, import_class_validator.IsEmail)(void 0, { message: emailErrorMessage })
], User.prototype, "email", 2);

// src/user/AccessKey.ts
var import_class_validator2 = require("class-validator");
var AccessKey = class {
};
__decorateClass([
  (0, import_class_validator2.IsNotEmpty)({ message: "Enter the access key to continue." }),
  (0, import_class_validator2.Length)(44, 44, {
    message: "Access key must be 44 digits long."
  })
], AccessKey.prototype, "accessKey", 2);

// src/dtos/users/create-user.dto.ts
var import_class_validator3 = require("class-validator");
var getCreateUserDto = (ApiPropertySwagger) => {
  const ApiProperty = ApiPropertySwagger || function() {
  };
  const isBackend = Boolean(ApiPropertySwagger);
  class CreateUserDto {
  }
  __decorateClass([
    (0, import_class_validator3.IsString)(),
    (0, import_class_validator3.MinLength)(2, {
      message: isBackend ? "At least 2 characters long" : "errorCode.createUser.firstName.minLength"
    }),
    (0, import_class_validator3.IsNotEmpty)({
      message: isBackend ? "firstName is required" : "errorCode.createUser.firstName.isNotEmpty"
    }),
    ApiProperty({
      description: "User first name: required and must be at least 2 characters long.",
      type: String
    })
  ], CreateUserDto.prototype, "firstName", 2);
  __decorateClass([
    (0, import_class_validator3.IsString)(),
    (0, import_class_validator3.MinLength)(2, {
      message: isBackend ? "At least 2 characters long" : "errorCode.createUser.lastName.minLength"
    }),
    (0, import_class_validator3.IsNotEmpty)({
      message: isBackend ? "lastName is required" : "errorCode.createUser.lastName.isNotEmpty"
    }),
    ApiProperty({
      description: "User last name: required and must be at least 2 characters long.",
      type: String
    })
  ], CreateUserDto.prototype, "lastName", 2);
  __decorateClass([
    (0, import_class_validator3.IsEmail)(
      {},
      {
        message: isBackend ? "must be a valid email" : "errorCode.createUser.isEmail"
      }
    ),
    (0, import_class_validator3.IsNotEmpty)({
      message: isBackend ? "email is required" : "errorCode.createUser.isNotEmpty"
    }),
    ApiProperty({
      description: "User email: required and must be a valid email.",
      type: String
    })
  ], CreateUserDto.prototype, "email", 2);
  __decorateClass([
    (0, import_class_validator3.IsString)(),
    (0, import_class_validator3.IsNotEmpty)({
      message: isBackend ? "companyFiscalId is required" : "errorCode.createUser.company.isNotEmpty"
    }),
    ApiProperty({
      description: "User company fiscal Id.",
      type: String
    })
  ], CreateUserDto.prototype, "companyFiscalId", 2);
  return CreateUserDto;
};

// src/dtos/users/create-admin.dto.ts
var import_class_validator4 = require("class-validator");
var getCreateAdminDto = (ApiPropertySwagger) => {
  const ApiProperty = ApiPropertySwagger || function() {
  };
  const isBackend = Boolean(ApiPropertySwagger);
  class CreateAdminDto {
  }
  __decorateClass([
    (0, import_class_validator4.IsString)(),
    (0, import_class_validator4.MinLength)(2, {
      message: isBackend ? "At least 2 characters long" : "errorCode.createUser.firstName.minLength"
    }),
    (0, import_class_validator4.IsNotEmpty)({
      message: isBackend ? "firstName is required" : "errorCode.createUser.firstName.isNotEmpty"
    }),
    ApiProperty({
      description: "User first name: required and must be at least 2 characters long.",
      type: String
    })
  ], CreateAdminDto.prototype, "firstName", 2);
  __decorateClass([
    (0, import_class_validator4.IsString)(),
    (0, import_class_validator4.MinLength)(2, {
      message: isBackend ? "At least 2 characters long" : "errorCode.createUser.lastName.minLength"
    }),
    (0, import_class_validator4.IsNotEmpty)({
      message: isBackend ? "lastName is required" : "errorCode.createUser.lastName.isNotEmpty"
    }),
    ApiProperty({
      description: "User last name: required and must be at least 2 characters long.",
      type: String
    })
  ], CreateAdminDto.prototype, "lastName", 2);
  __decorateClass([
    (0, import_class_validator4.IsEmail)(
      {},
      {
        message: isBackend ? "must be a valid email" : "errorCode.createUser.isEmail"
      }
    ),
    (0, import_class_validator4.IsNotEmpty)({
      message: isBackend ? "email is required" : "errorCode.createUser.isNotEmpty"
    }),
    ApiProperty({
      description: "User email: required and must be a valid email.",
      type: String
    })
  ], CreateAdminDto.prototype, "email", 2);
  return CreateAdminDto;
};

// src/dtos/users/get-users.dto.ts
var import_class_validator5 = require("class-validator");
var import_class_transformer = require("class-transformer");
var getUsersDto = (ApiPropertySwagger) => {
  const ApiProperty = ApiPropertySwagger || function() {
  };
  class GetUsersDto {
    constructor() {
      this.page = 1;
      this.pageSize = 10;
    }
  }
  __decorateClass([
    (0, import_class_transformer.Transform)(({ value }) => String(value)),
    (0, import_class_validator5.IsString)(),
    (0, import_class_validator5.IsOptional)(),
    ApiProperty({
      description: "Filters by user's name, email or company name.",
      required: false,
      type: String
    })
  ], GetUsersDto.prototype, "filter", 2);
  __decorateClass([
    (0, import_class_transformer.Transform)(({ value }) => Number(value)),
    (0, import_class_validator5.IsNumber)(),
    (0, import_class_validator5.IsOptional)(),
    (0, import_class_validator5.Min)(1),
    ApiProperty({
      description: "Page number.",
      required: false,
      type: Number
    })
  ], GetUsersDto.prototype, "page", 2);
  __decorateClass([
    (0, import_class_transformer.Transform)(({ value }) => Number(value)),
    (0, import_class_validator5.IsNumber)(),
    (0, import_class_validator5.IsOptional)(),
    ApiProperty({
      description: "Items per page.",
      required: false,
      type: Number
    })
  ], GetUsersDto.prototype, "pageSize", 2);
  return GetUsersDto;
};

// src/dtos/certificates/get-certificate-requests.dto.ts
var import_class_validator6 = require("class-validator");
var import_class_transformer2 = require("class-transformer");

// src/common/enum/blockchain/transaction-status.enum.ts
var TRANSACTION_STATUS = {
  PENDING: "0",
  CONFIRMED: "1",
  FAILED: "2"
};
var transactionStatus = Object.values(TRANSACTION_STATUS);

// src/common/enum/index.ts
var SortOrder = /* @__PURE__ */ ((SortOrder2) => {
  SortOrder2["ASC"] = "ASC";
  SortOrder2["DESC"] = "DESC";
  return SortOrder2;
})(SortOrder || {});
var CertificateRequestsSortBy = /* @__PURE__ */ ((CertificateRequestsSortBy2) => {
  CertificateRequestsSortBy2["createdAt"] = "createdAt";
  return CertificateRequestsSortBy2;
})(CertificateRequestsSortBy || {});
var CertificateShareRequestsSortBy = /* @__PURE__ */ ((CertificateShareRequestsSortBy2) => {
  CertificateShareRequestsSortBy2["createdAt"] = "createdAt";
  return CertificateShareRequestsSortBy2;
})(CertificateShareRequestsSortBy || {});
var CertificateViewersSortBy = /* @__PURE__ */ ((CertificateViewersSortBy2) => {
  CertificateViewersSortBy2["CREATED_AT"] = "createdAt";
  return CertificateViewersSortBy2;
})(CertificateViewersSortBy || {});

// src/dtos/certificates/get-certificate-requests.dto.ts
var import_db = require("@raizen/db");
var getGetCertificateRequestsDto = (ApiPropertySwagger) => {
  const ApiProperty = ApiPropertySwagger || function() {
  };
  class GetCertificateRequestsDto {
    constructor() {
      this.orderBy = "createdAt" /* createdAt */;
      this.order = "DESC" /* DESC */;
      this.page = 1;
      this.pageSize = 10;
    }
  }
  __decorateClass([
    (0, import_class_transformer2.Transform)(({ value }) => String(value)),
    (0, import_class_validator6.IsString)(),
    (0, import_class_validator6.IsOptional)(),
    ApiProperty({
      description: "Search by access key.",
      required: false,
      type: String
    })
  ], GetCertificateRequestsDto.prototype, "accessKey", 2);
  __decorateClass([
    (0, import_class_transformer2.Transform)(({ value }) => String(value)),
    (0, import_class_validator6.IsEnum)(import_db.CERTIFICATE_REQUEST_STATUS),
    (0, import_class_validator6.IsOptional)(),
    ApiProperty({
      enum: import_db.CERTIFICATE_REQUEST_STATUS,
      description: "Status filter.",
      required: false
    })
  ], GetCertificateRequestsDto.prototype, "statusFilter", 2);
  __decorateClass([
    (0, import_class_transformer2.Transform)(({ value }) => Number(value)),
    (0, import_class_validator6.IsNumber)(),
    (0, import_class_validator6.IsOptional)(),
    ApiProperty({
      description: "Date filter in unix timestamp.",
      required: false,
      type: Number
    })
  ], GetCertificateRequestsDto.prototype, "dateFilter", 2);
  __decorateClass([
    (0, import_class_validator6.IsOptional)(),
    (0, import_class_validator6.IsArray)(),
    ApiProperty({
      description: "Green factor filter.",
      required: false,
      example: import_db.greenFactorTypes
    })
  ], GetCertificateRequestsDto.prototype, "greenFactorFilter", 2);
  __decorateClass([
    (0, import_class_transformer2.Transform)(({ value }) => String(value)),
    (0, import_class_validator6.IsEnum)(CertificateRequestsSortBy),
    (0, import_class_validator6.IsOptional)(),
    ApiProperty({
      enum: CertificateRequestsSortBy,
      description: "Sort by field.",
      required: false
    })
  ], GetCertificateRequestsDto.prototype, "orderBy", 2);
  __decorateClass([
    (0, import_class_transformer2.Transform)(({ value }) => String(value)),
    (0, import_class_validator6.IsEnum)(SortOrder),
    (0, import_class_validator6.IsOptional)(),
    ApiProperty({
      enum: SortOrder,
      description: "Sort order.",
      required: false
    })
  ], GetCertificateRequestsDto.prototype, "order", 2);
  __decorateClass([
    (0, import_class_transformer2.Transform)(({ value }) => Number(value)),
    (0, import_class_validator6.IsNumber)(),
    (0, import_class_validator6.IsOptional)(),
    ApiProperty({
      description: "Page number.",
      required: false,
      type: Number
    })
  ], GetCertificateRequestsDto.prototype, "page", 2);
  __decorateClass([
    (0, import_class_transformer2.Transform)(({ value }) => Number(value)),
    (0, import_class_validator6.IsNumber)(),
    (0, import_class_validator6.IsOptional)(),
    ApiProperty({
      description: "Items per page.",
      required: false,
      type: Number
    })
  ], GetCertificateRequestsDto.prototype, "pageSize", 2);
  return GetCertificateRequestsDto;
};

// src/dtos/certificates/get-certificate-share-requests.dto.ts
var import_class_validator7 = require("class-validator");
var import_class_transformer3 = require("class-transformer");
var import_db2 = require("@raizen/db");
var getGetCertificateShareRequestsDto = (ApiPropertySwagger) => {
  const ApiProperty = ApiPropertySwagger || function() {
  };
  class GetCertificateShareRequestsDto {
    constructor() {
      this.orderBy = "createdAt" /* createdAt */;
      this.order = "DESC" /* DESC */;
      this.page = 1;
      this.pageSize = 10;
    }
  }
  __decorateClass([
    (0, import_class_transformer3.Transform)(({ value }) => String(value)),
    (0, import_class_validator7.IsString)(),
    (0, import_class_validator7.IsOptional)(),
    ApiProperty({
      description: "Search by access key, owner, viewer...",
      required: false,
      type: String
    })
  ], GetCertificateShareRequestsDto.prototype, "filter", 2);
  __decorateClass([
    (0, import_class_transformer3.Transform)(({ value }) => Number(value)),
    (0, import_class_validator7.IsNumber)(),
    (0, import_class_validator7.IsOptional)(),
    ApiProperty({
      description: "Date filter in unix timestamp.",
      required: false,
      type: Number
    })
  ], GetCertificateShareRequestsDto.prototype, "dateFilter", 2);
  __decorateClass([
    (0, import_class_transformer3.Transform)(({ value }) => String(value)),
    (0, import_class_validator7.IsEnum)(import_db2.CERTIFICATE_SHARE_REQUEST_STATUS),
    (0, import_class_validator7.IsOptional)(),
    ApiProperty({
      enum: import_db2.CERTIFICATE_SHARE_REQUEST_STATUS,
      description: "Status filter.",
      required: false
    })
  ], GetCertificateShareRequestsDto.prototype, "statusFilter", 2);
  __decorateClass([
    (0, import_class_transformer3.Transform)(({ value }) => String(value)),
    (0, import_class_validator7.IsEnum)(CertificateShareRequestsSortBy),
    (0, import_class_validator7.IsOptional)(),
    ApiProperty({
      enum: CertificateShareRequestsSortBy,
      description: "Sort by field.",
      required: false
    })
  ], GetCertificateShareRequestsDto.prototype, "orderBy", 2);
  __decorateClass([
    (0, import_class_transformer3.Transform)(({ value }) => String(value)),
    (0, import_class_validator7.IsEnum)(SortOrder),
    (0, import_class_validator7.IsOptional)(),
    ApiProperty({
      enum: SortOrder,
      description: "Sort order.",
      required: false
    })
  ], GetCertificateShareRequestsDto.prototype, "order", 2);
  __decorateClass([
    (0, import_class_transformer3.Transform)(({ value }) => Number(value)),
    (0, import_class_validator7.IsNumber)(),
    (0, import_class_validator7.IsOptional)(),
    ApiProperty({
      description: "Page number.",
      required: false,
      type: Number
    })
  ], GetCertificateShareRequestsDto.prototype, "page", 2);
  __decorateClass([
    (0, import_class_transformer3.Transform)(({ value }) => Number(value)),
    (0, import_class_validator7.IsNumber)(),
    (0, import_class_validator7.IsOptional)(),
    ApiProperty({
      description: "Items per page.",
      required: false,
      type: Number
    })
  ], GetCertificateShareRequestsDto.prototype, "pageSize", 2);
  return GetCertificateShareRequestsDto;
};

// src/dtos/certificates/create-certificate-request.dto.ts
var import_class_validator8 = require("class-validator");
var digitsAndHyphenRegExp = /[\d]+/;
var getCreateCertificateRequestDto = (ApiPropertySwagger) => {
  const ApiProperty = ApiPropertySwagger || function() {
  };
  class CreateCertificateRequestDto {
  }
  __decorateClass([
    ApiProperty({
      description: "Raizen Metadata access key to search.",
      required: true,
      type: String
    }),
    (0, import_class_validator8.Length)(44, 44, {
      message: "Access key must be 44 digits."
    }),
    (0, import_class_validator8.Matches)(digitsAndHyphenRegExp, {
      message: "Only numbers are allowed."
    }),
    (0, import_class_validator8.IsNotEmpty)({ message: "Enter the access key to continue." })
  ], CreateCertificateRequestDto.prototype, "accessKey", 2);
  return CreateCertificateRequestDto;
};

// src/dtos/certificates/confirm-certificate-request.dto.ts
var import_class_validator9 = require("class-validator");
var getConfirmCertificateRequestDto = (ApiPropertySwagger) => {
  const ApiProperty = ApiPropertySwagger || function() {
  };
  class ConfirmCertificateRequestDto {
  }
  __decorateClass([
    (0, import_class_validator9.IsString)(),
    ApiProperty({
      description: "Certificate Request UUID to confirm.",
      required: true,
      type: String
    })
  ], ConfirmCertificateRequestDto.prototype, "uuid", 2);
  return ConfirmCertificateRequestDto;
};

// src/dtos/certificates/reject-certificate-request.dto.ts
var import_db3 = require("@raizen/db");
var import_class_validator10 = require("class-validator");
var getRejectCertificateRequestDto = (ApiPropertySwagger) => {
  const ApiProperty = ApiPropertySwagger || function() {
  };
  class RejectCertificateRequestDto {
  }
  __decorateClass([
    (0, import_class_validator10.IsEnum)(import_db3.CERTIFICATE_REJECTION_REASON),
    ApiProperty({
      description: "Reason is the reason why the certificate was rejected.",
      required: true,
      enum: import_db3.CERTIFICATE_REJECTION_REASON,
      default: import_db3.CERTIFICATE_REJECTION_REASON.INVOICE_DUPLICATION
    }),
    (0, import_class_validator10.IsNotEmpty)({ message: "Reason is required." })
  ], RejectCertificateRequestDto.prototype, "rejectionReason", 2);
  __decorateClass([
    ApiProperty({
      description: "Observation is a detail on why the certificate request was rejected.",
      required: false,
      type: String
    }),
    (0, import_class_validator10.IsString)(),
    (0, import_class_validator10.IsOptional)()
  ], RejectCertificateRequestDto.prototype, "rejectionMessage", 2);
  return RejectCertificateRequestDto;
};

// src/dtos/certificates/create-certificate-share-request.dto.ts
var import_class_validator11 = require("class-validator");
var getCreateCertificateShareRequestDto = (ApiPropertySwagger) => {
  const ApiProperty = ApiPropertySwagger || function() {
  };
  const isBackend = Boolean(ApiPropertySwagger);
  class CreateCertificateShareRequestDto {
  }
  __decorateClass([
    (0, import_class_validator11.IsString)(),
    (0, import_class_validator11.MinLength)(2, {
      message: isBackend ? "At least 2 characters long" : "errorCode.createUser.firstName.minLength"
    }),
    (0, import_class_validator11.IsNotEmpty)({
      message: isBackend ? "firstName is required" : "errorCode.createUser.firstName.isNotEmpty"
    }),
    ApiProperty({
      description: "User first name: required and must be at least 2 characters long.",
      type: String
    })
  ], CreateCertificateShareRequestDto.prototype, "firstName", 2);
  __decorateClass([
    (0, import_class_validator11.IsString)(),
    (0, import_class_validator11.MinLength)(2, {
      message: isBackend ? "At least 2 characters long" : "errorCode.createUser.lastName.minLength"
    }),
    (0, import_class_validator11.IsNotEmpty)({
      message: isBackend ? "lastName is required" : "errorCode.createUser.lastName.isNotEmpty"
    }),
    ApiProperty({
      description: "User last name: required and must be at least 2 characters long.",
      type: String
    })
  ], CreateCertificateShareRequestDto.prototype, "lastName", 2);
  __decorateClass([
    (0, import_class_validator11.IsEmail)(
      {},
      {
        message: isBackend ? "must be a valid email" : "errorCode.createUser.isEmail"
      }
    ),
    (0, import_class_validator11.IsNotEmpty)({
      message: isBackend ? "email is required" : "errorCode.createUser.isNotEmpty"
    }),
    ApiProperty({
      description: "User email: required and must be a valid email.",
      type: String
    })
  ], CreateCertificateShareRequestDto.prototype, "email", 2);
  __decorateClass([
    (0, import_class_validator11.IsString)(),
    (0, import_class_validator11.IsNotEmpty)({
      message: isBackend ? "companyName is required" : "errorCode.createUser.companyName.isNotEmpty"
    }),
    ApiProperty({
      description: "Company name",
      type: String
    })
  ], CreateCertificateShareRequestDto.prototype, "companyName", 2);
  __decorateClass([
    (0, import_class_validator11.IsString)(),
    (0, import_class_validator11.IsNotEmpty)({
      message: isBackend ? "companyFiscalId is required" : "errorCode.createUser.company.isNotEmpty"
    }),
    ApiProperty({
      description: "User company fiscal Id.",
      type: String
    })
  ], CreateCertificateShareRequestDto.prototype, "companyFiscalId", 2);
  __decorateClass([
    (0, import_class_validator11.IsString)(),
    (0, import_class_validator11.IsNotEmpty)(),
    ApiProperty({
      description: "Certificate Request UUID",
      type: String
    })
  ], CreateCertificateShareRequestDto.prototype, "certificateRequestUuid", 2);
  return CreateCertificateShareRequestDto;
};

// src/dtos/certificates/approve-certificate-share-request.dto.ts
var import_db4 = require("@raizen/db");
var import_class_validator12 = require("class-validator");
var getApproveCertificateShareRequestDto = (ApiPropertySwagger) => {
  const ApiProperty = ApiPropertySwagger || function() {
  };
  const isBackend = Boolean(ApiPropertySwagger);
  class ApproveCertificateShareRequestDto {
  }
  __decorateClass([
    (0, import_class_validator12.IsString)(),
    (0, import_class_validator12.IsNotEmpty)({
      message: isBackend ? "companyFiscalId is required" : "sharingRequests.approvalModal.newCompany.sections.companyFiscalId.empty"
    }),
    ApiProperty({
      description: "Curated company fiscal ID with which the certificate is shared.",
      required: true,
      type: String
    })
  ], ApproveCertificateShareRequestDto.prototype, "companyFiscalId", 2);
  __decorateClass([
    (0, import_class_validator12.IsString)(),
    (0, import_class_validator12.IsNotEmpty)({
      message: isBackend ? "companyName is required" : "sharingRequests.approvalModal.newCompany.sections.companyName.empty"
    }),
    ApiProperty({
      description: "Company name",
      required: true,
      type: String
    })
  ], ApproveCertificateShareRequestDto.prototype, "companyName", 2);
  __decorateClass([
    (0, import_class_validator12.IsString)(),
    (0, import_class_validator12.IsEmail)(),
    (0, import_class_validator12.IsNotEmpty)({
      message: isBackend ? "userEmail is required" : "sharingRequests.approvalModal.newCompany.sections.email.empty"
    }),
    ApiProperty({
      description: "Curated user email with which the certificate is shared.",
      required: true,
      type: String
    })
  ], ApproveCertificateShareRequestDto.prototype, "userEmail", 2);
  __decorateClass([
    (0, import_class_validator12.IsEnum)(import_db4.VIEWER_USER_ROLES),
    ApiProperty({
      enum: import_db4.VIEWER_USER_ROLES,
      description: "Access Type.",
      required: true
    })
  ], ApproveCertificateShareRequestDto.prototype, "accessType", 2);
  return ApproveCertificateShareRequestDto;
};

// src/dtos/certificates/reject-certificate-share-request.dto.ts
var import_class_validator13 = require("class-validator");
var getRejectCertificateShareRequestDto = (ApiPropertySwagger) => {
  const ApiProperty = ApiPropertySwagger || function() {
  };
  const isBackend = Boolean(ApiPropertySwagger);
  class RejectCertificateShareRequestDto {
  }
  __decorateClass([
    (0, import_class_validator13.IsString)(),
    (0, import_class_validator13.MaxLength)(600, {
      message: isBackend ? "Maximum 600 characters" : "sharingRequests.rejectModal.rejectionMessage.maxLength"
    }),
    (0, import_class_validator13.IsNotEmpty)({
      message: isBackend ? "This field is required" : "sharingRequests.rejectModal.rejectionMessage.isNotEmpty"
    }),
    ApiProperty({
      description: "Rejection comment or observations",
      type: String
    })
  ], RejectCertificateShareRequestDto.prototype, "rejectionMessage", 2);
  return RejectCertificateShareRequestDto;
};

// src/dtos/certificates/get-certificate-viewers.dto.ts
var import_class_validator14 = require("class-validator");
var import_class_transformer4 = require("class-transformer");
var import_db5 = require("@raizen/db");
var getGetCertificateViewersDto = (ApiPropertySwagger) => {
  const ApiProperty = ApiPropertySwagger || function() {
  };
  class GetCertificateViewersDto {
    constructor() {
      this.orderBy = "createdAt" /* CREATED_AT */;
      this.order = "DESC" /* DESC */;
      this.page = 1;
      this.pageSize = 10;
    }
  }
  __decorateClass([
    (0, import_class_transformer4.Transform)(({ value }) => String(value)),
    (0, import_class_validator14.IsString)(),
    (0, import_class_validator14.IsOptional)(),
    ApiProperty({
      description: "Search by company name",
      required: false,
      type: String
    })
  ], GetCertificateViewersDto.prototype, "filter", 2);
  __decorateClass([
    (0, import_class_transformer4.Transform)(({ value }) => String(value)),
    (0, import_class_validator14.IsEnum)(import_db5.CERTIFICATE_SHARE_REQUEST_STATUS),
    (0, import_class_validator14.IsOptional)(),
    ApiProperty({
      enum: import_db5.CERTIFICATE_SHARE_REQUEST_STATUS,
      description: "Filter by status.",
      required: false
    })
  ], GetCertificateViewersDto.prototype, "status", 2);
  __decorateClass([
    (0, import_class_transformer4.Transform)(({ value }) => Number(value)),
    (0, import_class_validator14.IsNumber)(),
    (0, import_class_validator14.IsOptional)(),
    ApiProperty({
      description: "Requested date filter in unix timestamp.",
      required: false,
      type: Number
    })
  ], GetCertificateViewersDto.prototype, "dateFilter", 2);
  __decorateClass([
    (0, import_class_transformer4.Transform)(({ value }) => String(value)),
    (0, import_class_validator14.IsEnum)(CertificateViewersSortBy),
    (0, import_class_validator14.IsOptional)(),
    ApiProperty({
      enum: CertificateViewersSortBy,
      description: "Sort by field.",
      required: false
    })
  ], GetCertificateViewersDto.prototype, "orderBy", 2);
  __decorateClass([
    (0, import_class_transformer4.Transform)(({ value }) => String(value)),
    (0, import_class_validator14.IsEnum)(SortOrder),
    (0, import_class_validator14.IsOptional)(),
    ApiProperty({
      enum: SortOrder,
      description: "Sort order.",
      required: false
    })
  ], GetCertificateViewersDto.prototype, "order", 2);
  __decorateClass([
    (0, import_class_transformer4.Transform)(({ value }) => Number(value)),
    (0, import_class_validator14.IsNumber)(),
    (0, import_class_validator14.IsOptional)(),
    ApiProperty({
      description: "Page number.",
      required: false,
      type: Number
    })
  ], GetCertificateViewersDto.prototype, "page", 2);
  __decorateClass([
    (0, import_class_transformer4.Transform)(({ value }) => Number(value)),
    (0, import_class_validator14.IsNumber)(),
    (0, import_class_validator14.IsOptional)(),
    ApiProperty({
      description: "Items per page.",
      required: false,
      type: Number
    })
  ], GetCertificateViewersDto.prototype, "pageSize", 2);
  return GetCertificateViewersDto;
};

// src/dtos/certificates/get-no-requested-certificate-requests.dto.ts
var import_class_validator15 = require("class-validator");
var import_class_transformer5 = require("class-transformer");
var import_db6 = require("@raizen/db");
var getGetNoRequestedCertificateRequestsDto = (ApiPropertySwagger) => {
  const ApiProperty = ApiPropertySwagger || function() {
  };
  class GetNoRequestedCertificateRequestsDto {
    constructor() {
      this.page = 1;
      this.pageSize = 10;
    }
  }
  __decorateClass([
    (0, import_class_transformer5.Transform)(({ value }) => String(value)),
    (0, import_class_validator15.IsString)(),
    (0, import_class_validator15.IsOptional)(),
    ApiProperty({
      description: "Search term, by now searching by Access Key or company name.",
      required: false,
      type: String
    })
  ], GetNoRequestedCertificateRequestsDto.prototype, "searchTerm", 2);
  __decorateClass([
    (0, import_class_transformer5.Transform)(({ value }) => String(value)),
    (0, import_class_validator15.IsEnum)(import_db6.GREEN_FACTOR_VALUES),
    (0, import_class_validator15.IsOptional)(),
    ApiProperty({
      enum: import_db6.GREEN_FACTOR_VALUES,
      description: "Green factor filter.",
      required: false
    })
  ], GetNoRequestedCertificateRequestsDto.prototype, "greenFactor", 2);
  __decorateClass([
    (0, import_class_transformer5.Transform)(({ value }) => Number(value)),
    (0, import_class_validator15.IsNumber)(),
    (0, import_class_validator15.IsOptional)(),
    ApiProperty({
      description: "Page number.",
      required: false,
      type: Number
    })
  ], GetNoRequestedCertificateRequestsDto.prototype, "page", 2);
  __decorateClass([
    (0, import_class_transformer5.Transform)(({ value }) => Number(value)),
    (0, import_class_validator15.IsNumber)(),
    (0, import_class_validator15.IsOptional)(),
    ApiProperty({
      description: "Items per page.",
      required: false,
      type: Number
    })
  ], GetNoRequestedCertificateRequestsDto.prototype, "pageSize", 2);
  return GetNoRequestedCertificateRequestsDto;
};

// src/dtos/blockchain/certificate-request-creation-transaction-response.dto.ts
var import_class_validator16 = require("class-validator");
var getCertificateRequestCreationTransactionResponseDto = (ApiPropertySwagger) => {
  const ApiProperty = ApiPropertySwagger || function() {
  };
  class CertificateRequestCreationTransactionResponseDto {
  }
  __decorateClass([
    (0, import_class_validator16.IsIn)(transactionStatus),
    ApiProperty({
      enum: transactionStatus,
      description: `Status: '0' PENDING, '1' CONFIRMED, '2' FAILED.`,
      required: true
    })
  ], CertificateRequestCreationTransactionResponseDto.prototype, "status", 2);
  __decorateClass([
    (0, import_class_validator16.IsString)(),
    ApiProperty({
      description: "Transaction UID.",
      required: true,
      type: String
    })
  ], CertificateRequestCreationTransactionResponseDto.prototype, "uid", 2);
  __decorateClass([
    (0, import_class_validator16.IsString)(),
    ApiProperty({
      description: "Transaction gas used.",
      required: true,
      type: String
    })
  ], CertificateRequestCreationTransactionResponseDto.prototype, "gasUsed", 2);
  __decorateClass([
    (0, import_class_validator16.IsString)(),
    ApiProperty({
      description: "Transaction hash.",
      required: true,
      type: String
    })
  ], CertificateRequestCreationTransactionResponseDto.prototype, "hash", 2);
  return CertificateRequestCreationTransactionResponseDto;
};

// src/dtos/companies/get-companies.dto.ts
var import_class_validator17 = require("class-validator");
var import_class_transformer6 = require("class-transformer");
var getCompaniesDto = (ApiPropertySwagger) => {
  const ApiProperty = ApiPropertySwagger || function() {
  };
  class GetCompaniesDto {
  }
  __decorateClass([
    (0, import_class_transformer6.Transform)(({ value }) => String(value)),
    (0, import_class_validator17.IsString)(),
    (0, import_class_validator17.IsOptional)(),
    ApiProperty({
      description: "Filters by company name",
      required: false,
      type: String
    })
  ], GetCompaniesDto.prototype, "filter", 2);
  return GetCompaniesDto;
};
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  AccessKey,
  CertificateRequestsSortBy,
  CertificateShareRequestsSortBy,
  CertificateViewersSortBy,
  SortOrder,
  TRANSACTION_STATUS,
  User,
  getApproveCertificateShareRequestDto,
  getCertificateRequestCreationTransactionResponseDto,
  getCompaniesDto,
  getConfirmCertificateRequestDto,
  getCreateAdminDto,
  getCreateCertificateRequestDto,
  getCreateCertificateShareRequestDto,
  getCreateUserDto,
  getGetCertificateRequestsDto,
  getGetCertificateShareRequestsDto,
  getGetCertificateViewersDto,
  getGetNoRequestedCertificateRequestsDto,
  getRejectCertificateRequestDto,
  getRejectCertificateShareRequestDto,
  getUsersDto,
  transactionStatus
});
