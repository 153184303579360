import { httpClient } from '@raizen/client-auth';
import { CreateCertificateShareRequestDto } from './dtos/CreateCertificateShareRequestDto';
import { type CertificateShareRequest } from '@raizen/db';

export const createCertificateShareRequest = async (
  params: CreateCertificateShareRequestDto,
) => {
  const { data } = await httpClient.post<CertificateShareRequest>(
    'certificate-share-requests',
    params,
  );

  return data;
};
