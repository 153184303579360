import { Button, Flex } from '@chakra-ui/react';
import { useTranslation } from '@raizen/react-hooks';
import { Heading, Text } from '@raizen/ui';
import { useNavigate } from 'react-router-dom';

interface CertificateRequestedProps {
  requestUuid: string;
}
export const CertificateRequested = ({
  requestUuid,
}: CertificateRequestedProps) => {
  const { t } = useTranslation();
  const navigator = useNavigate();

  return (
    <Flex flexDir={'column'}>
      <Heading size={'xs'}>
        {t('common.certificate')}{' '}
        {t(
          'certificateRequestCreation.certificateRequested.successfullyRequested'
        )}
      </Heading>
      <Text size={'md'}>
        {t('certificateRequestCreation.certificateRequested.reviewing')}{' '}
        {t('certificateRequestCreation.certificateRequested.subHeading')}
      </Text>
      <Button
        size="md"
        variant="primary"
        w={{
          base: 'full',
          sm: 'full',
          md: 'fit-content',
        }}
        onClick={() => {
          navigator('/');
        }}
        mt={6}
      >
        {t('certificateRequestCreation.certificateRequested.viewCertificates')}
      </Button>
    </Flex>
  );
};
