import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { useTranslation } from '@raizen/react-hooks';
import {
  Alert,
  HelpIcon,
  ContextField,
  FormControl,
  FormLabel,
  RadioButton,
  RadioGroup,
  Text,
} from '@raizen/ui';
import { SubmitHandler, useForm } from 'react-hook-form';
import { UseMutateFunction } from 'react-query';
import { ApproveCertificateShareRequestDto } from '../../../services/certificateShareRequest/dtos/ApproveCertificateShareRequestDto';
import { Flex, Stack, Tooltip } from '@chakra-ui/react';
import { ListCertificateShareRequestsSerializer } from '@raizen/serializers';
import {
  VIEWER_USER_ROLES,
  ViewerUserRoles,
  viewerUserRoles,
} from '@raizen/db';
import { useEffect, useState } from 'react';

const resolver = classValidatorResolver(ApproveCertificateShareRequestDto);

interface Props {
  selectedShareRequest: ListCertificateShareRequestsSerializer;
  mutate: UseMutateFunction<
    any,
    unknown,
    ApproveCertificateShareRequestDto,
    unknown
  >;
}

export const NewCompanyApprovalForm = ({
  selectedShareRequest,
  mutate,
}: Props) => {
  const { t } = useTranslation();

  const {
    certificateShareRequestEmail: userEmail,
    certificateShareRequestCompanyFiscalId: companyFiscalId,
    certificateViewerCompanyName: companyName,
    companyAccessType: defaultAccessType,
  } = selectedShareRequest;

  const [accessType, setAccessType] = useState<ViewerUserRoles>(
    (defaultAccessType as ViewerUserRoles) || VIEWER_USER_ROLES.VIEWER_FULL,
  );

  const context = useForm<ApproveCertificateShareRequestDto>({
    mode: 'onChange',
    resolver,
    defaultValues: {
      userEmail,
      companyFiscalId,
      accessType,
      companyName,
    },
  });
  const { handleSubmit, formState, setValue } = context;

  const isInvalid =
    Boolean(formState.errors.userEmail) ||
    Boolean(formState.errors.companyFiscalId) ||
    Boolean(formState.errors.accessType);

  const onSubmit: SubmitHandler<any> = (data) => {
    mutate(data);
  };

  useEffect(() => {
    setValue('accessType', accessType as ViewerUserRoles);
  }, [accessType, setValue]);

  return (
    <FormControl isInvalid={isInvalid} isRequired={true}>
      <Flex
        as="form"
        flexDir="column"
        id="approve-form"
        gap={6}
        onSubmit={(ev) => {
          ev.preventDefault();
          handleSubmit(onSubmit)(ev);
        }}
      >
        <Alert
          variant="warning"
          title={t('sharingRequests.approvalModal.newCompany.alert.title')}
          description={t('sharingRequests.approvalModal.newCompany.alert.text')}
          showCloseButton={false}
        />

        <Text>
          {t('sharingRequests.approvalModal.newCompany.sections.email').replace(
            '{{email}}',
            userEmail,
          )}
        </Text>

        <ContextField
          context={context}
          name="companyFiscalId"
          label={t(
            'sharingRequests.approvalModal.newCompany.sections.companyFiscalId',
          )}
          errorMessageFormatter={t}
          options={{ required: true }}
        />

        <ContextField
          context={context}
          name="companyName"
          label={t(
            'sharingRequests.approvalModal.newCompany.sections.companyName',
          )}
          errorMessageFormatter={t}
          options={{ required: true }}
        />

        <Flex direction="column">
          <FormLabel>
            {t('sharingRequests.approvalModal.newCompany.sections.accessType')}
          </FormLabel>
          <RadioGroup
            onChange={(ev) => {
              setAccessType(ev as ViewerUserRoles);
            }}
            value={accessType}
          >
            <Stack spacing={4} direction="row">
              {viewerUserRoles.map((v) => (
                <Flex key={v}>
                  <RadioButton
                    size="md"
                    value={v}
                    label={t(
                      `sharingRequests.approvalModal.newCompany.sections.accessType.${v}`,
                    )}
                  />
                  {v === VIEWER_USER_ROLES.VIEWER_RESTRICTED && (
                    <Tooltip
                      label={t(
                        `sharingRequests.approvalModal.newCompany.sections.accessType.${v}.tooltip`,
                      )}
                      hasArrow={true}
                    >
                      <Flex alignItems="center" ml={2}>
                        <HelpIcon color="#667085" />
                      </Flex>
                    </Tooltip>
                  )}
                </Flex>
              ))}
            </Stack>
          </RadioGroup>
        </Flex>
      </Flex>
    </FormControl>
  );
};
