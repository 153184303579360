import { CertificateDataSerializer } from '@raizen/serializers';
import { CertificateRequestDetailRow } from '../CertificateRequestDetailsListSection';
import { useRowsMap } from '../useRowsMap';
import { useTranslation } from '@raizen/react-hooks';
import { Section } from './Section';
import { List } from './List';

interface Props {
  certificateData?: CertificateDataSerializer;
}

export const ProductInformationSection = ({ certificateData }: Props) => {
  const { t } = useTranslation();
  const rowsMap = useRowsMap();

  const rows = [
    rowsMap.bioparkName(certificateData?.bioparkName),
    rowsMap.bioparkCityAndState(certificateData?.bioparkCityState),
    rowsMap.productionDate(certificateData?.productionDate),
    rowsMap.productTotalQuantity(
      certificateData?.farmProductionQuantity ??
        certificateData?.productTotalQuantity,
      certificateData?.productQuantityUnit,
    ),
    rowsMap.productCertifiedQuantity(
      certificateData?.productCertifiedQuantity,
      certificateData?.productQuantityUnit,
    ),
    rowsMap.massBalance(certificateData?.massBalance),
    rowsMap.carbonFootprint(
      certificateData?.carbonFootprint,
      certificateData?.carbonFootprintQuantityUnit,
    ),
  ].filter((e) => e !== null) as CertificateRequestDetailRow[];

  return rows.length ? (
    <Section title={t('common.productInformation')}>
      <List rows={rows} />
    </Section>
  ) : null;
};
