import { httpClient } from '@raizen/client-auth';

export const rejectCertRequest = async (
  uuid: string,
  body: { rejectionReason: string; rejectionMessage: string }
) => {
  const { data } = await httpClient.patch<string>(
    `/certificate-requests/${uuid}/reject`,
    body
  );

  return data;
};
