import { Divider, Flex } from '@chakra-ui/react';
import {
  PreviewDetailRow,
  PreviewDetailRowProps,
} from '../../CertificateRequestCreation/partials/PreviewDetailRow';

export interface CertificateRequestDetailRow {
  id: string;
  data: PreviewDetailRowProps;
}
export interface CertificateRequestDetailsListSectionProps {
  rows: CertificateRequestDetailRow[];
}

export const List = ({ rows }: CertificateRequestDetailsListSectionProps) => {
  return (
    <Flex flexDir={'column'} gap={2} w="full">
      {rows.map(({ id, data }, index) => {
        const { icon, label, value, tooltip } = data;
        return (
          <Flex key={id + index} direction="column" gap={2}>
            <PreviewDetailRow
              icon={icon}
              label={label}
              value={value}
              tooltip={tooltip}
            />
            {index !== rows.length - 1 && <Divider />}
          </Flex>
        );
      })}
    </Flex>
  );
};
