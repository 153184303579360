import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import {
  TableWrapper,
  Table,
  Pagination,
  DateCalendar,
  SearchInput,
} from '@raizen/ui';
import { TableInstance, useTable } from 'react-table';
import { usePagination } from 'react-table';
import { Box, Button, Flex, Select, useDisclosure } from '@chakra-ui/react';
import { useTranslation } from '@raizen/react-hooks';
import { useColumns } from './columns';
import { getCertificateShareRequests } from '../../../services/certificateRequests/getCertificateShareRequests';

import { RejectModal } from '../Reject/RejectModal';
import { ApprovalModal } from '../Approve/ApprovalModal';
import { ListCertificateShareRequestsSerializer } from '@raizen/serializers';

import {
  CertificateShareRequestStatusType,
  certificateShareRequestStatusTypes,
} from '@raizen/db';
import { dateToUnixTimestamp } from '@raizen/common-utils';

export const SharingRequestsTable = () => {
  const { t } = useTranslation();

  const {
    isOpen: isOpenReject,
    onClose: onCloseReject,
    onOpen: onOpenReject,
  } = useDisclosure();

  const {
    isOpen: isOpenApprove,
    onClose: onCloseApprove,
    onOpen: onOpenApprove,
  } = useDisclosure();

  const [selectedShareRequest, setSelectedShareRequest] =
    useState<ListCertificateShareRequestsSerializer>();

  const onClickApprove = (request: ListCertificateShareRequestsSerializer) => {
    setSelectedShareRequest(request);
    onOpenApprove();
  };
  const onClickReject = (request: ListCertificateShareRequestsSerializer) => {
    setSelectedShareRequest(request);
    onOpenReject();
  };

  const { columns } = useColumns({ onClickApprove, onClickReject });

  const [filter, setFilter] = useState('');

  const [statusFilter, setStatusFilter] = useState<
    CertificateShareRequestStatusType | undefined
  >(undefined);

  const [fullDateFilter, setFullDateFilter] = useState<Date | undefined>(
    undefined,
  );
  const [dateFilter, setDateFilter] = useState<number | undefined>(undefined);

  const [page, setPage] = useState(1);

  const pageSize = 50;

  const { data, isLoading, refetch } = useQuery(
    [
      'certificateShareRequests',
      filter,
      dateFilter,
      statusFilter,
      page,
      pageSize,
    ],
    () =>
      getCertificateShareRequests({
        filter,
        dateFilter,
        statusFilter,
        page,
        pageSize,
      }),
  );

  const tableData = React.useMemo(
    () => data?.data || [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(data)],
  );
  const tableInstance = useTable<ListCertificateShareRequestsSerializer>(
    {
      columns: columns as any,
      data: tableData,
      initialState: { pageSize } as object,
    },
    usePagination,
  );

  const handleFilterChange = (e: any) => {
    const value = e.target.value || '';
    setFilter(value);
    setPage(1);
  };

  const handleStatusFilterChange = (e: any) => {
    const value = e.target.value || undefined;
    setStatusFilter(value);
    setPage(1);
  };

  const handleFiltersReset = () => {
    setFilter('');
    setStatusFilter(undefined);
    setFullDateFilter(undefined);
    setPage(1);
  };

  useEffect(() => {
    if (fullDateFilter) {
      setDateFilter(dateToUnixTimestamp(fullDateFilter));
    } else {
      setDateFilter(undefined);
    }
  }, [fullDateFilter, dateFilter]);

  return (
    <>
      <TableWrapper
        searchComponent={
          <SearchInput
            value={filter}
            onChange={handleFilterChange}
            placeholder={t('sharingRequests.table.filter')}
          />
        }
        quickFilters={
          <Flex
            gap={'16px'}
            flexDir={{ base: 'column', md: 'column', lg: 'row' }}
          >
            <Select
              minWidth={'fit-content'}
              placeholder={t(`sharingRequests.table.columns.status`)}
              textColor={'gray.500'}
              onChange={handleStatusFilterChange}
              value={statusFilter ?? ''}
            >
              {certificateShareRequestStatusTypes.map((status, key) => (
                <option value={status} key={key}>
                  {t(`sharingRequests.status.${status}`)}
                </option>
              ))}
            </Select>

            <DateCalendar
              placeholder={t(`sharingRequests.table.columns.createdAt`)}
              onChange={setFullDateFilter}
              maxDate={new Date()}
              value={fullDateFilter}
            />

            <Button
              variant={'link'}
              minWidth={'fit-content'}
              size={'sm'}
              onClick={handleFiltersReset}
            >
              {t(`common.resetFilters`)}
            </Button>
          </Flex>
        }
      >
        <Table
          tableInstance={tableInstance as TableInstance<object>}
          isLoading={isLoading}
          emptyStateContent={{
            title: t('sharingRequests.table.emptyState.title'),
            description: t('sharingRequests.table.emptyState.description'),
          }}
        />
      </TableWrapper>
      <Box my="3">
        <Pagination
          currentPage={data?.paginationData.currentPage!}
          totalPageCount={data?.paginationData.totalPages!}
          previousButtonLabel={t('common.pagination.previous')}
          nextButtonLabel={t('common.pagination.next')}
          onPageChange={setPage}
        />
      </Box>
      {selectedShareRequest ? (
        <>
          <RejectModal
            isOpen={isOpenReject}
            onClose={onCloseReject}
            selectedShareRequest={selectedShareRequest}
            setSelectedShareRequest={setSelectedShareRequest}
            refetch={refetch}
          />
          <ApprovalModal
            isOpen={isOpenApprove}
            onClose={onCloseApprove}
            selectedShareRequest={selectedShareRequest}
            setSelectedShareRequest={setSelectedShareRequest}
            refetch={refetch}
          />
        </>
      ) : null}
    </>
  );
};
